import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { map, forEach } from 'lodash-es';

// IMPORTING STYLES & HELPERS
import './News.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  Row,
  Form,
  Select,
  Button,
  Input,
  InputNumber,
  Pagination,
  Empty,
  Spin,
  Tooltip,
  DatePicker,
} from 'antd';
import dayjs from 'dayjs';
import EventCard from './EventCard';
import CalendarView from './CalendarView';
import { toJS } from 'mobx';
import { afterDateRule, beforeDateRule } from 'utils/functions';
import NewsletterButton from 'components/Shared/Buttons/NewsletterButton';

const { Search } = Input;

const formLayout12x24 = { xxl: 12, xl: 24, lg: 24, sm: 24, xs: 24 };

@inject(
  'breadcrumbStore',
  'newsletterStore',
  'uiStore',
  'categoryStore',
  'clusterStore',
  'formatStore'
)
@observer
class Calendar extends Component {
  state = {
    filter: '',
    searcher: '',
    cluster: '',
    loading: false,
    totalPage: 0,
    minIndex: 0,
    maxIndex: 0,
    activeCollapseKey: [],
    finishedDataload: false,
    showFilter: false,

    startDate: undefined,
    endDate: undefined
  };
  formRef = React.createRef();

  componentDidMount = async () => {
    this.props.breadcrumbStore.setBreadcrumbs('calendar');
    let sessionFilter = window.sessionStorage.getItem('filterEvents');
    if (sessionFilter) {
      try {
        sessionFilter = JSON.parse(sessionFilter);
        if (sessionFilter.current) {
          this.props.newsletterStore.eventObj = sessionFilter;
        }
      } catch {
        // ignore error
        sessionFilter = null;
      }
    }
    let { pageSize, filter, fil } = this.props.newsletterStore.eventObj;

    if (filter || pageSize != 3) {
      this.onCollapseChange(['1']);
    }

    await this.fetchData();
    await this.props.categoryStore.getAllTyped(false);
    await this.props.formatStore.getAll();
    this.setState({
      cluster: await this.props.clusterStore.getSimpleClustersOnline(true),
    });

    if (fil?.categories) {
      this.formRef.current?.setFieldsValue({
        categories: toJS(fil.categories),
      });
    }
    if (fil?.clusters) {
      this.formRef.current?.setFieldsValue({
        clusters: toJS(fil.clusters),
      });
    }
    this.formRef.current?.setFieldsValue({
      minDate: fil.minDate,
      maxDate: fil.maxDate,
      pages: pageSize,
    });
  };

  fetchData = async () => {
    let { current, pageSize, filter, searcher, sorter, isCalendarView } =
      this.props.newsletterStore.eventObj;
    if (isCalendarView) {
      pageSize = 5000;
      current = 1;
      // we can not use total size because if the filter did not find any element in the previous search we would never get any results anymore
      // and we would never get the original amount of result with the first reset filter button pressed
      searcher = '';
    }
    this.setState({ loading: true });
    await this.props.newsletterStore.getAllEventsFiltered(
      current,
      pageSize,
      filter,
      searcher,
      sorter
    );
    let events = this.props.newsletterStore.entities.slice();
    this.setState({
      finishedDataload: true,
      totalPage: events.length / pageSize,
      minIndex: 0,
      maxIndex: pageSize,
      filter: filter,
      searcher: searcher,
      sorter: sorter,
      loading: false,
    });
    window.sessionStorage.setItem(
      'filterEvents',
      JSON.stringify(this.props.newsletterStore.eventObj)
    );

    return events;
  };

  getEvents = (formats = undefined) => {
    return (
      <Spin spinning={this.state.loading}>
        {this.props.newsletterStore.entities?.length > 0 ? (
          <div>
            {this.props.newsletterStore.entities?.map(
              (data, index) =>
                index >= this.state.minIndex &&
                index < this.state.maxIndex && (
                  <EventCard key={data.id} event={data} formats={formats} />
                )
            )}
          </div>
        ) : (
          <Empty description="Keine Events gefunden" />
        )}
      </Spin>
    );
  };

  getCategoriesSelect() {
    let categories = this.props.categoryStore.entities.slice();
    return (
      <Select
        className="input-100"
        placeholder="Alle"
        mode="multiple"
        allowClear
        optionFilterProp="children"
        maxTagCount="responsive"
        showArrow
      >
        {map(categories, (category) => (
          <Select.Option value={category.id} key={category.id}>
            {category.name}
          </Select.Option>
        ))}
      </Select>
    );
  }

  getClusterSelect() {
    return (
      <Select
        className="input-100"
        placeholder="Alle"
        mode="multiple"
        allowClear
        optionFilterProp="children"
        maxTagCount="responsive"
        showArrow
      >
        {map(this.state.cluster, (cluster) => (
          <Select.Option value={cluster.id} key={cluster.id}>
            {cluster.name}
          </Select.Option>
        ))}
      </Select>
    );
  }

  onFilter = (values) => {
    this.props.newsletterStore.eventObj.fil = values;
    this.props.newsletterStore.eventObj.filter = '';
    this.props.newsletterStore.eventObj.current = 1;

    if (values.categories?.length > 0) {
      forEach(values.categories, (x) => {
        this.props.newsletterStore.eventObj.filter += `&categoryIds=${x}`;
      });
    }
    if (values.clusters?.length > 0) {
      forEach(values.clusters, (x) => {
        this.props.newsletterStore.eventObj.filter += `&clusterIds=${x}`;
      });
    }

    if (!this.props.newsletterStore.eventObj.isCalendarView) {
      if (values.pages) {
        this.props.newsletterStore.eventObj.pageSize = values.pages;
      }
    }
    if (values.minDate) {
      this.props.newsletterStore.eventObj.filter += `&minDate=${dayjs(
        values.minDate
      ).format('YYYY-MM-DD')}`;
    }
    if (values.maxDate) {
      this.props.newsletterStore.eventObj.filter += `&maxDate=${dayjs(
        values.maxDate
      ).format('YYYY-MM-DD')}`;
    }
    this.fetchData();
  };

  onSearch = (value) => {
    this.props.newsletterStore.eventObj.searcher = '';
    this.props.newsletterStore.eventObj.current = 1;
    if (value) {
      this.props.newsletterStore.eventObj.searcher += `&title=${value}`;
    }
    this.fetchData();
  };
  onChangeSearchInput = (event) => {
    this.props.newsletterStore.eventObj.sear = event.target.value;
  };

  //reset filter values
  onResetFilters = () => {
    window.sessionStorage.removeItem('filterEvents');
    this.formRef.current?.resetFields();
    this.props.newsletterStore.eventObj.current = 1;
    this.props.newsletterStore.eventObj.pageSize = 3;
    this.props.newsletterStore.eventObj.filter = '';
    this.fetchData();
  };

  onSort = () => {
    this.props.newsletterStore.eventObj.sorter = '';
    this.props.newsletterStore.eventObj.isSortedDown =
      !this.props.newsletterStore.eventObj.isSortedDown;
    this.props.newsletterStore.eventObj.sorter += `&sortedDown=${this.props.newsletterStore.eventObj.isSortedDown}`;
    this.fetchData();
  };

  handleChange = async (page, pageSize) => {
    this.props.newsletterStore.eventObj.current = page;
    this.props.newsletterStore.eventObj.pageSize = pageSize;
    await this.fetchData();
  };

  onCollapseChange = (e) => {
    this.setState({ activeCollapseKey: e });
  };

  loadNewsletterSite = () => {
    this.props.uiStore.navigateTo('/clusterplattform-oesterreich');
  };

  switchView = () => {
    this.props.newsletterStore.eventObj.isCalendarView =
      !this.props.newsletterStore.eventObj.isCalendarView;
    this.fetchData();
  };

  render() {
    let formats = this.props.formatStore.entities.slice();

    return (
      <div className="home">
        <div className="header">
          <h1 className="title">
            Veranstaltungskalender{' '}
            <FontAwesomeIcon icon={['far', 'calendar-alt']} className="header-icon" />
          </h1>
          <div className="reg-btn">
            <NewsletterButton />
            <div className="action-btns">
              <Button
                type="ghost"
                className="btn-with-icon secondary-button"
                onClick={this.switchView}
              >
                {this.props.newsletterStore.eventObj.isCalendarView ? (
                  <>
                    Listenansicht{' '}
                    <FontAwesomeIcon icon={['far', 'list']} className="btn-icon" />
                  </>
                ) : (
                  <>
                    Kalenderansicht{' '}
                    <FontAwesomeIcon
                      icon={['fas', 'calendar-alt']}
                      className="btn-icon"
                    />
                  </>
                )}
              </Button>
              <Button type="primary" onClick={this.loadNewsletterSite}>
                Zu den Newsbeiträgen{' '}
                <FontAwesomeIcon icon={['fas', 'newspaper']} className="btn-icon" />
              </Button>
            </div>
          </div>
        </div>

        {this.props.newsletterStore.eventObj.isCalendarView ? (
          <>
            {this.state.finishedDataload ? (
              <CalendarView
                /* data={this.props.newsletterStore.entities} */
                clusterFilter={this.getClusterSelect()}
                categoriesFilter={this.getCategoriesSelect()}
              ></CalendarView>
            ) : null}
          </>
        ) : (
          <>
            <div className="search-sorter">
              <div className="search">
                <Search
                  value={this.props.newsletterStore.eventObj.sear}
                  placeholder="Veranstaltungstitel suchen"
                  onSearch={this.onSearch}
                  onChange={this.onChangeSearchInput}
                  allowClear
                ></Search>
              </div>
              <div className="sorting">
                <Tooltip
                  title={
                    this.props.newsletterStore.eventObj.isSortedDown
                      ? 'Aktuell: Neueste Beiträge zuerst'
                      : 'Aktuell: Älteste Beiträge zuerst'
                  }
                >
                  <Button onClick={this.onSort} type="primary" className="btn-with-icon">
                    Sortierung
                    <FontAwesomeIcon
                      icon={
                        this.props.newsletterStore.eventObj.isSortedDown
                          ? ['fal', 'sort-circle-down']
                          : ['fal', 'sort-circle-up']
                      }
                    />
                  </Button>
                </Tooltip>
                <Button
                  type="primary"
                  className="btn-with-icon left15"
                  onClick={() => this.setState({ showFilter: !this.state.showFilter })}
                >
                  Filter auswählen
                  <FontAwesomeIcon
                    icon={
                      this.state.showFilter
                        ? ['fas', 'angle-double-up']
                        : ['fas', 'angle-double-down']
                    }
                  />
                </Button>
              </div>
            </div>
            {this.state.showFilter && (
              <div className="flex filter-panel-btn">
                <div className="filter">
                  <Form
                    ref={this.formRef}
                    scrollToFirstError={true}
                    name="filter-form"
                    onFinish={this.onFilter}
                    labelAlign="left"
                    layout="vertical"
                  >
                    <Row gutter={80}>
                      <Col {...formLayout12x24}>
                        <Form.Item
                          label={
                            <>
                              {'Kategorie(n)'}
                              <FontAwesomeIcon icon={['fas', 'tags']} />
                            </>
                          }
                          name="categories"
                        >
                          {this.getCategoriesSelect()}
                        </Form.Item>
                      </Col>
                      <Col {...formLayout12x24}>
                        <Form.Item
                          label={
                            <>
                              Cluster
                              <FontAwesomeIcon icon={['fas', 'thumbtack']} />
                            </>
                          }
                          name="clusters"
                        >
                          {this.getClusterSelect()}
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={80}>
                      <Col {...formLayout12x24}>
                        <Row className="filter-date-row">
                          <Form.Item
                            label={
                              <>
                                Datum von
                                <FontAwesomeIcon icon={['fas', 'calendar-day']} />
                              </>
                            }
                            name="minDate"
                            dependencies={['maxDate']}
                            rules={[
                              {
                                validator: (rule, validator) =>
                                  beforeDateRule(rule, validator, this.state.endDate),
                                message:
                                  'Das Startdatum darf nicht nach dem Enddatum liegen.',
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={(value) => this.setState({ startDate: value })}
                              className="input-100"
                              format={'DD.MM.YYYY'}
                            />
                          </Form.Item>
                          <Form.Item
                            label={
                              <>
                                Datum bis
                                <FontAwesomeIcon icon={['fas', 'calendar-day']} />
                              </>
                            }
                            name="maxDate"
                            dependencies={['minDate']}
                            rules={[
                              {
                                validator: (rule, validator) =>
                                  afterDateRule(rule, validator, this.state.startDate),
                                message:
                                  'Das Enddatum darf nicht vor dem Startdatum liegen.',
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={(value) => this.setState({ endDate: value })}
                              className="input-100"
                              format={'DD.MM.YYYY'}
                            />
                          </Form.Item>
                        </Row>
                      </Col>
                      {this.props.newsletterStore.eventObj.isCalendarView ? (
                        <></>
                      ) : (
                        <Col {...formLayout12x24}>
                          <Form.Item
                            label={
                              <>
                                Beiträge pro Seite
                                <FontAwesomeIcon icon={['fas', 'clone']} />
                              </>
                            }
                            name="pages"
                          >
                            <InputNumber
                              className="input-100"
                              min={1}
                              max={10}
                              defaultValue={3}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </Form>
                </div>
                <div className="filter-btns">
                  <Button onClick={this.onResetFilters}>
                    Filter zurücksetzen
                    <FontAwesomeIcon icon={['far', 'times']} className="icon" />
                  </Button>
                  <Button
                    type="primary"
                    className="margin-right-16"
                    onClick={() => this.formRef.current?.submit()}
                  >
                    Filter anwenden
                    <FontAwesomeIcon icon={['far', 'filter']} />
                  </Button>
                </div>
              </div>
            )}

            <div>{this.getEvents(formats)}</div>
            {this.props.newsletterStore.entities?.length > 0 ? (
              <>
                <div className="reg-btn-below">
                  <NewsletterButton />
                </div>
                <Pagination
                  defaultCurrent={1}
                  pageSize={this.props.newsletterStore.eventObj.pageSize}
                  current={this.props.newsletterStore.eventObj.current}
                  total={this.props.newsletterStore.total}
                  onChange={this.handleChange}
                />
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    );
  }
}

export default Calendar;
