import React from 'react';
import { Collapse, Row, Col } from 'antd';
import { newsletterType } from 'App/globalVar';
import DOMPurify from 'dompurify';

export default class NewsletterContent extends React.Component {
  render() {
    let newsLetter = this.props.newsletter;

    return (
      <div className="body-text">
        <Row>
          <h2 className="entry-title">{newsLetter.title}</h2>
        </Row>
        {this.props.justTeaser ? (
          <Row>
            <p
              className="text-ellipsis"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsLetter.content?.teaser) }}
            ></p>
          </Row>
        ) : (
          <Row>
            <p
              className="text-ellipsis"
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsLetter.content?.teaser) }}
            ></p>
            {newsLetter.type != newsletterType.teaser && (
              <>
                <p
                  className="text-ellipsis"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(newsLetter.content?.body) }}
                ></p>
              </>
            )}
          </Row>
        )}
      </div>
    );
  }
}
