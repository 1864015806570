import React from 'react';
import {
  Upload,
  Form,
  Button,
  Row,
  Image,
  Modal,
  Input,
  Radio,
  Skeleton,
  Col,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { httpsPrefixRegex, maxLength, urlRegex } from 'App/globalVar';

class GalleryComponent extends React.Component {
  modalFormRef = React.createRef();

  state = {
    fileList: [],
    previewFile: undefined,
    editVisible: false,
    loading: true,
    previewVisible: false,

    isLink: false
  };

  onModalFormFinish = (formValues) => {
    let files = this.state.fileList;
    let previewFile = this.state.previewFile;

    previewFile = { ...previewFile, ...formValues };

    // with this approach the order of the images changes when editing them; is this wanted behaviour?
    files = files.filter(
      (x) => !(x.uid == previewFile.uid || (x.id && x.id == previewFile.id))
    );
    files.push(previewFile);

    this.setState({ fileList: files });

    this.cancelPreview();
  };

  getBase64 = async (originFileObj) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(originFileObj);

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = (error) => reject(error);
    });
  };

  editFileDetails = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState(
      {
        editVisible: true,
        previewFile: file,
      },
      () => this.modalFormRef.current?.setFieldsValue(file)
    );
  };

  onPreview = async (file) => {
    this.setState({
      previewVisible: true,
      previewFile: file,
    });
  };

  removeFile = (file) => {
    this.setState({ fileList: this.state.fileList.filter((x) => x.uid != file.uid) });
  };

  getModalFileName = (file) => {
    if (!file) return '';
    return (
      file.imageTitle || file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  onChange = (fileListObject) => {
    // adding files to the list should be done in the upload props
    if (fileListObject.fileList.length <= this.state.fileList.length) {
      this.setState({ fileList: fileListObject.fileList });
    }
  };

  cancelPreview = () => {
    this.setState(
      { editVisible: false, previewFile: undefined, previewVisible: false, isLink: false },
      () =>
        // resetting the form or giving the form an empty object won't work
        this.modalFormRef.current.setFieldsValue({
          imageTitle: '',
          altText: '',
          source: '',
          isSourceLink: false,
          copyright: '',
        })
    );
  };
  componentDidMount() {
    if (Array.isArray(this.props.images)) {
      this.setState({ fileList: this.props.images });
    }
    this.setState({ loading: false });
  }

  render() {
    if (this.state.loading) return <Skeleton loading={true} />;
    let isViewMode = this.props.isViewMode;

    const galleryImageUploadProps = {
      beforeUpload: (file) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          let data = e.target.result;

          // preview gets used for dto
          let appFile = {
            uid: file.uid,
            preview: data,
            thumbUrl: data,
            status: 'success',
            name: file.name,
            type: file.type,
          };
          this.editFileDetails(appFile);
        };
        reader.readAsDataURL(file);

        return false;
      },
      disabled: true,
    };
    return (
      <>
        <h2 className="section-header">Bildergalerie erstellen</h2>
        <Upload
          accept="image/png, image/jpeg"
          {...galleryImageUploadProps}
          listType="picture-card"
          fileList={this.state.fileList}
          onPreview={this.onPreview}
          onChange={this.onChange}
          disabled={isViewMode}
          showUploadList={{
            showRemoveIcon: false,
          }}
        >
          {isViewMode || this.state.fileList?.length >= 5 ? null : (
            <div>
              <PlusOutlined />
              <div
                style={{
                  marginTop: 8,
                }}
              >
                Hochladen
              </div>
            </div>
          )}
        </Upload>
        <div className="buttonbar-container">
          {this.state.fileList.map((x) => (
            <div className="button-container">
              <Button
                onClick={() => this.editFileDetails(x)}
                className="buttonbar-button"
              >
                <FontAwesomeIcon icon={['fas', 'pencil']} />
              </Button>
              <Button onClick={() => this.removeFile(x)} className="buttonbar-button">
                <FontAwesomeIcon icon={['fas', 'trash-alt']} />
              </Button>
            </div>
          ))}
        </div>
        <Modal
          visible={this.state.editVisible}
          title={this.getModalFileName(this.state.previewFile)}
          footer={
            <div className="btns-gallery-modal">
              <Button
                type="secondary"
                className="btn-with-icon blue-medium"
                onClick={this.cancelPreview}
              >
                Abbrechen
                <FontAwesomeIcon icon={['far', 'times']} />
              </Button>
              <Button
                type="primary"
                className="btn-with-icon yellow"
                onClick={() => this.modalFormRef.current?.submit()}
              >
                Speichern
                <FontAwesomeIcon icon={['fas', 'check']} />
              </Button>
            </div>
          }
          onCancel={this.cancelPreview}
        >
          <Row>
            <Col span={24}>
              <Image
                src={this.state.previewFile?.url || this.state.previewFile?.preview}
              />
            </Col>
          </Row>
          <Form
            autoComplete="off"
            className="form-gallery-modal"
            ref={this.modalFormRef}
            name="modalForm"
            scrollToFirstError={true}
            layout="vertical"
            initialValues={this.state.previewFile}
            onFinish={this.onModalFormFinish}
          >
            <Row>
              <Col span={24}>
                <Form.Item
                  name="imageTitle"
                  label="Titel des Bildes"
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                    {
                      max: maxLength.Small,
                      message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Small} Zeichen!`,
                    },
                  ]}
                >
                  <Input disabled={isViewMode} placeholder="Titel des Bildes" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="altText"
                  label="Alternativtext"
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                    {
                      max: maxLength.Small,
                      message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Small} Zeichen!`,
                    },
                  ]}
                >
                  <Input disabled={isViewMode} placeholder="Alternativtext des Bildes" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="copyright"
                  label="Copyright"
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                    {
                      max: maxLength.Medium,
                      message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                    },
                  ]}
                >
                  <Input disabled={isViewMode} placeholder="Copyright für das Bild" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name="source"
                  label="Quelle"
                  dependencies={['isSourceLink']}
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                    {
                      max: maxLength.Medium,
                      message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                    },
                  ]
                  .concat(
                    this.state.isLink && [
                      {
                        pattern: urlRegex,
                        message: 'Geben Sie bitte eine valide Url an.',
                      },
                      {
                        pattern: httpsPrefixRegex,
                        message:
                          'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                      },
                    ]
                  )
                  }
                >
                  <Input disabled={isViewMode} placeholder="Quelle des Bildes angeben" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Art der Quelle"
                  name="isSourceLink"
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                  ]}
                >
                  <Radio.Group disabled={isViewMode} value={this.state.isLink} onChange={(e) => this.setState({isLink: e.target.value})}>
                    <Radio value={true}>Link</Radio>
                    <Radio value={false}>Text</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          visible={this.state.previewVisible}
          title={this.getModalFileName(this.state.previewFile)}
          footer={null}
          onCancel={this.cancelPreview}
        >
          <img
            src={this.state.previewFile?.preview}
            alt={this.state.previewFile?.altText}
            style={{ width: '100%' }}
          />
        </Modal>
      </>
    );
  }
}

export default GalleryComponent;
