import React from 'react';

import { Button, Row, Skeleton } from 'antd';
import { dateTimeFormat } from '../../../../utils/dayjsLocale';
import dayjs from 'dayjs';
import { find } from 'lodash-es';
import { inject } from 'mobx-react';
import { getAriaLabelDateStartEnd } from 'utils/aria-date';
import { createEmptyScreenreaderSpan } from 'utils/functions';

@inject('uiStore', 'formatStore')
export default class EventContentDates extends React.Component {
  getFormat(formatId) {
    let format = find(this.props.formatStore.entities, (x) => x.id === formatId);
    return format?.name;
  }

  async componentDidMount() {
    await this.props.formatStore.getAll();
    this.getFormat(this.props.event.formatId);
  }

  render() {
    if (this.props.uiStore.loading) return <Skeleton active />;

    let event = this.props.event;

    return (
      <>
        {this.props.addStyles && (
          <div role="heading" aria-level="3" className="section-title">
            Orts- und Zeitdaten
          </div>
        )}
        <div className="body-dates">
          <Row>
            <div className="region">
              <b>Ort: </b>
              <div className="location-text">{event?.location ?? 'Keine Angabe'}</div>
            </div>
          </Row>
          <Row>
            <div className="format">
              <b>Format: </b>
              {this.getFormat(event?.formatId) ?? 'Keine Angabe'}
            </div>
          </Row>
          <Row>
            <div className="date">
              <span aria-hidden="true">
                <b>Datum: </b>
                {event?.starting && dayjs(event?.starting).format(dateTimeFormat)}{' '}
                {event?.ending && '- ' + dayjs(event?.ending).format(dateTimeFormat)}{' '}
              </span>
              {createEmptyScreenreaderSpan(
                getAriaLabelDateStartEnd(event?.starting, event?.ending),
                '',
                'note'
              )}
            </div>
          </Row>
        </div>
      </>
    );
  }
}
