import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import EventEntry from './ContentParts/EventEntry';
import { onEnterKey } from 'utils/functions';

@inject('uiStore', 'formatStore')
@observer
export default class EventCard extends Component {
  async componentDidMount() {
    await this.props.formatStore.getAll();
  }

  navigateBetweenPosts = () => {
    this.props.uiStore.navigateBetweenPosts(`/calendar/${this.props.event.id}`);
  };

  render() {
    return (
        <EventEntry
          onClick={this.navigateBetweenPosts}
          onKeyDown={(e) => onEnterKey(e, this.navigateBetweenPosts)}
          isCard={true}
          hoverable={true}
          event={this.props.event}
          addIdToLink={true}
          showTeaser={true}
          showNavigator={false}
          addStyles={false}
        />
    );
  }
}
