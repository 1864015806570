import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class StateStore extends BaseEntitiesStore {
  constructor() {
    super('State', 'StateStore');
    if (!isProduction) {
      window[`StateStore`] = this;
    }
  }
}

export default new StateStore();
