import React, { Component, createRef } from 'react';
import { observer, inject } from 'mobx-react';
import { map, toInteger } from 'lodash-es';
import { toJS } from 'mobx';

// importing styles and helpers
import './Cluster.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// importing components
import {
  Button,
  Table,
  Tooltip,
  Collapse,
  Form,
  Row,
  Col,
  Input,
  Select,
  Tag,
  Skeleton,
  Alert,
} from 'antd';

import ClusterModal from './ClusterModal';
import ImportExcel from '../Modals/ImportExcel';
import {
  accessibleTableTitle,
  capitalizeFirstLetter,
  openNotificationWithIcon,
  reverseMap,
  getClusterStateTagColour,
} from '../../utils/functions';
import { clusterState, translationMap } from 'App/globalVar';
import dayjs from 'dayjs';
import { dateTimeFormat } from 'utils/dayjsLocale';
import ClusterChangelogs from 'components/Modals/Changelogs/ClusterChangelogs';
import MessageModal from './MessageModal';

const formLayout12x24 = { xxl: 12, xl: 24, lg: 24, sm: 24, xs: 24 };

@inject(
  'breadcrumbStore',
  'clusterStore',
  'industryStore',
  'stateStore',
  'userStore',
  'uiStore',
  'clusterHolderStore',
  'clusterClassStore',
  'emailTemplateStore'
)
@observer
export default class ClusterOverview extends Component {
  state = {
    showClusterAnlegenModal: false,
    showImportModal: false,
    entity: {},
    activeCollapseKey: [],
    filter: undefined,
    loading: true,
    reverseClusterStateMap: reverseMap(clusterState),

    showChangelogModal: false,
    changelogClusterId: -1,

    showMessageModal: false,
    selectedClusters: [],
    messageModalData: [],
    showAlertInactiveUsers: true,
    currentClusterInfo: {},
    alertDescription: [],
  };

  formRef = createRef();

  async componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumbs('clusterverwaltung');
    this.props.uiStore.setReturnLink('clusterForm', '/clusterverwaltung');
    this.onCollapseChange(['0']);
    await Promise.all([
      this.props.industryStore.getAll(),
      this.props.stateStore.getAll(),
      this.props.userStore.getUsersList(),
      this.props.clusterHolderStore.getAll(),
      this.props.clusterClassStore.getAll(),
      this.props.emailTemplateStore.getAll(),
    ]);

    await this.fetchTableData();
    this.setState({ loading: false });
  }

  toggleClusterAnlegenModal = async (showModal, isEdit, resetEntity) => {
    if(showModal && !isEdit){
      this.props.uiStore.navigateTo(`/cluster-form`);
      return;
    }

    if(resetEntity){
      this.setState({entity: {}});
    }
    this.setState({
      showClusterAnlegenModal: showModal,
    });

    await this.fetchTableData(this.state.filter);
  };

  toggleImportModal = (showModal) => {
    this.setState({
      showImportModal: showModal,
    });
  };

  //fetch data for table
  fetchTableData = async (filter = undefined) => {
    filter += `&displayHidden=false`;
    await this.props.clusterStore.getAllFiltered(filter);
  };
  
  //for filter panel
  onCollapseChange = (e) => {
    this.setState({ activeCollapseKey: e });
  };

  //reset filter values
  onResetFilters = () => {
    this.formRef.current?.resetFields();
    this.setState({ filter: undefined });
    this.fetchTableData();
  };

  //get table filtered
  onFilter = (values) => {
    let filter = '';
    if (values.name) {
      filter += `&name=${encodeURIComponent(values.name)}`;
    }
    if (values.clusterClass) {
      filter += `&clusterClass=${encodeURIComponent(values.clusterClass)}`;
    }
    if (values.clusterState) {
      filter += `&clusterState=${encodeURIComponent(toInteger(values.clusterState))}`;
    }

    this.setState({ filter: filter });
    this.fetchTableData(filter);
  };

  openChangelogModal = async (clusterId) => {
    await this.props.clusterStore.getChangelogsForCluster(clusterId);
    this.setState({ showChangelogModal: true, changelogClusterId: clusterId });
  };

  closeChangelogModal = () => {
    this.setState({ showChangelogModal: false, changelogClusterId: -1 });
  };

  openMessageModal = (data) => {
    this.setState({ showMessageModal: true, messageModalData: data });
  };

  closeMessageModal = () => {
    this.props.clusterStore.entity = {};
    this.setState({ showMessageModal: false });
  };

  checkClusterManagersValid = async (cluster) => {
    let mainContact = cluster.mainContact;
    let contactPersons = cluster.contactPersons;
    let joinedContacts = contactPersons;
    joinedContacts.push(mainContact);

    let inactiveUsers = await this.props.userStore.getUsersWithInactivAccount();
    let invalidUsers = [];

    for(let inactiveUser of inactiveUsers){
      for(let joinedContact of joinedContacts){
        if(inactiveUser.id === joinedContact.id){
          invalidUsers.push(joinedContact);
        }
      }
    }

    invalidUsers = invalidUsers?.filter((value, index, array) => array.indexOf(value) === index);
    let res = [];
    map(invalidUsers, x => res.push(<>{x.email} <br /></>));
    this.setState({ alertDescription: res })
  }

  render() {
    if (this.state.loading) return <Skeleton loading={this.state.loading} />;
    const data = this.props.clusterStore.entities.slice();

    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedClusters: selectedRows });
      },
    };

    const columns = [
      {
        title: accessibleTableTitle('Clustername'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: accessibleTableTitle('Klasse'),
        dataIndex: 'clusterClassId',
        key: 'clusterClassId',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
        render: (clusterClassId) => {
          return this.props.clusterClassStore.entities.find((x) => x.id == clusterClassId)
            ?.name;
        },
      },
      {
        title: accessibleTableTitle('Status'),
        dataIndex: 'clusterState',
        key: 'clusterState',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (clusterStateValue) => {
          return (
            <div className="table-tag" key={clusterStateValue}>
              <Tag color={getClusterStateTagColour(clusterStateValue)}>
                {capitalizeFirstLetter(
                  translationMap[this.state.reverseClusterStateMap[clusterStateValue]]
                )}
              </Tag>
            </div> 
          );
        },
      },
      {
        title: accessibleTableTitle('Letzte Statusänderung'),
        key: 'clusterStateLastChanged',
        dataIndex: 'clusterStateLastChanged',
        sorter: (a, b) =>
          a.clusterStateLastChanged?.localeCompare(b.clusterStateLastChanged),
        render: (utcTime) => {
          return !!utcTime ? dayjs(utcTime).format(dateTimeFormat) : '';
        },
      },
      {
        title: 'ECCP-Profil',
        key: 'eccp',
        dataIndex: 'eccp',
        render: (eccp) => {
          return eccp ? 'Ja' : 'Fehlt';
        },
      },
      {
        title: 'Item ansehen',
        key: 'seeProfile',
        render: (item) => {
          return (
            <Button
              onClick={() =>this.props.uiStore.navigateTo(`/clusterverwaltung/${item.id}`)}
            >
              Item ansehen
            </Button>
          );
        },
      },
      {
        title: 'Nachricht senden',
        key: 'sendMessage',
        render: (item) => {
          return (
            <Button onClick={() => this.openMessageModal(item)}>Nachricht senden</Button>
          );
        },
      },
      {
        title: 'Log-File sehen',
        key: 'seeLogFile',
        render: (data) => {
          return (
            <Button onClick={async () => await this.openChangelogModal(data.id)}>
              Log-File sehen
            </Button>
          );
        },
      },
      {
        title: 'Aktionsbuttons',
        align: 'center',
        render: (_field) => (
          <div className="table-actions">
            <Tooltip title="Bearbeiten">
              <Button
                className="table-action-btn yellow"
                aria-label="Bearbeiten"
                onClick={async () => {
                  await this.props.clusterStore.get(_field.id);
                  let entity = toJS(this.props.clusterStore.entity);
                  this.setState({
                    entity: entity,
                  });
                  this.toggleClusterAnlegenModal(true, true, false);
                }}
              >
                <FontAwesomeIcon icon={['far', 'pencil']} />
              </Button>
            </Tooltip>

            <Tooltip title={'Archivieren'}>
              <Button
                className="table-action-btn teal"
                aria-label="Archivieren"
                onClick={async () => {
                  if (
                    confirm(`Wollen Sie den Cluster: "${_field.name}" wirklich archivieren?`)
                  ) {
                    try {
                      await this.props.clusterStore.archiveCluster(_field.id, true);
                      this.forceUpdate();
                      openNotificationWithIcon(
                        'success',
                        'Cluster wurde erfolgreich archiviert!'
                      );
                    } catch {
                      openNotificationWithIcon(
                        'error',
                        'Es ist ein unerwarteter Fehler beim Archivieren aufgetreten'
                      );
                    }
                  }
                }}
              >
                <FontAwesomeIcon icon={['fas', 'inbox-in']} />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ];

    const nrSelectedClusters = this.state.selectedClusters.length;
    return (
      <div className="home">
        <div className="header">
          <h1 className="title">
            Admin-Übersicht{' '}
            <FontAwesomeIcon icon={['fas', 'chart-pie']} className="header-icon" />
          </h1>
          <div className="action-btns">
            {nrSelectedClusters > 0 && (
              <Button
                type="ghost"
                className="btn-with-icon"
                onClick={() => this.openMessageModal(this.state.selectedClusters)}
              >
                Aktualisierungsanfrage senden ({nrSelectedClusters})
                <FontAwesomeIcon icon={['fas', 'paper-plane']} />
              </Button>
            )}
            <Button
              type="primary"
              className="btn-with-icon yellow"
              onClick={async () => this.toggleClusterAnlegenModal(true, false, true)}
            >
              Cluster anlegen{' '}
              <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon" />
            </Button>
          </div>
        </div>

        {this.state.showAlertInactiveUsers && (
          <Alert
            style={{ marginBottom: '20px' }}
            message="Der Cluster kann erst in Freigabe gegeben werden, sobald alle angegebenen E-Mail-Adressen bestätigt wurden."
            description={this.state.alertDescription}
            type="error"
            closable
          />
        )}

        <Collapse
          defaultActiveKey={['']}
          ghost
          className="filter-panel"
          activeKey={this.state.activeCollapseKey}
          onChange={this.onCollapseChange}
        >
          <Collapse.Panel header={<div>Filter</div>} key="1">
            <div className="flex">
              <div className="filter">
                <Form
                  ref={this.formRef}
                  scrollToFirstError={true}
                  name="basic"
                  onFinish={this.onFilter}
                  layout="vertical"
                  labelAlign="left"
                >
                  <Row gutter={80}>
                    <Col {...formLayout12x24}>
                      <Form.Item label="Clustername" name="name">
                        <Input placeholder="Filter nach Clustername..." allowClear />
                      </Form.Item>
                    </Col>
                    <Col {...formLayout12x24}>
                      <Row gutter={20}>
                        <Col {...formLayout12x24}>
                          <Form.Item label="Klasse" name="clusterClass">
                            <Select placeholder="Filter nach Klassen..." allowClear>
                              {map(this.props.clusterClassStore.entities.slice(), (x) => (
                                <Select.Option key={x.id} value={x.id}>
                                  {x.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col {...formLayout12x24}>
                          <Form.Item label="Status" name="clusterState">
                            <Select placeholder="Filter nach Status..." allowClear>
                              {map(this.state.reverseClusterStateMap, (x, id) => (
                                <Select.Option key={id} value={id}>
                                  {capitalizeFirstLetter(
                                    translationMap[this.state.reverseClusterStateMap[id]]
                                  )}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="filter-btns">
                <Button onClick={this.onResetFilters}>
                  Filter zurücksetzen <FontAwesomeIcon icon={['fas', 'times']} />
                </Button>
                <Button
                  type="primary"
                  className="margin-right-16"
                  onClick={() => this.formRef.current?.submit()}
                >
                  Filter anwenden
                  <FontAwesomeIcon icon={['far', 'filter']} />
                </Button>
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>

        <div className="home-content">
          <div className="table-container">
            <Table
              className="ant-table-scroll"
              rowSelection={{ type: 'check', ...rowSelection }}
              loading={this.state.loading}
              dataSource={data}
              columns={columns}
              rowKey="id"
              pagination={false}
              scroll={{ x: true }}
            />
          </div>
        </div>
        {this.state.showClusterAnlegenModal && (
          <ClusterModal
            visible={this.state.showClusterAnlegenModal}
            toggleClusterAnlegenModal={this.toggleClusterAnlegenModal}
            industries={this.props.industryStore.entities.slice()}
            states={this.props.stateStore.entities.slice()}
            entity={this.state.entity}
            clusterHolders={this.props.clusterHolderStore.entities.slice()}
            usersList={this.props.userStore.usersList.slice()}
            clusterClasses={this.props.clusterClassStore.entities.slice()}
            setShowAlertInactiveUsers={(val) => this.setState({ showAlertInactiveUsers: val })}
            setCurrentClusterInfo={(val) => this.checkClusterManagersValid(val)}
          />
        )}
        {this.state.showChangelogModal && (
          <ClusterChangelogs
            visible={this.state.showChangelogModal}
            onCancel={this.closeChangelogModal}
            data={this.props.clusterStore.changelogs}
            industries={this.props.industryStore.entities.slice()}
            holders={this.props.clusterHolderStore.entities.slice()}
            clusterId={this.state.changelogClusterId}
          />
        )}
        {this.state.showMessageModal && (
          <MessageModal
            visible={this.state.showMessageModal}
            onCancel={this.closeMessageModal}
            data={this.state.messageModalData}
            clusterStore={this.props.clusterStore}
            emailTemplates={this.props.emailTemplateStore.entities.slice()}
          />
        )}
        <ImportExcel
          visible={this.state.showImportModal}
          toggleImportModal={this.toggleImportModal}
        />
      </div>
    );
  }
}
