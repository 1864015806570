import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

// IMPORTING STYLES & HELPERS
import './News.scss';
import NewsletterEntry from './ContentParts/NewsletterEntry';
import { onEnterKey } from 'utils/functions';

@inject('uiStore')
@observer
export default class NewsletterCard extends Component {
  navigateBetweenPosts = () => {
    this.props.uiStore.navigateBetweenPosts(
      `/clusterplattform-oesterreich/${this.props.newsLetter.id}`
    );
  };

  render() {
    return (
        <NewsletterEntry
          onClick={this.navigateBetweenPosts}
          onKeyDown={(e) => onEnterKey(e, this.navigateBetweenPosts)}
          isCard={true}
          hoverable={true}
          newsletter={this.props.newsLetter}
          addIdToLink={true}
          displayGallery={false}
          displayThumbnailTitle={false}
          showTeaser={true}
          showNavigator={false}
          addStyles={false}
          justTeaser={true}
        />
    );
  }
}
