import React from 'react';
import { withRouter } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { inject, observer } from 'mobx-react';

@inject('breadcrumbStore', 'uiStore')
@observer
class CustomBreadcrumbs extends React.Component {

  onNavigate = (e, key) => {
    e.preventDefault();
    this.props.history.push(key);
    this.props.uiStore.resetNewsletterNavigationStack();
  };

  render() {
    let breadcrumbs = this.props.breadcrumbStore.breadcrumbs?.map((breadcrumb) => {
      return (
        <Breadcrumb.Item
          key={breadcrumb.path}
          onClick={(e) => this.onNavigate(e, breadcrumb.path)}
          href={breadcrumb.path}
        >
          {breadcrumb.name}
        </Breadcrumb.Item>
      );
    });

    return (
      <div className="breadcrumbs">
        <Breadcrumb separator=">">{breadcrumbs}</Breadcrumb>
      </div>
    );
  }
}

const RoutedBreadcrumbs = withRouter(CustomBreadcrumbs);
export { RoutedBreadcrumbs as CustomBreadcrumbs };
