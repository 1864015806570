import { observable } from 'mobx';
import axios from 'axios';

import { isProduction, apiUrl } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';
import { getQueryStringFromObject } from 'utils/functions';
import { remove } from 'lodash-es';

class ClusterStore extends BaseEntitiesStore {
  constructor() {
    super('Cluster', 'ClusterStore');
    if (!isProduction) {
      window[`ClusterStore`] = this;
    }
  }

  archiveUrl = `${apiUrl}/cluster/archive`;

  @observable
  clusterData = [];

  @observable
  changelogs = [];

  @observable
  currentNewClusterDraftsTable;

  async put(id, entity) {
    return (await axios.put(`${this.path}/${id}`, entity)).data;
  }

  async getSimpleClusters(displayHidden) {
    let query = '';
    if(displayHidden != undefined){
      query = `?displayHidden=${displayHidden}`;
    }
    return (await axios.get(apiUrl + '/Cluster/simple' + query)).data;
  }

  async getSimpleClustersOnline(displayHidden) {
    let query = '';
    if(displayHidden != undefined){
      query = `?displayHidden=${displayHidden}`;
    }
    return (await axios.get(apiUrl + '/Cluster/simple/online' + query)).data;
  }

  async getAllFiltered(filter = undefined) {
    let resp = await axios.get(`${this.path}/filtered?${filter ? filter : ''}`);
    this.setEntities(resp.data);
  }

  async getTableData(filter) {
    let urlFilter = '';
    if (filter) {
      urlFilter = `?${getQueryStringFromObject(filter)}`;
    }
    let resp = await axios.get(`${this.path}/tableData${urlFilter}`);
    return (this.clusterData = resp.data);
  }

  async createWithValidityCheck(values) {
    return (await axios.post(`${this.path}/withApproval`, values)).data;
  }

  async updateWithValidityCheck(id, values) {
    return (await axios.put(`${this.path}/${id}/withApproval`, values)).data;
  }

  async updateClusterDateByHash(hash) {
    return (await axios.put(`${this.path}/${hash}/date`)).data;
  }

  async updateClusterByHash(hash, values) {
    return (await axios.put(`${this.path}/${hash}/update`, values)).data;
  }
  async updateClusterById(id, values) {
    return (await axios.put(`${this.path}/${id}/update`, values)).data;
  }

  async getClusterByHash(hash) {
    return (await axios.get(`${this.path}/${hash}`)).data;
  }

  async getClusterById(id) {
    return (await axios.get(`${this.path}/${id}`)).data;
  }

  async getApprovalClustersFilteredSorted(filter, sorter) {
    return (this.entities = (await axios.get(`${this.path}/approval`)).data);
  }

  async sendClusterForApproval(clusterId) {
    return (await axios.put(`${this.path}/${clusterId}/approve`)).data;
  }

  async sendClusterForDraftReturn(clusterId, comment) {
    return (
      await axios.put(`${this.path}/${clusterId}/sendToDrafts`, {
        id: clusterId,
        comment: comment,
      })
    ).data;
  }

  async getChangelogsForCluster(clusterId) {
    return (this.changelogs = (
      await axios.get(`${this.path}/${clusterId}/changelogs`)
    ).data);
  }

  async createCommentChangelogForCluster(clusterId, comment) {
    let response = (
      await axios.post(`${this.path}/${clusterId}/changelogs/comment`, {
        id: clusterId,
        comment: comment,
      })
    ).data;
    this.changelogs.splice(0, 0, response);
  }

  async updateClusterManager(id, values) {
    return (await axios.put(`${this.path}/${id}/updateManager`, values)).data;
  }

  async sendManualEmailToCluster(clusterIds, subject, body) {
    let queryString = getQueryStringFromObject(clusterIds);
    await axios.post(`${this.path}/sendManualMessage?${queryString}`, {
      subject: subject,
      emailBody: body,
    });
  }

  async getArchivedClusters(filter){
    let queryString = "";
    if(!!filter){
      queryString = `?${getQueryStringFromObject(filter)}`;
    }
    return (this.entities = (await axios.get(`${this.archiveUrl}?${queryString}`)).data);
  }

  async archiveCluster(clusterId, archived){
    const res = (await axios.put(`${this.archiveUrl}/${clusterId}?archived=${archived}`));
    if(res.status === 200){
      remove(this.entities, x => x.id == clusterId);
    }
  }

  // this route does not check for archive state of cluster
  async getClusterForAdmin(clusterId){
    return (this.entity = (await axios.get(`${this.path}/admin/${clusterId}`)).data);
  }
}

export default new ClusterStore();
