import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, Tag , Button} from 'antd';
import { clusterState, translationMap } from 'App/globalVar';
import dayjs from 'dayjs';
import { find } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { dateTimeFormat } from 'utils/dayjsLocale';
import { accessibleTableTitle, capitalizeFirstLetter, getClusterStateTagColour, reverseMap } from 'utils/functions';
import ClusterChangelogs from 'components/Modals/Changelogs/ClusterChangelogs';
import MessageModal from './MessageModal';

@inject(
  'clusterStore',
  'breadcrumbStore',
  'industryStore',
  'stateStore',
  'nuts3Store',
  'userStore',
  'uiStore',
  'clusterHolderStore',
  'clusterClassStore',
  'uiStore',
  'emailTemplateStore'
)
@observer
class ApproverTable extends React.Component {
  state = {
    loading: true,
    reverseClusterStateMap: reverseMap(clusterState),
    showChangelogModal: false,
    changelogClusterId: -1,

    showMessageModal: false,
    messageModalData: []
  };

  columns = [
    {
      title: accessibleTableTitle('Clustername'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: accessibleTableTitle('Klasse'),
      dataIndex: 'clusterClassId',
      key: 'clusterClassId',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (clusterClassId) => {
        return find(this.props.clusterClassStore.entities, (x) => x.id === clusterClassId)
          ?.name;
      },
    },
    {
      title: accessibleTableTitle('Status'),
      dataIndex: 'clusterState',
      key: 'clusterState',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (clusterState) => {
        return (
          <div className="table-tag" key={clusterState}>
            <Tag color={getClusterStateTagColour(clusterState)}>
              {capitalizeFirstLetter(
                translationMap[this.state.reverseClusterStateMap[clusterState]]
              )}
            </Tag>
          </div>
        );
      },
    },
    {
      title: accessibleTableTitle('Letzte Statusänderung'),
      key: 'stateLastChange',
      dataIndex: 'clusterStateLastChanged',
      sorter: (a, b) => a.clusterStateLastChanged?.localeCompare(b.clusterStateLastChanged),
      render: (utcTime) => {
        return !!utcTime ? dayjs(utcTime).format(dateTimeFormat) : "";
      }
    },
    {
      title: 'ECCP-Profil',
      key: 'eccp',
      dataIndex: 'eccp',
      render: (eccp) => {
        return eccp ? 'Ja' : 'Fehlt';
      },
    },
    {
      title: 'Item ansehen und freigeben',
      key: 'seeProfile',
      render: (data) => (
        <Button
          onClick={() => {this.props.uiStore.navigateTo(`/clusterverwaltung/${data.id}`)}}
        >
          Item ansehen
        </Button>
      )
    },
    {
      title: 'Nachricht senden',
      key: 'sendMessage',
      render: (data) => {
        return <Button onClick={() => this.openMessageModal(data)}>Nachricht senden</Button>;
      },
    },
    {
      title: 'Log-File sehen',
      key: 'seeLogFile',
      render: (data) => {
        return (
          <Button onClick={async () => await this.openChangelogModal(data.id)}>
            Log-File sehen
          </Button>
        );
      },
    },
  ];

  openChangelogModal = async (clusterId) => {
    await this.props.clusterStore.getChangelogsForCluster(clusterId);
    this.setState({ showChangelogModal: true, changelogClusterId: clusterId });
  }

  closeChangelogModal = () => {
    this.setState({ showChangelogModal: false, changelogClusterId: -1 });
  }

  openMessageModal = (data) => {
    this.setState({ showMessageModal: true, messageModalData: data });
  }

  closeMessageModal = () => {
    this.props.clusterStore.entity = {};
    this.setState({showMessageModal: false});
  }

  async componentDidMount() {
    await Promise.all([
      this.props.industryStore.getAll(),
      this.props.nuts3Store.getAll(),
      this.props.stateStore.getAll(),
      this.props.userStore.getUsersList(),
      this.props.clusterHolderStore.getAll(),
      this.props.clusterClassStore.getAll(),
      this.props.emailTemplateStore.getAll(),
    ]);

    this.props.breadcrumbStore.setBreadcrumbs('approve-overview');
    this.props.uiStore.setReturnLink('clusterForm', '/approve-overview');
    await this.fetchTableData();
    this.setState({ loading: false });
  }

  fetchTableData = async () => {
    await this.props.clusterStore.getApprovalClustersFilteredSorted();
    this.forceUpdate();
  };

  render() {
    let dataSource = this.props.clusterStore.entities;

    return (
      <div className="home">
        <div className="header">
          <h1 className="title">
            Cluster-Freigaben <FontAwesomeIcon icon={['fas', 'badge-check']} />
          </h1>
        </div>
        <div className="home-content">
          <div className="table-container">
            <Table
              dataSource={dataSource}
              columns={this.columns}
              className="ant-table-scroll"
              loading={this.state.loading}
              rowKey={'id'}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>
        </div>
        {this.state.showChangelogModal && (
          <ClusterChangelogs
            visible={this.state.showChangelogModal}
            onCancel={this.closeChangelogModal}
            data={this.props.clusterStore.changelogs}
            industries={this.props.industryStore.entities.slice()}
            holders={this.props.clusterHolderStore.entities.slice()}
            clusterId={this.state.changelogClusterId}
          />
        )}
        {this.state.showMessageModal && (
          <MessageModal
            visible={this.state.showMessageModal}
            onCancel={this.closeMessageModal}
            data={this.state.messageModalData}
            clusterStore={this.props.clusterStore}
            emailTemplates={this.props.emailTemplateStore.entities.slice()}
          />
        )}
      </div>
    );
  }
}

export default ApproverTable;
