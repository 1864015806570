import React from 'react';
import { newsletterType, newsletterState, translationMap } from 'App/globalVar';
import { inject } from 'mobx-react';
import {
  reverseMap,
  capitalizeFirstLetter,
  openNotificationWithIcon,
  accessibleTableTitle,
  accessibleFilterIcon,
} from 'utils/functions';
import { Skeleton, Tag, Tooltip, Button, Tabs, Table } from 'antd';
import dayjs from 'dayjs';
import { dateFormat } from 'utils/dayjsLocale';
import ChangelogModal from './ChangelogModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { TabPane } = Tabs;

@inject('newsletterStore', 'uiStore', 'userStore', 'categoryStore', 'breadcrumbStore')
class PersonalTable extends React.Component {
  state = {
    currentTableData: [],
    reverseTypeMap: {},
    reverseStateMap: {},
    currentTableType: newsletterType.newsletter,

    currentPage: 1,
    activeFilters: [],
    activeSorter: {},

    categoryFilters: [],
    stateFilters: [],

    showChangelogModal: false,
    entityForChangelog: null,

    stateFilterApplied: false,
    categoryFilterApplied: false,

    loading: true
  };

  async componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumbs('newsletterPersonal');
    let sessionFilter = window.sessionStorage.getItem('tableType');
    if (sessionFilter) {
        try {
          sessionFilter = JSON.parse(sessionFilter);
          if (sessionFilter.type) {
            this.props.newsletterStore.tableType = sessionFilter;
          }
        } catch {
          // ignore error
          sessionFilter = null;
        }
      }
    await this.props.newsletterStore.getAllSimple();
    await this.props.userStore.getUsersList();
    let stateFilters = this.buildFilterFromSimpleEntity(this.transformStatesToSimpleEntity(newsletterState));

    this.setState(
      {
        reverseTypeMap: reverseMap(newsletterType),
        reverseStateMap: reverseMap(newsletterState),

        stateFilters: stateFilters,
      },
      async () =>
        await this.getFilteredTableData(this.props.newsletterStore.tableType.type, [], {})
    );
  }

  getFilteredTableData = async (type, filters, sorter) => {
    if(!filters)
      filters = this.state.activeFilters;

    if(!sorter)
      sorter = this.state.activeSorter;

    this.props.newsletterStore.tableType.type = type;
    await this.props.categoryStore.getAllTyped(this.props.newsletterStore.tableType.type != newsletterType.event);
    let categoryFilters = this.buildFilterFromSimpleEntity(this.props.categoryStore.entities);
    this.setState({ currentTableType: type, activeFilters: filters, activeSorter: sorter, loading: false, categoryFilters: categoryFilters }, 
      async () => await this.fetchTableData(this.state.currentTableType));
    window.sessionStorage.setItem(
       'tableType',
       JSON.stringify(this.props.newsletterStore.tableType)
    );
  }

  fetchTableData = async (type) => {
    let entities;
    try{
      if(type == newsletterType.event){
        entities = await this.props.newsletterStore.getPersonalEventsSimplePaginated(
          this.state.currentPage,
          this.state.activeFilters,
          this.state.activeSorter
        );
      }
      else{
        entities = await this.props.newsletterStore.getPersonalSimpleTypedPaginated(
          this.state.reverseTypeMap[type],
          this.state.currentPage,
          this.state.activeFilters,
          this.state.activeSorter
        );
      }
  
      this.setState({currentTableData: entities});
    }
    catch {
      openNotificationWithIcon('error', 'Beim Laden der Tabelle ist ein Fehler aufgetreten.');
    }
  }

  openChangelogModal = (row) => {
    this.setState({showChangelogModal: true, entityForChangelog: row});
  }

  cancelChangelogModal = () => {
    this.setState({showChangelogModal: false, entityForChangelog: null});
  }

  buildFilterFromSimpleEntity = (collection) => {
    let filters = [];
    if(collection.length <= 0) return filters;

    collection.forEach(x => {
      filters.push({
        text: x.name,
        value: x.id
      })
    });
    return filters;
  }

  transformStatesToSimpleEntity = () => {
    let states = { ...newsletterState };
    delete states['draft'];
    let arr = [];
    Object.keys(states).forEach(key => {
      arr.push({
        name: translationMap[key],
        id: states[key]
      });
    });
    return arr;
  }

  navigateToPublishPage = (id) => {
    this.props.uiStore.setReturnLink('newsletterPublishPage', '/newsletter/personal');
    this.props.uiStore.navigateTo(`/newsletter/${id}/${this.state.currentTableType}?fromPersonal=true`);
  }

  updateStateFilterIcon = (iconState) => {
    this.setState({stateFilterApplied: iconState});
  }

  updateCategoryFilterIcon = (iconState) => {
    this.setState({categoryFilterApplied: iconState});
  }

  render() {
    let loading = this.state.loading;

    if(loading) return <Skeleton loading={loading} />;

    const categoryText =
      this.props.newsletterStore.tableType.type != newsletterType.event
        ? 'Arten des Beitrags'
        : 'Kategorien';
    let columns = [
      {
        title: accessibleTableTitle('Veröffentlicht'),
        dataIndex: 'publishingDate',
        key: 'publishingDate',
        sorter: true,
        render: (date) => {
          return date ? dayjs(date).format(dateFormat) : 'Nicht veröffentlicht';
        },
      },
      {
        title: accessibleTableTitle('Titel'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        filters: this.state.stateFilters,
        ...accessibleFilterIcon(
          'state-filter',
          this.state.stateFilterApplied,
          this.updateStateFilterIcon,
          'Status Filter'
        ),
        render: (state) => {
          return capitalizeFirstLetter(translationMap[this.state.reverseStateMap[state]]);
        },
      },
      {
        title: categoryText,
        dataIndex: 'categories',
        key: 'categories',
        filters: this.state.categoryFilters,
        ...accessibleFilterIcon(
          'category-filter',
          this.state.categoryFilterApplied,
          this.updateCategoryFilterIcon,
          `${categoryText} Filter`
        ),
        render: (categories) => {
          return categories.map(
            (x) =>
              x && (
                <div className="table-tag" key={x.id}>
                  <Tag color="geekblue" key={x.id}>
                    {x.name}
                  </Tag>
                </div>
              )
          );
        },
      },
      {
        title: accessibleTableTitle('Zuletzt bearbeitet am'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: true,
        render: (updatedAt) => {
          return updatedAt ? dayjs(updatedAt).format(dateFormat) : '';
        },
      },
      {
        title: accessibleTableTitle('Zuletzt bearbeitet von'),
        dataIndex: 'updatedById',
        key: 'updatedById',
        sorter: true,
        render: (updatedById) => {
          return this.props.userStore.usersList.slice().find((x) => x.id === updatedById)
            ?.email;
        },
      },
      {
        title: 'Aktionen',
        key: 'actions',
        align: 'center',
        width: 220,
        render: (_, row) => (
          <div className="table-actions">
            <Tooltip title="Weitere Informationen">
              <Button
                className="table-action-btn blue-light"
                onClick={() => this.openChangelogModal(row)}
                aria-label="Weitere Informationen"
              >
                <FontAwesomeIcon icon={['fas', 'info']} />
              </Button>
            </Tooltip>
            <Tooltip title="Beitrag ansehen">
              <Button
                className="table-action-btn teal"
                onClick={() => this.navigateToPublishPage(row.id)}
                aria-label="Beitrag ansehen"
              >
                <FontAwesomeIcon icon={['fas', 'eye']} />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ];

    if(this.state.currentTableType == newsletterType.event){
      columns = columns.filter((x) => x.title != 'Art');
    }

    let newsletters = this.state.currentTableData.result;

    return (
      <>
        <div>
          <h1 className="inline">
            Meine Beiträge{' '}
            <FontAwesomeIcon icon={['fas', 'user-edit']} className="header-icon" />
          </h1>
        </div>
        <Tabs
          onChange={(activeKey) => this.getFilteredTableData(activeKey)}
          className="table-tabswitch"
          defaultActiveKey={this.props.newsletterStore.tableType.type}
          tabIndex="-1"
        >
          <TabPane tab={'Newsbeiträge'} key={newsletterType.newsletter} />
          <TabPane tab={'Teaser'} key={newsletterType.teaser} />
          <TabPane tab={'Veranstaltungen'} key={newsletterType.event} />
        </Tabs>

        <Table
          getPopupContainer={element => element.parentElement}
          key="personalTable"
          rowKey={'id'}
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            total: this.state.currentTableData?.total,
            current: this.state.currentPage,
          }}
          scroll={{ scrollToFirstRowOnChange: true, x: true }}
          dataSource={newsletters}
          columns={columns}
          loading={loading}
          onChange={(p, f, s) => {
            this.setState({ currentPage: p.current }, async () => {
              await this.getFilteredTableData(this.state.currentTableType, f, s);
            });
          }}
        />

        {this.state.showChangelogModal && (
          <ChangelogModal
            onCancel={this.cancelChangelogModal}
            entity={this.state.entityForChangelog}
            type={this.state.currentTableType}
          />
        )}
      </>
    );
  }
}

export default PersonalTable;
