import React, { Component, createRef } from 'react';
import { observer, inject } from 'mobx-react';
import { find, upperFirst } from 'lodash-es';

// importing styles and helpers
import '../Clusterverwaltung/Cluster.scss';

// importing components
import { Editor } from '@tinymce/tinymce-react';
import { Form, Input, Button, Skeleton } from 'antd';
import { QuestionCircleFilled, CopyFilled, LockFilled, BankOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter, checkIfFormIsDirty, navigateToHomeWithError, openNotificationWithIcon } from '../../utils/functions';
import { toJS } from 'mobx';
import { Prompt } from 'react-router-dom';

@inject('uiStore', 'pageAdministrationStore', 'breadcrumbStore')
@observer
export default class PageAdministration extends Component {
  formRef = createRef();
  validPages = ['hilfe', 'impressum', 'datenschutz', 'barrierefreiheit']
  state = {
    editorText: '',
    isDirtyForm: false,
    isLoading: true
  };
  async componentDidMount() {
    await this.loadSite();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.match?.params?.page != this.props.match?.params?.page) {
      this.setState({ isLoading: true });
      await this.loadSite();
    }
  }

  async loadSite() {
      let page = this.props.match?.params?.page;
      if (!this.validPages.includes(page))
        navigateToHomeWithError('Diese Seite existiert nicht.');
      this.props.breadcrumbStore.setBreadcrumbs('verwaltung', {
        page: page,
        pageName: capitalizeFirstLetter(page),
      });
      await this.props.pageAdministrationStore.getByName(page);
      this.setState({ isLoading: false });
      let entity = toJS(this.props.pageAdministrationStore.entity);
      this.setState({ editorText: entity.text });
  }

  getTitle() {
    if (this.props.match?.params?.page == 'hilfe') {
      return <>{'Hilfe '}<QuestionCircleFilled/></>;
    }
    if (this.props.match?.params?.page == 'impressum') {
      return <>{'Impressum '}<CopyFilled/></>;
    }
    if (this.props.match?.params?.page == 'datenschutz') {
      return <>{'Datenschutz '}<LockFilled/></>;
    }
    if(this.props.match?.params?.page == 'barrierefreiheit') {
      return <>{'Barrierefreiheit '}<BankOutlined /></>
    }
  }

  onFinish = async (values) => {
    const vals = {
      id: values.id ? values.id : 0,
      page: this.props.match?.params?.page,
      text: this.state.editorText,
    }
    try{
      await this.props.pageAdministrationStore.updatePage(vals);
      openNotificationWithIcon(
        'success',
        `${upperFirst(vals.page)}-Seite wurde erfolgreich bearbeitet!`
      );
    }
    catch (ex){
      openNotificationWithIcon(
        'error',
        'Es ist ein unerwarteter Fehler beim Speichern aufgetreten'
      );
    }

    this.forceUpdate();
  }

  render() {
    const isLoading = this.state.isLoading;
    if(isLoading) return <Skeleton active />;

    let entity = toJS(this.props.pageAdministrationStore.entity);

    return (
      <>
        <Prompt
          when={this.state.isDirtyForm}
          message="Sie haben ungespeicherte Änderungen im Formular. Wollen Sie die Seite wirklich verlassen? Wenn Sie bestätigen, werden die Änderungen verworfen."
        />
        <div className="home">
          <div className="header">
            <h1 className="title">{this.getTitle()}</h1>
          </div>
          <div className="home-content">
            <div className="table-container">
              <Form
                autoComplete="off"
                layout="horizontal"
                size="large"
                labelCol={{ span: 10 }}
                labelAlign="left"
                ref={this.formRef}
                initialValues={entity}
                onFinish={this.onFinish}
                onValuesChange={(changedFields) =>
                  this.setState({
                    isDirtyForm: checkIfFormIsDirty(changedFields, entity),
                  })
                }
              >
                <Form.Item hidden name={'id'}>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item hidden name={'page'}>
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item hidden name={'text'}>
                  <Input type="hidden" />
                </Form.Item>
              </Form>

              <Editor
              apiKey='vyx1306a6zywmysbbdvjrvhsqhuh0nbuh3wtlunris79j2k3'
                initialValue={this.state.editorText}
                onEditorChange={(value, editor) => {
                  let initialValue = this.state.editorText;
                  this.setState({
                    isDirtyForm: value != initialValue,
                  });
                }}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount',
                  ],
                  toolbar:
                    'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | link | image | help',

                  // this disables h1
                  block_formats: 'Absatz=p; Kopfzeile 2=h2; Kopfzeile 3=h3',
                  language: 'de',
                  help_tabs: [
                    'shortcuts', // the default shortcuts tab
                    'keyboardnav', // the default keyboard navigation tab
                  ],

                  /* enable title field in the Image dialog*/
                  image_title: true,
                  /* enable automatic uploads of images represented by blob or data URIs*/
                  automatic_uploads: true,
                  file_picker_types: 'image',
                  file_picker_callback: (cb, value, meta) => {
                    let input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.onchange = async () => {
                      let file = input.files[0];
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = async () => {
                        let id = 'blobid' + new Date().getTime();
                        let blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        let base64 = reader.result.split(',')[1];
                        let blobInfo = blobCache.create(id, file, base64);
                        blobCache.add(blobInfo);
                        cb(blobInfo.blobUri(), { title: file.name });
                      };
                    };
                    input.click();
                  },
                  content_style:
                    'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
              />
            </div>
          </div>
          <div className="button-page">
            <Button
              key="submit"
              type="primary"
              className="yellow"
              onClick={async () => {
                await this.setState({
                  editorText: tinyMCE.activeEditor.getContent(),
                  isDirtyForm: false,
                });
                this.formRef.current?.submit();
              }}
            >
              Seite bearbeiten
            </Button>
          </div>
        </div>
      </>
    );
  }
}
