import React, { Component, createRef } from 'react';
import { observer, inject } from 'mobx-react';
import { find, trim } from 'lodash-es';

// importing styles and helpers
import '../Modals/Modals.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// importing components
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  Switch,
  Card,
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import { openNotificationWithIcon } from 'utils/functions';
import {
  emailRegex,
} from 'App/globalVar';
import { toJS } from 'mobx';

@inject('clusterStore')
@observer
export default class ChangeClusterUserModal extends Component {
  formRef = createRef();

  state = {
    currentStep: 0,
    managerPublicSwitchData: true,
    isManagerMainContactSwitchData: true,
  };

  componentDidMount() {
    let isManagerMainContact = true;
    const contactPersonsHlp = this.props.entity.contactPersons ?? [];
    if (this.props.entity.clusterManager?.id !== this.props.entity.mainContact?.id) {
      isManagerMainContact = false;
      contactPersonsHlp.find(
        (x) => x.id === this.props.entity.mainContact?.id
      ).isMainContact = true;
    }

    this.setState({
      isManagerMainContactSwitchData: isManagerMainContact,
    });
    this.formRef.current?.setFieldsValue({ contactPersons: contactPersonsHlp });
  }

  //used to refresh data for next modal popup right after the first one is closed but the component is not unmounted yet
  componentDidUpdate() {
    if (this.props.isNewItem) {
      let isManagerMainContact = true;
      const contactPersonsHlp = this.props.entity.contactPersons ?? [];

      if (this.props.entity.clusterManager?.id !== this.props.entity.mainContact?.id) {
        isManagerMainContact = false;
        contactPersonsHlp.find(
          (x) => x.id === this.props.entity.mainContact?.id
        ).isMainContact = true;
      }

      this.setState({
        isManagerMainContactSwitchData: isManagerMainContact,
      });
      this.formRef.current?.setFieldsValue({ contactPersons: contactPersonsHlp.slice() });

      this.props.setIsNewItemFalse();
    }
  }

  findMainContactEmail = (values) => {
    if (!this.state.isManagerMainContactSwitchData) {
      return find(values.contactPersons, (x) => x.isMainContact).email;
    }

    return this.getClusterManagerEmail(values);
  };

  getClusterManagerEmail = (values) => {
    return values.clusterManager.email;
  }

  onCancel = async () => {
    this.setState({
      currentStep: 0,
      managerPublicSwitchData: true,
    });
    this.formRef.current?.resetFields();
    this.props.clusterStore.entity = {};
    this.props.closeModal();
  };

  onOkay = async () => {
    this.setState({
      currentStep: 0,
      managerPublicSwitchData: true,
    });
    this.formRef.current?.resetFields();
    this.props.clusterStore.entity = {};
    this.props.onOkay();
  };

  mainContactChanged = (value, x) => {
    let formValues = this.formRef.current?.getFieldsValue();
    if(x === 'manager'){
      if(value) {
        formValues.contactPersons?.forEach(x => x.isMainContact = false);
      } else {
        if(!formValues.contactPersons || formValues.contactPersons.length < 1) {
          formValues.contactPersons = [
            {
              isMainContact: true
            }
          ];
        }
        else if(formValues.contactPersons.filter(x => x.isMainContact).length < 1) {
          formValues.contactPersons[0].isMainContact = true;
        }
      }
      this.setState({ isManagerMainContactSwitchData: value });
    } else {
      let mainContacts = formValues.contactPersons.filter(x => x.isMainContact);
      if(value && mainContacts.length > 1){
        formValues.contactPersons.forEach(x => x.isMainContact = false);
        formValues.contactPersons[x].isMainContact = true;
      } else if(!value && mainContacts.length < 1) {
        formValues.contactPersons[x].isMainContact = true;
      }
    }

    this.formRef.current?.setFieldsValue({ ...formValues });
  }

  checkIfDeletedWasMainContact = () => {
    let formData = this.formRef.current?.getFieldsValue();
    if (formData.contactPersons.length < 1) {
      this.setState({ isManagerMainContactSwitchData: true });
    } else if (formData.contactPersons.filter((x) => x.isMainContact).length < 1) {
      formData.contactPersons[0].isMainContact = true;
      this.formRef.current?.setFieldsValue({ ...formData });
    }
  };

  onFinish = async (values) => {
    let notificiationText = 'Hauptkontakt und Clustermanagende Person darf nicht die zu löschende Person sein!';
    if (this.findMainContactEmail(values) !== this.props.userToDelete.email && this.getClusterManagerEmail(values) !== this.props.userToDelete.email) {
      try {
        await this.props.clusterStore.updateClusterManager(
          this.props.entity.id,
          {
            id: this.props.entity.id,
            ...values,
            isClusterManagerPublic: this.state.managerPublicSwitchData,
            isManagerMainContact: this.state.isManagerMainContactSwitchData,
          }
        );
        openNotificationWithIcon(
          'success',
          'Cluster wurde erfolgreich bearbeitet.'
        );
        this.formRef.current.resetFields();
        this.onOkay();
      } catch (ex) {
        notificiationText = 'Es ist ein unerwarteter Fehler beim Speichern aufgetreten';
        if (ex.response?.status === 409) {
          notificiationText = 'Ein Cluster mit diesem Namen existiert bereits.';
        }
        openNotificationWithIcon('error', notificiationText);
      }
    } else {
      openNotificationWithIcon(
        'error',
        notificiationText
      );
    }
  };

  render() {
    let usersList = this.props.usersList;
    const content = (
      <>
        <Form.Item hidden name="clusterManagerId">
          <Input />
        </Form.Item>
        <Form.Item
          name={['clusterManager', 'email']}
          label="Clustermanagende Person"
          rules={[
            {
              required: true,
              message: 'Bitte geben Sie eine Clustermanagende Person an.',
            },
            {
              pattern: emailRegex,
              message: 'Bitte geben Sie eine valide Email-Adresse an.',
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Max Mustermann"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {usersList.map((x) => (
              <Select.Option key={x.id} value={x.email}>
                {x.fullname}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="isClusterManagerPublic"
          label="Kontaktdaten Clustermanagende Person öffentlich sichtbar?"
        >
          <Switch
            className="switch yellow"
            checked={this.state.managerPublicSwitchData}
            onChange={(value) => this.setState({ managerPublicSwitchData: value })}
          />
        </Form.Item>
        <Form.Item
          name="isManagerMainContact"
          label="Clustermanagende Person Hauptansprechperson?"
          help="Sie müssen mindestens eine Person als Hauptansprechperson markieren."
        >
          <Switch
            className="switch yellow"
            checked={this.state.isManagerMainContactSwitchData}
            onChange={(value) => this.mainContactChanged(value, 'manager')}
          />
        </Form.Item>
        <>
          <Form.List name="contactPersons">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restFields }) => (
                  <Card className="contact-card">
                    <Form.Item
                      name={[name, 'email']}
                      label="Kontaktperson"
                      rules={[
                        {
                          required: true,
                          type: 'email',
                          message: 'Bitte geben Sie eine Clustermanagende Person an.',
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Max Mustermann"
                        showSearch
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {usersList.map((x) => (
                          <Select.Option key={x.id} value={x.email}>
                            {x.fullname}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[name, 'functionArea']}
                      label="Rolle / Sektor"
                      rules={[
                        {
                          required: true,
                          message:
                            'Bitte geben Sie eine Rolle / einen Sektor für diesen Benutzer an.',
                        },
                      ]}
                    >
                      <Input placeholder="Rolle / Sektor" />
                    </Form.Item>

                    {!this.state.isManagerMainContactSwitchData && (
                      <Form.Item
                        valuePropName="checked"
                        name={[name, 'isMainContact']}
                        label="Ist Hauptansprechperson?"
                      >
                        <Switch
                          className="switch yellow"
                          onChange={(checked) => this.mainContactChanged(checked, name)}
                        />
                      </Form.Item>
                    )}
                    <div className="delete-contact-button-wrapper">
                      <Button
                        className="delete-contact-button"
                        type="secondary"
                        onClick={() => {
                          // remove function is not fast enough for check, so we "await" the promise resolving
                          new Promise((resolve) => {
                            remove(name);
                            resolve();
                          }).then((x) => {
                            if (!this.state.isManagerMainContactSwitchData) {
                              this.checkIfDeletedWasMainContact();
                            }
                          });
                        }}
                        icon={<MinusOutlined />}
                      >
                        Kontaktperson entfernen
                      </Button>
                    </div>
                  </Card>
                ))}
                <div className="add-contact-button-wrapper">
                  <Button
                    className="add-contact-button"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Weitere Kontaktperson hinzufügen
                  </Button>
                </div>
              </>
            )}
          </Form.List>
        </>
      </>
    );

    let footerButtons = [
      <Button onClick={this.onCancel} style={{ float: 'left' }}>
        Abbrechen <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon rotate" />
      </Button>,
    ];

    footerButtons.push(
      <Button
        getPopupContainer={(element) => element.parentElement}
        trigger="click"
        className="cluster-dropdown-btn yellow"
        key="submit"
        type="primary"
        loading={this.state.buttonLoading}
        onClick={() => this.formRef.current?.submit()}
      >
        Clustermanagende Person und/oder Hauptkontakt ändern
      </Button>
    );

    return (
      <Modal
        destroyOnClose
        visible={this.props.visible}
        onCancel={this.onCancel}
        footer={footerButtons}
        title={`Clustermanagende Person und/oder Hauptkontakt für "${this.props.entity.name}" ändern, um Benutzer zu löschen`}
        className="app-modal"
      >
        <div className="modal-content">
          <div className="content-form">
            <Form
              className="add-cluster-form"
              layout="horizontal"
              size="large"
              labelCol={{ span: 10 }}
              labelAlign="left"
              ref={this.formRef}
              initialValues={toJS(this.props.entity)}
              onFinish={this.onFinish}
            >
              <div>{content}</div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}
