import './Benutzerverwaltung.scss';
import { Form, Select, Input, Button, Divider } from 'antd';
import { academicTitlesRegex, genders, passwordRegex, phoneRegex, wordsOnlyRegex } from 'App/globalVar';
import { inject } from 'mobx-react';
import React from 'react';
import { map } from 'lodash-es';
import { openNotificationWithIcon, validateFirstLetterUppercase } from 'utils/functions';
import PasswordSetterComponent from 'components/Shared/PasswordSetterComponent';
import PhoneNumberFormItem from 'components/Shared/PhoneNumberFormItem';

@inject('userStore', 'uiStore')
class UserInformationForm extends React.Component {

  formRef = React.createRef();

  updateUserInfo = async (values) => {
    try{
      await this.props.userStore.updateCurrentUserInformation(values);
      openNotificationWithIcon('success', 'Ihre Benutzerinformationen wurden aktualisiert.');
      this.props.uiStore.navigateTo('/');
    }
    catch(ex){
      openNotificationWithIcon('error', 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.');
    }
  }

  render() {
    let user = this.props.userStore.currentUser;
    return (
      <>
        <div className="user-info-form-container">
          <h1>Sehr geehrte/r/s {user.fullname}!</h1>
          <p>
            Bevor Sie in der Applikation starten können, benötigen wir noch ein neues
            Passwort um die Sicherheit Ihrer Daten gewährleisten zu können.
          </p>
          <Form
            className="user-info-form"
            layout="vertical"
            size="large"
            labelAlign="left"
            autoComplete="off"
            ref={this.formRef}
            onFinish={this.updateUserInfo}
            initialValues={user}
          >
            <Form.Item hidden name="id">
              <Input />
            </Form.Item>
            <Form.Item hidden name="role">
              <Input />
            </Form.Item>
            <div className="centered-email-heading">
              <Form.Item name="email" className="ant-form-readonly centered">
                <Input disabled />
              </Form.Item>
            </div>
            <Divider />
            <Form.Item
              name="gender"
              label="Geschlecht"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie ein Geschlecht an.',
                },
              ]}
            >
              <Select
                placeholder="Geschlecht"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {map(genders, (item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={'title'}
              label="Akademischer Titel"
              rules={[
                {
                  validator: validateFirstLetterUppercase,
                  pattern: academicTitlesRegex,
                  message:
                    'Der akademische Titel enthält nicht erlaubte Zeichen oder beginnt nicht mit einem Großbuchstaben.',
                },
                {
                  max: 30,
                  message: 'Der akademische Titel darf maximal 30 Zeichen haben.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name={'givenname'}
              label="Vorname"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie einen Vornamen an.',
                },
                {
                  pattern: wordsOnlyRegex,
                  message: 'Der Vorname darf nur aus Buchstaben bestehen.',
                },
              ]}
            >
              <Input placeholder="Max" autoComplete="new-password"/>
            </Form.Item>
            <Form.Item
              name={'surname'}
              label="Nachname"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie einen Nachnamen an.',
                },
                {
                  pattern: wordsOnlyRegex,
                  message: 'Der Nachname darf nur aus Buchstaben bestehen.',
                },
              ]}
            >
              <Input placeholder="Mustermann" autoComplete="new-password"/>
            </Form.Item>
            <PhoneNumberFormItem 
              name="phoneNumber"
              label="Telefon"
              required={true}
            />
            <PhoneNumberFormItem 
              name="mobilePhoneNumber"
              label="Telefon 2"
            />
            <Divider />
            <PasswordSetterComponent form={this.formRef} />
          </Form>
        </div>
        <div className="user-info-button-container">
          <Button
            onClick={async () => await this.formRef.current?.submit()}
            className="user-info-button yellow"
            type="primary"
          >
            Informationen speichern
          </Button>
        </div>
      </>
    );
  }
}

export default UserInformationForm;
