import { observable } from 'mobx';
import { navigateToHomeWithError } from 'utils/functions';
/**
 * Ths store is only for UI purposes an should never contain any ajax requests
 */
class UIStore {
  // this could be a variable for accessibility and change a class on app.js to increase the font size
  @observable
  fontSize = 12;

  @observable
  loading = true;

  @observable
  path = '';

  colors = [
    { title: 'Petrol', colorName: 'teal' },
    { title: 'Dunkelblau', colorName: 'blue-dark' },
    { title: 'Hellblau', colorName: 'blue-medium' },
    { title: 'Gelb', colorName: 'yellow' },
    { title: 'Orange', colorName: 'orange' },
  ];

  history = undefined;
  returnPaths = JSON.parse(window.localStorage.getItem('returnPaths')) ?? {};
  newsletterNavigationStack = [];

  navigateTo = (key) => {
    if (this.history) {
      this.history.push(key);
      this.path = key;
    }
    this.resetNewsletterNavigationStack();
  };

  navigateBack = () => {
    if(this.history && this.history.length > 0){
      let key = this.history.pop();
      this.path = key;
    }
  }

  navigateBetweenPosts = (key) => {
    if (this.history) {
      this.history.push(key);
      this.path = key;
    }
  }

  resetNewsletterNavigationStack = () => {
    this.newsletterNavigationStack = [];
  }

  setReturnLink = (key, path) => {
    this.returnPaths[key] = path;
    window.localStorage.setItem(
      'returnPaths', JSON.stringify(this.returnPaths));
  }

  navigateToReturnLink = (key) => {
    if(this.returnPaths[key])
    {
      this.path = this.returnPaths[key];
      this.navigateTo(this.path);
    }
    else
    {
      navigateToHomeWithError('Beim Weiterleiten ist ein Fehler aufgetreten.');
    }
  }

  getReturnLink = (key) => {
    return this.returnPaths[key];
  }

  onNavigateLink = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.history) {
      this.history.push(key);
      this.path = key;
    }
  };

  getParams = () => {
    return this.props.match.params;
  };
}

export default new UIStore();
