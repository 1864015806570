import React from 'react';
import { Form, Input, Skeleton, Select, Button, Modal, Table } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import { find, map } from 'lodash-es';
import {maxLength} from 'App/globalVar';

class MailForm extends React.Component {
  state = {
    showHelpModal: false,
  };

  helpTableColumns = [
    {
        title: "Phrase",
        dataIndex: "phrase",
        key: "phrase",
        align: 'center'
    },
    {
        title: "Wird ersetzt durch",
        dataIndex: "replacement",
        key: "replacement",
        align: 'center'
    }
  ];

  helpTableData = [
    {
        phrase: "{Vorname}",
        replacement: "Max"
    },
    {
        phrase: "{Nachname}",
        replacement: "Mustermann"
    },
    {
        phrase: "{Name}",
        replacement: "Max Mustermann"
    },
  ];

  templateSelected = (id) => {
    const template = find(this.props.emailTemplates, (x) => x.id == id);
    if (!template) {
      this.props.formRef.current?.resetFields();
    } else {
      this.props.formRef.current?.setFieldsValue(template);
    }
    tinyMCE.activeEditor.setContent(template?.body ?? '');
  };

  render() { 
    return (
      <>
        <Form
          autoComplete="off"
          layout="vertical"
          size="large"
          labelCol={{ span: 10 }}
          labelAlign="left"
          ref={this.props.formRef}
          onFinish={this.props.onFinish}
          initialValues={this.props.initialValues}
        >
          <Form.Item hidden name="id">
            <Input />
          </Form.Item>
          {!!this.props.displayTemplateSelect && (
            <Form.Item label="Vorlage auswählen">
              <Select onChange={this.templateSelected} allowClear placeholder="Vorlage">
                {map(this.props.emailTemplates, (x) => (
                  <Select.Option value={x.id} key={`template_${x.id}`}>
                    {x.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {!!this.props.displayNameField && (
            <Form.Item
              name="name"
              label="Kurzname"
              rules={[{ required: true, message: 'Bitte geben Sie einen Kurznamen an.' }]}
            >
              <Input />
            </Form.Item>
          )}
          <Form.Item
            name="subject"
            label="Betreff"
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie einen Betreff an.',
              },
            ]}
          >
            <Input disabled={this.props.disabled} />
          </Form.Item>
          <Form.Item
            name="body"
            rules={[
              {
                required: true,
                message: 'Bitte geben Sie einen Text ein.',
                pattern: new RegExp(/\S/),
              },
              {
                type: 'string',
                max: maxLength._2MB
              }
            ]}
          >
            <div>
            <Editor
            apiKey='vyx1306a6zywmysbbdvjrvhsqhuh0nbuh3wtlunris79j2k3'
            initialValue={this.props.editorText}
            disabled={this.props.disabled}
            onBlur={(e) =>
              this.props.formRef.current?.setFieldsValue({ body: e.target.getContent() })
            }
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              toolbar:
                'undo redo | formatselect | ' +
                'bold italic backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | link | help',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }', 
            }}
          />
          </div>
          </Form.Item>
        </Form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            className="button-as-link"
            type="ghost"
            onClick={() => this.setState({ showHelpModal: true })}
          >
            Hilfe anzeigen
          </Button>
        </div>
        {this.state.showHelpModal && (
          <Modal
            destroyOnClose
            className="app-modal"
            onCancel={() => this.setState({ showHelpModal: false })}
            title="Hilfe"
            footer={[]}
            visible={this.state.showHelpModal}
          >
            <Table
              columns={this.helpTableColumns}
              dataSource={this.helpTableData}
              rowKey="property"
              pagination={false}
              key="helpTable"
            />
          </Modal>
        )}
      </>
    );
  }
}

export default MailForm;
