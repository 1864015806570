import axios from 'axios';
import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class CategoryStore extends BaseEntitiesStore {
  constructor() {
    super('Category', 'CategoryStore');
    if (!isProduction) {
      window[`CategoryStore`] = this;
    }
  }

  // specifications used to an additional entity for news/teasers
  // they're now part of the category entity, since they're not used anywhere else
  // and the customer said they're not necessary
  isSpecificationPage = true;

  async post(entity){
    if (!entity.id) {
      entity.id = 0;
    }
    entity.isNewsCategory = this.isSpecificationPage;
    let resp = await axios.post(this.path, entity);
    this.addEntity(resp.data);
  }

  async put(id, entity){
    entity.isNewsCategory = this.isSpecificationPage;
    await axios.put(`${this.path}/${id}`, entity);
    this.modifyEntity(entity);
  }

  async getAllTyped (isNews) {
    let query = '';
    if(isNews != undefined){
      query += isNews ? '?IsNewsCategory=true' : '?IsNewsCategory=false';
    }
    const entities = (await axios.get(`${this.path}${query}`)).data;
    this.setEntities(entities);
    return entities;
  }

  async getAll() {
    return this.getAllTyped(this.isSpecificationPage);
  }
}

export default new CategoryStore();
