import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Tooltip, Button, Tag, Row } from 'antd';
import dayjs from 'dayjs';
import { dateFormat } from '../../../../utils/dayjsLocale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash-es';
import {
  createEmptyScreenreaderSpan,
  normalizeUrl,
  onEnterKey,
} from '../../../../utils/functions';
import { getAriaLabelDate } from 'utils/aria-date';

@inject('uiStore', 'newsletterStore', 'breadcrumbStore')
@observer
export default class NewsletterFooter extends Component {
  state = {
    linkTooltipText: 'Link kopieren',
  };

  getClusterTags(newsLetter) {
    return (
      <div className="tags-list-container" role="list" aria-label="Cluster">
        {map(newsLetter.cluster, (cluster) => (
          <Tag
            className="tag-spacing cluster"
            role="listitem"
            aria-label={cluster.name}
            key={cluster.id}
          >
            <span className="tag-text">{cluster.name}</span>
          </Tag>
        ))}
      </div>
    );
  }

  getCategoriesTags(newsLetter) {
    return (
      <div className="tags-list-container" role="list" aria-label="Kategorien">
        {map(newsLetter.categories, (categories) => (
          <Tag
            className="tag-spacing category"
            role="listitem"
            aria-label={categories.name}
            key={categories.id}
          >
            <span className="tag-text">{categories.name}</span>
          </Tag>
        ))}
      </div>
    );
  }

  onCopyLinkButton = (id) => {
    let addedId = this.props.addIdToLink ? '/' + id : '';
    let link = window.location.href.includes('clusterplattform-oesterreich')
      ? window.location.href
      : `${window.location.href}clusterplattform-oesterreich`;
    navigator.clipboard.writeText(`${link}${addedId}`);
    this.setState({
      linkTooltipText: 'Link kopiert!',
    });
  };

  onReadMoreButton = (link) => {
    window.open(normalizeUrl(link), '_blank');
  };

  render() {
    let newsLetter = this.props.newsletter;

    return (
      <>
        <Row className="card-footer">
          <div className="card-categories">
            <Tooltip title="Kategorie(n)">
              <FontAwesomeIcon icon={['far', 'tags']} />
            </Tooltip>
            {this.getCategoriesTags(newsLetter)}
          </div>
          <div className="card-clusters">
            <Tooltip title="Cluster">
              <FontAwesomeIcon icon={['far', 'thumbtack']} />
            </Tooltip>
            {this.getClusterTags(newsLetter)}
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            <Tooltip title={this.state.linkTooltipText}>
              <Button
                className="footer-btn"
                onClick={() => this.onCopyLinkButton(newsLetter.id)}
                onKeyDown={(e) => onEnterKey(e, this.onCopyLinkButton, newsLetter.id)}
                type="text"
                aria-label={this.state.linkTooltipText}
              >
                <FontAwesomeIcon icon={['far', 'code-branch']} />
              </Button>
            </Tooltip>
          </div>
          {newsLetter.link ? (
            <div onClick={(e) => e.stopPropagation()}>
              <Tooltip title={'Mehr lesen'}>
                <Button
                  type="text"
                  className="footer-btn"
                  aria-label="Mehr lesen"
                  onClick={() => this.onReadMoreButton(newsLetter?.link)}
                >
                  <FontAwesomeIcon icon={['far', 'info']} />
                </Button>
              </Tooltip>
            </div>
          ) : (
            <></>
          )}
        </Row>
        <div className="footer-pub-dates">
          <div className="publishing-dates">
            <div className="published">
              <Tooltip title="Erscheinungsdatum">
                <FontAwesomeIcon icon={['far', 'clock']} />
                {createEmptyScreenreaderSpan(
                  `Erscheinungsdatum: ${getAriaLabelDate(newsLetter.publishingDate)}`,
                  '',
                  'note'
                )}
                <span aria-hidden="true">
                  {newsLetter.publishingDate
                    ? dayjs(newsLetter.publishingDate).format(dateFormat)
                    : 'nicht veröffentlicht'}
                </span>
              </Tooltip>
            </div>
            <div className="author">
              <Tooltip title="Autor">
                <FontAwesomeIcon icon={['far', 'user']} />
                {createEmptyScreenreaderSpan('Autor', '', 'note')}
                {newsLetter.author ?? 'Keine Angabe'}
              </Tooltip>
            </div>
          </div>
        </div>
      </>
    );
  }
}
