import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, Input, Modal, Popconfirm, Skeleton, Table, Tooltip } from 'antd';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { accessibleTableTitle, openNotificationWithIcon, getDirtyFormConfirmationWithCheck } from 'utils/functions';

@observer
@inject('breadcrumbStore')
class BaseEntityAdministration extends React.Component {
  formRef = React.createRef();

  state = {
    modalVisible: false,
    isEdit: false,
    loading: true
  };

  async componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumbs(this.props.breadcrumbsRoute);
    await this.props.store.getAll();
    this.setState({loading: false});
  }

  handleCancel = () => {
    this.setState({ modalVisible: false, isEdit: false });
    this.props.store.setEntity({});
  };

  onCreateOrEdit = async () => {
    const verb = this.state.isEdit ? 'bearbeitet' : 'erstellt';
    try {
      const values = await this.formRef.current.validateFields();
      if (this.state.isEdit) {
        await this.props.store.put(values.id, values);
      } else {
        await this.props.store.post(values);
      }

      openNotificationWithIcon(
        'success',
        `${this.props.entityName} wurde erfolgreich ${verb}.`
      );
      this.handleCancel();
    } catch (err) {
      let notificiationText = `${this.props.entityName} konnte nicht ${verb} werden.`;
      if (err.response?.status === 409) {
        notificiationText = `${this.props.entityName} mit diesem Namen existiert bereits.`;
      }
      openNotificationWithIcon('error', notificiationText);
    }
    this.forceUpdate();
  };

  onDelete = async (id) => {
    try {
      await this.props.store.delete(id);
      openNotificationWithIcon(
        'success',
        `${this.props.entityName} wurde erfolgreich gelöscht.`
      );
    } catch (err) {
      openNotificationWithIcon(
        'error',
        `${this.props.entityName} wird verwendet, löschen nicht möglich.`
      );
    }
    this.forceUpdate();
  };

  render() {
    if(this.state.loading) return <Skeleton loading={this.state.loading} />;
    const columns = [
      {
        title: accessibleTableTitle(this.props.entityName),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Aktionsbuttons',
        width: 220,
        align: 'center',
        render: (field) => (
          <div className="table-actions">
            <Tooltip title="Bearbeiten">
              <Button
                className="table-action-btn yellow"
                aria-label="Bearbeiten"
                onClick={() => {
                  this.props.store.setEntity(field);
                  this.setState({ modalVisible: true, isEdit: true });
                }}
              >
                <FontAwesomeIcon icon={['far', 'pencil']} />
              </Button>
            </Tooltip>
            <Tooltip title="Löschen">
              <Popconfirm
                title="Sind Sie sicher, dass Sie diesen Eintrag löschen wollen? Diese Aktion kann nicht widerrufen werden."
                okText="Ja"
                cancelText="Nein"
                onConfirm={() => this.onDelete(field.id)}
                getPopupContainer={(element) => element.parentNode}
              >
                <Button className="table-action-btn red" aria-label="Löschen">
                  <FontAwesomeIcon icon={['fas', 'trash-alt']} />
                </Button>
              </Popconfirm>
            </Tooltip>
          </div>
        ),
      },
    ];
    const actionTitle = `${this.props.entityName} ${
      this.state.isEdit ? 'bearbeiten' : 'anlegen'
    }`;
    return (
      <div className="home">
        <div className="header">
          <h1 className="title">
            {this.props.headerTitle} {this.props.headerIcon}
          </h1>
          <Button
            type="primary"
            className="header-btn yellow"
            onClick={() => this.setState({ modalVisible: true })}
          >
            {`${this.props.entityName} anlegen`}{' '}
            <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon" />
          </Button>
        </div>
        <div className="home-content">
          <div className="table-container">
            <Table
              key={`${this.props.entityName}Table`}
              scroll={{ x: true }}
              dataSource={this.props.store.entities.slice()}
              columns={columns}
              rowKey="id"
              pagination={false}
            />
          </div>
        </div>

        {this.state.modalVisible && (
          <Modal
            destroyOnClose
            className="app-modal"
            onCancel={this.handleCancel}
            visible={this.state.modalVisible}
            title={actionTitle}
            footer={[
              <Button
                key="cancelBtn"
                type="primary"
                className="teal"
                onClick={this.handleCancel}
              >
                Abbrechen{' '}
                <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon rotate" />
              </Button>,
              <Button key="submitBtn" type="primary" onClick={this.onCreateOrEdit} className="yellow">
                {actionTitle}
                <FontAwesomeIcon
                  icon={['fas', this.state.isEdit ? 'pencil' : 'plus']}
                  className="btn-icon"
                />
              </Button>,
            ]}
          >
            <div className="modal-content">
              <div className="content-form">
                <Form
                  layout="horizontal"
                  size="large"
                  labelCol={{ span: 10 }}
                  labelAlign="left"
                  ref={this.formRef}
                  initialValues={this.props.store.entity}
                  autoComplete="off"
                >
                  <Form.Item hidden name="id">
                    <Input type="hidden" />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      { required: true, message: 'Bitte geben Sie einen Namen an.' },
                    ]}
                  >
                    <Input placeholder="Name" autoFocus />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default BaseEntityAdministration;
