import '../News.scss';

import React from 'react';
import { Image, Col, Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash-es';
export default class Gallery extends React.Component {
  render() {
    let entity = this.props.entity;

    return (
      <>
        {entity.galleryImages[0] ? (
          <Col>
            <div className="gallery">
              <div className="section-title">Galerie</div>
              <div className="image-flex-container">
                <Image.PreviewGroup>
                  {map(entity.galleryImages, (image) => (
                    <div className="image-container" key={image.imageTitle}>
                      <Image
                        src={image.preview}
                        className="gallery-image"
                        alt={image.altText}
                      />
                      <Tooltip
                        title={
                          <>
                            <FontAwesomeIcon
                              className="info-btn"
                              icon={['far', 'copyright']}
                            />
                            {' ' + image.copyright}
                          </>
                        }
                        trigger="click"
                        placement="right"
                      >
                        <Button
                          className="hero-btn-gallery"
                          type="text"
                          shape="circle"
                          icon={
                            <FontAwesomeIcon
                              className="info-btn"
                              icon={['fas', 'info-circle']}
                            />
                          }
                        />
                      </Tooltip>
                      <div className="img-dates-gallery">
                        <div className="image-title">{image.imageTitle}</div>
                      </div>
                    </div>
                  ))}
                </Image.PreviewGroup>
              </div>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </>
    );
  }
}
