import {
  Form,
  Row,
  Input,
  Col,
  Select,
  Radio,
  Button,
  Divider,
  DatePicker
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map, filter } from 'lodash-es';
import { inject } from 'mobx-react';
import React from 'react';
import {
  httpsPrefixRegex,
  maxLength,
  modalType,
  newsletterState,
  newsletterType,
  urlRegex,
} from '../../../globalVar';
import {
  openNotificationWithIcon,
  transformLoadedNewsletter,
  getErrorsFromResponse,
  checkIfFormIsDirty,
  afterDateRule,
  beforeDateRule,
  maxWordsRule,
  maxThreeMonthsRule,
  roundTimeNearestQuarterHour,
} from '../../../utils/functions';
import dayjs from 'dayjs';

import TinyMCE from 'components/Shared/TinyMCE';

import '../Newsletter.scss';
import ThumbnailCropper from 'components/Shared/ThumbnailCropper';

const formLayout12x24 = { xxl: 12, xl: 12, lg: 12, md: 24, sm: 24, xs: 24 };
const formLayout6x24 = { xxl: 6, xl: 6, lg: 6, md: 24, sm: 24, xs: 24 };
const formLayout12x22 = { xxl: 14, xl: 14, lg: 14, sm: 22, xs: 22 };
const formLayout12x10 = { xxl: 5, xl: 5, lg: 5, sm: 12, xs: 12 };
const formLayout12x12 = { xxl: 4, xl: 4, lg: 4, sm: 12, xs: 12 };
const formLayout1x2 = { lg: 1, sm: 2, xs: 2 };

@inject('newsletterStore')
export default class EventForm extends React.Component {
  formRef = React.createRef();
  teaserEditorRef = React.createRef();
  bodyEditorRef = React.createRef();
  thumbnailRef = React.createRef();

  state = {
    displayImageError: {
      typeOk: true,
      sizeOk: true,
    },
    sourceCounter: 0,
    isOnlineEvent: false,
    onlineEventId: null,

    startDate: undefined,
    endDate: undefined
  };

  //#region Editor specific functions
  getSourceCounter = () => this.state.sourceCounter;
  setSourceCounter = (value) => this.setState({ sourceCounter: value });
  getSourceKeyCount = () =>
    this.bodyEditorRef.current.getAnnotationCount() +
    this.teaserEditorRef.current.getAnnotationCount();
  removeAnnotations = (oldSources, sources) => {
    this.bodyEditorRef.current.updateAnnotation(oldSources, sources);
    this.teaserEditorRef.current.updateAnnotation(oldSources, sources);
  };
  setInitialStyles = (entity) => {
    if (entity.sources) {
      this.bodyEditorRef.current.setInitalStyles(entity);
      this.teaserEditorRef.current.setInitalStyles(entity);
      this.setSourceCounter(entity.sources.length);
    }
  };
  addStyle = (uid, value) => {
    this.bodyEditorRef.current.addStyleToEditorHead(uid, value);
    this.teaserEditorRef.current.addStyleToEditorHead(uid, value);
  };
  updateAnnotations = () => {
    let teaserAnnotations = this.teaserEditorRef.current.getSourceAnnotations();
    let bodyAnnotations = this.bodyEditorRef.current.getSourceAnnotations();
    let allAnnotations = teaserAnnotations.concat(bodyAnnotations);
    this.teaserEditorRef.current.updateListReferences(allAnnotations);
    let count = this.teaserEditorRef.current.updateAnnotationViaCount(
      1,
      this.teaserEditorRef.current.getIdsFromAnnotations(teaserAnnotations)
    );
    this.bodyEditorRef.current.updateAnnotationViaCount(
      count,
      this.bodyEditorRef.current.getIdsFromAnnotations(bodyAnnotations)
    );
  };
  getUpdatedEditorTexts = (oldContent) => {
    let teaser = this.teaserEditorRef.current.getUpdatedContent();
    let body = this.bodyEditorRef.current.getUpdatedContent();
    return {
      id: oldContent.id,
      teaser: teaser,
      body: body,
    };
  };
  //#endregion

  //#region Thumbnail cropper specific functions
  setDisplayImageError = (value) => this.setState({displayImageError: value});
  //#endregion

  buildSelects = () => {
    let categorySelect = this.props.categories.map((item) => (
      <Select.Option value={item.id} key={item.id}>
        {item.name}
      </Select.Option>
    ));

    let simpleClusterSelect = map(this.props.simpleClusters, (item) => (
      <Select.Option value={item.id} key={item.id}>
        {item.name}
      </Select.Option>
    ));

    let onlyPublished = filter(
      this.props.newsletters,
      (item) => item.publishingDate != null && item.publishingDate != false
    );
    let previousSelect = map(onlyPublished, (item) => (
      <Select.Option value={item.id} key={item.id}>
        {item.title}
      </Select.Option>
    ));

    let formatSelect = map(this.props.formats, (item) => (
      <Select.Option value={item.id} key={item.id}>
        {item.name}
      </Select.Option>
    ));

    return {
      categorySelect,
      simpleClusterSelect,
      previousSelect,
      formatSelect,
    };
  };

  onFinish = async () => {
    let values = this.formRef.current?.getFieldsValue();

    if (values.starting) values.starting = dayjs(values.starting);
    if (values.ending) values.ending = dayjs(values.ending);

    values.content = this.getUpdatedEditorTexts(values.content);
    values.type = newsletterType.event;

    let verb =
      this.props.newsletterStore.modalType == modalType.new ? 'erstellt' : 'aktualisiert';

    try {
      let fieldsToValidate = [
        'title',
        !!values.thumbnail?.thumbnailSource?.text && [
          'thumbnail',
          'thumbnailSource',
          'text',
        ],
        !!values.content.teaser && ['content', 'teaser']
      ];

      values.sources?.forEach((x, idx) => {
        fieldsToValidate.push(['sources', idx, 'text']);
        fieldsToValidate.push(['sources', idx, 'isLink']);
      });
      await this.formRef.current?.validateFields(fieldsToValidate);

      // if fromDraftTable = false, a winnovation user edited the newsletter
      if (!this.props.fromDraftTable) {
        values.state = newsletterState.inProcessWin;
        await this.updateEvent(values);
      } else {
        values.state = newsletterState.draft;
        await this.saveAsDraft(values);
      }

      openNotificationWithIcon('success', `Veranstaltung wurde ${verb}!`);
      this.props.handleDirtyForm(false);
      this.props.onCancel();
    } catch (err) {
      this.scrollToField(err);
      openNotificationWithIcon('error', `Veranstaltung konnte nicht ${verb} werden.`, {
        description: getErrorsFromResponse(err),
      });
    }
  };

  updateEvent = async (values) => {
    if (this.props.newsletterStore.modalType === modalType.edit) {
      await this.props.newsletterStore.putEvent(values.id, values);
    }
  };

  saveAsDraft = async (values) => {
    if (this.props.newsletterStore.modalType == modalType.new) {
      await this.props.newsletterStore.postEventDraft(values);
    } else if (this.props.newsletterStore.modalType == modalType.edit) {
      await this.props.newsletterStore.putEventDraft(values.id, values);
    }
  };

  checkValidity = async () => {
    try {
      await this.formRef.current?.validateFields();
      openNotificationWithIcon('success', 'Beitrag ist vollständig.');
    } catch (e) {
      this.scrollToField(e);
    }
  };

  scrollToField = (error) => {
    if (error.errorFields && error.errorFields.length > 0) {
      let scrollTo = error.errorFields[0]?.name;
      this.formRef.current?.scrollToField(scrollTo, {
        behavior: 'smooth',
        scrollMode: 'always',
      });
    }
  };

  componentDidMount() {
    if (this.props.newsletterStore.modalType !== modalType.view) {
      this.props.addSubmitButton([
        <div key="optionButtons" className="save-footer-btns">
          <Button
            type="secondary"
            className="btn-with-icon secondary yellow"
            key="validityCheck"
            onClick={this.checkValidity}
          >
            Vollständigkeit überprüfen
            <FontAwesomeIcon icon={['fas', 'tasks']} />
          </Button>
          <Button
            type="primary"
            className="btn-with-icon yellow"
            key="saveAsDraft"
            onClick={this.onFinish}
          >
            {this.props.fromDraftTable &&
            this.props.newsletterStore.modalType === modalType.new
              ? `Als Draft speichern`
              : `Änderungen speichern`}
            <FontAwesomeIcon icon={['fas', 'check']} />
          </Button>
        </div>,
      ]);
    }

    let event =
      Object.keys(this.props.newsletterStore.editObject).length > 0
        ? this.props.newsletterStore.editObject
        : undefined;

    let onlineEventId = this.props.formats.filter((el) => el.name.toString().toLowerCase() === 'online' && el.id ).map((elem) => (elem.id))[0] ?? null;
    
    this.setState({
      onlineEventId: onlineEventId,
      isOnlineEvent: event ? onlineEventId == event.formatId : false,
      startDate: event ? event.starting : undefined,
      endDate: event ? event.ending : undefined,
    });
  }

  componentWillUnmount() {
    this.props.newsletterStore.file = {};
  }

  render() {
    let gutter = { xs: 8, sm: 16, md: 24, lg: 32 };
    let isViewMode = this.props.newsletterStore.modalType === modalType.view;

    let { categorySelect, simpleClusterSelect, previousSelect, formatSelect } =
      this.buildSelects();

    let newsletter =
      Object.keys(this.props.newsletterStore.editObject).length > 0
        ? this.props.newsletterStore.editObject
        : undefined;
    if (newsletter) newsletter = transformLoadedNewsletter(newsletter);
    
    return (
      <Form
        autoComplete="off"
        ref={this.formRef}
        scrollToFirstError={true}
        layout={'vertical'}
        name="newsletterForm"
        initialValues={newsletter}
        onFinish={this.onFinish}
        onValuesChange={(changedFields) =>
          this.props.handleDirtyForm(
            checkIfFormIsDirty(changedFields, this.props.newsletterStore.editObject)
          )
        }
      >
        <Form.Item label="id" name="id" hidden>
          <Input disabled={true} />
        </Form.Item>
        <h2 className="section-header">Allgemeine Daten</h2>
        <Row gutter={gutter}>
          <Col {...formLayout12x24}>
            <Form.Item
              label="Titel"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Dieses Feld darf nicht leer sein!',
                },
                {
                  max: maxLength.Medium,
                  message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                placeholder="Titel der Veranstaltung"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col {...formLayout12x24}>
            <Form.Item
              label="Cluster auswählen"
              name="clusterIds"
              rules={[{ required: true, message: 'Dieses Feld darf nicht leer sein!' }]}
            >
              <Select
                disabled={isViewMode}
                mode="multiple"
                placeholder="Cluster auswählen"
                optionFilterProp="children"
                maxTagCount="responsive"
                showArrow
                allowClear
              >
                {simpleClusterSelect}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={gutter}>
          <Col {...formLayout12x24}>
            <Form.Item
              rules={[{ required: true, message: 'Dieses Feld darf nicht leer sein!' }]}
              label="Kategorie(n)"
              name="categoryIds"
            >
              <Select
                disabled={isViewMode}
                mode="multiple"
                placeholder="Kategorie(n) auswählen"
                optionFilterProp="children"
                maxTagCount="responsive"
                showArrow
                allowClear
              >
                {categorySelect}
              </Select>
            </Form.Item>
          </Col>
          <Col {...formLayout6x24}>
            <Form.Item
              label="Autor"
              name="author"
              rules={[
                { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                {
                  max: maxLength.Medium,
                  message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                },
              ]}
            >
              <Input disabled={isViewMode} placeholder="Autor des Beitrages" allowClear />
            </Form.Item>
          </Col>
          <Col {...formLayout6x24}>
            <Form.Item label="Vorhergehender Beitrag" name="previousNewsletterId">
              <Select
                disabled={isViewMode}
                placeholder="Vorherigen Beitrag auswählen"
                optionFilterProp="children"
                showSearch
                allowClear
              >
                {previousSelect}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
        <h2 className="section-header">Orts- und Zeitdaten</h2>
        <Row gutter={gutter}>
          <Col {...formLayout6x24}>
            <Form.Item
              id="formatId"
              name="formatId"
              label="Format"
              rules={[
                {
                  required: true,
                  message: 'Dieses Feld darf nicht leer sein!',
                },
              ]}
            >
              <Select
                disabled={isViewMode}
                placeholder="Format auswählen"
                optionFilterProp="children"
                showSearch
                allowClear
                onChange={(id) => {
                  if (id == this.state.onlineEventId) {
                    this.setState({ isOnlineEvent: true });
                    this.formRef.current.setFieldsValue({ location: 'Online' });
                  } else {
                    this.setState({ isOnlineEvent: false });
                    this.formRef.current.setFieldsValue({ location: null });
                  }
                }}
              >
                {formatSelect}
              </Select>
            </Form.Item>
          </Col>
          <Col {...formLayout6x24}>
            <Form.Item
              id="location"
              name="location"
              label="Ort"
              rules={[
                {
                  required: true,
                  message: 'Dieses Feld darf nicht leer sein!',
                },
                {
                  max: maxLength.Small,
                  message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Small} Zeichen!`,
                },
              ]}
            >
              <Input.TextArea
                disabled={this.state.isOnlineEvent || isViewMode}
                placeholder="Ort der Veranstaltung"
                allowClear
              />
            </Form.Item>
          </Col>
          <Col {...formLayout6x24}>
            <Form.Item
              key="starting"
              name="starting"
              label="Startdatum"
              dependencies={['ending']}
              rules={[
                {
                  required: true,
                  message: 'Dieses Feld darf nicht leer sein!',
                },
                {
                  validator: (rule, value) =>
                    beforeDateRule(rule, value, this.state.endDate),
                  message: 'Das Startdatum darf nicht nach dem Enddatum liegen.',
                },
                {
                  validator: (rule, value) => maxThreeMonthsRule(rule, value, this.state.startDate, this.state.endDate),
                  message: 'Die Veranstaltung darf nicht länger als 3 Monate dauern.'
                }
              ]}
            >
              <DatePicker
                minuteStep={15}
                disabled={isViewMode}
                style={{ width: '100%' }}
                showTime={{ defaultValue: dayjs('12:00', 'HH:mm') }}
                format={'DD.MM.YYYY HH:mm'}
                allowClear
                disabledDate={(current) => {
                  return current.isBefore(new Date());
                }}
                onChange={(startDate) => {
                  const roundedTime = roundTimeNearestQuarterHour(startDate);
                  this.formRef.current?.setFieldsValue({ starting: roundedTime });
                  this.setState({ startDate: roundedTime });
                }}
              />
            </Form.Item>
          </Col>
          <Col {...formLayout6x24}>
            <Form.Item
              key="ending"
              name="ending"
              label="Enddatum"
              dependencies={['starting']}
              rules={[
                {
                  required: true,
                  message: 'Dieses Feld darf nicht leer sein!',
                },
                {
                  validator: (rule, value) =>
                    afterDateRule(rule, value, this.state.startDate),
                  message: 'Das Enddatum darf nicht vor dem Startdatum liegen.',
                },
                {
                  validator: (rule, value) => maxThreeMonthsRule(rule, value, this.state.startDate, this.state.endDate),
                  message: 'Die Veranstaltung darf nicht länger als 3 Monate dauern.'
                }
              ]}
            >
              <DatePicker
                minuteStep={15}
                disabled={isViewMode}
                style={{ width: '100%' }}
                showTime={{ defaultValue: dayjs('12:00', 'HH:mm') }}
                format={'DD.MM.YYYY HH:mm'}
                allowClear
                disabledDate={(current) => {
                  return current.isBefore(new Date());
                }}
                onChange={(endDate) => {
                  const roundedTime = roundTimeNearestQuarterHour(endDate);
                  this.formRef.current?.setFieldsValue({ ending: roundedTime });
                  this.setState({ endDate: roundedTime });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <h2 className="section-header">Titelbild erstellen</h2>
        <Row gutter={40} className="cover-image-section">
          <Col span={24}>
            <div className="upload-cover-image">
              <Form.Item hidden name={['thumbnail', 'id']} key="thumbnailId">
                <Input />
              </Form.Item>
              <Form.Item hidden name={["thumbnail", "originalImage"]} key="originalImage">
                <Input />
              </Form.Item>
              <Form.Item hidden name={["thumbnail", "type"]} key="type">
                <Input />
              </Form.Item>
              <Form.Item hidden name={["thumbnail", "name"]} key="thumbnailName">
                <Input />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Bitte laden sie ein Thumbnail (.png, .jpg, .jpeg) hoch!',
                  },
                ]}
                id="image"
                name={["thumbnail", "image"]}
              >
                <div className="image-wrapper">
                  <ThumbnailCropper
                    ref={this.thumbnailRef}
                    form={this.formRef}
                    property={'thumbnail'}
                    isViewMode={isViewMode}
                    thumbnail={newsletter?.thumbnail}
                    setDisplayImageError={this.setDisplayImageError}
                  />
                </div>
              </Form.Item>

              {!this.state.displayImageError.sizeOk && (
                <p className="image-upload-error-text">
                  Bitte laden Sie ein Thumbnail mit maximal 5 MB hoch.
                </p>
              )}
              {!this.state.displayImageError.typeOk && (
                <p className="image-upload-error-text">
                  Bitte laden Sie ein Thumbnail mit folgenden Dateiendungen hoch:
                  <ul>
                    <li>.png</li>
                    <li>.jpg</li>
                    <li>.jpeg</li>
                  </ul>
                </p>
              )}
            </div>
          </Col>

          <Col span={24} className="cover-image-dates">
            <Row gutter={40}>
              <Col {...formLayout12x24}>
                <Form.Item
                  label="Titel des Bildes"
                  name={['thumbnail', 'imageTitle']}
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                    {
                      max: maxLength.Small,
                      message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Small} Zeichen!`,
                    },
                  ]}
                >
                  <Input
                    disabled={isViewMode}
                    placeholder="Titel des Bildes"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col {...formLayout12x24}>
                <Form.Item
                  label="Alternativtext"
                  name={['thumbnail', 'altText']}
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                    {
                      max: maxLength.Small,
                      message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Small} Zeichen!`,
                    },
                  ]}
                >
                  <Input
                    disabled={isViewMode}
                    placeholder="Alternativtext des Bildes"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col {...formLayout12x24}>
                <Form.Item
                  label="Bildquelle"
                  name={['thumbnail', 'thumbnailSource', 'text']}
                  dependencies={['thumbnail', 'thumbnailSource', 'isLink']}
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                    {
                      max: maxLength.Medium,
                      message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                    },
                  ].concat(
                    this.formRef.current?.getFieldsValue(['thumbnail']).thumbnail
                      ?.thumbnailSource?.isLink && [
                      {
                        pattern: urlRegex,
                        message: 'Geben Sie bitte eine valide Url an.',
                      },
                      {
                        pattern: httpsPrefixRegex,
                        message:
                          'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                      },
                    ]
                  )}
                >
                  <Input
                    disabled={isViewMode}
                    placeholder="Quelle des Bildes angeben"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col {...formLayout12x24}>
                <Form.Item
                  label="Copyright"
                  name={['thumbnail', 'copyright']}
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                    {
                      max: maxLength.Medium,
                      message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                    },
                  ]}
                >
                  <Input
                    disabled={isViewMode}
                    placeholder="Copyright für das Bild"
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col {...formLayout12x24}>
                <Form.Item
                  hidden
                  name={['thumbnail', 'thumbnailSource', 'id']}
                  key="imageSourceId"
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Art der Quelle"
                  name={['thumbnail', 'thumbnailSource', 'isLink']}
                  rules={[
                    { required: true, message: 'Dieses Feld darf nicht leer sein!' },
                  ]}
                >
                  <Radio.Group disabled={isViewMode} value={false}>
                    <Radio value={true}>Link</Radio>
                    <Radio value={false}>Text</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />
        <Form.Item key="contentId" name={['content', 'id']} hidden>
          <Input />
        </Form.Item>
        <h2 className="section-header">Inhalt</h2>
        <Col span={24}>
          <Form.Item
            label="Teaser"
            name={['content', 'teaser']}
            rules={[
              {
                required: true,
                message: 'Teaser muss ausgefüllt werden!',
                pattern: new RegExp(/\S/),
              },
              {
                validator: (rule, value) => maxWordsRule(rule, value, this.teaserEditorRef.current?.getWordCount(), 80),
                message: 'Teaser-Text darf maximal 80 Wörter beinhalten.'
              },
              {
                type: 'string',
                message: '(Achtung! Die Formatierungen erhöhen auch die Zeichenanzahl)',
                max: maxLength._2MB,
              },
            ]}
          >
            <TinyMCE
              ref={this.teaserEditorRef}
              disabled={isViewMode}
              form={this.formRef}
              property={['content', 'teaser']}
              isTeaser={true}
              entity={newsletter}
              getSourceCounter={this.getSourceCounter}
              setSourceCounter={this.setSourceCounter}
              getSourceKeyCount={this.getSourceKeyCount}
              removeAnnotations={this.removeAnnotations}
              setInitialStyles={this.setInitialStyles}
              addStyle={this.addStyle}
              updateAnnotations={this.updateAnnotations}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Text"
            name={['content', 'body']}
            rules={[
              {
                type: 'string',
                max: maxLength._2MB,
              },
              {
                type: 'string',
                message: '(Achtung! Die Formatierungen erhöhen auch die Zeichenanzahl)',
                max: maxLength._2MB,
              },
              {
                required: true,
                message: 'Text muss ausgefüllt werden!',
                pattern: new RegExp(/\S/),
              },
            ]}
          >
            <TinyMCE
              ref={this.bodyEditorRef}
              disabled={isViewMode}
              form={this.formRef}
              property={['content', 'body']}
              isTeaser={false}
              entity={newsletter}
              getSourceCounter={this.getSourceCounter}
              setSourceCounter={this.setSourceCounter}
              getSourceKeyCount={this.getSourceKeyCount}
              removeAnnotations={this.removeAnnotations}
              setInitialStyles={this.setInitialStyles}
              addStyle={this.addStyle}
              updateAnnotations={this.updateAnnotations}
            />
          </Form.Item>
        </Col>
        <Divider />
        <h2 className="section-header">Quellenangaben</h2>
        <Form.List name={'sources'}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row key={key}>
                  <Form.Item key={key} hidden name={[name, 'id']}>
                    <Input />
                  </Form.Item>
                  <Col {...formLayout1x2}>
                    <Form.Item key={key} name={[name, 'reference']}>
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col {...formLayout12x22}>
                    <Form.Item
                      key={key}
                      name={[name, 'text']}
                      dependencies={[name, 'isLink']}
                      rules={[
                        {
                          required: true,
                          message: 'Dieses Feld darf nicht leer sein!',
                        },
                        {
                          max: maxLength.Medium,
                          message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                        },
                      ].concat(
                        this.formRef.current?.getFieldsValue(['sources']).sources[name]
                          ?.isLink && [
                          {
                            pattern: urlRegex,
                            message: 'Geben Sie bitte eine valide Url an.',
                          },
                          {
                            pattern: httpsPrefixRegex,
                            message:
                              'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                          },
                        ]
                      )}
                    >
                      <Input disabled={isViewMode} allowClear />
                    </Form.Item>
                  </Col>
                  <Col {...formLayout12x10} className="radio-btns-col">
                    <Form.Item
                      key={key}
                      name={[name, 'isLink']}
                      rules={[
                        {
                          required: true,
                          message: 'Dieses Feld darf nicht leer sein!',
                        },
                      ]}
                    >
                      <Radio.Group disabled={isViewMode}>
                        <Radio value={true}>Link</Radio>
                        <Radio value={false}>Text</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {!this.formRef.current?.getFieldsValue().sources[name]?.reference && (
                    <Col {...formLayout12x12}>
                      <Button
                        type="secondary"
                        className="btn-with-icon remove-row-btn"
                        hidden={isViewMode}
                        onClick={() => remove(name)}
                      >
                        Löschen
                        <FontAwesomeIcon icon={['fas', 'times']} />
                      </Button>
                    </Col>
                  )}
                </Row>
              ))}
              <Button
                className="add-row-btn"
                hidden={isViewMode}
                type="dashed"
                icon={<PlusOutlined />}
                onClick={() => add()}
              >
                Hinzufügen
              </Button>
            </>
          )}
        </Form.List>
        <Divider />
        <h2 className="section-header">Weitere Links</h2>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Link für weitere Informationen"
              name="link"
              rules={[
                {
                  max: maxLength.Medium,
                  message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                },
                {
                  pattern: httpsPrefixRegex,
                  message:
                    'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                },
                {
                  pattern: urlRegex,
                  message: 'Geben Sie bitte eine valide Url an.',
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                placeholder="http://www.domain.at"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Anmeldelink"
              name="registrationLink"
              rules={[
                {
                  max: maxLength.Medium,
                  message: `Dieses Feld erlaubt eine Länge von maximal ${maxLength.Medium} Zeichen!`,
                },
                {
                  pattern: httpsPrefixRegex,
                  message:
                    'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                },
                {
                  pattern: urlRegex,
                  message: 'Geben Sie bitte eine valide Url an.',
                },
                this.state.isOnlineEvent && {
                  required: true,
                  message: 'Dieses Feld darf nicht leer sein!',
                },
              ]}
            >
              <Input
                disabled={isViewMode}
                placeholder="http://www.domain.at"
                allowClear
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
