import axios from 'axios';
import mobx, {
    observable,
} from 'mobx';
import { apiUrl } from '../globalVar';


const baseUrl = `${apiUrl}/powerbi`

class PowerBiStore {

    @observable
    powerBiConfig = {};

    async fetchReportConfig() {
        return await axios.get(`${baseUrl}/GetReport`).then(resp => {
            this.powerBiConfig = resp.data;
            return this.powerBiConfig;
        });
    }

    async fetchReportConfigWithUsername() {
        return await axios.get(`${baseUrl}/GetReportWithUsername`).then(resp => {
            this.powerBiConfig = resp.data;
            return this.powerBiConfig;
        });
    }


}

export default new PowerBiStore();
