import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button, Table } from 'antd';
import { isEqual, map, find } from 'lodash-es';
import './PowerBiPage.scss';
import { accessibleFilterIcon, accessibleTableTitle, buildFilterFromSimpleEntity, normalizeUrl } from 'utils/functions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

@inject(
  'industryStore',
  'stateStore',
  'nuts3Store',
  'clusterHolderStore',
  'clusterStore',
)
@observer
export default class ClusterTable extends Component {
  state = {
    loading: true,
    currentFilter: {
      holderIds: null,
      industryIds: null,
      stateId: null
    },

    industryFilters: [],
    industryFilterApplied: false,

    holderFilters: [],
    holderFiltersApplied: false,

    stateFilters: [],
    stateFiltersApplied: false,
  };

  async componentDidMount() {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    await Promise.all([
      this.props.industryStore.getAll(),
      this.props.stateStore.getAll(),
      this.props.nuts3Store.getAll(),
      this.props.clusterHolderStore.getAll(),
      this.props.clusterStore.getTableData(),
    ]);

    this.setState({
      loading: false,
      industryFilters: buildFilterFromSimpleEntity(
        this.props.industryStore.entities.slice()
      ),
      holderFilters: buildFilterFromSimpleEntity(
        this.props.clusterHolderStore.entities.slice()
      ),
      stateFilters: buildFilterFromSimpleEntity(
        this.props.stateStore.entities.slice()
      )
    });
  }

  updateHoldersFilterIcon = (value) => {
    this.setState({ holderFiltersApplied: value });
  }

  updateIndustryFilterIcon = (value) => {
    this.setState({ industryFilterApplied: value });
  }

  updateStateFilters = (value) => {
    this.setState({ stateFiltersApplied: value });
  }

  getIndustryById(id) {
    return find(this.props.industryStore.entities.slice(), (x) => x.id === id);
  }

  getStateById(id) {
    return find(this.props.stateStore.entities.slice(), (x) => x.id === id);
  }

  getHolderById(id){
    return find(this.props.clusterHolderStore.entities.slice(), (x) => x.id === id);
  }

  render() {
    const data = this.props.clusterStore.clusterData.slice();
    let columns = [
      {
        title: accessibleTableTitle('Clustername'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Branche',
        dataIndex: 'industryIds',
        key: 'industryIds',
        width: 300,
        filters: this.state.industryFilters,
        ...accessibleFilterIcon(
          'industry-filter',
          this.state.industryFilterApplied,
          this.updateIndustryFilterIcon,
          'Branche Filter'
        ),
        render: (ids) => {
          return (
            <ul className='no-bullets'>
              {map(ids, (id) => (
                <li>{this.getIndustryById(id)?.name}</li>
              ))}
            </ul>
          );
        },
      },
      {
        title: 'Träger',
        dataIndex: 'holderIds',
        key: 'holderIds',
        width: 500,
        filters: this.state.holderFilters,
        ...accessibleFilterIcon(
          'holders-filter',
          this.state.holderFiltersApplied,
          this.updateHoldersFilterIcon,
          'Träger Filter'
        ),
        render: (ids) => {
          return (
            <ul className='no-bullets'>
              {map(ids, (id) => (
                <li>{this.getHolderById(id)?.name}</li>
              ))}
            </ul>
          )
        }
      },
      {
        title: 'Bundesland',
        dataIndex: 'stateId',
        key: 'stateId',
        filters: this.state.stateFilters,
        ...accessibleFilterIcon(
          'state-filter',
          this.state.stateFiltersApplied,
          this.updateStateFilters,
          'Bundesland Filter'
        ),
        render: (id) => this.getStateById(id)?.name,
      },
      {
        title: accessibleTableTitle('Kontakt', true),
        key: 'contact',
        dataIndex: 'contact'
      },
      {
        title: accessibleTableTitle('Mail', true),
        dataIndex: 'displayedEmail',
        key: 'displayedEmail',
        render: (email) => {
          return (
            <Button onClick={(e) => {
              window.location.href = `mailto:${email}`;
              e.preventDefault();
            }} className="table-action-btn teal">
              <FontAwesomeIcon icon={['fas', 'envelope']} />
            </Button>
          );
        }
      },
      {
        title: accessibleTableTitle('Telefonnummer', true),
        key: 'displayedPhoneNumber',
        render: (item) => {
          let aria = [...item.normalizedPhoneNumber].reduce((prev, cur) => prev += ` ${cur}`, ""); // NOSONAR
          return (
            <a aria-label={aria} href={`tel:${item.normalizedPhoneNumber}`}>
              {item.displayedPhoneNumber}
            </a>
          );
        }
      },
      {
        title: accessibleTableTitle('Homepage', true),
        dataIndex: 'homepage',
        key: 'homepage',
        render: (homepage) => {
          return (
            <Button
              className="table-action-btn blue-dark"
              onClick={(e) => {
                window.open(normalizeUrl(homepage), '_blank');
                e.preventDefault();
              }}
            >
              <FontAwesomeIcon icon={['fas', 'external-link']} />
            </Button>
          );
        }
      },
    ];

    return data ? (
      <Table
        loading={this.state.loading}
        pagination={false}
        dataSource={data}
        columns={columns}
        rowKey={'id'}
        getPopupContainer={element => element.parentElement}
        onChange={async (p,f,s) => {
          if(!isEqual(f, this.state.currentFilter)){
            await this.props.clusterStore.getTableData(f);
            this.setState({ currentFilter: f });
          }
        }}
      />
    ) : null;
  }
}
