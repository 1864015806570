import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Form, Input, Modal, Skeleton, Table, Tooltip } from 'antd';
import MailForm from 'components/Shared/MailForm';
import { inject } from 'mobx-react';
import React from 'react';
import { accessibleTableTitle, getDirtyFormConfirmationWithCheck, openNotificationWithIcon } from 'utils/functions';

@inject('emailTemplateStore','breadcrumbStore')
class EmailTemplates extends React.Component {
  formRef = React.createRef();

  state = {
    modalVisible: false,
    editorText: '',
    isEdit: false,
    entity: {},
    loading: true,
    isSaveDirtyForm: false
  };

  async componentDidMount(){
    this.props.breadcrumbStore.setBreadcrumbs('emailTemplate');
    await this.props.emailTemplateStore.getAll();
    this.setState({loading: false});
  }

  columns = [
    {
      title: accessibleTableTitle('Name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Aktionsbuttons',
      width: 220,
      align: 'center',
      render: (_field) => (
        <div className="table-actions">
          <Tooltip title="Bearbeiten">
            <Button
              className="table-action-btn yellow"
              aria-label="Bearbeiten"
              onClick={() => {
                this.setState({
                  entity: _field,
                  isEdit: true,
                  modalVisible: true,
                  editorText: _field.body,
                });
              }}
            >
              <FontAwesomeIcon icon={['far', 'pencil']} />
            </Button>
          </Tooltip>
          <Tooltip title="Löschen">
            <Button
              className="table-action-btn red"
              aria-label="Löschen"
              onClick={() => {
                if (
                  confirm(
                    `Wollen Sie die Vorlage mit dem Namen "${_field.name}" wirklich löschen?`
                  )
                ) {
                  this.onDelete(_field);
                }
              }}
            >
              <FontAwesomeIcon icon={['fas', 'trash-alt']} />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  handleCancel = () => {
    let cancelDirtyForm = this.state.isSaveDirtyForm ? true : getDirtyFormConfirmationWithCheck(
      this.formRef.current?.getFieldsValue(),
      this.state.entity,
      this.state.editorText != tinyMCE.activeEditor.getContent()
    );

    if(cancelDirtyForm) this.setState({ isEdit: false, modalVisible: false, editorText: '', entity: {}, isSaveDirtyForm: false });
  };

  createOrEdit = async () => {
    let verb = 'erstellt';
    await this.formRef.current.validateFields();
    let values = this.formRef.current?.getFieldsValue();

    
    try {
      values.body = tinyMCE.activeEditor.getContent();
      if (values.id) {
        await this.props.emailTemplateStore.put(values.id, values);
        verb = 'bearbeitet';
      } else {
        await this.props.emailTemplateStore.post(values);
      }
      openNotificationWithIcon('success', `Vorlage "${values.name}" wurde ${verb}.`);
      this.formRef.current?.resetFields();
      this.setState({isSaveDirtyForm: true});
      this.handleCancel();
    } catch (ex) {
      let notificiationText = `Vorlage "${values.name}" konnte nicht ${verb} werden.`;
      if (ex.response.status === 409) {
        notificiationText = `Vorlage mit diesem Namen existiert bereits.`;
      }
      openNotificationWithIcon('error', notificiationText);
    }
  };

  onDelete = async (_field) => {
    try {
      await this.props.emailTemplateStore.delete(_field.id);
      openNotificationWithIcon('success', `Vorlage "${_field.name}" wurde gelöscht.`);
      this.forceUpdate();
    } catch (ex) {
      openNotificationWithIcon('error', 'Vorlage konnte nicht gelöscht werden.');
    }
  };

  render() {
    if (this.state.loading) return <Skeleton loading={this.state.loading} />;
    const data = this.props.emailTemplateStore.entities.slice();
    return (
      <div className="home">
        <div className="header">
          <h1 className="title">
            Email-Vorlagen <FontAwesomeIcon icon={['fas', 'envelope']} />
          </h1>
          <Button
            type="primary"
            className="header-btn btn-with-icon yellow"
            onClick={() => {
              this.setState({ modalVisible: true });
            }}
          >
            Email-Template erstellen <FontAwesomeIcon icon={['fas', 'plus']} />
          </Button>
        </div>
        <div className="home-content">
          <div className="table-container">
            <Table
              scroll={{ x: true }}
              dataSource={data}
              columns={this.columns}
              rowKey="id"
              pagination={false}
            />
          </div>
        </div>

        {this.state.modalVisible && (
          <Modal
            destroyOnClose
            className="app-modal"
            onCancel={this.handleCancel}
            visible={this.state.modalVisible}
            title={this.state.isEdit ? 'Vorlage bearbeiten' : 'Vorlage erstellen'}
            footer={[
              <Button
                key="cancel"
                type="primary"
                className="teal"
                onClick={this.handleCancel}
              >
                Abbrechen{' '}
                <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon rotate" />
              </Button>,
              <Button key="submit" type="primary" onClick={this.createOrEdit}>
                {this.state.isEdit ? 'Vorlage bearbeiten' : 'Vorlage erstellen'}{' '}
                <FontAwesomeIcon
                  icon={['fas', this.state.isEdit ? 'pencil' : 'plus']}
                  className="btn-icon"
                />
              </Button>,
            ]}
          >
            <div className="modal-content">
              <div className="content-form">
                <MailForm 
                  formRef={this.formRef}
                  onFinish={this.createOrEdit}
                  initialValues={this.state.entity}
                  displayNameField={true}
                  editorText={this.state.editorText}
                  disabled={false}
                />
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}

export default EmailTemplates;
