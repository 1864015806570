import React from 'react';

import { Image, Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createEmptyScreenreaderSpan } from 'utils/functions';
import placeholderImg from "../../../../images/placeholder-image.jpg";

export default class EventImage extends React.Component {

  render() {
    let event = this.props.event;

    return (
      <div className="img-wrapper">
        <Image
          onClick={(e) => e.stopPropagation()}
          preview={{
            src:event.thumbnail?.originalImage
          }}
          src={event.thumbnail?.image}
          alt={event.thumbnail?.altText}
          fallback={placeholderImg}
          className="cover-image"
        />
        {this.props.displayThumbnailTitle && (
          <>
            <Tooltip
              title={
                <>
                  <FontAwesomeIcon className="info-btn" icon={['far', 'copyright']} />
                  {' ' + event.thumbnail?.copyright ?? ''}
                </>
              }
              trigger="click"
              placement="right"
            >
              <Button
                className="hero-btn"
                type="text"
                shape="circle"
                icon={
                  <FontAwesomeIcon className="info-btn" icon={['fas', 'info-circle']} />
                }
                aria-label={`Copyright ${event.thumbnail?.copyright ?? ''}`}
              />
            </Tooltip>

            <p className="image-title">
              {createEmptyScreenreaderSpan(
                `Bild-Untertitel: ${event.thumbnail?.imageTitle}`,
                'image-title',
                'note'
              )}
              <span aria-hidden="true">{event.thumbnail?.imageTitle}</span>
            </p>
          </>
        )}
      </div>
    );
  }
}
