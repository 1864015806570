import React from 'react';
import { notification } from 'antd';
import dayjs from 'dayjs';
import { newsletterState, clusterState, httpsPrefixRegex, urlRegex } from '../globalVar';
import AccessibleFilterDropdown from 'components/Shared/AccessibleFilterDropdown';
import { FilterFilled, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import UIStore from 'stores/UIStore';

/**
 *
 * @param {string} type - success | info | warning | error [required]
 * @param {string} title - title of the notification [required]
 * @param {Object} optional - object which contains:
 * @param {string} optional.description - description text for notification [default: undefined]
 * @param {string} optional.placement - topLeft | topRight | bottomLeft | bottomRight [default: topRight]
 * @param {float} optional.duration - how long notification should be displayed [default: 4.5]
 */
export const openNotificationWithIcon = (
  type,
  title,
  optional = {
    description: undefined,
    placement: 'topRight',
    duration: 4.5,
    onClose: () => {
      return undefined;
    },
  }
) => {
  notification[type]({
    message: title,
    description: optional.description,
    placement: optional.placement,
    duration: optional.duration,
    onClose: optional.onClose,
  });
};

export function numberFormatter(x) {
  if (x && x !== undefined) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  else return '';
}

export function boolToIcon(value) {
  return value ? <CheckCircleOutlined /> : <CloseCircleOutlined />;
}

export function capitalizeFirstLetter(x) {
  if (!x) return;
  return x.charAt(0).toUpperCase() + x.slice(1);
}

export function b64toBlob(base64str, type) {
  // decode base64 string, remove space for IE compatibility
  const binary = atob(base64str.replace(/\s/g, ''));
  const len = binary.length;
  let buffer = new ArrayBuffer(len);
  let view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }

  // create the blob object with content-type "application/pdf"
  return new Blob([view], { type: type });
}

// this function extracts the necessary data from newsletter.thumbnail
// and writes them into the object so it's conform to the used form
export function getLoadedImage(newsletter) {
  newsletter.imageSourceId = newsletter.thumbnail?.thumbnailSource.id;
  newsletter.altText = newsletter.thumbnail?.altText;
  newsletter.imageSource = newsletter.thumbnail?.thumbnailSource?.text;
  newsletter.imageTitle = newsletter.thumbnail?.imageTitle;
  newsletter.isLink = newsletter.thumbnail?.thumbnailSource?.isLink;
  newsletter.originalImage = newsletter.thumbnail?.originalImage;
  newsletter.copyright = newsletter.thumbnail?.copyright;
  
  if(!!newsletter.thumbnail?.image && newsletter.thumbnail?.image === 'data:;base64,') {
    newsletter.thumbnail.image = undefined;
  }
  newsletter.image = newsletter.thumbnail?.image;

  return newsletter;
}

// this function transforms a newletter / event entity into an object that can be used by forms
export function transformLoadedNewsletter(newsletter) {
  newsletter = getLoadedImage(newsletter);

  newsletter.thumbnailId = newsletter.thumbnail?.id;

  let arr = [];
  newsletter.categories.forEach((x) => arr.push(x.id));
  newsletter.categoryIds = arr;
  arr = [];
  newsletter.cluster.forEach((x) => arr.push(x.id));
  newsletter.clusterIds = arr;

  if (newsletter.starting) newsletter.starting = dayjs(newsletter.starting);

  if (newsletter.ending) newsletter.ending = dayjs(newsletter.ending);

  // the image in the thumnail gets treated separately
  // any already existing newsletter has an image so this bypasses the false validation error
  newsletter.throwawayItem =
    newsletter.image != 'data:;base64,' ? 'bypassValidation' : undefined;

  return newsletter;
}

export function reverseMap(object) {
  let map = {};
  let keys = Object.keys(object);
  let values = Object.values(object);

  for (let i = 0; i < keys.length; i++) {
    map[values[i]] = keys[i];
  }

  return map;
}

export function buildBackendFilter(filterObj) {
  let backendFilter = [];
  for (let property in filterObj) {
    backendFilter.push({
      field: property,
      value: filterObj[property],
    });
  }

  return backendFilter;
}

export function isStateInProcess(state) {
  if (!state) return;
  return (
    state == newsletterState.inProcessWin ||
    state == newsletterState.inProcessSD ||
    state == newsletterState.inProcessPR
  );
}

/**
 * This function will take the file response result and return a filename if specific headers are set or return the default value given as param to this function
 * @param {any} resp - response object ob the download request
 * @param {string} defaultName - the default value of the file if headers are not found
 * @returns {string} - filename or defaul name
 */
export const getFileName = (resp, defaultName) => {
  let name = (
    resp.headers['Content-Disposition'] || resp.headers['content-disposition']
  )?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
  if (name?.length > 0) {
    name = name[1];
  } else {
    name = defaultName;
  }

  return name;
};

export const getErrorsFromResponse = (exception) => {
  if (exception.request && exception.response) {
    let errors = exception.response.data?.errors;
    if (errors) {
      let errorString = '';
      Object.keys(errors).forEach((key) => {
        errorString += `${errors[key]}.\n`;
      });
      return errorString;
    }
  }
};

export const onEnterKey = async (keyEvent, fn, args) => {
  if (keyEvent.key == 'Enter') {
    keyEvent.stopPropagation();
    if (fn) {
      if (args) {
        if (Array.isArray(args)) {
          await fn(...args);
        } else {
          await fn(args);
        }
      } else {
        await fn();
      }
    }
  }
};

export const accessibleTableTitle = (title, noSort) => {
  let label = !noSort ? "Sortierung: " : "";
  return (
    <button aria-label={`${label}${title}`} className="custom-ant-table-column-title">
      {title}
    </button>
  );
};

function clickFilter(id, callback) {
  document.getElementById(id).click();
  if (callback) {
    callback();
  }
}

// table -> getPopupContainer={element => element.parentElement} um tabIndex richtig zu setzen
// css: .ant-table-content { overflow: visible !important; }
// statevar: set to random in component and update on closing the dropdown to ensure creating a new one
export const accessibleFilterIcon = (
  idInDom,
  classSwitchVar,
  onUpdateIcon,
  ariaLabelText,
  onBtnDownCallback,
  stateVar
) => {
  return {
    filterIcon: (
      <FilterFilled
        className={classSwitchVar ? 'active-sorting-icon' : 'inactive-sorting-icon'}
        id={idInDom}
        onKeyDown={(e) => onEnterKey(e, clickFilter, [idInDom, onBtnDownCallback])}
        onClick={() => {
          if (onBtnDownCallback) {
            onBtnDownCallback();
          }
        }}
        aria-label={ariaLabelText}
        tabIndex="0"
      />
    ),
    filterDropdown: (props) => {
      return (
        <AccessibleFilterDropdown
          key={stateVar}
          returnFocusTo={idInDom}
          setSelectedKeys={props.setSelectedKeys}
          selectedKeys={props.selectedKeys}
          options={props.filters}
          confirm={props.confirm}
          clearFilters={props.clearFilters}
          updateIcon={onUpdateIcon}
          tabIndex="0"
        />
      );
    },
  };
};

// Mit einem leeren Span-Tag kann "geforct" werden, dass ein Screenreader den aria-label vorliest
export const createEmptyScreenreaderSpan = (label, classAdditions, role) => {
  if (role) {
    return (
      <span
        className={`empty-screenreader-span ${classAdditions ?? ''}`}
        aria-label={label}
        role={role}
      />
    );
  } else {
    return (
      <span
        className={`empty-screenreader-span ${classAdditions ?? ''}`}
        aria-label={label}
      />
    );
  }
};

export const getQueryStringFromObject = (objectToTransform) => {
  let obj = {};

  Object.keys(objectToTransform).forEach((key) => {
    let queryString = '';
    if (objectToTransform[key] != null) {
      if (Array.isArray(objectToTransform[key])) {
        objectToTransform[key].forEach((value) => {
          queryString += `&${key}=${value}`;
        });
        obj[key] = queryString;
      } else {
        queryString += `&${key}=${objectToTransform[key]}`;
        obj[key] = queryString;
      }
    }
  });

  let result = '';
  Object.keys(obj).forEach((x) => {
    result += obj[x];
  });

  return result;
};

export function getClusterStateTagColour(clusterStateId) {
  switch (clusterStateId) {
    case clusterState['clusterDraft']:
      return '';
    case clusterState['clusterInProcess']:
      return 'yellow';
    case clusterState['clusterOnline']:
      return 'green';
    case clusterState['clusterOutdated']:
      return 'volcano';
    default:
      return '';
  }
}

export function navigateToHomeWithError(message) {
  openNotificationWithIcon('error', 'Sie wurden umgeleitet.', { description: message });
  UIStore.navigateTo('/');
}

export function getApplicationPathFromUrl(url) {
  return url.substring(`${location.protocol}//${window.location.host}`.length);
}

export function buildFilterFromSimpleEntity(collection) {
  return collection.map((x) => {
    return {
      text: x.name,
      value: x.id,
    };
  });
}

export function validateFirstLetterUppercase(rule, value){
  return !value ||
    (typeof value === 'string' &&
      value.charAt(0) != ' ' &&
      value.charAt(0) === value.charAt(0).toUpperCase())
    ? Promise.resolve(value)
    : Promise.reject(rule.message);
}

export function getDirtyFormConfirmation(isDirtyForm) {
  if (isDirtyForm) {
    return confirm(
      'Sie haben ungespeicherte Änderungen im Formular. Wollen Sie die Seite wirklich verlassen? Wenn Sie bestätigen, werden die Änderungen verworfen.'
    );
  }
  return true;
}

export function checkIfFormIsDirty(changedFields, initialValues) {
  let fieldName = Object.keys(changedFields)[0];
  if(initialValues[fieldName] === undefined && changedFields[fieldName] === '') return false;
  return initialValues[fieldName] !== changedFields[fieldName];
}

export function getDirtyFormConfirmationWithCheck(currentFormValues, initialValues, isTinyMceDirty = false) {
  let isDirtyForm = false;

  let fieldNames = Object.keys(currentFormValues);
    fieldNames.forEach((fieldName) => {
      if(initialValues[fieldName] !== undefined || currentFormValues[fieldName] !== '') {
        if (initialValues[fieldName] !== currentFormValues[fieldName]) {
          isDirtyForm = true;
          return;
        }
      }
  });

  if (isDirtyForm || isTinyMceDirty) {
    return confirm(
      'Sie haben ungespeicherte Änderungen im Formular. Wollen Sie die Seite wirklich verlassen? Wenn Sie bestätigen, werden die Änderungen verworfen.'
    );
  }

  return true;
}

export function preventWhitespace(e) {
  if(e.keyCode === 32)
  {
    e.preventDefault();
  }
}

export function normalizeUrl(url){
  return url.startsWith('http') ? url : `http://${url}`;
}

export function beforeDateRule(rule, startDate, endDate) {
  if (startDate != undefined && endDate != undefined) {
    if (!dayjs(endDate).isAfter(dayjs(startDate))) {
      return Promise.reject(rule.message);
    }
  }
  return Promise.resolve(startDate);
}

export function afterDateRule(rule, endDate, startDate) {
  if (startDate != undefined && endDate != undefined) {
    if (!dayjs(startDate).isBefore(dayjs(endDate))) {
      return Promise.reject(rule.message);
    }
  }
  return Promise.resolve(endDate);
}

export function maxWordsRule(rule, value, count, maxWords) {
  if (count > maxWords) {
    return Promise.reject(rule.message);
  }
  return Promise.resolve(value);
}

export function maxThreeMonthsRule(rule, value, startDate, endDate) {
  if (startDate != undefined && endDate != undefined) {
    if (dayjs(endDate).diff(dayjs(startDate), 'day') > 92) {
      return Promise.reject(rule.message);
    }
  }
  return Promise.resolve(value);
}

export function roundTimeNearestQuarterHour(dateInput){
  if(!dateInput) return undefined;
  const date = dayjs(dateInput);
  const minutes = date.minute();
  const quarterHours = Math.round(minutes / 15);
  return date.minute(quarterHours * 15);
}
