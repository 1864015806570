import { inject } from 'mobx-react';
import React from 'react';
import { Button, Skeleton, Tooltip, Table, Tag, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import dayjs from 'dayjs';

import NewsletterWizard from './WIzard/NewsletterWizard';
import ChangelogModal from './ChangelogModal';

import { modalType, newsletterState, newsletterType, translationMap } from 'App/globalVar';
import { dateFormat } from 'utils/dayjsLocale';
import { openNotificationWithIcon, reverseMap, accessibleTableTitle, accessibleFilterIcon, getDirtyFormConfirmation } from 'utils/functions';

import './Newsletter.scss';

const { TabPane } = Tabs;

@inject(
  'breadcrumbStore',
  'uiStore',
  'newsletterStore',
  'userStore',
  'categoryStore'
)
export default class DraftTable extends React.Component {

  state = {
    currentTableData: [],

    reverseTypeMap: {},
    reverseStateMap: {},
    currentTableType: newsletterType.newsletter,

    showTypeSelect: false,
    modalToOpen: undefined,

    showChangelogModal: false,
    entityForChangelog: null,

    currentPage: 1,
    activeFilters: [],
    activeSorter: {},

    typeFilters: [],
    categoryFilters: [],

    categoryFilterApplied: false,
    isDirtyForm: false,

    loading: true
  };

  async componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumbs('newsletterDrafts');
    let sessionFilter = window.sessionStorage.getItem('tableType');
    if (sessionFilter) {
      try {
        sessionFilter = JSON.parse(sessionFilter);
        if (sessionFilter.type) {
          this.props.newsletterStore.tableType = sessionFilter;
        }
      } catch {
        // ignore error
        sessionFilter = null;
      }
    }
    await this.props.userStore.getUsersList();

    let typeFilters = this.buildFilterFromSimpleEntity(this.transformTypesToSimpleEntity());

    this.setState({
      reverseTypeMap: reverseMap(newsletterType),
      reverseStateMap: reverseMap(newsletterState),

      typeFilters: typeFilters,
    }, async () => await this.getTableData(this.props.newsletterStore.tableType.type, [], {}));
  }

  createNew = () => {
    this.props.newsletterStore.editObject = {};
    this.props.newsletterStore.modalType = modalType.new;
    this.setState({ showModal: true, showTypeSelect: true, modalToOpen: undefined });
  };

  onEdit = async (row) => {
    this.props.newsletterStore.editObject = await this.props.newsletterStore.getById(
      row.id,
      this.state.currentTableType
    );
    this.props.newsletterStore.modalType = modalType.edit;
    this.setState({ showModal: true, showTypeSelect: false, modalToOpen: this.state.currentTableType });
  };

  onDelete = async (id) => {
    try {
      await this.props.newsletterStore.deleteDraft(id, this.state.currentTableType);
      openNotificationWithIcon('success', 'Beitrag wurde gelöscht.');
    } catch (err) {
      openNotificationWithIcon('error', 'Beitrag konnte nicht gelöscht werden.');
    }

    this.getTableData(this.state.currentTableType);
  };

  getTableData = async (type, filters, sorter) => {
    if(!filters)
      filters = this.state.activeFilters;

    if(!sorter)
      sorter = this.state.activeSorter;

    this.props.newsletterStore.tableType.type = type;
    await this.props.categoryStore.getAllTyped(this.props.newsletterStore.tableType.type != newsletterType.event);
    let categoryFilters = this.buildFilterFromSimpleEntity(this.props.categoryStore.entities);

    this.setState({
      currentTableType: type,
      activeFilters: filters,
      activeSorter: sorter,
      loading: false,
      categoryFilters: categoryFilters,
    }, async () => this.fetchTableData());

    window.sessionStorage.setItem(
      'tableType',
      JSON.stringify(this.props.newsletterStore.tableType)
    );
  };

  fetchTableData = async () => {
    try{
      this.setState({
        currentTableData: await this.props.newsletterStore.getSimpleDrafts(
          this.state.currentPage,
          this.state.activeFilters,
          this.state.activeSorter,
          this.state.currentTableType
        ),
      });
    } catch {
      openNotificationWithIcon('error', 'Beim Laden der Tabelle ist ein Fehler aufgetreten.');
    }
  }

  handleDirtyForm(isDirtyForm) {
    this.setState({isDirtyForm: isDirtyForm});
  }

  onCancel = async () => {
    let cancelDirtyForm = getDirtyFormConfirmation(this.state.isDirtyForm);

    if(cancelDirtyForm)
    {
      this.props.newsletterStore.editObject = {};
      this.props.newsletterStore.modalType = undefined;
      this.setState({ showModal: false, isDirtyForm: false });
      await this.getTableData(this.state.currentTableType);
    }
  }

  openChangelogModal = (row) => {
    this.setState({showChangelogModal: true, entityForChangelog: row});
  }

  cancelChangelogModal = () => {
    this.setState({showChangelogModal: false, entityForChangelog: null});
  }

  buildFilterFromSimpleEntity = (collection) => {
    let filters = [];
    if(collection.length <= 0) return filters;

    collection.forEach(x => {
      filters.push({
        text: x.name,
        value: x.id
      })
    });
    return filters;
  }

  transformTypesToSimpleEntity = () => {
    let types = { ...newsletterType };
    let arr = [];
    Object.keys(types).forEach(key => {
      arr.push({
        name: translationMap[key],
        id: types[key]
      });
    });
    return arr;
  }

  startPublishingProcess = async (entity) => {
    this.props.uiStore.setReturnLink('newsletterPublishPage', '/newsletter/drafts');
    this.props.uiStore.navigateTo(`/newsletter/${entity.id}/${this.state.currentTableType}?fromDrafts=true`); 
  }

  updateCategoryFilterIcon = (iconState) => {
    this.setState({categoryFilterApplied: iconState});
  }

  render() {
    let loading = this.state.loading;

    if (loading) return <Skeleton loading={loading} />;

    const categoryText =
      this.props.newsletterStore.tableType.type != newsletterType.event
        ? 'Arten des Beitrags'
        : 'Kategorien';
    let columns = [
      {
        title: accessibleTableTitle('Veröffentlicht'),
        dataIndex: 'publishingDate',
        key: 'publishingDate',
        render: (date) => {
          return date ? dayjs(date).format(dateFormat) : 'Nicht veröffentlicht';
        },
      },
      {
        title: accessibleTableTitle('Titel'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
      },
      {
        title: categoryText,
        dataIndex: 'categories',
        key: 'categories',
        filters: this.state.categoryFilters,
        ...accessibleFilterIcon(
          'category-filter',
          this.state.categoryFilterApplied,
          this.updateCategoryFilterIcon,
          `${categoryText} Filter`
        ),
        render: (categories) => {
          return map(
            categories,
            (x) =>
              x && (
                <div className="table-tag" key={x.id}>
                  <Tag color="geekblue">{x.name}</Tag>
                </div>
              )
          );
        },
      },
      {
        title: accessibleTableTitle('Zuletzt bearbeitet am'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: true,
        render: (updatedAt) => {
          return updatedAt ? dayjs(updatedAt).format(dateFormat) : '';
        },
      },
      {
        title: accessibleTableTitle('Zuletzt bearbeitet von'),
        dataIndex: 'updatedById',
        key: 'updatedById',
        sorter: true,
        render: (updatedById) => {
          return this.props.userStore.usersList.slice().find((x) => x.id === updatedById)
            ?.email;
        },
      },
      {
        title: 'Aktionen',
        key: 'actions',
        align: 'center',
        width: '10',
        render: (_, row) => (
          <div className="table-actions">
            <Tooltip title="Bearbeiten">
              <Button
                className="table-action-btn yellow"
                onClick={() => this.onEdit(row)}
                aria-label="Bearbeiten"
              >
                <FontAwesomeIcon icon={['fas', 'pencil']} />
              </Button>
            </Tooltip>
            <Tooltip title="Weitere Informationen">
              <Button
                className="table-action-btn blue-light"
                onClick={() => this.openChangelogModal(row)}
                aria-label="Weitere Informationen"
              >
                <FontAwesomeIcon icon={['fas', 'info']} />
              </Button>
            </Tooltip>
            <Tooltip title="Löschen">
              <Button
                className="table-action-btn red"
                aria-label="Löschen"
                onClick={() => {
                  if(confirm("Sind Sie sicher, dass Sie diesen Beitrag löschen möchten?")){
                    this.onDelete(row.id);
                  }
                }}
              >
                <FontAwesomeIcon icon={['fas', 'trash-alt']} />
              </Button>
            </Tooltip>
            <Tooltip title="In Freigabe geben">
              <Button
                className="table-action-btn teal"
                onClick={() => this.startPublishingProcess(row)}
                aria-label="In Freigabe geben"
              >
                <FontAwesomeIcon icon={['fas', 'paper-plane']} />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ];

    if(this.state.currentTableType == newsletterType.event){
      columns = columns.filter(x => x.title != "Art");
    }

    let drafts = this.state.currentTableData.result;

    return (
      <>
        <div>
          <h1 className="inline">
            Drafts{' '}
            <FontAwesomeIcon icon={['fas', 'feather-alt']} className="header-icon" />
          </h1>
          <div className="action-btns inline" style={{ float: 'right' }}>
            <Button
              type="primary"
              className="btn-with-icon yellow"
              onClick={() => this.createNew()}
            >
              Neuen Beitrag erstellen <FontAwesomeIcon icon={['fas', 'plus']} />
            </Button>
          </div>
        </div>

        <Tabs
          onChange={(activeKey) => this.getTableData(activeKey)}
          className="table-tabswitch"
          defaultActiveKey={this.props.newsletterStore.tableType.type}
          tabIndex="-1"
        >
          <TabPane tab={'Newsbeiträge'} key={newsletterType.newsletter} />
          <TabPane tab={'Teaser'} key={newsletterType.teaser} />
          <TabPane tab={'Veranstaltungen'} key={newsletterType.event} />
        </Tabs>

        <Table
          getPopupContainer={element => element.parentElement}
          key="draftTable"
          rowKey="id"
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            total: this.state.currentTableData.total,
          }}
          scroll={{ scrollToFirstRowOnChange: true, x: true }}
          dataSource={drafts}
          columns={columns}
          loading={loading}
          onChange={(p, f, s) => {
            this.setState({ currentPage: p.current }, async () => {
              await this.getTableData(this.state.currentTableType, f, s);
            });
          }}
        />

        {this.state.showModal && (
          <NewsletterWizard
            onCancel={this.onCancel}
            showTypeSelect={this.state.showTypeSelect}
            newsletterType={this.state.modalToOpen}
            fromDraftTable={true}
            handleDirtyForm={this.handleDirtyForm.bind(this)}
          />
        )}

        {this.state.showChangelogModal && (
          <ChangelogModal
            onCancel={this.cancelChangelogModal}
            entity={this.state.entityForChangelog}
            type={this.state.currentTableType}
          />
        )}
      </>
    );
  }
}
