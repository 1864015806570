import { observable, action } from 'mobx';
import axios from 'axios';
import { remove, trim } from 'lodash-es';

import { apiUrl, defaultGuid } from '../globalVar';
import { openNotificationWithIcon } from '../utils/functions';
import { Catch } from '../utils/TryCatchDecorator';

/**
 * This is a base enitities store which loads a list or single entity and provides funcionality to manipulate it.
 *
 * Additional functionalities may be added ONLY IF said functionality is needed in all stores which extend this base store
 * IF that is not the case add a new store which extends this one and provides the additional features (e.g. a BaseUploadStore)
 */
export default class BaseEntitiesStore {
  @observable
  entities = [];

  @observable
  entity = {};

  /**
   *
   * @param {string} path - path of your api request without ./api prefix. The base store will build the url based on the apiUrl defined in globalVar.js
   * @param {string} name - name of your Store, only needed for debugging purpose, code will not be part of prod build
   */
  constructor(path = '', name = 'BaseEntitiesStore') {
    this.path = `${apiUrl}/${trim(path, ' /').toLowerCase()}` || '';
    this.name = name;
  }

  @action('Set entities')
  setEntities(entities) {
    this.entities = entities;
  }

  @action('Add entity to array')
  addEntity(entity) {
    this.entities.push(entity);
  }

  @action('Set entity')
  setEntity(entity) {
    this.entity = entity;
  }

  @action('Delete entity from array')
  deleteEntity(id) {
    remove(this.entities, (x) => x.id === id);
  }

  @action('Update entity in array')
  modifyEntity(entity) {
    Object.assign(
      this.entities.find((x) => x.id === entity.id),
      entity
    );
  }

  async getAll() {
    var resp = await axios.get(`${this.path}`);
    this.setEntities(resp.data);
  }

  async get(id) {
    var resp = await axios.get(`${this.path}/${id}`);
    this.setEntity(resp.data);
  }

  async post(entity) {
    if (!entity.id) {
      entity.id = 0;
    }
    let resp = await axios.post(this.path, entity);
    this.addEntity(resp.data);
  }

  async delete(id) {
    await axios.delete(`${this.path}/${id}`);
    this.deleteEntity(id);
  }

  async put(id, entity) {
    await axios.put(`${this.path}/${id}`, entity);
    this.modifyEntity(entity);
  }
}
