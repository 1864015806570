import React from 'react';
import { Row, Col, Collapse } from 'antd';
import { normalizeUrl } from 'utils/functions';

class SourceList extends React.Component {
  addStyleToHead = (uid, content) => {
    let style = `#${uid}::after{content: "[${content}]"; vertical-align: super; font-size: 14px;}`;
    let cssDoc = document.createElement('style');
    cssDoc.type = 'text/css';
    cssDoc.id = `${uid}-style`;
    let textNode = document.createTextNode(style);
    cssDoc.appendChild(textNode);

    document.head.appendChild(cssDoc);
  };

  componentDidMount() {
    if (this.props.entity && this.props.addStyles) {
      this.props.entity.sources?.forEach((x) => {
        if (x.reference) {
          this.addStyleToHead(`mce-${x.reference}`, x.reference);
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.props.entity && this.props.addStyles) {
      this.props.entity.sources?.forEach((x) => {
        if (x.reference) {
          document.getElementById(`mce-${x.reference}-style`)?.remove();
        }
      });
    }
  }

  render() {
    let entity = this.props.entity;
    let sources = [];
    if (entity?.sources) {
      entity.sources.forEach((x) =>
        sources.push(
          !x.isLink ? (
            <div key={x.reference}>
              <div className="sourecs">
                {x.reference && <div className="number">[{x.reference}]</div>}
                <div className="text">{x.text}</div>
              </div>
            </div>
          ) : (
            <a href={normalizeUrl(x.text)} target="_blank" className="sourecs underlined" key={x.reference}>
              {x.reference && <div className="number">[{x.reference}]</div>}
              <div className="text">{x.text}</div>
            </a>
          )
        )
      );
    }

    return (
      <>
        {this.props.addStyles && Array.isArray(sources) && sources.length > 0 && (
          <div className="collapse-header">
            <Collapse ghost expandIconPosition="right">
              <Collapse.Panel header={<span className="section-title">Quellenverzeichnis</span>}>
                {sources}
              </Collapse.Panel>
            </Collapse>
          </div>
        )}
      </>
    );
  }
}

export default SourceList;
