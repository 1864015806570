import { Card, Row, Skeleton } from 'antd';
import { newsletterType } from 'App/globalVar';
import { inject } from 'mobx-react';
import React from 'react';
import EventContentDates from './Event/EventContentDates';
import EventContentText from './Event/EventContentText';
import EventFooter from './Event/EventFooter';
import EventImage from './Event/EventImage';
import SourceList from './SourceList';
import StackNavigator from './StackNavigator';
import { openNotificationWithIcon } from 'utils/functions';

@inject('newsletterStore', 'uiStore', 'breadcrumbStore')
class LazyEventEntry extends React.Component {
  state = {
    entityId: null,
    fromDidMount: false
  };

  async componentDidMount() {
    this.setState({ entityId: this.props.newsletterStore.entityId, fromDidMount: true });
  }

  async shouldComponentUpdate(nextProps, nextState){
    let entityId = this.props.newsletterStore.entityId;
    let entityType = this.props.newsletterStore.entityType;

    if(nextState.fromDidMount || (entityId != nextState.entityId && entityType == newsletterType.event)){
      this.setState({entityId: entityId, fromDidMount: false});
      await this.loadEntityLazily(entityId);
    }
  }

  loadEntityLazily = async (entityId) => {
    // make 3 calls
    // set parts of the content in newsletterStore
    let urlType = newsletterType.event;
    let isProcessUrl = this.props.isProcessUrl;
    let store = this.props.newsletterStore;

    try {
      await store.getByIdLazy(entityId, urlType, isProcessUrl);
      if(this.props.onMetaLoaded){
        await this.props.onMetaLoaded();
      }
      store
        .getAndSetContent(entityId, urlType, isProcessUrl)
        .then(() => this.forceUpdate());
      store
        .getAndSetThumbnail(entityId, urlType, isProcessUrl)
        .then(() => this.forceUpdate());
      store
        .getAndSetGallery(entityId, urlType, isProcessUrl)
        .then(() => this.forceUpdate());
        
        if(window.location.pathname.includes('newsletter'))
        {
          this.props.breadcrumbStore.addCustomToBreadcrumbs(store.entity.title, window.location.pathname + window.location.search);
        }
    } catch (err) {
      if (Object.keys(store.entity).length == 0) {
        this.giveErrorAndRedirect();
      }
    }
  }

  componentWillUnmount() {
    this.props.newsletterStore.setEntity({});
    this.props.newsletterStore.eventEntity = {};
  }

  giveErrorAndRedirect = () => {
    openNotificationWithIcon(
      'error',
      'Ein Fehler beim Laden des Beitrags ist aufgetreten.'
    );
    this.props.uiStore.navigateToReturnLink('newsletterPublishPage');
  };

  render() {
    let event = this.props.newsletterStore.entity;

    if(!event || Object.keys(event).length == 0)
      return <Skeleton active />;

    return (
      <>
        <div className="card">
          <Card
            hoverable={this.props.hoverable}
            onClick={this.props.onClick}
            tabIndex={0}
            role="article"
            className="antd-card"
          >
            <div
              className={
                this.props.isCard ? 'card-content eventcard' : 'card-content events'
              }
            >
              <div className="card-body">
                {!!event?.content ? (
                  <>
                    <EventContentText
                      event={event}
                      showTeaser={this.props.showTeaser}
                      addStyles={this.props.addStyles}
                    />
                    {this.props.addStyles && (
                      <>
                        <SourceList entity={event} addStyles={this.props.addStyles} />
                      </>
                    )}
                  </>
                ) : (
                  <Skeleton loading={true} />
                )}
                <EventContentDates event={event} addStyles={this.props.addStyles} />
                <EventFooter
                  event={event}
                  addIdToLink={this.props.addIdToLink}
                  isCard={this.props.isCard}
                />
              </div>
              {!!event?.thumbnail ? (
                <EventImage
                  displayThumbnailTitle={this.props.displayThumbnailTitle}
                  event={event}
                />
              ) : (
                <div className="lazy-loading-image-container">
                  <Skeleton className="lazy-loading-image" loading={true} />
                </div>
              )}
            </div>
          </Card>
          {(event?.previousNewsletterId ||
            this.props.uiStore.newsletterNavigationStack.length > 0) &&
            this.props.showNavigator && (
              <StackNavigator entity={event} type={newsletterType.event} />
            )}
        </div>
      </>
    );
  }
}

export default LazyEventEntry;
