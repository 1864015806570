import axios from 'axios';
import { observable } from 'mobx';
import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class ClusterDraftStore extends BaseEntitiesStore {
  constructor() {
    super('ClusterDraft', 'ClusterDraftStore');
    if (!isProduction) {
      window[`ClusterDraftStore`] = this;
    }
  }
  
  @observable
  async getAllFiltered(filter = undefined) {
    let resp = await axios.get(`${this.path}/filtered?${filter ? filter : ''}`);
    this.setEntities(resp.data);
  }
}

export default new ClusterDraftStore();