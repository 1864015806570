import './Newsletter.scss';

import { inject } from 'mobx-react';
import React from 'react';

import { Button, Skeleton, Tooltip, Table, Tag, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  modalType,
  newsletterState,
  newsletterType,
  ROLES,
  translationMap,
} from '../../globalVar';
import {
  openNotificationWithIcon,
  capitalizeFirstLetter,
  reverseMap,
  accessibleTableTitle,
  accessibleFilterIcon,
  buildFilterFromSimpleEntity,
  getDirtyFormConfirmation,
} from '../../utils/functions';
import dayjs from 'dayjs';
import { dateFormat } from '../../utils/dayjsLocale';
import NewsletterWizard from './WIzard/NewsletterWizard';
import ChangelogModal from './ChangelogModal';

const { TabPane } = Tabs;

@inject('newsletterStore', 'uiStore', 'userStore', 'categoryStore', 'breadcrumbStore')
export default class NewsletterTable extends React.Component {
  state = {
    showModal: false,

    currentTableData: [],
    reverseTypeMap: {},
    reverseStateMap: {},
    currentTableType: newsletterType.newsletter,

    currentPage: 1,
    activeFilters: [],
    activeSorter: {},

    showTypeSelect: false,
    modalToOpen: undefined,

    categoryFilters: [],
    stateFilters: [],

    userRole: null,
    roleStateMap: {},

    showChangelogModal: false,
    entityForChangelog: null,

    stateFilterApplied: false,
    categoryFilterApplied: false,
    isDirtyForm: false,

    loading: true
  };

  async componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumbs('newsletterRelease');
    let sessionFilter = window.sessionStorage.getItem('tableType');
    if (sessionFilter) {
      try {
        sessionFilter = JSON.parse(sessionFilter);
        if (sessionFilter.type) {
          this.props.newsletterStore.tableType = sessionFilter;
        }
      } catch {
        // ignore error
        sessionFilter = null;
      }
    }
    await this.props.newsletterStore.getAllSimple();
    await this.props.userStore.getUsersList();

    let stateFilters = buildFilterFromSimpleEntity(this.transformStatesToSimpleEntity(newsletterState));

    let roleStateMap = {
      [ROLES.winnovation]: newsletterState.inProcessWin,
      [ROLES.publicRelation]: newsletterState.inProcessPR,
      [ROLES.subjectDepartment]: newsletterState.inProcessSD
    };

    this.setState(
      {
        reverseTypeMap: reverseMap(newsletterType),
        reverseStateMap: reverseMap(newsletterState),

        stateFilters: stateFilters,
        userRole: this.props.userStore.currentUser.role,
        loading: false,
        roleStateMap: roleStateMap,
      },
      async () =>
        await this.getFilteredTableData(this.props.newsletterStore.tableType.type, [], {})
    );
  }

  getFilteredTableData = async (type, filters, sorter) => {
    if(!filters)
      filters = this.state.activeFilters;

    if(!sorter)
      sorter = this.state.activeSorter;

    this.props.newsletterStore.tableType.type = type;
    await this.props.categoryStore.getAllTyped(this.props.newsletterStore.tableType.type != newsletterType.event);
    let categoryFilters = buildFilterFromSimpleEntity(this.props.categoryStore.entities);
    this.setState({ currentTableType: type, activeFilters: filters, activeSorter: sorter, loading: false, categoryFilters: categoryFilters }, 
      async () => await this.fetchTableData(this.state.currentTableType));

    window.sessionStorage.setItem(
      'tableType',
      JSON.stringify(this.props.newsletterStore.tableType)
    );
  }

  fetchTableData = async (type) => {
    try{
      let entities;
      if(type == newsletterType.event){
        entities = await this.props.newsletterStore.getEventsSimple(
          this.state.currentPage,
          this.state.activeFilters,
          this.state.activeSorter
        );
      }
      else{
        entities = await this.props.newsletterStore.getAllSimpleTypedPaginated(
          this.state.reverseTypeMap[type],
          this.state.currentPage,
          this.state.activeFilters,
          this.state.activeSorter
        );
      }

      this.setState({currentTableData: entities}); 
    }
    catch{
      openNotificationWithIcon('error', 'Beim Laden der Tabelle ist ein Fehler aufgetreten.');
    }
  }

  onEdit = async (row) => {
    this.props.newsletterStore.editObject = await this.props.newsletterStore.getById(
      row.id,
      this.state.currentTableType
    );
    this.props.newsletterStore.modalType = modalType.edit;
    this.setState({
      showModal: true,
      showTypeSelect: false,
      modalToOpen: this.state.currentTableType,
    });
  };

  handleDirtyForm(isDirtyForm) {
    this.setState({ isDirtyForm: isDirtyForm });
  }

  onCancel = async () => {
    let cancelDirtyForm = getDirtyFormConfirmation(this.state.isDirtyForm);

    if (cancelDirtyForm) {
      this.props.newsletterStore.editObject = {};
      this.props.newsletterStore.modalType = undefined;

      this.setState(
        {
          showModal: false,
          isDirtyForm: false
        },
        async () => this.getFilteredTableData(this.state.currentTableType)
      );
    }
  };

  openChangelogModal = (row) => {
    this.setState({showChangelogModal: true, entityForChangelog: row});
  }

  cancelChangelogModal = () => {
    this.setState({showChangelogModal: false, entityForChangelog: null});
  }

  publishNews = async (id) => {
    this.props.uiStore.setReturnLink('newsletterPublishPage', '/newsletter/release');
    this.props.uiStore.navigateTo(`/newsletter/${id}/${this.state.currentTableType}?fromNews=true`);
  };

  transformStatesToSimpleEntity = () => {
    let states = { ...newsletterState };
    delete states['draft'];
    let arr = [];
    Object.keys(states).forEach(key => {
      arr.push({
        name: translationMap[key],
        id: states[key]
      });
    });
    return arr;
  }

  updateStateFilterIcon = (iconState) => {
    this.setState({stateFilterApplied: iconState});
  }

  updateCategoryFilterIcon = (iconState) => {
    this.setState({categoryFilterApplied: iconState});
  }

  render() {
    let loading = this.state.loading;

    if (loading) return <Skeleton loading={loading} />;

    const categoryText =
      this.props.newsletterStore.tableType.type != newsletterType.event
        ? 'Arten des Beitrags'
        : 'Kategorien';
    let columns = [
      {
        title: accessibleTableTitle('Veröffentlicht'),
        dataIndex: 'publishingDate',
        key: 'publishingDate',
        sorter: true,
        render: (date) => {
          return date ? dayjs(date).format(dateFormat) : 'Nicht veröffentlicht';
        },
      },
      {
        title: accessibleTableTitle('Titel'),
        dataIndex: 'title',
        key: 'title',
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'state',
        key: 'state',
        filters: this.state.stateFilters,
        ...accessibleFilterIcon(
          'state-filter',
          this.state.stateFilterApplied,
          this.updateStateFilterIcon,
          'Status Filter'
        ),
        render: (state) => {
          return capitalizeFirstLetter(translationMap[this.state.reverseStateMap[state]]);
        },
      },
      {
        title: categoryText,
        dataIndex: 'categories',
        key: 'categories',
        filters: this.state.categoryFilters,
        ...accessibleFilterIcon(
          'category-filter',
          this.state.categoryFilterApplied,
          this.updateCategoryFilterIcon,
          `${categoryText} Filter`
        ),
        render: (categories) => {
          return categories.map(
            (x) =>
              x && (
                <div className="table-tag"  key={x.id}>
                  <Tag color="geekblue" key={x.id}>
                    {x.name}
                  </Tag>
                </div>
              )
          );
        },
      },
      {
        title: accessibleTableTitle('ÖA-Relevant'),
        dataIndex: 'isPRRelevant',
        key: 'isPRRelevant',
        sorter: true,
        render: (isPRRelevant) => {
          return isPRRelevant ? 'Ja': 'Nein';
        }
      },
      {
        title: accessibleTableTitle('Zuletzt bearbeitet am'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: true,
        render: (updatedAt) => {
          return updatedAt ? dayjs(updatedAt).format(dateFormat) : '';
        },
      },
      {
        title: accessibleTableTitle('Zuletzt bearbeitet von'),
        dataIndex: 'updatedById',
        key: 'updatedById',
        sorter: true,
        render: (updatedById) => {
          return this.props.userStore.usersList.slice().find((x) => x.id === updatedById)
            ?.email;
        },
      },
      {
        title: 'Aktionen',
        key: 'actions',
        align: 'center',
        width: 220,
        render: (_, row) => (
          <div className="table-actions">
            {(row.state == newsletterState.inProcessWin && this.state.userRole == ROLES.winnovation) && (
                <Tooltip title="Bearbeiten">
                  <Button
                    className="table-action-btn yellow"
                    onClick={() => this.onEdit(row)}
                    aria-label="Bearbeiten"
                  >
                    <FontAwesomeIcon icon={['fas', 'pencil']} />
                  </Button>
                </Tooltip>
              )}
            <Tooltip title="Weitere Informationen">
              <Button
                className="table-action-btn blue-light"
                onClick={() => this.openChangelogModal(row)}
                aria-label="Weitere Informationen"
              >
                <FontAwesomeIcon icon={['fas', 'info']} />
              </Button>
            </Tooltip>
            {(row.state != newsletterState.published && this.state.userRole != ROLES.user && row.state == this.state.roleStateMap[this.state.userRole]) && (
                <Tooltip title="Status ändern">
                  <Button
                    className="table-action-btn teal"
                    onClick={() => this.publishNews(row.id)}
                    aria-label="Status ändern"
                  >
                    <FontAwesomeIcon icon={['fas', 'paper-plane']} />
                  </Button>
                </Tooltip>
              )}
          </div>
        ),
      },
    ];

    if(this.state.currentTableType == newsletterType.event){
      columns = columns.filter(x => x.title != "Art");
    }

    let newsletters = this.state.currentTableData.result;

    return (
      <>
        <div>
          <h1 className="inline">
            Freigaben{' '}
            <FontAwesomeIcon icon={['fas', 'newspaper']} className="header-icon" />
          </h1>
        </div>
        <Tabs
          onChange={(activeKey) => this.getFilteredTableData(activeKey)}
          className="table-tabswitch"
          defaultActiveKey={this.props.newsletterStore.tableType.type}
          tabIndex="-1"
        >
          <TabPane tab={'Newsbeiträge'} key={newsletterType.newsletter} />
          <TabPane tab={'Teaser'} key={newsletterType.teaser} />
          <TabPane tab={'Veranstaltungen'} key={newsletterType.event} />
        </Tabs>

        <Table
          getPopupContainer={element => element.parentElement}
          key="newsletterTable"
          rowKey={'id'}
          pagination={{
            showSizeChanger: false,
            pageSize: 10,
            total: this.state.currentTableData?.total,
            current: this.state.currentPage,
          }}
          scroll={{ scrollToFirstRowOnChange: true, x: true }}
          dataSource={newsletters}
          columns={columns}
          loading={loading}
          onChange={(p, f, s) => {
            this.setState({ currentPage: p.current }, async () => {
              await this.getFilteredTableData(this.state.currentTableType, f, s);
            });
          }}
        />

        {this.state.showModal && (
          <NewsletterWizard
            onCancel={this.onCancel}
            showTypeSelect={this.state.showTypeSelect}
            newsletterType={this.state.modalToOpen}
            fromDraftTable={false}
            handleDirtyForm={this.handleDirtyForm.bind(this)}
          />
        )}

        {this.state.showChangelogModal && (
          <ChangelogModal
            onCancel={this.cancelChangelogModal}
            entity={this.state.entityForChangelog}
            type={this.state.currentTableType}
          />
        )}
      </>
    );
  }
}
