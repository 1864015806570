import React, { Component, createRef } from 'react';
import { observer, inject } from 'mobx-react';
import { map } from 'lodash-es';

// importing styles and helpers
import '../Modals/Modals.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// importing components
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  InputNumber,
  Steps,
  Divider,
  Switch,
  Card,
  Tooltip,
} from 'antd';
import {
  LeftOutlined,
  RightOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {
  getDirtyFormConfirmationWithCheck,
  openNotificationWithIcon,
} from 'utils/functions';
import {
  phoneRegex,
  clusterState,
  locationRegex,
  urlRegex,
  httpsPrefixRegex,
} from 'App/globalVar';
import PersonNotFoundText from 'components/Shared/PersonNotFoundText';
import PhoneNumberFormItem from 'components/Shared/PhoneNumberFormItem';

const { Step } = Steps;

@inject('clusterStore', 'clusterHolderStore', 'nuts3Store')
@observer
export default class ClusterModal extends Component {
  formRef = createRef();

  state = {
    currentStep: 0,

    currentFormData: {},

    managerPublicSwitchData: true,
    isManagerMainContactSwitchData: true,

    contactPersons: [],

    clusterManagerEmail: {
      value: '',
    },

    contactPersonEmails: [],

    knownEmails: [],
    isDirtyFormSaving: false,
  };

  async componentDidMount() {
    await this.props.nuts3Store.getAll();
    let isManagerMainContact = true;
    let contactPersons = this.props.entity.contactPersons ?? [];
    let contactPersonEmails = contactPersons.map((x) => {
      return {
        value: x.email,
      };
    });
    if (this.props.entity.clusterManager?.id !== this.props.entity.mainContact?.id) {
      isManagerMainContact = false;
      contactPersons.find(
        (x) => x.id === this.props.entity.mainContact?.id
      ).isMainContact = true;
    }

    this.setState({
      managerPublicSwitchData: this.props.entity.isClusterManagerPublic,
      contactPersons: contactPersons,
      isManagerMainContactSwitchData: isManagerMainContact,
      knownEmails: this.props.usersList.map((x) => x.email),
      contactPersonEmails: contactPersonEmails,
    });
  }

  changeStep = async (step) => {
    if (step > this.state.currentStep) {
      await this.formRef.current?.validateFields();
    }
    // this prevents jumping from tab 1 to tab 3
    if (step - 1 == this.state.currentStep || step + 1 === this.state.currentStep) {
      this.setState({ currentStep: step });
    }
  };

  onCancel = async () => {
    let cancelDirtyForm = true;
    if (!this.state.isDirtyFormSaving) {
      cancelDirtyForm = getDirtyFormConfirmationWithCheck(
        this.formRef.current?.getFieldsValue(),
        this.props.clusterStore.entity
      );
    }

    if (cancelDirtyForm) {
      this.setState({
        currentStep: 0,
        managerPublicSwitchData: true,
        isManagerMainContactSwitchData: true,
        isDirtyFormSaving: false,
      });

      this.formRef.current?.resetFields();
      this.props.clusterStore.entity = {};
      await this.props.clusterHolderStore.getAll();
      await this.props.toggleClusterAnlegenModal(false, false, true);
    }
  };

  nextStep = async () => {
    await this.formRef.current.validateFields();
    this.updateFormData();
    await this.changeStep(this.state.currentStep + 1);
  };

  updateFormData = async (callBack) => {
    let formValues = this.formRef.current?.getFieldsValue();
    let clusterHolders = [];
    let clusterHoldersIds = [];

    if (formValues.hasOwnProperty('clusterHolderIds')) {
      formValues.clusterHolderIds?.forEach((value) => {
        if (typeof value == 'string') clusterHolders.push({ name: value });
        else clusterHoldersIds.push(value);
      });
      formValues.clusterHolderIds = clusterHoldersIds;
      formValues.clusterHolders = clusterHolders;
    }

    if (callBack)
      this.setState(
        {
          currentFormData: {
            ...this.state.currentFormData,
            ...formValues,
          },
        },
        async () => await callBack()
      );
    else
      this.setState({
        currentFormData: {
          ...this.state.currentFormData,
          ...formValues,
        },
      });
  };

  // since we are using tabs in this form, not all form data is available immediately.
  // in fact, the form data gets loaded when a new tab has been reached
  // this leads to us having to use the state as our source of truth for the contactPersons list
  // since by the time you can access the "isMainContact" field, the contactsPersons list has not been loaded yet
  mainContactChanged = (value, x) => {
    let stateContacts = this.state.contactPersons;
    let contactPersonEmails = this.state.contactPersonEmails;

    if (x == 'manager') {
      if (value) {
        stateContacts?.forEach((x) => (x.isMainContact = false));
      } else {
        if (this.state.contactPersons.length < 1) {
          let newEntry = {
            email: undefined,
            isMainContact: true
          };
          contactPersonEmails = this.state.contactPersonEmails;
          contactPersonEmails.push({
            value: '',
          });
          let contactPersonHelper = this.state.contactPersons;
          contactPersonHelper.push(newEntry);
          this.setState({
            contactPersons: contactPersonHelper,
            contactPersonEmails: contactPersonEmails,
          });
        } else if (stateContacts.filter((x) => x.isMainContact).length < 1) {
          stateContacts[0].isMainContact = true;
        }
      }
      this.setState({
        isManagerMainContactSwitchData: value,
        contactPersons: stateContacts,
      });
    } else {
      let mainContacts = stateContacts.filter((x) => x.isMainContact === true);
      if (value && mainContacts.length > 1) {
        stateContacts.forEach((x) => (x.isMainContact = false));
        stateContacts[x].isMainContact = true;
      } else if (!value && mainContacts.length < 1) {
        stateContacts[x].isMainContact = true;
      }
    }

    const formContacts = this.formRef.current?.getFieldsValue().contactPersons;
    if(formContacts){
      stateContacts.forEach(stateContact => stateContact.functionArea = formContacts.find(x => x.email === stateContact.email).functionArea);
    }

    this.setState(
      { contactPersons: stateContacts, contactPersonEmails: contactPersonEmails },
      () => {
        this.formRef.current?.setFieldsValue({
          contactPersons: this.state.contactPersons,
        });
      }
    );
  };

  checkIfDeletedWasMainContact = () => {
    let formData = this.formRef.current?.getFieldsValue();
    if (formData.contactPersons.length < 1) {
      this.setState({ isManagerMainContactSwitchData: true });
    } else if (formData.contactPersons.filter((x) => x.isMainContact).length < 1) {
      formData.contactPersons[0].isMainContact = true;
      this.formRef.current?.setFieldsValue({ ...formData });
    }
  };

  onFinish = async () => {
    this.updateFormData(async () => {
      try {
        let cluster = await this.props.clusterStore.updateWithValidityCheck(
          this.state.currentFormData.id,
          {
            ...this.state.currentFormData,
            isClusterManagerPublic: this.state.managerPublicSwitchData,
            isManagerMainContact: this.state.isManagerMainContactSwitchData,
          }
        );
        if (cluster.clusterState == clusterState.clusterInProcess) {
          openNotificationWithIcon(
            'success',
            'Cluster wurde erfolgreich bearbeitet und in die Freigabe gegeben!'
          );
        } else {
          openNotificationWithIcon(
            'success',
            'Cluster wurde erfolgreich bearbeitet, konnte jedoch nicht in die Freigabe gegeben werden.'
          );
          this.props.setShowAlertInactiveUsers(true);
          this.props.setCurrentClusterInfo(cluster);
        }
        this.formRef.current.resetFields();
        this.setState({ isDirtyFormSaving: true });
        this.onCancel();
      } catch (ex) {
        let notificiationText =
          'Es ist ein unerwarteter Fehler beim Speichern aufgetreten';
        if (ex.response.status === 409) {
          notificiationText = 'Ein Cluster mit diesem Namen existiert bereits.';
        }
        openNotificationWithIcon('error', notificiationText);
      }
    });
  };

  saveWithoutValidityCheck = () => {
    this.updateFormData(async () => {
      try {
        await this.formRef.current?.validateFields();
        const updatedCluster = await this.props.clusterStore.put(
          this.state.currentFormData.id,
          {
            ...this.state.currentFormData,
            isClusterManagerPublic: this.state.managerPublicSwitchData,
            isManagerMainContact: this.state.isManagerMainContactSwitchData,
          }
        );

        if (
          this.props.entity.clusterState > clusterState.clusterDraft &&
          (!this.props.usersList.find((x) => x.id === updatedCluster.clusterManager.id) ||
            !this.props.usersList.find((x) => x.id === updatedCluster.mainContact.id))
        ) {
          openNotificationWithIcon('warning', 'Cluster wurde erfolgreich bearbeitet!', {
            description:
              'Achtung: Clustermanagende Person oder Hauptansprechperson haben keinen aktivierten Account. Dies könnte die Kommunikation mit dem Cluster oder das Management dieses Clusters erschweren.',
            duration: 10,
          });
        } else {
          openNotificationWithIcon('success', 'Cluster wurde erfolgreich bearbeitet!');
        }
        this.formRef.current.resetFields();
        this.setState({ isDirtyFormSaving: true });
        this.onCancel();
      } catch (ex) {
        let notificiationText =
          'Es ist ein unerwarteter Fehler beim Speichern aufgetreten';
        if (ex.response?.status === 409) {
          notificiationText = 'Ein Cluster mit diesem Namen existiert bereits.';
        }
        openNotificationWithIcon('error', notificiationText);
      }
    });
  };

  setContactPersonState = (values, field) => {
    let contactPersonEmails = this.state.contactPersonEmails;
    let formData = this.formRef.current?.getFieldsValue('contactPersons');
    contactPersonEmails[field] = {value: values};
    formData.contactPersons[field].email = values;
    this.formRef.current?.setFieldsValue(formData);
    this.setState({ contactPersonEmails: contactPersonEmails, contactPersons: formData.contactPersons });
  };

  postalCodeBlur = async (e) => {
    let postalCode = e.target.value;
    if(isFinite(postalCode)){
      if (postalCode.length > 4) {
        return;
      }
      const nuts = await this.props.nuts3Store.getNuts3ByPostalCode(postalCode);
      if(!!nuts){
        this.formRef.current?.setFieldsValue({ nuts3Id: nuts.id });
      }
    }
  }

  render() {
    let industries = this.props.industries;
    let nuts3 = this.props.nuts3Store.entities.slice();
    let states = this.props.states;
    let clusterHolder = this.props.clusterHolders;
    let usersList = this.props.usersList;
    let clusterClasses = this.props.clusterClasses;

    const steps = [
      {
        title: 'Allgemeine Clusterdaten',
        content: (
          <>
            <Form.Item hidden name={'id'}>
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="name"
              label="Name des Clusters"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie einen Namen für den Cluster an.',
                },
              ]}
            >
              <Input placeholder="Mustercluster" />
            </Form.Item>
            <Form.Item
              name="clusterHolderIds"
              label="Trägerorganisationen"
              rules={[
                {
                  required: true,
                  message: 'Bitte wählen Sie zumindest eine Trägerorganisation aus.',
                },
              ]}
            >
              <Select
                placeholder="Musterträger"
                mode="tags"
                showSearch
                filterOption={(input, option) => {
                  return typeof option.value === 'string'
                    ? option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {clusterHolder.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="industryIds"
              label="Branche"
              rules={[
                {
                  required: true,
                  message: 'Bitte wählen Sie zumindest eine Branche aus.',
                },
              ]}
            >
              <Select
                placeholder="Branche"
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {map(industries, (industry) => (
                  <Select.Option key={industry.id} value={industry.id}>
                    {industry.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="clusterClassId" label="Klasse">
              <Select
                allowClear
                placeholder="Klasse"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {map(clusterClasses, (clusterClass) => (
                  <Select.Option key={clusterClass.id} value={clusterClass.id}>
                    {clusterClass.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="eccp"
              label={
                <>
                  <FontAwesomeIcon
                    icon={['fas', 'link']}
                    className="link-icon not-required"
                  />
                  ECCP-Link{' '}
                  <Tooltip title="European Cluster Collaboration Platform">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[
                {
                  pattern: httpsPrefixRegex,
                  message:
                    'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                },
                {
                  pattern: urlRegex,
                  message: 'Geben Sie bitte eine valide Url an.',
                },
              ]}
            >
              <Input placeholder="https://clustercollaboration.eu" />
            </Form.Item>
            <p
              style={{
                fontSize: 14,
                color: 'grey',
              }}
            >
              Sollte dieser Cluster noch nicht offiziell bei ECCP angelegt sein, klicken
              Sie bitte{' '}
              <a
                className="underlined"
                href="https://clustercollaboration.eu/user/register"
                target="_blank"
              >
                "hier"
              </a>{' '}
              und melden diesen an.
            </p>
          </>
        ),
      },
      {
        title: 'Standortinformationen',
        content: (
          <>
            <Form.Item
              name="stateId"
              label="Bundesland"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie ein Bundesland an.',
                },
              ]}
            >
              <Select
                placeholder="Bundesland"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {states.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="postalCode"
              label="Postleitzahl"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie eine Postleitzahl an.',
                },
                {
                  type: 'number',
                  max: 9999,
                  min: 1000,
                  message: 'Die Postleitzahl darf aus 4 Ziffern bestehen.',
                },
              ]}
            >
              <InputNumber
                min={1000}
                max={9999}
                placeholder="1010"
                onBlur={(e) => this.postalCodeBlur(e)}
              />
            </Form.Item>
            <Form.Item
              name="location"
              label="Ort"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie einen Ort an.',
                },
                {
                  pattern: locationRegex,
                  message: 'Bitte geben Sie einen validen Ortsnamen an.',
                },
              ]}
            >
              <Input placeholder="Wien" />
            </Form.Item>
            <Form.Item
              name="street"
              label="Straße"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie eine Straße an.',
                },
                {
                  pattern: locationRegex,
                  message: 'Der Straßenname darf nur aus Buchstaben bestehen.',
                },
              ]}
            >
              <Input placeholder="Musterstraße" />
            </Form.Item>
            <Form.Item
              name="houseNumber"
              label="Hausnummer"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie eine Hausnummer an.',
                },
              ]}
            >
              <InputNumber placeholder="30" />
            </Form.Item>
            <Form.Item name="additionalLocationData" label="Adresszusatz">
              <Input placeholder="Tür 3" />
            </Form.Item>
            <Form.Item
              name="nuts3Id"
              label={
                <>
                  NUTS-3-Region{' '}
                  <a
                    className="cluster-anlegen-link underlined"
                    href="https://ec.europa.eu/eurostat/web/nuts/background"
                    target="_blank"
                  >
                    <InfoCircleOutlined />
                  </a>
                </>
              }
              rules={[
                {
                  required: true,
                  message: 'Bitte wählen Sie eine Nuts3-Region aus.',
                },
              ]}
            >
              <Select
                placeholder="Nuts3-Region"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {nuts3.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {`${item.nutsCode} - ${item.nutsName}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="website"
              label={
                <>
                  <FontAwesomeIcon icon={['fas', 'link']} className="link-icon" />
                  Link zur Webseite
                </>
              }
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie einen Link zur Webseite an.',
                },
                {
                  pattern: httpsPrefixRegex,
                  message:
                    'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                },
                {
                  pattern: urlRegex,
                  message: 'Geben Sie bitte eine valide Url an.',
                },
              ]}
            >
              <Input placeholder="https://your-domain.at" />
            </Form.Item>
            <Form.Item
              name="generalEmail"
              label="Allgemeine Email-Adresse"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Bitte geben Sie eine allgemeine Email-Adresse an.',
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
            >
              <Input placeholder="mustercluster@my-mail.com" />
            </Form.Item>
            <PhoneNumberFormItem
              name="generalPhoneNumber"
              label="Allgemeine Telefonnummer"
              disabled={this.state.isViewMode}
              required={true}
            />
          </>
        ),
      },
      {
        title: 'Clustermanagement Informationen',
        content: (
          <>
            <Form.Item hidden name="clusterManagerId">
              <Input />
            </Form.Item>
            <Form.Item
              name={['clusterManager', 'email']}
              label="Clustermanagende Person"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Bitte geben Sie eine Clustermanagende Person an.',
                },
              ]}
            >
              <Select
                allowClear
                placeholder="Max Mustermann"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {usersList.map((x) => (
                  <Select.Option key={x.id} value={x.email}>
                    {x.fullname}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <PersonNotFoundText />
            <Form.Item
              name="isClusterManagerPublic"
              label="Kontaktdaten Clustermanagende Person öffentlich sichtbar?"
            >
              <Switch
                className="switch yellow"
                checked={this.state.managerPublicSwitchData}
                onChange={(value) => this.setState({ managerPublicSwitchData: value })}
              />
            </Form.Item>
            <Form.Item
              name="isManagerMainContact"
              label="Clustermanagende Person Hauptansprechperson?"
              help="Sie müssen mindestens eine Person als Hauptansprechperson markieren."
            >
              <Switch
                className="switch yellow"
                checked={this.state.isManagerMainContactSwitchData}
                onChange={(value) => this.mainContactChanged(value, 'manager')}
              />
            </Form.Item>
          </>
        ),
      },
      {
        title: 'Weitere Kontaktpersonen',
        content: (
          <>
            <Form.List name="contactPersons">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restFields }) => (
                    <Card className="contact-card">
                      <Form.Item
                        name={[name, 'email']}
                        label="Kontaktperson"
                        rules={[
                          {
                            required: true,
                            type: 'email',
                            message:
                              'Bitte geben Sie eine Rolle / einen Sektor für diese Person an.',
                          },
                        ]}
                      >
                        <Select
                          allowClear
                          placeholder="Max Mustermann"
                          showSearch
                          filterOption={(input, option) =>
                            option.children?.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          }
                          onChange={(value) => this.setContactPersonState(value, name)}
                        >
                          {usersList.map((x) => (
                            <Select.Option key={x.id} value={x.email}>
                              {x.fullname}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[name, 'functionArea']}
                        label="Rolle / Sektor"
                        rules={[
                          {
                            required: true,
                            message: 'Bitte geben Sie eine Rolle / einen Sektor an.',
                          },
                        ]}
                      >
                        <Input placeholder="Rolle / Sektor" />
                      </Form.Item>
                      <PersonNotFoundText />
                      {!this.state.isManagerMainContactSwitchData && (
                        <Form.Item
                          valuePropName="checked"
                          name={[name, 'isMainContact']}
                          label="Ist Hauptansprechperson?"
                        >
                          <Switch
                            className="switch yellow"
                            onChange={(checked) => {
                              let stateContacts = this.state.contactPersons;
                              stateContacts[name].isMainContact = checked;
                              this.setState({ contactPersons: stateContacts });
                              this.mainContactChanged(checked, name);
                            }}
                          />
                        </Form.Item>
                      )}
                      <div className="delete-contact-button-wrapper">
                        <Button
                          className="delete-contact-button"
                          type="secondary"
                          onClick={() => {
                            // remove function is not fast enough for check, so we "await" the promise resolving
                            new Promise((resolve) => {
                              let contactPersonEmails = this.state.contactPersonEmails;
                              contactPersonEmails.splice(name, 1);
                              this.setState({
                                contactPersons: this.state.contactPersons.filter(
                                  (x, idx) => idx != name
                                ),
                                contactPersonEmails: contactPersonEmails,
                              });

                              remove(name);
                              resolve();
                            }).then((x) => {
                              if (!this.state.isManagerMainContactSwitchData) {
                                this.checkIfDeletedWasMainContact();
                              }
                            });
                          }}
                          icon={<MinusOutlined />}
                        >
                          Kontaktperson entfernen
                        </Button>
                      </div>
                    </Card>
                  ))}
                  <div className="add-contact-button-wrapper">
                    <Button
                      className="add-contact-button"
                      onClick={() => {
                        let newEntry = {
                          email: undefined,
                        };
                        let contactPersonEmails = this.state.contactPersonEmails;
                        contactPersonEmails.push({
                          value: '',
                          functionArea: '',
                        });
                        add(newEntry);
                        this.setState({
                          contactPersons: [...this.state.contactPersons, newEntry],
                          contactPersonEmails: contactPersonEmails,
                        });
                      }}
                      icon={<PlusOutlined />}
                    >
                      Weitere Kontaktperson hinzufügen
                    </Button>
                  </div>
                </>
              )}
            </Form.List>
          </>
        ),
      },
    ];

    let footerButtons = [
      <Button onClick={this.onCancel} style={{ float: 'left' }}>
        Abbrechen <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon rotate" />
      </Button>,
    ];

    if (this.state.currentStep !== 0) {
      footerButtons.push(
        <Button
          key="backButton"
          type="primary"
          className="teal"
          onClick={async () => await this.changeStep(this.state.currentStep - 1)}
        >
          Zurück <LeftOutlined />
        </Button>
      );
    }

    let advancingButtons = <></>; // NOSONAR

    if (this.state.currentStep === steps.length - 1) {
      if (this.props.entity.clusterState > clusterState.clusterDraft) {
        advancingButtons = (
          <Button onClick={this.saveWithoutValidityCheck}>Speichern</Button>
        );
      } else {
        advancingButtons = (
          <>
            <Button onClick={this.saveWithoutValidityCheck}>Speichern</Button>
            <Button
              trigger="click"
              className="cluster-dropdown-btn yellow"
              key="submit"
              type="primary"
              onClick={() => this.formRef.current?.submit()}
            >
              Speichern und in Freigabe geben
            </Button>
          </>
        );
      }
    } else {
      advancingButtons = (
        <Button
          key="continueButton"
          type="primary"
          className="btn-with-icon yellow"
          onClick={async () => await this.nextStep()}
        >
          Weiter <RightOutlined />
        </Button>
      );
    }

    footerButtons.push(advancingButtons);

    return (
      <Modal
        destroyOnClose
        visible={this.props.visible}
        onCancel={this.onCancel}
        title="Cluster bearbeiten"
        footer={footerButtons}
        className="app-modal"
      >
        <div className="modal-content">
          <div className="content-form">
            <Form
              className="add-cluster-form"
              layout="horizontal"
              size="large"
              labelCol={{ span: 10 }}
              labelAlign="left"
              ref={this.formRef}
              initialValues={this.props.entity}
              onFinish={this.onFinish}
              autoComplete="off"
            >
              <Steps
                current={this.state.currentStep}
                onChange={this.changeStep}
                responsive={true}
              >
                {steps.map((step) => (
                  <Step title={step.title} disabled={true} />
                ))}
              </Steps>
              <Divider />
              <div>{steps[this.state.currentStep].content}</div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}
