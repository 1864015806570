import axios from 'axios';

import { isProduction, apiUrl } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class ImportStore extends BaseEntitiesStore {
  constructor() {
    super('Import', 'ImportStore');
    if (!isProduction) {
      window[`ImportStore`] = this;
    }
  }
  async importExcel(formData) {
    let resp = await axios({
      method: 'post',
      url: `${apiUrl}/import`,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return resp;
  }
}

export default new ImportStore();
