import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseEntityAdministration from './BaseEntityAdministration';
import { Skeleton } from 'antd';

@inject('categoryStore')
@observer
export default class Beitragsartverwaltung extends Component {

  state = {
    loading: true
  }

  componentDidMount(){
    this.props.categoryStore.isSpecificationPage = true;
    this.setState({loading: false});
  }

  render() {
    if(this.state.loading) return <Skeleton loading={this.state.loading} />;
    return <BaseEntityAdministration
      store={this.props.categoryStore}
      breadcrumbsRoute={"beitragsart"}
      entityName={"Beitragsart"}
      headerTitle={"Beitragsartverwaltung"}
      headerIcon={<FontAwesomeIcon icon={['fas', 'cabinet-filing']} className="header-icon" />}
    />;
  }
}
