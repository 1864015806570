import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';
import axios from 'axios';

class PageAdministrationStore extends BaseEntitiesStore {
  constructor() {
    super('PageAdministration', 'PageAdministrationStore');
    if (!isProduction) {
      window[`PageAdministrationStore`] = this;
    }
  }

  async getByName(pageName) {
    const response = (await axios.get(`${this.path}/${pageName}`)).data;
    this.setEntity(response ?? {});
  }

  async updatePage(values) {
    const response = (await axios.put(`${this.path}/${values.id}`, values))
      .data;
    this.setEntity(response);
  }
}

export default new PageAdministrationStore();
