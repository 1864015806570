import { observable, computed } from 'mobx';
import axios from 'axios';

import { isProduction, apiUrl } from '../globalVar';

class PermissionStore {
  @observable
  user = { givenName: 'No', surName: 'User', role: '', completeRoleString: '' };

  constructor() {
    if (!isProduction) {
      window[`PermissionStore`] = this;
    }
  }

  @computed get isAdmin() {
    return this.user.role.includes('ADMIN');
  }

  @computed get isApprover() {
    return this.user.role.includes('APPROVER');
  }

  async fetchUser() {
    var resp = await axios.get(`${apiUrl}/permission/getuser`);
    this.user = resp.data;
  }
}

export default new PermissionStore();
