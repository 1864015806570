import "../Newsletter/Newsletter.scss";
import { Button, Card, Col, Modal, Row, Steps } from 'antd';
import React from 'react';
import { onEnterKey, openNotificationWithIcon } from 'utils/functions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MailForm from "components/Shared/MailForm";

const { Step } = Steps;
const formLayout12x24 = { xxl: 12, xl: 12, lg: 12, md: 12, sm: 24, xs: 24 };

class MessageModal extends React.Component {
  formRef = React.createRef();

  state = {
    loading: true,
    modalTitle: "Nachricht senden",
    currentStep: 0,
    isMultipleMode: false,
    individualMessageMode: false
  };

  componentDidMount() {
    this.setState({
      loading: false,
      isMultipleMode: Array.isArray(this.props.data),
    }, () => this.setModalTitle());
  }

  changeStep = (step) => {
    this.setState({
      currentStep: step,
      currentModalFooter: step === 0 ? [] : this.state.currentModalFooter,
    }, () => this.setModalTitle());
  };

  getModalTitle = () => {
    let title = 'Nachricht senden'; // NOSONAR
    if (this.state.isMultipleMode) {
      title = 'Aktualisierungsanfragen senden';
    } else {
      if (this.state.currentStep === 0) {
        title = 'Aktion wählen';
      } else {
        if (this.state.individualMessageMode) {
          title = 'Individuelle Nachricht senden';
        } else {
          title = 'Aktualisierungsanfrage senden';
        }
      }
    }
    return title;
  };

  selectAction = (isIndividualMode) => {
    this.setState({
      individualMessageMode: isIndividualMode,
      currentStep: (this.state.currentStep + 1),
    }, () => this.setModalTitle());
  };

  setModalTitle = () => {
    this.setState({ modalTitle: this.getModalTitle() });
  }

  buildFooterButtons = () => {
    const buttons = [];
    const message = this.state.isMultipleMode ? 'Nachrichten' : 'Nachricht';
    const nrOfSteps = this.state.isMultipleMode ? 1 : 2;

    if (this.state.currentStep === 0) {
      buttons.push(
        <>
          <div className="close-footer-btns">
            <Button
              className="btn-with-icon blue-medium"
              type="secondary"
              key="onCancelButton"
              onClick={this.props.onCancel}
            >
              Abbrechen
              <FontAwesomeIcon icon={['fas', 'times']} />
            </Button>
          </div>
        </>
      );
    } else {
      buttons.push(
        <>
          <div className="close-footer-btns">
            <Button
              key="backButton"
              type="secondary"
              className="btn-with-icon-right blue-medium"
              onClick={() => this.changeStep(this.state.currentStep - 1)}
            >
              <FontAwesomeIcon icon={['fas', 'step-backward']} />
              Zurück
            </Button>
            <Button
              className="btn-with-icon blue-medium"
              type="secondary"
              key="onCancelButton"
              onClick={this.props.onCancel}
            >
              Abbrechen
              <FontAwesomeIcon icon={['fas', 'times']} />
            </Button>
          </div>
        </>
      );
    }

    if (this.state.currentStep === nrOfSteps - 1) {
      buttons.push(
        <div className="save-footer-btns">
          <Button
            type="primary"
            className="btn-with-icon yellow"
            key="submitButton"
            onClick={() => this.formRef.current?.submit()}
          >
            {message} senden <FontAwesomeIcon icon={['fas', 'paper-plane']} />
          </Button>
        </div>
      );
    }

    return buttons;
  };

  buildSteps = () => {
    const steps = [];

    if (!this.state.isMultipleMode) {
      steps.push({
        title: 'Aktion wählen',
        content: (
          <>
            <Row gutter={30} role="list">
              <Col {...formLayout12x24} className="select-card" role="listitem">
                <Card
                  className="type-card"
                  hoverable
                  onClick={() => this.selectAction(false)}
                  onKeyDown={async (e) => await onEnterKey(e, this.selectAction, [false])}
                  tabIndex="0"
                  title="Anfrage zum Aktualisieren senden"
                >
                  <p className="card-content title">
                    Wählen Sie eine Vorlage aus und senden Sie eine Email basierend auf
                    dieser.
                  </p>
                </Card>
              </Col>
              <Col {...formLayout12x24} className="select-card" role="listitem">
                <Card
                  className="type-card"
                  hoverable
                  onClick={() => this.selectAction(true)}
                  onKeyDown={async (e) => await onEnterKey(e, this.selectAction, [true])}
                  tabIndex="0"
                  title="Individuelle Nachricht senden"
                >
                  <p className="card-content title">
                    Schreiben Sie eine individuelle Nachricht und senden Sie diese direkt
                    als Email.
                  </p>
                </Card>
              </Col>
            </Row>
          </>
        ),
      });
    }

    if (this.state.individualMessageMode) {
      steps.push({
        title: 'Individuelle Nachricht schreiben',
        content: (
          <>
            <MailForm
              formRef={this.formRef}
              onFinish={this.sendMails}
              displayNameField={false}
              disableEditor={false}
            />
          </>
        ),
      });
    } else {
      steps.push({
        title: 'Vorlage verwenden',
        content: (
          <>
            <MailForm 
              formRef={this.formRef}
              onFinish={this.sendMails}
              displayNameField={false}
              disabled={true}
              emailTemplates={this.props.emailTemplates}
              displayTemplateSelect={true}
            />
          </>
        ),
      });
    }

    return steps;
  };

  sendMails = async () => {
    try {
      await this.formRef.current?.validateFields();
      const values = this.formRef.current?.getFieldsValue();
      let clusterIds = [];
      if (this.state.isMultipleMode) {
        clusterIds = this.props.data.map((x) => x.id);
      } else {
        clusterIds.push(this.props.data.id);
      }
      await this.props.clusterStore.sendManualEmailToCluster(
        { clusterIds: clusterIds },
        values.subject,
        tinyMCE.activeEditor.getContent()
      );
      openNotificationWithIcon('success', 'Nachricht wurde gesendet.');
      this.props.onCancel();
    } catch (ex) {
      openNotificationWithIcon('error', 'Nachricht konnte nicht gesendet werden.');
    }
  };

  render() {
    const steps = this.buildSteps();
    const modalFooterButtons = this.buildFooterButtons();
    return (
      <Modal
        className="selectTypeModal"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        title={this.state.modalTitle}
        footer={<div className="footer-btns">{modalFooterButtons}</div>}
      >
        <Steps
          size="small"
          style={{ display: 'none' }}
          current={this.state.currentStep}
          onChange={this.changeStep}
        >
          {steps.map((step) => {
            return <Step title={step.title} key={step.title} />;
          })}
        </Steps>

        <div className="step-content">{steps[this.state.currentStep]?.content}</div>
      </Modal>
    );
  }
}

export default MessageModal;
