import { library, dom } from '@fortawesome/fontawesome-svg-core';

import{
  faCabinetFiling,
  faDatabase,
  faFileAlt,
  faSortCircleDown as faSortCircleDownLight,
  faSortCircleUp as faSortCircleUpLight

}
from'@fortawesome/pro-light-svg-icons';


library.add(
  faSortCircleDownLight,
  faSortCircleUpLight
);
  

import {
  faHome,
  faShapes,
  faCommentExclamation,
  faExclamationTriangle,
  faInfoCircle,
  faBug,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faPencil,
  faChartArea,
  faUsers,
  faUser,
  faCheck,
  faTimes,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFile,
  faDownload,
  faUpload,
  faPlus,
  faMinus,
  faBars,
  faEnvelope,
  faPhone,
  faFolder,
  faChartPie,
  faBuilding,
  faImage,
  faImages,
  faIndustry,
  faMountain,
  faHouse,
  faCodeBranch,
  faTools,
  faKey,
  faThumbtack,
  faTags,
  faSearch,
  faSortUp,
  faSortDown,
  faArrowAltToLeft,
  faFilter,
  faNewspaper,
  faCalendarAlt,
  faUndo,
  faFileExport,
  faCalendarDay,
  faClone,
  faSortCircleDown,
  faSortCircleUp,
  faList,
  faInfo,
  faFolderOpen,
  faCopyright,  
  faBallotCheck,
  faTasks,
  faStepBackward,
  faBoxes,
  faClock
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faHome,
  faShapes,
  faCommentExclamation,
  faMinus,
  faPlus,
  faExclamationTriangle,
  faInfoCircle,
  faBug,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faPencil,
  faUsers,
  faUser,
  faChartArea,
  faCheck,
  faTimes,
  faFile,
  faFileExcel,
  faFileWord,
  faFilePdf,
  faDownload,
  faUpload,
  faBars,
  faEnvelope,
  faPhone,
  faFolder,
  faChartPie,
  faBuilding,
  faImage,
  faImages,
  faIndustry,
  faMountain,
  faHouse,
  faCodeBranch,
  faTools,
  faKey,
  faThumbtack,
  faTags,
  faSearch,
  faSortUp,
  faSortDown,
  faArrowAltToLeft,
  faFilter,
  faNewspaper,
  faCalendarAlt,
  faUndo,
  faFileExport,
  faCalendarDay,
  faClone,
  faSortCircleDown,
  faSortCircleUp,
  faList,
  faInfo,
  faFolderOpen,
  faCopyright,
  faBallotCheck,
  faTasks,
  faStepBackward,
  faCabinetFiling,
  faDatabase,
  faFileAlt,
  faFolder,
  faBoxes,
  faSignIn,
  faClock
);

import {
  faListUl as faListUlSolid,
  faAngleDoubleDown as faAngleDoubleDown,
  faUser as faUserSolid,
  faUsers as faUsersSolid,
  faSignOut as faSignOutSolid,
  faPencil as faPencilSolid,
  faPlus as faPlusSolid,
  faSave as faSaveSolid,
  faInfo as faInfoSolid,
  faTrashAlt as faTrashAltSolid,
  faEye as faEyeSolid,
  faExternalLink as faExternalLinkSolid,
  faTools as faToolsSolid,
  faUserCircle as faUserCircleSolid,
  faCaretUp as faCaretUpSolid,
  faCaretDown as faCaretDownSolid,
  faSortDown as faSortDownSolid,
  faMapMarkedAlt as faMapMarkedAltSolid,
  faChartPie as faChartPieSolid,
  faFileChartPie as faFileChartPieSolid,
  faImage as faImageSolid,
  faImages as faImagesSolid,
  faIndustry as faIndustrySolid,
  faMountain as faMountainSolid,
  faHouse as faHouseSolid,
  faCodeBranch as faCodeBranchRegular,
  faKey as faKeySolid,
  faArrowUp as faArrowUpSolid,
  faCloudUpload as faCloudUploadSolid,
  faNewspaper as faNewspaperSolid,
  faSortUp as faSortUpSolid,
  faTags as faTagsSolid,
  faThumbtack as faThumbtackSolid,
  faFeatherAlt as faFeatherAltSolid,
  faFolderOpen as faFolderOpenSolid,
  faCubes as faCubesSolid,
  faPaperPlane as faPaperPlaneSolid,
  faCalendarAlt as faCalendarAltSolid,
  faArrowAltToLeft as faArrowAltToLeftSolid,
  faEyeSlash as faEyeSlashSolid,
  faLink as faLink,
  faUndo as faUndoSolid,
  faEnvelope as faEnvelopeSolid,
  faCalendarDay as faCalendarDaySolid,
  faClone as faCloneSolid,
  faCheck as faCheckSolid,
  faArrowAltCircleLeft as faArrowAltCircleLeftSolid,
  faArrowAltCircleRight as faArrowAltCircleRightSolid,
  faFileExport as faFileExportSolid,
  faInfoCircle as faInfoCircleSolid,
  faBullhorn as faBullhornSolid,
  faArchive as faArchiveSolid,
  faInboxOut as faInboxOutSolid,
  faInboxIn as faInboxInSolid,
  faClipboardCheck as faClipboardCheckSolid,
  faUserEdit as faUserEditSolid,
  faSortCircleDown as faSortCircleDownSolid,
  faSortCircleUp as faSortCircleUpSolid,
  faTimes as faTimesSolid,
  faTasks as faTasksSolid,
  faStepBackward as faStepBackwardSolid,
  faCrop as faCropSolid,
  faCabinetFiling as faCabinetFilingSolid,
  faDatabase as faDatabaseSolid,
  faFileAlt as faFileAltSolid,
  faFolders as faFoldersSolid,
  faBoxes as faBoxesSolid,
  faBadgeCheck as faBadgeCheckSolid,
  faAngleDoubleUp as faAngleDoubleUp,
  faInbox as faInboxSolid,
  faSignIn as faSignIn,
  faCopyright as faCopyrightSolid,
  faInfoSquare as faInfoSquareSolid
  
} from '@fortawesome/pro-solid-svg-icons';

//Add all solid icons
library.add(
  faListUlSolid,
  faUserSolid,
  faUsersSolid,
  faSignOutSolid,
  faPencilSolid,
  faPlusSolid,
  faSaveSolid,
  faInfoSolid,
  faTrashAltSolid,
  faEyeSolid,
  faExternalLinkSolid,
  faToolsSolid,
  faUserCircleSolid,
  faCaretUpSolid,
  faCaretDownSolid,
  faSortDownSolid,
  faMapMarkedAltSolid,
  faChartPieSolid,
  faImageSolid,
  faImagesSolid,
  faIndustrySolid,
  faMountainSolid,
  faHouseSolid,
  faCodeBranchRegular,
  faKeySolid,
  faArrowUpSolid,
  faCloudUploadSolid,
  faNewspaperSolid,
  faSortUpSolid,
  faTagsSolid,
  faThumbtackSolid,
  faFeatherAltSolid,
  faFolderOpenSolid,
  faCubesSolid,
  faPaperPlaneSolid,
  faNewspaperSolid,
  faCalendarAltSolid,
  faArrowAltToLeftSolid,
  faEyeSlashSolid,
  faLink,
  faUndoSolid,
  faEnvelopeSolid,
  faCalendarDaySolid,
  faCloneSolid,
  faCheckSolid,
  faArrowAltCircleLeftSolid,
  faArrowAltCircleRightSolid,
  faFileExportSolid,
  faInfoCircleSolid,
  faBullhornSolid,
  faArchiveSolid,
  faInboxOutSolid,
  faInboxInSolid,
  faClipboardCheckSolid,
  faUserEditSolid,
  faSortCircleDownSolid,
  faSortCircleUpSolid,
  faTimesSolid,
  faTasksSolid,
  faStepBackwardSolid,
  faCropSolid,
  faCabinetFilingSolid,
  faDatabaseSolid,
  faFileAltSolid,
  faFoldersSolid,
  faBoxesSolid,
  faFileChartPieSolid,
  faBadgeCheckSolid,
  faAngleDoubleDown,
  faAngleDoubleUp,
  faInboxSolid,
  faCopyrightSolid,
  faInfoSquareSolid
);

//Add your icons here from https://fontawesome.com/icons?d=gallery&s=solid

// See https://fontawesome.com/how-to-use/use-with-node-js#pro
// Be sure to use the pro icons! https://fontawesome.com/how-to-use/js-component-packages
// Custom icons (regular or light ones) can be added too

// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch();
