import React from 'react';
import { Card, Row } from 'antd';

import EventContentText from './Event/EventContentText';
import EventFooter from './Event/EventFooter';
import EventImage from './Event/EventImage';
import EventContentDates from './Event/EventContentDates';
import StackNavigator from './StackNavigator';
import { newsletterType } from 'App/globalVar';
import { inject } from 'mobx-react';
import SourceList from './SourceList';

@inject('uiStore')
export default class EventEntry extends React.Component {

  render() {
    let event = this.props.event;

    return (
      <>
        <div className="card">
          <Card hoverable={this.props.hoverable} onKeyDown={this.props.onKeyDown} onClick={this.props.onClick} tabIndex={0} className="antd-card elevate">
            <div className={this.props.isCard ? "card-content eventcard" : "card-content events"}>
              <div className="card-body">
                <EventContentText event={event} showTeaser={this.props.showTeaser} addStyles={this.props.addStyles} />
                {this.props.addStyles && (
                  <>
                    <SourceList entity={event} addStyles={this.props.addStyles} />
                  </>
                )}
                <EventContentDates event={event} addStyles={this.props.addStyles}/>
                <EventFooter event={event} addIdToLink={this.props.addIdToLink} isCard={this.props.isCard}/>
              </div>
              <EventImage displayThumbnailTitle={this.props.displayThumbnailTitle} event={event} />
            </div>
          </Card>
          {(event.previousNewsletterId || this.props.uiStore.newsletterNavigationStack.length > 0) && this.props.showNavigator && (
            <StackNavigator entity={event} type={newsletterType.event} />
          )}
        </div>
      </>
    );
  }
}
