import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseEntityAdministration from './BaseEntityAdministration';

@inject('industryStore')
@observer
export default class Branchenverwaltung extends Component {
  render() {
    return (
      <BaseEntityAdministration
        store={this.props.industryStore}
        breadcrumbsRoute={'branchenverwaltung'}
        entityName={'Branche'}
        headerTitle={'Branchenverwaltung'}
        headerIcon={
          <FontAwesomeIcon icon={['fas', 'industry']} className="header-icon" />
        }
      />
    );
  }
}
