import './globalImports';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from 'utils/api-authorization/ApiAuthorizationConstants';

import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import { initAuthentication } from 'utils/api-authorization/AuthorizeService';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

initAuthentication();

// This will spy on mobx actions and log them to dev tools, use this code in dev only!
// spy((event) => {
//   if (event.type === 'action') {
//     console.log(`${event.name} with args: ${event.arguments}`);
//   }
// });

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <Switch>
      <Route
        path={ApplicationPaths.ApiAuthorizationPrefix}
        component={ApiAuthorizationRoutes}
      />
      <Route path="/" component={App} />
    </Switch>
  </BrowserRouter>,
  rootElement
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
serviceWorker.unregister();
