import { observable } from 'mobx';
import axios from 'axios';

import { isProduction, apiUrl } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

/**
 * This is a base enitities store which loads a list or single entity and provides funcionality to manipulate it.
 *
 * Additional functionalities may be added ONLY IF said functionality is needed in all stores which extend this base store
 * IF that is not the case add a new store which extends this one and provides the additional features (e.g. a BaseUploadStore)
 */
export default class BasePagedEntitiesStore extends BaseEntitiesStore {
  @observable
  current = 1;

  @observable
  pageSize = 3;

  @observable
  total = 0;

  /**
   * 
   * @param {string} path - path of your api request without ./api prefix. The base store will build the url based on the apiUrl defined in globalVar.js
   * @param {string} name - name of your Store, only needed for debugging purpose, code will not be part of prod build
   */
  constructor(path = '', name = 'BasePagedEntitiesStore') {
    super(path, name);
  }

  async getAllFiltered(current = 1, pageSize = 10, filter = undefined, searcher = undefined, sorter = undefined, updateRecord = true) {
    var resp = await axios.get(`${apiUrl}/newsletter/filtered?current=${current}&pageSize=${pageSize}${filter ? filter : ''}${searcher ? searcher : ''}${sorter ? sorter : ''}`);
    let data = resp.data;
    this.current = data.current;
    this.pageSize = data.pageSize;
    this.total = data.total;
    if (updateRecord) {
      this.setEntities(data.result);
    }
    return data.result;
  }

  async getAllEventsFiltered(calendarView = false, current = 1, pageSize = 10, filter = undefined, searcher = undefined, sorter = undefined, updateRecord = true) {
    var resp = await axios.get(`${apiUrl}/event/filtered?calendarView=${calendarView}&current=${current}&pageSize=${pageSize}${filter ? filter : ''}${searcher ? searcher : ''}${sorter ? sorter : ''}`);
    let data = resp.data;
    this.current = data.current;
    this.pageSize = data.pageSize;
    this.total = data.total;
    if (updateRecord) {
      this.setEntities(data.result);
    }
    return data;
  }
}
