import './Headbar.scss';

import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { Dropdown, Menu, Button } from 'antd';
import logo from '../../images/edit_connectingClustersLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalLinks } from './ExternalLinks';

import { ApplicationPaths } from '../../utils/api-authorization/ApiAuthorizationConstants';
import { onEnterKey } from 'utils/functions';
import authService from 'utils/api-authorization/AuthorizeService';
import { Link } from 'react-router-dom';
import { toJS } from 'mobx';

@inject('userStore', 'uiStore')
@observer
export class Headbar extends Component {
  state = {
    username: '',
    _isAuthenticated: false,
    isVisible: false,
    path: '',
  };

  componentDidMount = async () => {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();

    await this.props.userStore.getUserInformation();

    this.setState({
      path:
        window.location.origin +
        document.getElementsByTagName('base')[0].getAttribute('href'),
    });
  };

  toggleMenu = (that, newState) => {
    that.setState({ isVisible: newState });
  };

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();

    if (authenticated) {
      await this.props.userStore.getUserInformation();
    }

    this.setState({
      ready: true,
      _isAuthenticated: authenticated,
    });
  }

  async authenticationChanged() {
    this.setState({ ready: false, _isAuthenticated: false });
    await this.populateAuthenticationState();
  }

  render() {
    const { currentUser } = this.props.userStore;

    return (
      <div
        className={this.props.showMobileMenu ? 'headbar backgroundBlueLight' : 'headbar'}
      >
        <div
          tabIndex="0"
          className={'logo-wrapper' + (this.props.showMobileMenu ? '' : ' indent')}
          onClick={() => this.props.uiStore.navigateTo('/')}
          onKeyDown={(e) => onEnterKey(e, this.props.uiStore.navigateTo, '/')}
          role="img"
          aria-label="Logo Connecting Clusters - Startseite"
        >
          <img aria-disabled src={logo} alt="Logo Connecting Clusters - Startseite" className="logo" />
        </div>
        <div className="headbar-right">
          {/* IMPORT EXTERNAL LINKS */}
          <ExternalLinks />

          <Button
            className="toggle-menu-btn"
            onClick={() => this.props.toggleMobileMenu()}
          >
            <FontAwesomeIcon icon={['far', 'bars']} />
            <FontAwesomeIcon icon={['fas', 'user']} />
          </Button>

          {Object.keys(toJS(this.props.userStore.currentUser)).length > 0 ? (
            <Dropdown
              getPopupContainer={(element) => element.parentElement}
              visible={this.state.isVisible}
              overlay={menu(this.state._isAuthenticated)}
              className="user-area"
            >
              <a
                aria-label={`${currentUser.fullname} - Benutzermenü ${
                  this.state.isVisible ? 'schließen' : 'öffnen'
                }`}
                tabIndex="0"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ isVisible: !this.state.isVisible });
                }}
                onKeyDown={(e) =>
                  onEnterKey(e, this.toggleMenu, [this, !this.state.isVisible])
                }
              >
                <FontAwesomeIcon icon={['fas', 'user']} />
                <span>{currentUser.fullname}</span>
                <FontAwesomeIcon icon={['fas', 'sort-down']} className="user-area-icon" />
              </a>
            </Dropdown>
          ) : (
            <Link
              to={{
                pathname: `${ApplicationPaths.Login}`,
              }}
            >
              <Button
                className="user-area"
                tabIndex="0"
                key="login"
                icon={<FontAwesomeIcon icon={['fas', 'user']} />}
              >
                <span>Login</span>
              </Button>
            </Link>
          )}
        </div>
      </div>
    );
  }
}

function menu(isAuthenticated) {
  return (
    <Menu aria-label="Benutzermenü - Dropdown">
      <Menu.Item key="login">
        <Link
          to={{
            pathname: !isAuthenticated
              ? `${ApplicationPaths.Login}`
              : `${ApplicationPaths.LogOut}`,
          }}
          className="logout-link"
        >
          <FontAwesomeIcon icon={['fas', 'sign-out']} />
          <span className="logout-text">
            {!isAuthenticated ? 'Anmelden' : 'Abmelden'}
          </span>
        </Link>
      </Menu.Item>
    </Menu>
  );
}
