import '../Modals/Modals.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  LeftOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  Form,
  Input,
  InputNumber,
  Select,
  Skeleton,
  Switch,
  Tooltip,
  Tag,
  Modal,
  Col,
  Row,
} from 'antd';
import { map } from 'lodash-es';
import { inject } from 'mobx-react';
import React from 'react';
import {
  emailRegex,
  clusterState,
  translationMap,
  ROLES,
  locationRegex,
  urlRegex,
  httpsPrefixRegex,
} from 'App/globalVar';
import {
  openNotificationWithIcon,
  getClusterStateTagColour,
  reverseMap,
  capitalizeFirstLetter,
  navigateToHomeWithError,
  checkIfFormIsDirty,
} from 'utils/functions';
import { toJS } from 'mobx';
import TextArea from 'antd/lib/input/TextArea';
import { Prompt } from 'react-router-dom';
import PersonNotFoundText from 'components/Shared/PersonNotFoundText';
import PhoneNumberFormItem from 'components/Shared/PhoneNumberFormItem';

const formLayout12x24 = { xxl: 12, xl: 24, lg: 24, sm: 24, xs: 24 };
@inject(
  'uiStore',
  'breadcrumbStore',
  'clusterStore',
  'industryStore',
  'stateStore',
  'nuts3Store',
  'clusterHolderStore',
  'userStore',
  'clusterClassStore',
  'clusterDraftStore',
  'clusterDataCacheStore'
)
class ClusterForm extends React.Component {
  formRef = React.createRef();
  modalFormRef = React.createRef();

  state = {
    loading: true,
    buttonLoading: false,

    managerPublicSwitchData: true,
    isManagerMainContactSwitchData: true,

    contactPersonEmails: [],
    contactPersons: [],
    knownEmails: [],
    entity: {},
    isViewMode: false,
    reverseClusterStateMap: reverseMap(clusterState),

    returnUrl: '',
    fromApproveOverview: false,
    showCommentModal: false,
    navigateBackUrl: '/clusterverwaltung',
    isDraft: false,
    isDirtyForm: false,

    // cluster cache
    showCacheModal: false,
    disableClusterButton: true,
    disableCacheButton: true,
    cluster: {},
    clusterCache: {},
    isCacheVersion: false,

    displaySaveAsDraftButton: true,
  };

  validRolesForApprovalProcess = [ROLES.admin, ROLES.winnovation];

  saveWithoutValidityCheck = async () => {
    try {
      await this.formRef.current?.validateFields();

      let formValues = this.formRef.current?.getFieldsValue();
      if (this.state.isDraft) {
        formValues.clusterDataDraftId = formValues.id;
        // for some reason setting value to null / something other than a string sets id to 0
        // this throws an error in the BE
        formValues.clusterManager.id = 'overwrite';
        formValues.contactPersons = formValues.contactPersons.map((x) => {
          x.id = 'overwrite';
          return x;
        });
      }

      let values = {
        ...formValues,
        isClusterManagerPublic: this.state.managerPublicSwitchData,
        isManagerMainContact: this.state.isManagerMainContactSwitchData,
      };

      let clusterHolders = [];
      let clusterHoldersId = [];

      values.clusterHolderIds?.forEach((value) => {
        if (typeof value == 'string') {
          clusterHolders.push({ name: value });
        } else {
          clusterHoldersId.push(value);
        }
      });
      values.clusterHolderIds = clusterHoldersId;
      values.clusterHolders = clusterHolders;

      if (!this.state.displaySaveAsDraftButton) {
        await this.props.clusterStore.put(values.id, values);
        openNotificationWithIcon('success', 'Cluster wurde gespeichert!');
      } else {
        await this.props.clusterStore.post(values);
        openNotificationWithIcon('success', 'Cluster wurde erstellt!');
      }

      this.setState({ isDirtyForm: false });
      this.navigateBack();
    } catch (ex) {
      // in this case it's a form validation error
      if (Object.keys(ex).includes('errorFields')) {
        this.formRef.current?.scrollToField(ex.errorFields[0]?.name, {
          behavior: 'smooth',
          scrollMode: 'always',
        });
        // in this case it's about the response of the request
      } else {
        let notificationText =
          'Es ist ein unerwarteter Fehler beim Speichern aufgetreten';
        if (ex.response.status === 409) {
          notificationText = 'Ein Cluster mit diesem Namen existiert bereits.';
        }
        openNotificationWithIcon('error', notificationText);
      }
    }
  };

  onFinish = async (values) => {
    this.setState({ buttonLoading: true });
    try {
      if (this.state.isDraft) {
        values.clusterDataDraftId = values.id;
        values.id = null;
        // for some reason setting value to null / something other than a string sets id to 0
        // this throws an error in the BE
        values.clusterManager.id = 'overwrite';
        values.contactPersons = values.contactPersons.map((x) => {
          x.id = 'overwrite';
          return x;
        });
      }

      let clusterHolders = [];
      let clusterHoldersId = [];
      values.clusterHolderIds?.forEach((value) => {
        if (typeof value == 'string') {
          clusterHolders.push({ name: value });
        } else {
          clusterHoldersId.push(value);
        }
      });
      values.clusterHolderIds = clusterHoldersId;

      values = {
        ...values,
        isClusterManagerPublic: this.state.managerPublicSwitchData,
        isManagerMainContact: this.state.isManagerMainContactSwitchData,
        clusterHolders: clusterHolders,
      };
      if (this.props.match?.params?.hash) {
        await this.props.clusterStore.updateClusterByHash(
          this.props.match.params.hash,
          values
        );
        openNotificationWithIcon('success', 'Cluster wurde bearbeitet!');
      } else {
        if (this.props.match?.params?.clusterId) {
          await this.props.clusterStore.updateClusterById(
            this.props.match.params.clusterId,
            values
          );
          openNotificationWithIcon('success', 'Cluster wurde bearbeitet!');
        } else if (!this.state.displaySaveAsDraftButton) {
          values.clusterState = clusterState.clusterInProcess;
          let cluster = await this.props.clusterStore.updateWithValidityCheck(
            values.id,
            values
          );
          if (cluster.clusterState == clusterState.clusterInProcess) {
            openNotificationWithIcon(
              'success',
              'Cluster wurde bearbeitet und in die Freigabe gegeben!'
            );
          } else {
            openNotificationWithIcon(
              'success',
              'Cluster wurde bearbeitet, konnte jedoch nicht in die Freigabe gegeben werden.'
            );
            this.props.clusterStore.currentNewClusterDraftsTable = cluster;
          }
        } else {
          let cluster = await this.props.clusterStore.createWithValidityCheck(values);
          if (cluster.clusterState == clusterState.clusterInProcess) {
            openNotificationWithIcon(
              'success',
              'Cluster wurde erstellt und in die Freigabe gegeben!'
            );
          } else {
            openNotificationWithIcon(
              'success',
              'Cluster wurde erstellt, konnte jedoch nicht in die Freigabe gegeben werden.'
            );
            this.props.clusterStore.currentNewClusterDraftsTable = cluster;
          }
        }
      }
      this.setState({ isDirtyForm: false, buttonLoading: false });
      this.navigateBack();
    } catch (ex) {
      let notificationText = 'Es ist ein unerwarteter Fehler beim Speichern aufgetreten';
      if (this.props.match?.params?.hash) {
        openNotificationWithIcon('error', notificationText);
      } else {
        let notificationText =
          'Es ist ein unerwarteter Fehler beim Speichern aufgetreten';
        if (ex.response.status === 409) {
          notificationText = 'Ein Cluster mit diesem Namen existiert bereits.';
        }
        openNotificationWithIcon('error', notificationText);
      }
      this.setState({ buttonLoading: false });
    }
  };

  saveCache = async () => {
    try {
      await this.formRef.current?.validateFields();
      let formValues = this.formRef.current.getFieldsValue();

      let clusterHolders = [];
      let clusterHoldersId = [];
      formValues.clusterHolderIds?.forEach((value) => {
        if (typeof value == 'string') {
          clusterHolders.push({ name: value });
        } else {
          clusterHoldersId.push(value);
        }
      });
      formValues.clusterHolderIds = clusterHoldersId;

      let values = {
        ...formValues,
        isClusterManagerPublic: this.state.managerPublicSwitchData,
        isManagerMainContact: this.state.isManagerMainContactSwitchData,
        clusterHolders: clusterHolders,
      };
      if (this.props.match?.params?.hash) {
        await this.props.clusterDataCacheStore.updateCacheByHashAsync(
          this.props.match.params.hash,
          values
        );

        openNotificationWithIcon('success', 'Cluster wurde zwischengespeichert!');
      }
      if (this.props.match?.params?.clusterId) {
        await this.props.clusterDataCacheStore.updateCacheByClusterIdAsync(
          this.props.match.params.clusterId,
          values
        );
        openNotificationWithIcon('success', 'Cluster wurde zwischengespeichert!');
      }
      this.setState({ isDirtyForm: false });
      this.navigateBack();
    } catch {
      let notificationText =
        'Es ist ein unerwarteter Fehler beim Zwischenspeichern aufgetreten';
      openNotificationWithIcon('error', notificationText);
    }
  };

  saveAsDraft = async () => {
    try {
      await this.formRef.current?.validateFields(['name']);

      let formValues = this.formRef.current.getFieldsValue();
      formValues.clusterHolderIds = formValues.clusterHolderIds?.map((x) => x.toString());

      if (formValues.clusterManager.email === undefined) delete formValues.clusterManager;
      let values = {
        ...formValues,
        isClusterManagerPublic: this.state.managerPublicSwitchData,
        isManagerMainContact: this.state.isManagerMainContactSwitchData,
      };

      await this.props.clusterDraftStore.post(values);
      openNotificationWithIcon('success', 'Draft gespeichert!');
      this.setState({ isDirtyForm: false });

      this.props.uiStore.navigateTo('/cluster-drafts');
    } catch (ex) {
      // scroll to name field (should be only required field)
      if (Object.keys(ex).includes('errorFields')) {
        this.formRef.current?.scrollToField(ex.errorFields[0]?.name, {
          behavior: 'smooth',
          scrollMode: 'always',
        });
        // in this case it's about the response of the request
      } else {
        let notificationText =
          'Es ist ein unerwarteter Fehler beim Speichern aufgetreten';
        if (ex.response.status === 409) {
          notificationText = 'Ein Cluster mit diesem Namen existiert bereits.';
        }
        openNotificationWithIcon('error', notificationText);
      }
    }
  };

  async componentDidMount() {
    await Promise.all([
      this.props.clusterClassStore.getAll(),
      this.props.industryStore.getAll(),
      this.props.stateStore.getAll(),
      this.props.nuts3Store.getAll(),
      this.props.clusterHolderStore.getAll(),
    ]);

    // check for ClusterJob email link
    if (this.props.match?.params?.hash) {
      try {
        let cluster = await this.props.clusterStore.getClusterByHash(
          this.props.match.params.hash
        );
        let clusterCache = await this.props.clusterDataCacheStore.getCacheByHash(
          this.props.match.params.hash
        );
        if (clusterCache !== '') {
          this.setState({
            disableClusterButton: false,
            disableCacheButton: false,
            showCacheModal: true,
            cluster: cluster,
            clusterCache: clusterCache,
          });
        } else {
          this.setState({
            disableClusterButton: false,
            disableCacheButton: true,
            showCacheModal: true,
            cluster: cluster,
          });
        }
      } catch {
        navigateToHomeWithError('Link ist abgelaufen.');
      }
    } else {
      let usersList = await this.props.userStore.getUsersList();
      this.setState({ knownEmails: usersList.map((x) => x.email) });

      let queryParams = new URLSearchParams(window.location.search);
      let clusterEntity = {};

      if (this.props.match?.params?.clusterId) {
        let isCache = queryParams.get('isCache');
        let isCacheVersion = false;
        if (isCache === 'false') {
          clusterEntity = await this.props.clusterStore.getClusterById(
            this.props.match.params.clusterId
          );
          isCacheVersion = false;
        } else {
          clusterEntity = await this.props.clusterDataCacheStore.getCacheByClusterId(
            this.props.match.params.clusterId
          );
          isCacheVersion = true;
        }
        this.setEntityValues(clusterEntity, false, false);

        this.props.breadcrumbStore.setBreadcrumbs('clusterverwaltungForm', {
          id: this.state.entity.id,
          name: this.state.entity.name,
        });
        this.setState({
          isCacheVersion: isCacheVersion,
          loading: false,
        });
      } else {
        let clusterType = queryParams.get('clusterType');
        let statusDraft = queryParams.get('statusDraft');
        let returnUrl = this.props.uiStore.getReturnLink('clusterForm');
        let fromDraft = returnUrl.includes('cluster-drafts');
        let fromApproveOverview = returnUrl.includes('approve-overview');
        let fromArchive = returnUrl.includes('archive');
        let isViewMode = false;
        let isDraft = false;
        let clusterId = this.props.match.params.id;
        if (!returnUrl) {
          navigateToHomeWithError('Ein Fehler ist aufgetreten.');
          return;
        }
        if (clusterId) {
          if (clusterType === 'draft') {
            await this.props.clusterDraftStore.get(clusterId);
            clusterEntity = toJS(this.props.clusterDraftStore.entity);
            isDraft = true;
          } else {
            if (fromArchive) {
              await this.props.clusterStore.getClusterForAdmin(clusterId);
            } else {
              await this.props.clusterStore.get(clusterId);
            }
            clusterEntity = toJS(this.props.clusterStore.entity);
            isViewMode = !(statusDraft === 'true');
          }

          this.setEntityValues(clusterEntity, isDraft, isViewMode);

          let breadcrumbRoute = fromApproveOverview
            ? 'approve-overview'
            : fromDraft
            ? 'cluster-drafts'
            : fromArchive
            ? 'clusterarchive'
            : 'clusterverwaltungForm';

          this.props.breadcrumbStore.setBreadcrumbs(breadcrumbRoute, {
            id: this.state.entity.id,
            name: this.state.entity.name,
            isFromCluster: true,
          });
        } else {
          this.props.breadcrumbStore.setBreadcrumbs('clusterverwaltungForm');
        }

        this.setState({
          loading: false,
          returnUrl: returnUrl,
          fromApproveOverview: fromApproveOverview,
          fromDraft: fromDraft,
          displaySaveAsDraftButton: !statusDraft || !(statusDraft === 'true'),
        });
      }
    }
  }

  setEntityValues = (clusterEntity, isDraft, isViewMode) => {
    let isManagerMainContact = true;
    let contactPersons = clusterEntity.contactPersons ?? [];
    let contactPersonEmails = {};

    if (!this.props.match?.params?.hash || !this.props.match?.params?.clusterId) {
      contactPersonEmails = contactPersons.map((x) => {
        return {
          value: x.email,
        };
      });

      if (isDraft) {
        isManagerMainContact = clusterEntity.isManagerMainContact;

        if (contactPersons.length < 1 && !isManagerMainContact) {
          contactPersonEmails.push({
            value: '',
          });
          contactPersons.push({ isMainContact: true });
        }
      } else {
        if (clusterEntity.clusterManager?.id !== clusterEntity.mainContact?.id) {
          isManagerMainContact = false;
          if (contactPersons.find((x) => x.id === clusterEntity.mainContact?.id)) {
            contactPersons.find(
              (x) => x.id === clusterEntity.mainContact?.id
            ).isMainContact = true;
          }
        }
      }
    }

    clusterEntity.clusterHolderIds = clusterEntity.clusterHolderIds?.map((value) => {
      if (typeof value == 'string') {
        if (!isNaN(value)) {
          return (
            this.props.clusterHolderStore.entities
              .slice()
              .find((x) => x.id === parseInt(value))?.id ?? value
          );
        } else {
          return value;
        }
      } else if (typeof value == 'number') {
        return (
          this.props.clusterHolderStore.entities
            .slice()
            .find((x) => x.id === parseInt(value))?.id ?? value
        );
      } else {
        return value;
      }
    });

    this.setState({
      contactPersonEmails: contactPersonEmails,
      isManagerMainContactSwitchData: isManagerMainContact,
      contactPersons: contactPersons,
      managerPublicSwitchData: clusterEntity.isClusterManagerPublic,
      isClusterManagerPublic: clusterEntity.isClusterManagerPublic,
      entity: clusterEntity,
      isDraft: isDraft,
      isViewMode: isViewMode,
    });
  };

  navigateBack = () => {
    this.props.uiStore.navigateToReturnLink('clusterForm');
  };

  mainContactChanged = (value, x) => {
    let formValues = this.formRef.current?.getFieldsValue();
    if (x == 'manager') {
      if (value) {
        formValues.contactPersons?.forEach((x) => (x.isMainContact = false));
      } else {
        if (!formValues.contactPersons || formValues.contactPersons.length < 1) {
          formValues.contactPersons = [
            {
              isMainContact: true,
            },
          ];

          let contactPersonEmails = this.state.contactPersonEmails;
          contactPersonEmails.push({
            value: '',
          });
          this.setState({ contactPersonEmails: contactPersonEmails });
        } else if (formValues.contactPersons.filter((x) => x.isMainContact).length < 1) {
          formValues.contactPersons[0].isMainContact = true;
        }
      }
      this.setState({ isManagerMainContactSwitchData: value });
    } else {
      let mainContacts = formValues.contactPersons.filter((x) => x.isMainContact);
      if (value && mainContacts.length > 1) {
        formValues.contactPersons.forEach((x) => (x.isMainContact = false));
        formValues.contactPersons[x].isMainContact = true;
      } else if (!value && mainContacts.length < 1) {
        formValues.contactPersons[x].isMainContact = true;
      }
    }

    this.formRef.current?.setFieldsValue({ ...formValues });
  };

  checkIfDeletedWasMainContact = () => {
    let formData = this.formRef.current?.getFieldsValue();
    if (formData.contactPersons.length < 1) {
      this.setState({ isManagerMainContactSwitchData: true });
    } else if (formData.contactPersons.filter((x) => x.isMainContact).length < 1) {
      formData.contactPersons[0].isMainContact = true;
      this.formRef.current?.setFieldsValue({ ...formData });
    }
  };

  publishCluster = async () => {
    try {
      let values = this.formRef.current?.getFieldsValue();
      await this.props.clusterStore.sendClusterForApproval(values.id);
      openNotificationWithIcon('success', 'Cluster wurde veröffentlicht.');
      this.navigateBack();
    } catch (ex) {
      openNotificationWithIcon(
        'error',
        'Es ist ein unerwarteter Fehler beim Veröffentlichen aufgetreten'
      );
    }
  };

  openCommentModal = () => {
    this.setState({ showCommentModal: true });
  };

  closeModal = () => {
    this.setState({ showCommentModal: false });
    this.modalFormRef.current?.resetFields();
  };

  sendClusterToDrafts = async () => {
    try {
      let comment = this.modalFormRef.current.getFieldsValue().comment;
      let id = this.formRef.current.getFieldsValue().id;
      await this.props.clusterStore.sendClusterForDraftReturn(id, comment);
      openNotificationWithIcon(
        'success',
        'Cluster wurde dem Benutzer in die Drafts zurückgestellt.'
      );
      this.navigateBack();
    } catch (ex) {
      openNotificationWithIcon(
        'error',
        'Es ist ein unerwarteter Fehler beim Zurückstellen aufgetreten'
      );
    }
  };

  postalCodeBlur = async (e) => {
    let postalCode = e.target.value;
    if(isFinite(postalCode)){
      if (postalCode.length != 4) {
        return;
      }
      const nuts = await this.props.nuts3Store.getNuts3ByPostalCode(postalCode);
      if(!!nuts){
        this.formRef.current?.setFieldsValue({ nuts3Id: nuts.id });
      }
    }
  }

  render() {
    let industries = this.props.industryStore.entities.slice();
    let states = this.props.stateStore.entities.slice();
    let nuts3 = this.props.nuts3Store.entities.slice();
    let clusterHolder = this.props.clusterHolderStore.entities.slice();
    let usersList = this.props.userStore.usersList.slice();
    let clusterClasses = this.props.clusterClassStore.entities.slice();

    if (this.state.loading)
      return (
        <>
          <Skeleton loading={this.state.loading} />
          <Modal
            className="selectTypeModal"
            closable={false}
            visible={this.state.showCacheModal}
            cancelButtonProps={{ style: { display: 'none' } }}
            title={'Aktion wählen'}
            footer={<div className="footer-btns"></div>}
          >
            <div className="step-content">
              <Row gutter={30} role="list">
                <Col {...formLayout12x24} className="select-card" role="listitem">
                  <Card
                    className="type-card"
                    hoverable
                    onClick={() => {
                      this.setEntityValues(this.state.cluster, false, false);
                      this.props.breadcrumbStore.setBreadcrumbs('clusterverwaltungForm', {
                        id: this.state.entity.id,
                        name: this.state.entity.name,
                      });
                      this.setState({
                        showCacheModal: false,
                        loading: false,
                        isCacheVersion: false,
                      });
                    }}
                    onKeyDown={() => {
                      this.setEntityValues(this.state.cluster, false, false);
                      this.props.breadcrumbStore.setBreadcrumbs('clusterverwaltungForm', {
                        id: this.state.entity.id,
                        name: this.state.entity.name,
                      });
                      this.setState({
                        showCacheModal: false,
                        loading: false,
                        isCacheVersion: false,
                      });
                    }}
                    tabIndex="0"
                    title="Aktueller Cluster"
                  >
                    <p className="card-content title">
                      Wählen Sie diese Version aus und bearbeiten Sie den aktuellen
                      Cluster.
                    </p>
                  </Card>
                </Col>
                <Col {...formLayout12x24} className="select-card" role="listitem">
                  {!this.state.disableCacheButton && (
                    <Card
                      className="type-card"
                      hoverable
                      onClick={() => {
                        this.setEntityValues(this.state.clusterCache, false, false);
                        this.props.breadcrumbStore.setBreadcrumbs(
                          'clusterverwaltungForm',
                          {
                            id: this.state.entity.id,
                            name: this.state.entity.name,
                          }
                        );
                        this.setState({
                          showCacheModal: false,
                          loading: false,
                          isCacheVersion: true,
                        });
                      }}
                      onKeyDown={() => {
                        this.setEntityValues(this.state.clusterCache, false, false);
                        this.props.breadcrumbStore.setBreadcrumbs(
                          'clusterverwaltungForm',
                          {
                            id: this.state.entity.id,
                            name: this.state.entity.name,
                          }
                        );
                        this.setState({
                          showCacheModal: false,
                          loading: false,
                          isCacheVersion: true,
                        });
                      }}
                      tabIndex="0"
                      title="Zwischengespeicherter Cluster"
                    >
                      <p className="card-content title">
                        Wählen Sie diese Version aus und bearbeiten Sie den
                        zwischengespeicherten Cluster.
                      </p>
                    </Card>
                  )}
                </Col>
              </Row>
            </div>
          </Modal>
        </>
      );
    let currentUser = this.props.userStore.currentUser;

    return (
      <>
        <Prompt
          when={this.state.isDirtyForm}
          message="Sie haben ungespeicherte Änderungen im Formular. Wollen Sie die Seite wirklich verlassen? Wenn Sie bestätigen, werden die Änderungen verworfen."
        />
        <div className="home">
          <div className="header">
            <h1 className="title">
              {!this.state.displaySaveAsDraftButton ? (
                this.state.entity.name
              ) : this.state.isViewMode ? (
                this.state.entity.name
              ) : this.props.match?.params?.hash ||
                this.props.match?.params?.clusterId ? (
                this.state.isCacheVersion ? (
                  this.state.entity.name + ' (Zwischengespeicherte Version)'
                ) : (
                  this.state.entity.name
                )
              ) : (
                <>
                  Cluster erstellen <FontAwesomeIcon icon={['fas', 'pencil']} />{' '}
                </>
              )}
            </h1>
            <div className="action-btns">
              {!this.props.match?.params?.hash && (
                <Button
                  type="ghost"
                  onClick={() => this.navigateBack()}
                  icon={<LeftOutlined />}
                  className="header-btn"
                >
                  Zurück
                </Button>
              )}
              {this.state.fromApproveOverview &&
                this.validRolesForApprovalProcess.includes(currentUser.role) && (
                  <>
                    <Button
                      className="btn-with-icon teal"
                      type="primary"
                      onClick={() => this.openCommentModal()}
                    >
                      Zu Drafts zurückstufen
                      <FontAwesomeIcon icon={['fas', 'feather-alt']} />
                    </Button>
                    <Button
                      className="btn-with-icon yellow"
                      type="primary"
                      onClick={() => this.publishCluster(true)}
                    >
                      Freigeben
                      <FontAwesomeIcon
                        icon={['fas', 'paper-plane']}
                        className="icon-left"
                      />
                    </Button>
                  </>
                )}
            </div>
          </div>
          <Form
            className={
              this.state.isViewMode
                ? 'add-cluster-form ant-form-readonly'
                : 'add-cluster-form'
            }
            size="large"
            labelCol={{ span: 10 }}
            labelAlign="left"
            ref={this.formRef}
            onFinish={this.onFinish}
            disabled={this.state.isViewMode}
            initialValues={this.state.entity}
            onValuesChange={(changedFields) =>
              this.setState({
                isDirtyForm: checkIfFormIsDirty(changedFields, this.state.entity),
              })
            }
            scrollToFirstError={{
              behavior: 'smooth',
              scrollMode: 'always',
            }}
            autoComplete="off"
          >
            <Form.Item hidden name={'id'}>
              <Input type="hidden" />
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h2>Allgemeine Clusterdaten</h2>
              {this.state.isViewMode && (
                <div>
                  Status:{' '}
                  <Tag color={getClusterStateTagColour(this.state.entity.clusterState)}>
                    {capitalizeFirstLetter(
                      translationMap[
                        this.state.reverseClusterStateMap[this.state.entity.clusterState]
                      ]
                    )}
                  </Tag>
                </div>
              )}
            </div>
            <Form.Item
              name="name"
              label="Name des Clusters"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie einen Namen für den Cluster an.',
                },
              ]}
            >
              <Input
                placeholder="Clustername"
                disabled={this.state.isViewMode}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="clusterHolderIds"
              label="Trägerorganisationen"
              rules={[
                {
                  required: true,
                  message: 'Bitte wählen Sie zumindest eine Trägerorganisation aus.',
                },
              ]}
            >
              <Select
                placeholder="Musterträger"
                mode="tags"
                showSearch
                filterOption={(input, option) => {
                  return typeof option.value === 'string'
                    ? option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    : option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
                disabled={this.state.isViewMode}
              >
                {clusterHolder.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="industryIds"
              label="Branche"
              rules={[
                {
                  required: true,
                  message: 'Bitte wählen Sie zumindest eine Branche aus.',
                },
              ]}
            >
              <Select
                placeholder="Branche"
                mode="multiple"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={this.state.isViewMode}
              >
                {map(industries, (industry) => (
                  <Select.Option key={industry.id} value={industry.id}>
                    {industry.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="clusterClassId" label="Klasse">
              <Select
                allowClear
                placeholder="Klasse"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={this.state.isViewMode}
              >
                {map(clusterClasses, (clusterClass) => (
                  <Select.Option key={clusterClass.id} value={clusterClass.id}>
                    {clusterClass.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="eccp"
              label={
                <>
                  <FontAwesomeIcon
                    icon={['fas', 'link']}
                    className="link-icon not-required"
                  />
                  ECCP-Link{' '}
                  <Tooltip title="European Cluster Collaboration Platform">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[
                {
                  pattern: httpsPrefixRegex,
                  message:
                    'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                },
                {
                  pattern: urlRegex,
                  message: 'Geben Sie bitte eine valide Url an.',
                },
              ]}
            >
              <Input
                placeholder="https://clustercollaboration.eu"
                disabled={this.state.isViewMode}
              />
            </Form.Item>
            <p
              style={{
                fontSize: 14,
                color: 'grey',
              }}
            >
              Sollte dieser Cluster noch nicht offiziell bei ECCP angelegt sein, klicken
              Sie bitte{' '}
              <a
                className="underlined"
                href="https://clustercollaboration.eu/user/register"
                target="_blank"
              >
                "hier"
              </a>{' '}
              und melden diesen an.
            </p>
            <h2>Standortinformationen</h2>
            <Form.Item
              name="stateId"
              label="Bundesland"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie ein Bundesland an.',
                },
              ]}
            >
              <Select
                placeholder="Bundesland"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={this.state.isViewMode}
              >
                {states.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="postalCode"
              label="Postleitzahl"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie eine Postleitzahl an.',
                },
                {
                  type: 'number',
                  max: 9999,
                  min: 1000,
                  message: 'Die Postleitzahl darf nur aus 4 Ziffern bestehen.',
                },
              ]}
            >
              <InputNumber
                min={1000}
                max={9999}
                placeholder="1010"
                autoComplete="new-password"
                disabled={this.state.isViewMode}
                onBlur={(e) => this.postalCodeBlur(e)}
              />
            </Form.Item>
            <Form.Item
              name="location"
              label="Ort"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie einen Ort an.',
                },
                {
                  pattern: locationRegex,
                  message: 'Bitte geben Sie einen validen Ortsnamen an.',
                },
              ]}
            >
              <Input
                placeholder="Wien"
                disabled={this.state.isViewMode}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="street"
              label="Straße"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie eine Straße an.',
                },
                {
                  pattern: locationRegex,
                  message: 'Der Straßenname darf nur aus Buchstaben bestehen.',
                },
              ]}
            >
              <Input
                placeholder="Musterstraße"
                disabled={this.state.isViewMode}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="houseNumber"
              label="Hausnummer"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie eine Hausnummer an.',
                },
              ]}
            >
              <InputNumber placeholder="30" disabled={this.state.isViewMode} />
            </Form.Item>
            <Form.Item name="additionalLocationData" label="Adresszusatz">
              <Input
                placeholder="Tür 3"
                disabled={this.state.isViewMode}
                autoComplete="new-password"
              />
            </Form.Item>
            <Form.Item
              name="nuts3Id"
              label={
                <>
                  NUTS-3-Region{' '}
                  <a
                    className="cluster-anlegen-link underlined"
                    href="https://ec.europa.eu/eurostat/web/nuts/background"
                    target="_blank"
                  >
                    <InfoCircleOutlined />
                  </a>
                </>
              }
              rules={[
                {
                  required: true,
                  message: 'Bitte wählen Sie eine Nuts3-Region aus.',
                },
              ]}
            >
              <Select
                placeholder="Nuts3-Region"
                showSearch
                filterOption={(input, option) =>
                  option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                disabled={this.state.isViewMode}
              >
                {nuts3.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {`${item.nutsCode} - ${item.nutsName}`}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="website"
              label={
                <>
                  <FontAwesomeIcon icon={['fas', 'link']} className="link-icon" />
                  Link zur Webseite
                </>
              }
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie einen Link zur Webseite an.',
                },
                {
                  pattern: httpsPrefixRegex,
                  message:
                    'Geben Sie bitte das Protokoll am Anfang an (http:// bzw. https://).',
                },
                {
                  pattern: urlRegex,
                  message: 'Geben Sie bitte eine valide Url an.',
                },
              ]}
            >
              <Input
                placeholder="https://your-domain.at"
                disabled={this.state.isViewMode}
              />
            </Form.Item>
            <Form.Item
              name="generalEmail"
              label="Allgemeine Email-Adresse"
              rules={[
                {
                  required: true,
                  message: 'Bitte geben Sie eine allgemeine Email-Adresse an.',
                },
                {
                  pattern: emailRegex,
                  message: 'Bitte geben Sie eine valide Email-Adresse an.',
                },
              ]}
              normalize={(value, prevVal, prevVals) => value.trim()}
            >
              <Input
                placeholder="mustercluster@my-mail.com"
                disabled={this.state.isViewMode}
                autoComplete="new-password"
              />
            </Form.Item>
            <PhoneNumberFormItem
              name="generalPhoneNumber"
              label="Allgemeine Telefonnummer"
              disabled={this.state.isViewMode}
              required={true}
            />
            {!this.props.match?.params?.hash ? (
              <>
                <h2>Clustermanagement Informationen</h2>
                <Form.Item hidden name={['clusterManager', 'id']}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name={['clusterManager', 'email']}
                  label="Clustermanagende Person"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Bitte geben Sie eine Clustermanagende Person an.',
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Max Mustermann"
                    showSearch
                    filterOption={(input, option) =>
                      option.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    disabled={this.state.isViewMode}
                  >
                    {usersList.map((x) => (
                      <Select.Option key={x.id} value={x.email}>
                        {x.fullname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {!this.state.isViewMode && <PersonNotFoundText />}
                <Form.Item
                  name="isClusterManagerPublic"
                  label="Kontaktdaten Clustermanagende Person öffentlich sichtbar?"
                >
                  <Switch
                    className="switch yellow"
                    checked={this.state.managerPublicSwitchData}
                    onChange={(value) =>
                      this.setState({ managerPublicSwitchData: value })
                    }
                    disabled={this.state.isViewMode}
                  />
                </Form.Item>
                <Form.Item
                  name="isManagerMainContact"
                  label="Clustermanagende Person Hauptansprechperson?"
                  help={
                    !this.state.isViewMode
                      ? 'Sie müssen mindestens eine Person als Hauptansprechperson markieren.'
                      : ''
                  }
                >
                  <Switch
                    className="switch yellow"
                    checked={this.state.isManagerMainContactSwitchData}
                    onChange={(value) => this.mainContactChanged(value, 'manager')}
                    disabled={this.state.isViewMode}
                  />
                </Form.Item>
                {(this.state.isViewMode &&
                  this.state.entity.contactPersons &&
                  this.state.entity.contactPersons.length > 0) ||
                !this.state.isViewMode ? (
                  <>
                    <h2>Weitere Kontaktpersonen</h2>
                    <Form.List name="contactPersons">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restFields }) => (
                            <Card className="contact-card">
                              <Form.Item
                                name={[name, 'email']}
                                label="Kontaktperson"
                                rules={[
                                  {
                                    required: true,
                                    type: 'email',
                                    message:
                                      'Bitte geben Sie eine weitere Kontaktperson an.',
                                  },
                                ]}
                              >
                                <Select
                                  allowClear
                                  placeholder="Max Mustermann"
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children
                                      ?.toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  disabled={this.state.isViewMode}
                                >
                                  {usersList.map((x) => (
                                    <Select.Option key={x.id} value={x.email}>
                                      {x.fullname}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              <Form.Item
                                name={[name, 'functionArea']}
                                label="Rolle / Sektor"
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      'Bitte geben Sie eine Rolle / einen Sektor für diese Person an.',
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Rolle / Sektor"
                                  disabled={this.state.isViewMode}
                                  autoComplete="new-password"
                                />
                              </Form.Item>
                              {!this.state.isViewMode && <PersonNotFoundText />}
                              {!this.state.isManagerMainContactSwitchData && (
                                <Form.Item
                                  valuePropName="checked"
                                  name={[name, 'isMainContact']}
                                  label="Ist Hauptansprechperson?"
                                >
                                  <Switch
                                    className="switch yellow"
                                    onChange={(checked) =>
                                      this.mainContactChanged(checked, name)
                                    }
                                    disabled={this.state.isViewMode}
                                  />
                                </Form.Item>
                              )}
                              {!this.state.isViewMode && (
                                <div className="delete-contact-button-wrapper">
                                  <Button
                                    className="delete-contact-button"
                                    type="secondary"
                                    onClick={() => {
                                      // remove function is not fast enough for check, so we "await" the promise resolving
                                      new Promise((resolve) => {
                                        remove(name);
                                        let contactPersonEmails =
                                          this.state.contactPersonEmails;
                                        contactPersonEmails.splice(name, 1);

                                        this.setState({
                                          contactPersonEmails: contactPersonEmails,
                                        });
                                        resolve();
                                      }).then((x) => {
                                        if (!this.state.isManagerMainContactSwitchData) {
                                          this.checkIfDeletedWasMainContact();
                                        }
                                      });
                                    }}
                                    icon={<MinusOutlined />}
                                  >
                                    Kontaktperson entfernen
                                  </Button>
                                </div>
                              )}
                            </Card>
                          ))}
                          {!this.state.isViewMode && (
                            <div className="add-contact-button-wrapper">
                              <Button
                                className="add-contact-button"
                                onClick={() => {
                                  let contactPersonEmails =
                                    this.state.contactPersonEmails;
                                  contactPersonEmails.push({
                                    value: '',
                                  });
                                  this.setState({
                                    contactPersonEmails: contactPersonEmails,
                                  });
                                  add();
                                }}
                                icon={<PlusOutlined />}
                              >
                                Weitere Kontaktperson hinzufügen
                              </Button>
                            </div>
                          )}
                        </>
                      )}
                    </Form.List>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <Form.Item
                name="isClusterManagerPublic"
                label="Kontaktdaten Clustermanagende Person öffentlich sichtbar?"
              >
                <Switch
                  className="switch yellow"
                  checked={this.state.managerPublicSwitchData}
                  onChange={(value) => this.setState({ managerPublicSwitchData: value })}
                  disabled={this.state.isViewMode}
                />
              </Form.Item>
            )}
          </Form>
          {!this.state.isViewMode &&
            !this.props.match?.params?.hash &&
            !this.props.match?.params.clusterId && (
              <>
                <Divider />
                <div className="action-btns">
                  {this.state.displaySaveAsDraftButton ? (
                    <Button
                      onClick={this.saveAsDraft}
                      className="draft-button"
                      type="ghost"
                    >
                      Als Draft speichern
                    </Button>
                  ) : (
                    <Button
                      style={{ marginRight: '10px' }}
                      onClick={this.saveWithoutValidityCheck}
                      loading={this.state.buttonLoading}
                    >
                      Speichern
                    </Button>
                  )}
                  <Button
                    getPopupContainer={(element) => element.parentElement}
                    trigger="click"
                    type="primary"
                    className="btn-with-icon yellow"
                    loading={this.state.buttonLoading}
                    onClick={() => this.formRef.current?.submit()}
                  >
                    Speichern und in Freigabe geben
                  </Button>
                </div>
              </>
            )}
          {(this.props.match?.params?.hash || this.props.match?.params?.clusterId) && (
            <>
              <Divider />
              <div className="action-btns">
                <Button onClick={this.saveCache} className="draft-button" type="ghost">
                  Zwischenspeichern
                </Button>
                <Button
                  onClick={() => this.formRef.current?.submit()}
                  className="draft-button yellow"
                  type="primary"
                >
                  Speichern
                </Button>
              </div>
            </>
          )}
        </div>

        <Modal
          key="commentModal"
          visible={this.state.showCommentModal}
          onCancel={this.closeModal}
          onOk={this.sendClusterToDrafts}
          title="Kommentar hinzufügen"
          className="app-modal"
        >
          <Form ref={this.modalFormRef}>
            <Form.Item id="comment" name="comment" label="Kommentar">
              <TextArea />
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ClusterForm;
