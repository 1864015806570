import React from 'react';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function NewsletterButton(props) {
  return (
    <Button
      {...props}
      type="primary"
      className="btn-with-icon teal"
      href="https://seu2.cleverreach.com/f/366799-369432/"
      target="_blanket"
    >
      Zum Newsletter anmelden
      <FontAwesomeIcon icon={['fas', 'envelope']} className="btn-icon" />
    </Button>
  );
}
