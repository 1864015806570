import { Button, Form, Modal, Skeleton, Table, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { changelogTypes, clusterState, translationMap } from 'App/globalVar';
import dayjs from 'dayjs';
import { find, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { dateTimeFormat } from 'utils/dayjsLocale';
import {
  capitalizeFirstLetter,
  getClusterStateTagColour,
  openNotificationWithIcon,
  reverseMap,
} from 'utils/functions';

@observer
@inject('clusterStore')
class ClusterChangelogs extends React.Component {
  formRef = React.createRef();

  state = {
    loading: true,
    reverseClusterStates: reverseMap(clusterState),

    showCommentModal: false,
  };

  collectionProperties = ['IndustryIds', 'ClusterHolderIds', 'ContactPersons'];

  componentDidMount() {
    this.setState({ loading: false });
  }

  getClusterChangelogTag = (type) => {
    let color = 'grey';
    switch (type) {
      case 0:
        color = 'volcano';
        break;
      case 1:
        color = 'magenta';
        break;
      case 2:
        color = 'geekblue';
        break;
      case 3:
        color = 'gold';
        break;
      case 4:
        color = 'green';
        break;
      case 5: 
        color = 'red';
        break;
      default:
        break;
    }
    return <Tag color={color}>{changelogTypes[type]}</Tag>;
  };

  transformValue = (value, property) => {
    if (this.collectionProperties.includes(property)) {
      let arr = JSON.parse(value);
      switch (property) {
        case this.collectionProperties[0]:
          return (
            <ul className="no-bullets">
              {map(arr, (value) => (
                <li>{find(this.props.industries, (x) => x.id == value).name}</li>
              ))}
            </ul>
          );
        case this.collectionProperties[1]:
          return (
            <ul className="no-bullets">
              {map(arr, (value) => (
                <li>{find(this.props.holders, (x) => x.id == value).name}</li>
              ))}
            </ul>
          );
        case this.collectionProperties[2]:
          return (
            <ul className="no-bullets">
              {map(arr, (value) => (
                <li>{value}</li>
              ))}
            </ul>
          );
      }
    } else if (property == 'ClusterState') {
      value = `cluster${value}`;
      return (
        <div className="table-tag">
          <Tag color={getClusterStateTagColour(clusterState[value])}>
            {capitalizeFirstLetter(translationMap[value])}
          </Tag>
        </div>
      );
    }
    return value;
  };

  columns = [
    {
      title: 'Aktion',
      dataIndex: 'type',
      key: 'type',
      render: (type) => {
        return this.getClusterChangelogTag(type);
      },
    },
    {
      title: 'Feld',
      dataIndex: 'property',
      key: 'property',
      render: (property) => {
        return <span>{translationMap.clusterProperties[property]}</span>;
      },
    },
    {
      title: 'Alter Wert',
      key: 'oldValue',
      render: (data) => {
        return this.transformValue(data.oldValue, data.property);
      },
    },
    {
      title: 'Neuer Wert',
      key: 'newValue',
      render: (data) => {
        return this.transformValue(data.newValue, data.property);
      },
    },
    {
      title: 'Geändert von',
      key: 'changed',
      render: (value) => {
        return (
          <ul className="no-bullets">
            <li>{value.userFullName}</li>
            <li>{dayjs(value.timestamp).format(dateTimeFormat)}</li>
          </ul>
        );
      },
    },
  ];

  openCommentModal = () => {
    this.setState({ showCommentModal: true });
  };

  onCommentModalCancel = () => {
    this.setState({ showCommentModal: false });
  };

  onCommentModalSubmit = async () => {
    try {
      let comment = this.formRef.current.getFieldsValue().comment;
      await this.props.clusterStore.createCommentChangelogForCluster(
        this.props.clusterId,
        comment
      );
      openNotificationWithIcon('success', 'Kommentar wurde hinzugefügt.');
      this.onCommentModalCancel();
    } catch (ex) {
      openNotificationWithIcon(
        'error',
        'Bitte geben Sie einen Kommentar an und versuchen Sie es erneut.'
      );
    }
  };

  render() {
    if (this.state.loading) return <Skeleton loading={this.state.loading} />;

    let data = this.props.clusterStore.changelogs.slice();
    return (
      <Modal
        className="app-modal"
        visible={this.props.visible}
        onCancel={this.props.onCancel}
        title={
          <div className="modal-header-with-button">
            <span className="modal-title">Changelogs</span>
            <Button
              onClick={this.openCommentModal}
              className="header-button"
              type="ghost"
            >
              Kommentar hinzufügen
            </Button>
          </div>
        }
        footer={[
          <Button type="primary" className='yellow' onClick={() => this.props.onCancel()}>
            Schließen
          </Button>,
        ]}
      >
        <Table
          className="ant-table-scroll"
          dataSource={data}
          columns={this.columns}
          rowKey={'id'}
          pagination={false}
          scroll={{ x: true }}
        />

        {this.state.showCommentModal && (
          <Modal
            key="commentModal"
            visible={this.state.showCommentModal}
            onCancel={this.onCommentModalCancel}
            onOk={this.onCommentModalSubmit}
            okButtonProps={{ className: 'yellow' }}
            title="Neuen Kommentar hinzufügen"
            className="app-modal"
          >
            <Form ref={this.formRef}>
              <Form.Item id="comment" name="comment" label="Kommentar">
                <TextArea />
              </Form.Item>
            </Form>
          </Modal>
        )}
      </Modal>
    );
  }
}

export default ClusterChangelogs;
