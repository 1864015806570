import './News.scss';
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Calendar, Select } from 'antd';
import { dateFormat } from 'utils/dayjsLocale';
import dayjs from 'dayjs';
import { map } from 'lodash-es';
import { onEnterKey } from 'utils/functions';

const listRef = React.createRef();

@inject('uiStore', 'formatStore', 'newsletterStore')
@observer
export default class CalendarView extends Component {
  state = {
    isSelectedEvent: false,
    selectedDate: undefined,
    listData: [],
  };

  componentDidMount = () => {
    this.onSelect(Calendar.value);
  };

  getListData = (value) => {
    let listData = [];
    const dateValue = dayjs(value)?.format(dateFormat);
    const entities = this.props.newsletterStore.entities.slice();
    for(let entity of entities){
      let arr = this.getInBetween(entity);
      for(let currentDate of arr.dates){
        if(currentDate == dateValue){
          listData.push({
            content: `${arr.title}`,
            id: arr.id
          });
        }
      }
    }
    return listData;
  };

  redirectFromLink = (id) => {
    this.props.uiStore.navigateTo(`/calendar/${id}`);
  }

  dateCellRender = (value) => {
    const listData = this.getListData(value);
    if (listData.length === 0) {
      return null;
    }
    return (
      <div className="calendar-entries-bg">
        <ul className="calendar-entries">
          {map(listData, (item) => (
            <li key={item.content}>
              <a
                aria-label={item.content}
                tabIndex="0"
                className="event-link-calendar"
                onKeyDown={(e) => onEnterKey(e, this.redirectFromLink, item.id)}
                onClick={() => this.redirectFromLink(item.id)}
              >
                {item.content}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  getInBetween = (event) => {
    let test = {
      dates: [],
      id: event.id,
      title: event.title,
    };
    const num1 = new Date(new Date(event.starting).getTime());
    const num2 = new Date(new Date(event.ending).getTime());

    const dates = [];

    while (num1 <= num2) { // NOSONAR
      dates.push(new Date(num1));
      num1.setDate(num1.getDate() + 1);
    }
    for (let i = 0; i < dates.length; i++) {
      test.dates[i] = dayjs(dates[i]).format(dateFormat);
    }

    return test;
  };

  onSelect = (selectedDate) => {
    let isSelectedEvent = false;
    if (this.getListData(selectedDate).length !== 0) {
      isSelectedEvent = true;
    }
    listRef.current?.scrollIntoView({ behavior: 'smooth' });
    this.setState({selectedDate: selectedDate, isSelectedEvent: isSelectedEvent});
  };

  renderTableHeader = (value, type, onChange, onTypeChange) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];

    let current = dayjs(value);
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current = current.month(i);
      months.push(localeData.monthsShort(current));
    }

    for (let i = start; i < end; i++) {
      monthOptions.push(
        <Select.Option key={i} value={i} className="month-item">
          {months[i]}
        </Select.Option>,
      );
    }

    const year = value.year();
    const month = value.month();
    const yearOptions = [];
    for (let i = year - 10; i < year + 10; i += 1) {
      yearOptions.push(
        <Select.Option key={i} value={i} className="year-item">
          {i}
        </Select.Option>,
      );
    }

    return (
      <div className="ant-picker-calendar-header">
        <Select
          className="ant-select ant-picker-calendar-year-select ant-select-single ant-select-show-arrow"
          dropdownMatchSelectWidth={false}
          value={year}
          onChange={(newYear) => {
            onChange(dayjs(value).year(newYear));
          }}
        >
          {yearOptions}
        </Select>
        <Select
          className="ant-select ant-picker-calendar-month-select ant-select-single ant-select-show-arrow"
          defaultValue={month}
          dropdownMatchSelectWidth={false}
          value={month}
          onChange={(newMonth) => {
            onChange(dayjs(value).month(newMonth));
          }}
        >
          {monthOptions}
        </Select>
      </div>
    );
  }

  render() {
    return (
      <div className="calendar-view">
        <Calendar
          dateCellRender={this.dateCellRender}
          onSelect={(x) => {
            this.onSelect(x);
          }}
          className="calendar-component"
          mode="month"
          headerRender={({value, type, onChange, onTypeChange}) => this.renderTableHeader(value, type, onChange, onTypeChange)}
        />

        <div className="list-underneath" ref={listRef}>
          <h3>Veranstaltungen am {dayjs(this.state.selectedDate).format(dateFormat)}</h3>
          {this.state.isSelectedEvent ? (
            <>{this.dateCellRender(this.state.selectedDate)}</>
          ) : (
            <div className="no-entries">Keine Ereignisse</div>
          )}
        </div>
      </div>
    );
  }
}
