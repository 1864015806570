import { noEndWithSpaceRegex, phoneRegex } from 'App/globalVar';
import { Form, Input } from 'antd';
import React from 'react';

class PhoneNumberFormItem extends React.Component {

  render() {
    return (
      <Form.Item
        name={this.props.name}
        label={this.props.label}
        rules={[
          this.props.required
            ? {
                required: true,
                message: 'Bitte geben Sie eine Telefonnummer an.',
              }
            : [],
          {
            pattern: phoneRegex,
            message: 'Bitte geben Sie eine valide österreichische Telefonnummer an.',
          },
          {
            pattern: noEndWithSpaceRegex,
            message: 'Die Telefonnummer darf nicht mit einem Leerzeichen enden.',
          },
        ].concat(this.props.rules ? this.props.rules : [])}
      >
        <Input
          disabled={this.props.disabled}
          prefix="+43"
          placeholder="650 1234567"
          autoComplete="new-password"
        />
      </Form.Item>
    );
  }
}

export default PhoneNumberFormItem;
