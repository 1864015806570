export const apiUrl = './api';
export const defaultGuid = '00000000-0000-0000-0000-000000000000';
export const isProduction = process.env.NODE_ENV === 'production';

export const mobileBreakpoint = 768;
export const headbarFullMaxWidth = 920;

export const maxDate = new Date(8640000000000000);
export const minDate = new Date(-8640000000000000);

export const APP_defaultClusterName = "Nationale Clusterplattform";

export const passwordStates = {
  passwordOk: 10,
  passwordReset: 5,
  passwordNeverSet: 0
}

export const clusterState = {
  clusterDraft: 0,
  clusterInProcess: 5,
  clusterOnline: 10,
  clusterOutdated: 15
}

export const modalType = {
  new: 0,
  edit: 1,
  view: 2,
};

export const changelogTypes = {
  0: 'Bearbeitet',
  1: 'Kommentar',
  2: 'Status Veränderung',
  3: 'Anfrage versandt',
  4: 'Anfrage beantwortet',
  5: 'Archiviert'
}

export const newsletterType = {
  newsletter: 0,
  teaser: 5,
  event: 10,
}

export const ROLES = {
  admin: 'ADMIN',
  user: 'USER',
  winnovation: 'WINNOVATION',
  subjectDepartment: 'SUBJECT_DEPARTMENT',
  publicRelation: 'PUBLIC_RELATION',
  approver: 'APPROVER'
};

export const newsletterState = {
  draft: 0,
  inProcessWin: 2,
  inProcessSD: 4,
  inProcessPR: 6,
  published: 10,

  //only in frontend used for Newsletter OverviewTable
  // archived: -1,
}

export const maxLength = {
  Small: 125,
  Medium: 250,
  Large: 500,
  Huge: 1000,
  _2KB: 2048,
  _2MB: 2097152,
};

export const translationMap = {
  "event" : "Veranstaltung",
  "teaser": "Teaser",
  "newsletter": "Newsbeitrag",
  "published": "Veröffentlicht",
  "inProcess": "In Freigabe",
  "draft" : "In Bearbeitung",
  "archived": "Archiviert",

  "inProcessWin": "In Freigabe - winnovation",
  "inProcessSD": "In Freigabe - Fachabteilung",
  "inProcessPR": "In Freigabe - Öffentlichkeitsarbeit",

  "clusterDraft": "Draft",
  "clusterInProcess": "Anfrage in Bearbeitung",
  "clusterOnline": "Online",
  "clusterOutdated": "Outdated",

  clusterProperties: {
    "Name" : "Name",
    "ClusterHolderIds" : "Träger",
    "StateId" : "Bundesland",
    "Founded": "Gegründet am",
    "Website": "Homepage",
    "ECCP": "ECCP",
    "IndustryIds": "Branchen",
    "Nuts3Id": "NUTS3-Region",
    "PostalCode": "PLZ",
    "Location": "Ort",
    "Street": "Straße",
    "HouseNumber": "Hausnummer",
    "AdditionalLocationData": "Adresszusatz",
    "GeneralEmail": "Allgemeine Email-Adresse",
    "GeneralPhoneNumber": "Allgemeine Telefonnummer",
    "ClusterManager": "Clustermanagende Person",
    "MainContact": "Hauptansprechperson",
    "IsClusterManagerPublic": "Kontaktdaten Clustermanagende Person öffentlich sichtbar?",
    "ContactPersons": "Weitere Kontaktpersonen",
    "ClusterClassId": "Klasse",
    "ClusterState": "Status",

    "Comment": "Kommentar",
    "Cluster": "Cluster",
    "Deleted": "Archiviert",

    "MainTopicsGoals": "Hauptthemen und Ziele",
    "ClusterMembers": "Mitgliederanzahl",
    "KmuRatio": "KMU-Anteil",
    "TotalSales": "Gesamtumsatz",
    "AllMembers": "Gesamtmitgliederanzahl",
    "ResearchAndDevelopmentQuota": "Quote für Forschung und Entwicklung",
    "ExportQuota": "Exportquote",
  },

  [ROLES.admin]: "Admin",
  [ROLES.user]: "Benutzer",
  [ROLES.winnovation]: "winnovation",
  [ROLES.subjectDepartment]: "Fachabteilung",
  [ROLES.publicRelation]: "Öffentlichkeitsarbeit",
}

export const genders = [
  "männlich",
  "weiblich",
  "nicht-binär",
  "nicht angegeben"
];

export const emailRegex = /^(?!\s)\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g;
export const phoneRegex = /^(0)?(\d){3}(\s)?(\s?[\d]){1,12}$/g;
export const noEndWithSpaceRegex = /[^\s]$/g;
export const wordsOnlyRegex = /^[^.\s][a-zß-üA-Z\s\-\p{L}]+$/g;
export const passwordRegex = /^(?=.*\d)(?=.*[a-züöä])(?=.*[A-ZÜÖÄ])(?=.*[a-zß-üA-Z])(?=.*[!@#$%^&*_=+-.]).{6,}$/g;
export const academicTitlesRegex = /^[A-Z][a-zA-Z\s\-\.]{0,29}$/g;
export const locationRegex = /^[^.\s][a-zß-üA-Z\s\-\.\(\)]*$/g;
export const urlRegex = /(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/g;
export const httpsPrefixRegex = /^(http[s]?:\/\/)/g;