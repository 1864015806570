import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class ClusterClassStore extends BaseEntitiesStore {
  constructor() {
    super('ClusterClass', 'ClusterClassStore');
    if (!isProduction) {
      window[`ClusterClassStore`] = this;
    }
  }
}

export default new ClusterClassStore();
