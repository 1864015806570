import { inject } from 'mobx-react';
import React from 'react';
import { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  ApplicationPaths,
  QueryParameterNames,
} from 'utils/api-authorization/ApiAuthorizationConstants';
import authService from 'utils/api-authorization/AuthorizeService';

@inject('userStore')
export default class RoleAuthRoute extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      authenticated: false,
      hasRequiredRoles: false
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.authenticationChanged());
    this.populateAuthenticationState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  render() {
    const { ready, authenticated, hasRequiredRoles } = this.state;
    var link = document.createElement('a');
    link.href = this.props.path;
    const redirectUrl = `${ApplicationPaths.Login}?${
      QueryParameterNames.ReturnUrl
    }=${encodeURI(window.location.href)}`;
    const redirectUrlNotRequiredRoles = '';
    if (!ready) {
      return <div></div>;
    } else {
      const { component: Component, ...rest } = this.props;
      return (
        <Route
          {...rest}
          render={(props) => {
            if (authenticated) {
                if(hasRequiredRoles){
                    return <Component {...props} />;
                }
                else {
                    return <Redirect to={redirectUrlNotRequiredRoles} />;
                }
            } else {
              return <Redirect to={redirectUrl} />;
            }
          }}
        />
      );
    }
  }

  async populateAuthenticationState() {
    const authenticated = await authService.isAuthenticated();
    let hasRequiredRoles = await this.hasUserRequiredRoles();
    this.setState({ ready: true, authenticated, hasRequiredRoles: hasRequiredRoles });
  }

  async authenticationChanged() {
    this.setState({ ready: false, authenticated: false });
    await this.populateAuthenticationState();
  }

  async hasUserRequiredRoles(){
    if(!this.props.roles || ! await authService.getUser()) return true;
    await this.props.userStore.getUserInformation();
    // let userId = (await authService.getUser()).sub;
    // await this.props.userStore.get(userId);
    // let authUser = toJS(this.props.userStore.entity);
    return !!(this.props.roles.find(x => x == this.props.userStore.currentUser.role));
  }
}
