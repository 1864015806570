import './App.scss';
import '../../styles/main.scss';

import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import deDE from 'antd/lib/locale/de_DE';

import * as stores from 'stores';

import { ConfigProvider } from 'antd';
import AppRouter from './AppRouter';

@observer
class App extends Component {
  componentDidMount = async () => {
    stores.uiStore.history = this.props.history;
    stores.uiStore.loading = false;
  };

  render() {
    return (
      <Provider {...stores}>
        <ConfigProvider locale={deDE}>
          <AppRouter />
        </ConfigProvider>
      </Provider>
    );
  }
}
export default App;
