import { Button, Divider, Modal, Skeleton, Table } from 'antd';
import dayjs from 'dayjs';
import { inject } from 'mobx-react';
import React from 'react';
import { translationMap, newsletterType, newsletterState } from '../../globalVar';
import { dateFormat } from '../../utils/dayjsLocale';
import { reverseMap } from '../../utils/functions';

@inject('newsletterStore')
class ChangelogModal extends React.Component {
  state = {
    reverseTypeMap: reverseMap(newsletterType),
    reverseStateMap: reverseMap(newsletterState),
    loading: true,
    tableLoading: true,
    lastChange: {},

    allChanges: [],
  };

  loadTableData = () => {
    this.setState({tableLoading: false}, async () => {
      this.setState({allChanges: await this.props.newsletterStore.getAllChangelogs(this.state.lastChange.entityId, this.props.type)})
    });
  };

  render() {
    if (this.state.loading)
      return (
        <Modal>
          <Skeleton loading={this.state.loading} />
        </Modal>
      );

    let lastChangeEntity = this.state.lastChange;
    let columns = [
      {
        title: 'Änderungsdatum',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: '10%',
        render: (date) => {
          return dayjs(date).format(dateFormat);
        }
      },
      {
        title: 'Geändert von',
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: '30%',
      },
      {
        title: 'Schritt vor Änderung',
        dataIndex: 'previousState',
        key: 'previousState',
        width: '30%',
        render: (state) => {
          return translationMap[this.state.reverseStateMap[state]];
        }
      },
      {
        title: 'Schritt nach Änderung',
        dataIndex: 'currentState',
        key: 'currentState',
        width: '30%',
        render: (state) => {
          return translationMap[this.state.reverseStateMap[state]];
        }
      },
    ];

    return (
      <Modal
        destroyOnClose
        width={'33%'}
        visible={true}
        title={this.props.entity?.title}
        closable={true}
        onCancel={this.props.onCancel}
        footer={[
          <Button key="changelogCloseBtn" type='ghost' onClick={this.props.onCancel}>Schließen</Button>
        ]}
      >
        {!lastChangeEntity ? (
          <p>Keine Änderungen an {this.props.type == newsletterType.event ? 'dieser Veranstaltung.' : 'diesem Beitrag.'}</p>
        ) : (
          <div>
            <p>
              Am&nbsp;
              <strong>{dayjs(lastChangeEntity.createdAt).format('DD.MM.YYYY')}</strong>
              &nbsp; um&nbsp;
              <strong>{dayjs(lastChangeEntity.createdAt).format('HH:mm')}</strong>&nbsp;
              hat&nbsp;
              <strong>{lastChangeEntity.createdBy}</strong>&nbsp; den/die&nbsp;
              <strong>{translationMap[this.state.reverseTypeMap[this.props.type]]}</strong>&nbsp; von&nbsp;
              <strong>{translationMap[this.state.reverseStateMap[lastChangeEntity.previousState]]}</strong>&nbsp; zu&nbsp;
              <strong>{translationMap[this.state.reverseStateMap[lastChangeEntity.currentState]]}</strong>&nbsp; gestuft.&nbsp;
            </p>
            {lastChangeEntity.comment && (
              <>
                <p>Kommentar:</p>
                <p>{lastChangeEntity.comment}</p>
              </>
            )}
          </div>
        )}
        <Divider />
        {!!lastChangeEntity && (this.state.tableLoading) && (
          <div>
            <Button type="ghost" onClick={() => this.loadTableData()}>
              Alle Änderungen laden
            </Button>
          </div>
        )}

        {!this.state.tableLoading && (
          <Table
            loading={this.state.allChanges.length == 0}
            rowKey={'createdAt'}
            columns={columns}
            expandable={{
              expandedRowRender: (record) => (
                <p style={{margin: 0}}>{record.comment}</p>
              ),
              rowExpandable: (record) => !!record.comment
            }}
            scroll={{ scrollToFirstRowOnChange: true, x: true }}
            dataSource={this.state.allChanges}
            pagination={{
              pageSize: 5
            }}
          />
        )}
      </Modal>
    );
  }

  async componentDidMount() {
    let lastChange = await this.props.newsletterStore.getLastChangelog(
      this.props.entity.id,
      this.props.type
    );
    this.setState({ lastChange: lastChange ?? {}, loading: false });
  }
}

export default ChangelogModal;
