import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Spin } from 'antd';
import { cloneDeep } from 'lodash-es';
import { openNotificationWithIcon } from 'utils/functions';
import { keyboardnavExplanation, sourceExplanation } from './TinyMCE-Help/keyboardnav';

export default class TinyMCE extends React.Component {
  state = {
    loading: true,

    editor: null,
    lastClickedAnnotation: null,

    currentSelection: '',
    preventSources: false,
  };
  editorRef = React.createRef();
  formRef = this.props.form;

  // this does not fire for editors on submit, so we need the getUpdatedContent function to update the content in form object
  onChange = (e) => {
    let { form, property } = this.props;
    if (typeof property === 'string') {
      form.current?.setFieldsValue({ [property]: e.target.getContent() });
    } else {
      // only works for property assignment with an array with max 2 elements
      // questions? - ask günther wtf is happening here
      let obj = {};
      obj[property[0]] = {};
      Object.values(obj)[0][property[1]] = e.target.getContent();
      form.current?.setFieldsValue(obj);
    }
  };

  getWordCount = () => {
    return this.state.editor.plugins.wordcount.body.getWordCount();
  }

  getAnnotationCount = () => {
    return this.state.editor.contentWindow.document.querySelectorAll(
      '[data-mce-annotation="source"]'
    ).length;
  };

  updateAnnotation = (oldSources, sources) => {
    let toFind;
    let replaceBy;
    let newContent = this.state.editor.getContent();

    oldSources.forEach((x, idx) => {
      if (x != undefined) {
        let index = sources.findIndex((src) => src.reference == x);
        if (index != -1) {
          toFind = `<span id="mce-${x}" class="mce-annotation" data-mce-annotation-uid="mce-${x}" data-mce-annotation="source">`;
          replaceBy = `<span id="mce-${sources[index].reference}" class="mce-annotation" data-mce-annotation-uid="mce-${sources[index].reference}" data-mce-annotation="source">`;
          newContent = this.replaceLast(newContent, toFind, replaceBy);
        }
      }
    });

    this.state.editor.setContent(newContent);
  };

  updateAnnotationViaCount = (sourceCount, refsToReplace) => {
    let toFind;
    let replaceBy;
    let newContent = this.state.editor.getContent();

    refsToReplace.forEach((x) => {
      toFind = `<span id="mce-${x}" class="mce-annotation" data-mce-annotation-uid="mce-${x}" data-mce-annotation="source">`;
      replaceBy = `<span id="mce-${sourceCount}" class="mce-annotation" data-mce-annotation-uid="mce-${sourceCount}" data-mce-annotation="source">`;
      newContent = this.replaceLast(newContent, toFind, replaceBy);
      sourceCount++;
    });

    this.state.editor.setContent(newContent);
    return sourceCount;
  };

  // found at: https://www.30secondsofcode.org/js/s/replace-last
  replaceLast = (str, pattern, replacement) => {
    const match =
      typeof pattern === 'string'
        ? pattern
        : (str.match(new RegExp(pattern.source, 'g')) || []).slice(-1)[0];
    if (!match) return str;
    const last = str.lastIndexOf(match);
    return last !== -1
      ? `${str.slice(0, last)}${replacement}${str.slice(last + match.length)}`
      : str;
  };

  setInitalStyles = (newsletter) => {
    newsletter.sources?.forEach((x) => {
      if (x.reference) {
        this.addStyleToEditorHead(`mce-${x.reference}`, x.reference);
      }
    });
  };

  updateReferences = (sources) => {
    let noRefCount = 0;
    sources.forEach((x, idx) => {
      if (x.reference != undefined) {
        x.reference = idx - noRefCount + 1;
      } else {
        noRefCount++;
      }
    });
    this.props.setSourceCounter(sources.length - noRefCount);
    this.formRef.current?.setFieldsValue({
      sources: sources,
    });

    return sources;
  };

  updateListReferences = (spans) => {
    let oldSorting = [];
    let currentSorting = [];
    let sources = this.formRef.current.getFieldsValue().sources;
    spans.forEach((span) => {
      let id = this.getIdFromTag(span);
      oldSorting.push({ reference: id });
      currentSorting.push(sources.find((x) => x.reference == id));
    });

    this.updateReferences(currentSorting.concat(sources.filter((x) => !x.reference)));
  };

  getIdFromTag = (span) => {
    let startIndex = span.outerHTML.indexOf('uid=') + 9;
    return span.outerHTML.substring(startIndex, span.outerHTML.indexOf('"', startIndex));
  };

  getSourceAnnotations = () => {
    return Array.from(
      this.state.editor.contentWindow.document.getElementsByClassName('mce-annotation')
    );
  };

  getIdsFromAnnotations = (annotations) => {
    return annotations.map((x) => this.getIdFromTag(x));
  };

  addSource = () => {
    if (this.state.preventSources) {
      openNotificationWithIcon(
        'error',
        'Sie dürfen nur Text mit gleicher Formatierung, der sich im gleichen Absatz befindet, als Quelle hinzufügen.'
      );
    } else {
      if (
        !!this.state.editor.selection.getContent() &&
        !this.state.lastClickedAnnotation
      ) {
        let editor = this.state.editor;
        let sourceCounter = this.props.getSourceCounter();
        let uid = `mce-${sourceCounter + 1}`;
        editor.annotator.annotate('source', {
          uid: uid,
          id: uid,
        });
        this.props.addStyle(uid, sourceCounter + 1);
        let sources = this.formRef.current?.getFieldsValue().sources;
        if (!sources) {
          this.formRef.current?.setFieldsValue({
            sources: [],
          });
        }
        editor.focus();
      }
    }
  };

  removeSource = () => {
    this.state.editor.annotator.remove('source');
  };

  addStyleToEditorHead = (uid, content) => {
    let editor = this.state.editor;
    if (editor) {
      let style = `#${uid}::after{content: "[${content}]"; vertical-align: super; font-size: 14px;}`;
      let cssDoc = document.createElement('style');
      cssDoc.type = 'text/css';
      cssDoc.id = `${uid}-style`;
      let textNode = document.createTextNode(style);
      cssDoc.appendChild(textNode);

      editor.contentWindow.document.head.appendChild(cssDoc);
    }
  };

  onLoaded = () => {
    this.setState({ loading: false });
  };

  getUpdatedContent = () => {
    return this.state.editor.getContent();
  };

  // this fn selects the entire word when only part of it has been selected
  adjustCurrentSelection = () => {
    let editor = this.state.editor;

    let testRange = editor.selection.getRng().cloneContents();
    let div = document.createElement('div');
    div.appendChild(testRange);

    let children = div.childNodes;

    // if different sorts of text have been selected, make sure user cannot add sources
    // example: <p>lorem <strong>ipsum</strong></p> or <p>lorem <span ...>ipsum</span></p> are not allowed
    // while something like <p><strong>Lorem ipsum</strong></p> is allowed
    if (children.length > 1) {
      this.setState({ preventSources: true });

      let containsPNode = false;
      let textNodes = [];
      for (let i = 0; i < children.length; i++) {
        if (children[i].nodeName === 'P') {
          containsPNode = true;
          break;
        }
        if (children[i].nodeName === '#text') textNodes.push(children[i]);
      }

      // if current selection is only one paragraph and all of them are text nodes,
      // "update" content, to trigger union of text nodes
      if (!containsPNode && children.length === textNodes.length) {
        editor.setContent(editor.getContent());
      }
      return;
    }

    // only adjust selection if something has been selected
    if (!!this.state.currentSelection && !!editor.selection.getContent()) {
      let startIdx;
      let endIdx;
      let startBiggerThanEnd = false;

      // figure out starting position in anchor node (where a selection happened)
      let selection = editor.selection.getSel();
      let content = selection.anchorNode.textContent;

      if (selection.anchorOffset < selection.focusOffset) {
        startIdx = selection.anchorOffset;
        endIdx = selection.focusOffset;
      } else {
        startIdx = selection.focusOffset;
        endIdx = selection.anchorOffset;
        startBiggerThanEnd = true;
      }
      endIdx -= 1;

      // look for boundaries of word (leading / trailing whitespaces or end of paragraph)
      let startFound = false;
      let endFound = false;
      while (!(startFound && endFound)) {
        if (!startFound && content[startIdx].trim() !== '' && !!content[startIdx - 1]) {
          startIdx--;
        } else {
          startFound = true;
        }
        if (!endFound && content[endIdx].trim() !== '' && !!content[endIdx + 1]) {
          endIdx++;
        } else {
          endFound = true;
        }
      }

      if (content.length > 1) {
        startIdx = content[startIdx].trim() === '' ? (startIdx += 1) : startIdx;
        endIdx = content[endIdx].trim() !== '' ? (endIdx += 1) : endIdx;
      }

      // get anchor node and set selection of editor window
      let startNode = editor.selection.getRng().startContainer;
      editor.contentWindow.document
        .getSelection()
        .setBaseAndExtent(
          startNode,
          startBiggerThanEnd ? endIdx : startIdx,
          startNode,
          startBiggerThanEnd ? startIdx : endIdx
        );
    }
  };

  render() {
    let value = this.props.value;
    var that = this;
    let newsletter = this.props.entity;
    return (
      <div style={{ height: this.props.isTeaser ? 300 : 600 }}>
        {this.state.loading && <Spin spinning={this.state.loading}></Spin>}
        <Editor
          apiKey='vyx1306a6zywmysbbdvjrvhsqhuh0nbuh3wtlunris79j2k3'
          onInit={(evt, editor) => {
            this.editorRef.current = editor;
            this.onLoaded();
            editor.selection.select(editor.getBody(), true);
            editor.selection.collapse(false);
          }}
          initialValue={value || ''}
          disabled={this.props.disabled}
          onBlur={this.onChange}
          init={{
            height: '100%',
            menubar: false,
            placeholder: 'Text verfassen...',
            plugins: [
              'advlist autolink link lists image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount help',
            ],
            contextmenu: 'addSource | removeSource',
            contextmenu_never_use_native: true,
            toolbar: [
              { name: 'Springen', items: ['undo', 'redo'] },
              { name: 'Format', items: ['formatselect'] },
              {
                name: 'Align',
                items: ['alignleft', 'aligncenter', 'alignright', 'alignjustify'],
              },
              { name: 'Formatierung', items: ['bold', 'italic', 'backcolor'] },
              { name: 'Listen', items: ['bullist', 'numlist'] },
              { name: 'Einzug', items: ['outdent', 'indent'] },
              { name: 'Entfernen', items: ['removeformat'] },
              { name: 'Link', items: ['link'] },
              { name: 'Hilfe', items: ['help'] },
              { name: 'Quellen', items: ['addSourceBtn', 'removeSourceBtn'] },
            ],
            toolbar_mode: 'sliding',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            language: 'de',
            help_tabs: [
              'shortcuts', // the default shortcuts tab
              {
                name: 'Tastaturnavigation',
                title: 'Tastaturnavigation',
                items: [
                  {
                    type: 'htmlpanel',
                    html: keyboardnavExplanation,
                  }
                ]
              },
              {
                name: 'sources',
                title: 'Quellen Tastenkombinationen',
                items: [
                  {
                    type: 'htmlpanel',
                    html: sourceExplanation,
                  },
                ],
              },
            ],
            block_formats: 'Absatz=p; Kopfzeile 2=h2; Kopfzeile 3=h3',
            setup: function (editor) {
              editor.on('init', () => {
                that.setState({ editor: editor });
                editor.annotator.register('source', {
                  persistent: true,
                  decorate: (uid, data) => {
                    return {
                      attributes: {
                        id: uid,
                      },
                    };
                  },
                });

                editor.on('keydown', (e) => {
                  let allowedKeys = [35, 36, 37, 39];
                  if (
                    that.state.lastClickedAnnotation &&
                    !allowedKeys.includes(e.keyCode)
                  ) {
                    e.preventDefault();
                  }
                });

                editor.on('mouseUp', () => {
                  if (!!that.state.currentSelection) {
                    that.adjustCurrentSelection();
                  }
                });

                editor.on('keyup', (e) => {
                  if (!e.shiftKey && !!that.state.currentSelection) {
                    that.adjustCurrentSelection();
                  }
                });

                editor.on('selectionChange', () => {
                  let selection = editor.selection.getContent();
                  if (that.state.currentSelection && !selection) {
                    that.setState({ currentSelection: '', preventSources: false });
                  }
                  if (
                    !that.state.currentSelection &&
                    selection != that.state.currentSelection
                  ) {
                    that.setState({ currentSelection: selection });
                  }
                });

                editor.annotator.annotationChanged('source', (state, name, obj) => {
                  let sourceAnnotationCount = that.props.getSourceKeyCount();
                  let sourceCount = that.props.getSourceCounter();
                  let sources = that.formRef.current?.getFieldsValue().sources;

                  if (sourceAnnotationCount != sourceCount) {
                    let oldSources = cloneDeep(sources);
                    if (sourceAnnotationCount > sourceCount) {
                      // add
                      let newSource = {
                        reference: sourceCount + 1,
                        isLink: false,
                        text: undefined,
                      };

                      sources = Array.isArray(sources)
                        ? [...sources, newSource]
                        : [newSource];

                      that.formRef.current?.setFieldsValue({
                        sources: sources,
                      });

                      let fieldsToValidate = [];
                      let sourcesWithoutText = sources.filter((x) => !x.text);
                      sourcesWithoutText.forEach((x, idx) => {
                        fieldsToValidate.push(['sources', idx, 'text']);
                        fieldsToValidate.push(['sources', idx, 'isLink']);
                      });

                      that.formRef.current?.validateFields(fieldsToValidate);
                      that.props.setSourceCounter((sourceCount += 1));
                    } else if (sourceAnnotationCount < sourceCount) {
                      // remove
                      let refId = that.state.lastClickedAnnotation?.uid.substring(4);
                      if (refId) {
                        sources = sources.filter((x) => x.reference != refId);
                        oldSources = cloneDeep(sources);
                        that.props.setSourceCounter((sourceCount -= 1));
                      }
                    }
                    that.props.updateAnnotations(oldSources, sources);
                  }
                  that.setState({
                    lastClickedAnnotation: obj,
                  });
                });
                if (newsletter?.sources) {
                  that.props.setInitialStyles(newsletter);
                }
              });
              editor.ui.registry.addMenuItem('addSource', {
                text: 'Quelle hinzufügen',
                icon: 'comment-add',
                onAction: () => {
                  that.addSource();
                },
              });
              editor.ui.registry.addMenuItem('removeSource', {
                text: 'Quelle entfernen',
                icon: 'comment',
                onAction: () => {
                  that.removeSource();
                },
              });

              editor.ui.registry.addButton('addSourceBtn', {
                type: 'button',
                text: 'Quelle hinzufügen',
                icon: 'comment-add',
                onAction: () => {
                  that.addSource();
                },
              });
              editor.ui.registry.addButton('removeSourceBtn', {
                type: 'button',
                text: 'Quelle entfernen',
                icon: 'comment',
                onAction: () => {
                  that.removeSource();
                },
              });
              editor.addShortcut('meta+q', 'Quelle hinzufügen', () => {
                that.addSource();
              });
              editor.addShortcut('meta+r', 'Quelle entfernen', () => {
                that.removeSource();
              });
            },
          }}
        />
      </div>
    );
  }
}
