import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Row } from 'antd';
import { newsletterType } from 'App/globalVar';
import { inject } from 'mobx-react';
import React from 'react';
import { openNotificationWithIcon } from 'utils/functions';

@inject('newsletterStore', 'breadcrumbStore', 'formatStore', 'uiStore')
class StackNavigator extends React.Component {
  toFutureNewsletter = async () => {
    await this.navigate(this.props.uiStore.newsletterNavigationStack.pop());
  };

  toPastNewsletter = async (id, pastId, type) => {
    this.props.uiStore.newsletterNavigationStack.push({
      id: id,
      type: type,
    });
    // since it's always a previous newsletter, never a previous event we need to use the newsletter type here
    await this.navigate({ id: pastId, type: newsletterType.newsletter });
  };

  navigate = async (navigationObj) => {
    let id = navigationObj.id;
    let type = navigationObj.type;
    try{
        this.props.newsletterStore.entity = {};
        this.props.newsletterStore.entityId = id;
        this.props.newsletterStore.entityType = type;

      if (type == newsletterType.event) {
        await this.props.formatStore.getAll();
        this.props.uiStore.navigateBetweenPosts(`/calendar/${id}`);
        this.props.breadcrumbStore.setBreadcrumbs('calendar', {
          id: this.props.newsletterStore.entity.id,
          name: this.props.newsletterStore.entity.title,
        });
      } else {
        this.props.uiStore.navigateBetweenPosts(`/clusterplattform-oesterreich/${id}`);
        this.props.breadcrumbStore.setBreadcrumbs('clusterplattform-oesterreich', {
          id: this.props.newsletterStore.entity.id,
          name: this.props.newsletterStore.entity.title,
        });
      }
    } catch (ex) {
      openNotificationWithIcon('error', 'Ein Fehler ist aufgetreten!');
    }
  }

  render() {
    let entity = this.props.entity;
    let type = this.props.type;

    return (
      <div className="stack-navigator">
        {this.props.uiStore.newsletterNavigationStack.length > 0 && (
          <Button
            type="ghost"
            className="btn-with-icon-right secondary-button future"
            onClick={() => this.toFutureNewsletter()}
          >
            <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-left']} />
            Zum zuletzt gelesenen Beitrag
          </Button>
        )}
        {entity.previousNewsletterId && (
          <Button
            type="ghost"
            className="btn-with-icon secondary-button past"
            onClick={() =>
              this.toPastNewsletter(entity.id, entity.previousNewsletterId, type)
            }
          >
            Zum vorhergehenden Beitrag
            <FontAwesomeIcon icon={['fas', 'arrow-alt-circle-right']} />
          </Button>
        )}
      </div>
    );
  }
}

export default StackNavigator;
