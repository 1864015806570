import { Row, Checkbox } from 'antd';
import React from 'react';

class AccessibleFilterDropdown extends React.Component {
  // iconState = false -> no filters have been set; Icon should be grey
  // iconState = true -> filters have been set; Icon should be yellow
  finish = (iconState, callback) => {
    this.props.updateIcon(iconState);
    if (this.props.tabIndex === 0) {
      document.getElementById(this.props.returnFocusTo).focus({ focusVisible: true });
    }
    callback();
  };

  render() {
    let options = this.props.options?.map((x) => {
      return {
        label: x.text,
        value: x.value,
      };
    });
    return !!options && options.length > 0 && (
      <div
        className="ant-table-filter-dropdown"
        aria-live="polite"
        aria-relevant="additions text"
      >
        <Checkbox.Group
          style={{ width: '100%' }}
          onChange={this.props.setSelectedKeys}
          value={this.props.selectedKeys}
          id="accessible-checkboxes"
        >
          {options.map((option, idx) => (
            <Row className="accessible-checkmark-row">
              <Checkbox
                className="accessible-checkmark"
                value={option.value}
                tabIndex="0"
              >
                <span aria-label={`${option.label} Eintrag ${idx+1} von ${options.length}`}>{option.label}</span>
              </Checkbox>
            </Row>
          ))}
        </Checkbox.Group>
        <div className="ant-table-filter-dropdown-btns">
          <button
            disabled={this.props.selectedKeys.length == 0}
            type="button"
            className="ant-btn ant-btn-link ant-btn-sm"
            onClick={() => this.finish(false, this.props.clearFilters)}
            tabIndex="0"
          >
            <span>Zurücksetzen</span>
          </button>
          <button
            type="button"
            className="ant-btn ant-btn-primary ant-btn-sm yellow"
            onClick={() =>
              this.finish(this.props.selectedKeys.length > 0, this.props.confirm)
            }
            tabIndex="0"
          >
            <span>OK</span>
          </button>
        </div>
      </div>
    );
  }
}

export default AccessibleFilterDropdown;
