import { observable } from 'mobx';
import axios from 'axios';

import { isProduction, apiUrl } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class ClusterDataCacheStore extends BaseEntitiesStore {
  constructor() {
    super('ClusterDataCache', 'ClusterDataCacheStore');
    if (!isProduction) {
      window[`ClusterDataCacheStore`] = this;
    }
  }

  async getCacheByClusterId(clusterId) {
    return (await axios.get(`${this.path}/${clusterId}/id`)).data;
  }

  async getCacheByHash(hash) {
    return (await axios.get(`${this.path}/${hash}/hash`)).data;
  }

  async updateCacheByClusterIdAsync(clusterId, values) {
    return (await axios.put(`${this.path}/${clusterId}/id`, values)).data;
  }

  async updateCacheByHashAsync(hash, values) {
    return (await axios.put(`${this.path}/${hash}/hash`, values)).data;
  }
}

export default new ClusterDataCacheStore();
