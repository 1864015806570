import React from 'react';
import { inject } from 'mobx-react';
import { Alert, Button, Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UserTable from './UserTable';
import UserModal from './UserModal';
import {
  getDirtyFormConfirmationWithCheck,
} from 'utils/functions';

@inject('userStore', 'breadcrumbStore', 'clusterStore')
class UserOverview extends React.Component {
  state = {
    allClusters: [],
    showUserCreationModal: false,
    isEdit: false,
    loading: true,
    isDirtyFormSaving: false
  };

  async componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumbs('benutzerverwaltung');
    await this.props.userStore.getAllUserRoles();
    let clusters = await this.props.clusterStore.getSimpleClusters(false);

    await this.getFilteredTableData();

    this.setState({ loading: false, allClusters: clusters });
  }

  getFilteredTableData = async (filters, sorter) => {
    await this.props.userStore.getUsersSortedAndFiltered(filters, sorter);
    this.forceUpdate();
  };
  
  handleSavingDirtyForm = (isDirtyFormSaving) => {
    this.setState({isDirtyFormSaving: isDirtyFormSaving});
  }

  setUserModalVisible = async ( isVisible = false, isEdit = false, currentFormValues = [] ) => {
    let cancelDirtyForm = this.state.isDirtyFormSaving ? true : getDirtyFormConfirmationWithCheck(
            currentFormValues,
            this.props.userStore.entity
          );

    if (cancelDirtyForm) {
      if (!isEdit) {
        this.props.userStore.entity = {};
        await this.getFilteredTableData();
      }
      this.setState({
        showUserCreationModal: isVisible,
        isEdit: isEdit,
        isDirtyFormSaving: false,
      });
    }
  };

  render() {
    if (this.state.loading) return <Skeleton loading={this.state.loading} />;

    return (
      <div className="home">
        <div className="header">
          <h1 className="title">
            Benutzerverwaltung{' '}
            <FontAwesomeIcon icon={['fas', 'users']} className="header-icon" />
          </h1>
          <div className="action-btns">
            <Button
              type="primary"
              onClick={async () => this.setUserModalVisible(true)}
              className="header-btn yellow"
            >
              Benutzer anlegen{' '}
              <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon" />
            </Button>
          </div>
        </div>
        <Alert
            style={{ marginBottom: '20px' }}
            message="Benutzer kann keinem Cluster zugeteilt werden, wenn dieser keinen aktivierten Account hat."
            type="warning"
            closable
          />
        <UserTable
          store={this.props.userStore}
          setUserModalVisible={this.setUserModalVisible}
          getFilteredTableData={this.getFilteredTableData}
          clusters={this.state.allClusters}
        />
        {this.state.showUserCreationModal && (
          <UserModal
            visible={this.state.showUserCreationModal}
            setUserModalVisible={this.setUserModalVisible}
            isEdit={this.state.isEdit}
            store={this.props.userStore}
            handleSavingDirtyForm={this.handleSavingDirtyForm.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default UserOverview;
