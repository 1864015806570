import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../Modals/Modals.scss";
import { Modal, Button } from 'antd';
import React from 'react';
import { upperFirst } from "lodash-es";
import DOMPurify from "dompurify";

class HeadbarModal extends React.Component {

  render() {
    const entity = this.props.entity;
    return (
      <Modal
        destroyOnClose
        width={1200}
        visible={this.props.visible}
        onCancel={() => this.props.closeModal()}
        title={<h1>{upperFirst(entity.page)}</h1>}
        footer={[
          <Button
            key="cancel"
            onClick={() => this.props.closeModal()}
            type="primary"
            className="teal"
          >
            Schließen{' '}
            <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon rotate" />
          </Button>,
        ]}
        className="headbar-modal"
      >
        <div className="modal-content">
          <div className="content-form">
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(entity.text) }} />
          </div>
        </div>
      </Modal>
    );
  }
}

export default HeadbarModal;
