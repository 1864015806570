import React, { Component } from 'react';

const bars = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="bars">
    <path d="M436 124H12c-6.627 0-12-5.373-12-12V80c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12zm0 160H12c-6.627 0-12-5.373-12-12v-32c0-6.627 5.373-12 12-12h424c6.627 0 12 5.373 12 12v32c0 6.627-5.373 12-12 12z" />
  </svg>
);

const caretRight = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" className="caret-right">
    <path d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z" />
  </svg>
);

const caretLeft = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" className="caret-left">
    <path d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z" />
  </svg>
);

export class ToggleButton extends Component {
  render() {
    return (
      <button className={`toggle-apps-nav-btn ${this.props.showMobileMenu ? 'useAlt' : 'doNotUseAlt'}`} onClick={this.props.toggleMobileMenu}>
        <div className="toggle-apps-nav-container">
          {this.props.showMobileMenu && caretLeft}
          {bars}
          {!this.props.showMobileMenu && caretRight}
        </div>
      </button>
    );
  }
}
