export const keyboardnavExplanation = `
<div role="document" data-alloy-tabstop="true" tabindex="-1" class="tox-form__group">
    <h1>Editor Tastaturnavigation</h1>

    <h2>Tastaturnavigation aktivieren</h2>

    <p>
        Die Sektionen der äußeren Benutzeroberfläche des Editors - die Menüleiste, die Symbolleiste, die Seitenleiste und der
        Fußbereich - sind alle über die Tastatur navigierbar. Daher gibt es mehrere Möglichkeiten, die Tastaturnavigation zu
        aktivieren:
    </p>
    <ul>
        <li>Menüleiste fokussieren: Alt + F9 (Windows) oder ⌥F9 (MacOS)</li>
        <li>Symbolleiste fokussieren: Alt + F10 (Windows) oder ⌥F10 (MacOS)</li>
        <li>Fußbereich fokussieren: Alt + F11 (Windows) oder ⌥F11 (MacOS)</li>
    </ul>
    
    <p>
        Durch das Fokussieren der Menüleiste oder Symbolleiste wird die Tastaturnavigation beim ersten Element gestartet und
        mit einem grauen Hintergrund hervorgehoben. Im Fußbereich wird das aktive Element unterstrichen. Um wieder zum
        Bereich zu gelangen, in dem geschrieben werden kann, muss eine Aktion mit Enter oder Leertaste gewählt werden.
    </p>

    <h2>Navigieren zwischen Sektionen</h2>

    <p>
        Wenn die Tastaturnavigation aktiviert wurde, kann mit Tab zwischen den Hauptsprungmarken navigiert werden.
        Hierbei können folgende Sektionen angesprungen werden:
    </p>
    <ul>
        <li>Jede Gruppe der Symbolleiste</li>
        <li>Der Elementpfad im Fußbereich</li>
        <li>Der Wortanzahl-Schalter im Fußbereich</li>
        <li>Der Tiny-Produkt-Link</li>
    </ul>

    <p>
        Durch Drücken der Hochstelltaste + Tab wird rückwärts durch die gleichen Sektionen navigiert, außer beim Wechsel vom
        Fußbereich zur Symbolleiste. Wenn der Elementpfad fokussiert ist und dann die Hochstelltaste + Tab gedrückt wird, wird
        der Fokus auf die erste Symbolleistengruppe und nicht auf die Letzte verschoben.
    </p>

    <h2>Navigieren in den einzelnen Sektionen</h2>

    <p>
        Die Tastaturnavigation in den einzelnen Sektionen wird üblicherweise über die Pfeiltasten getätigt. Beispiele hierfür
        sind:
    </p>
    <ul>
        <li>bewegen in Untermenüs der Menüleiste</li>
        <li>bewegen zwischen Buttons in der Symbolleiste</li>
    </ul>

    <p>
        In all diesen Sektionen funktioniert die Navigation zyklisch. Das bedeutet, dass nach Auswahl des letzten Elements
        und erneutem drücken der rechten Pfeiltaste, das erste Element dieser Gruppe ausgewählt wird.
    </p>

    <h1>Aktionen ausführen</h1>

    <p>Um eine Aktion auszuführen, navigieren Sie zunächst zur gewollten Schaltfläche und drücken Sie die Leertaste oder Enter.</p>

    <h1>Öffnen, Navigieren und Schließen von Menüs</h1>

    <p>
        Wenn eine Schaltfläche mit einem Menü ausgewählt wurde, kann dieses mit der Leertaste, Enter oder der Pfeiltaste nach
        unten geöffnet werden. Wenn das Menü geöffnet wurde, wird das erste Element ausgewählt. Um sich im Menü nach oben oder
        unten zu bewegen, drücken Sie die Pfeiltaste nach oben oder unten. Dies gilt auch für Untermenüs, die auch mit den
        Pfeiltasten nach links und rechts geöffnet und geschlossen werden können.
    </p>
    <p>
        Um ein aktives Menü zu schließen, drücken Sie die Escape-Taste. Wenn ein Menü geschlossen wird, wird die Auswahl zum
        zuletzt ausgewählten Element zurückgesetzt. Dies gilt auch für das Schließen von Untermenüs.
    </p>

    <h1>Kontextmenü</h1>
    <p>Die Navigation des Kontextmenüs funktioniert gleich wie die Navigation der Symbolleisten.</p>

    <h1>Navigation in einem Dialog</h1>

    <p>Im Editor werden zwei Arten von Dialogen verwendet: Reiter-basierte Dialoge und nicht Reiter-basierte Dialoge.</p>

    <p>
        Wenn ein nicht Reiter-basierter Dialog geöffnet wird, wird das erste interaktive Element im Dialog fokussiert. Benutzer
        können durch die interaktiven Komponenten mit der Tab-Taste navigieren. Dies beinhaltet auch alle
        Fußbereich-Schaltflächen. Die Navigation wird zum ersten Dialogelement zurückkehren, wenn die Tab-Taste gedrückt wird,
        während das letzte Element im Dialog fokussiert ist. Durch Drücken der Umschalttaste + Tab-Taste wird rückwärts navigiert.
    </p>

    <p>
        Wenn ein Reiter-basierter Dialog geöffnet wird, wird die erste Schaltfläche im Reiter-Menü fokussiert. Durch Drücken der
        Tab-Taste wird zur ersten interaktiven Komponente in diesem Reiter navigiert. Folgend werden die Komponenten des Reiters, die
        Fußbereich-Schaltflächen und dann zurück zur Reiter-Schaltfläche zyklisch navigiert. Um zu einem anderen Reiter zu wechseln,
        fokussieren Sie die Reiter-Schaltfläche für den aktuellen Tab und verwenden Sie die Pfeiltasten, um durch die
        Tab-Schaltflächen zu navigieren.
    </p>
</div>
`;

export const sourceExplanation = `
<div role="document" data-alloy-tabstop="true" tabindex="-1" class="tox-form__group">
    <table style="width: 100%; border-collapse: separate; border-spacing: 1em;">
        <thead style="text-align: left; font-weight: bold;">
            <th>Aktion</th>
            <th>Tastenkombination</th>
        </thead>
        <tbody>
            <tr>
                <td>Quelle hinzufügen</td>
                <td>Strg + Q</td>
            </tr>
            <tr>
                <td>Quelle entfernen</td>
                <td>Strg + R</td>
            </tr>
        </tbody>
    </table>
</div>
`;
