import React from 'react';
import { Button } from 'antd';

import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-image-crop/dist/ReactCrop.css';
import '../Newsletter/Newsletter.scss';
import { onEnterKey, openNotificationWithIcon } from 'utils/functions';
import { inject } from 'mobx-react';
import placeholderImg from '../../images/placeholder-image.jpg';

@inject('newsletterStore')
class ThumbnailCropper extends React.Component {
  imgRef = React.createRef();
  canvasRef = React.createRef();

  acceptedImageTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg"
  ]

  state = {
    croppedImg: '',
    crop: undefined,
    aspect: 4 / 3,
    inEditMode: false,
    oldCrop: undefined,

    shouldCrop: true,

    imageMetadata: {}
  };

  componentDidMount() {
    if (this.props.thumbnail?.image?.length > "data:;base64,".length) {
      let thumbnail = this.props.thumbnail;
      let image = new Image();
      image.onload = () => {
        this.props.newsletterStore.file = {
          originalImage: thumbnail.originalImage,
          image: thumbnail.originalImage ?? thumbnail.image,
          name: thumbnail.name,
          type: thumbnail.type,
          width: image.width,
          height: image.height,
        };
      };
      image.src = thumbnail.originalImage ?? thumbnail.image;
      this.setState({shouldCrop: false, croppedImg: this.props.thumbnail.image});
    } else {
      this.setState({croppedImg: placeholderImg});
    }
  }

  componentWillUnmount() {
    this.props.newsletterStore.file = {};
  }

  onChange = (e) => {
    let { form, property } = this.props;
    const stateValues = this.state.imageMetadata;
    const formValues = form.current.getFieldsValue([property])[property];

    let val = {
      originalImage: this.props.newsletterStore.file.originalImage,
      image: this.state.croppedImg,
      type: this.props.newsletterStore.file.type,
      name: this.props.newsletterStore.file.name,
    };

    let objectToCompleteWith;
    // for some reason thumbnail gets set to string with "fakepath" (C:/fakepath/thumbnail.png) when uploading a new image
    if(typeof(formValues) != 'string'){
      objectToCompleteWith = formValues;
    } else {
      objectToCompleteWith = stateValues;
    }

    val.id = objectToCompleteWith.id;
    val.thumbnailSource = objectToCompleteWith.thumbnailSource;
    val.imageTitle = objectToCompleteWith.imageTitle;
    val.altText = objectToCompleteWith.altText;
    val.copyright = objectToCompleteWith.copyright;

    if (typeof property === 'string') {
      form.current?.setFieldsValue({ [property]: val });
    } else {
      let obj = {};
      obj[property[0]] = {};
      Object.values(obj)[0][property[1]] = val;
      form.current?.setFieldsValue(obj);
    }
  };

  centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        { unit: '%', width: 64, height: 48 },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.setState({ crop: undefined }); // re-init crop
      const reader = new FileReader();

      let file = e.target.files[0];
      let sizeOk = true;
      let typeOk = true;

      if (file.size / 1024 / 1024 > 5) {
        sizeOk = false;
      }

      if(!this.acceptedImageTypes.includes(file.type)){
        typeOk = false;
      }

      if(!sizeOk || !typeOk){
        this.props.setDisplayImageError({
          typeOk: typeOk,
          sizeOk: sizeOk
        });
      }

      const { form, property } = this.props;

      // save current "snapshot" of formdata to state, for reason check onChange
      this.setState({
        imageMetadata: form.current.getFieldsValue([property])[property] ?? {}
      });

      reader.addEventListener('load', () => {
        if (reader.result) {
          let result = reader.result.toString();
          let image = new Image();
          image.onload = () => {
            this.props.newsletterStore.file = {
              originalImage: result,
              image: result,
              name: file.name,
              type: file.type,
              width: image.width,
              height: image.height
            };

            this.setState(
              {
                croppedImg: result,
                shouldCrop: true,
                crop: this.centerAspectCrop(image.width, image.height, this.state.aspect),
              },
              () => this.doCropImage()
            );
            this.props.setDisplayImageError({
              typeOk: typeOk,
              sizeOk: sizeOk
            });
          }
          image.src = result;
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  onImageLoad = (e) => {
    if (this.state.aspect && !this.state.crop) {
      const { width, height } = e.currentTarget;
      this.setState(
        { crop: this.centerAspectCrop(width, height, this.state.aspect) },
        () => this.doCropImage()
      );
    }
  };

  startEdit = () => {
    if(this.state.croppedImg != placeholderImg){
      this.setState({ oldCrop: this.state.crop, inEditMode: true, shouldCrop: true });
    }
  };

  saveCrop = async () => {
    this.doCropImage();
    this.setState({ inEditMode: false });
  };

  cancelCrop = () => {
    this.setState({ crop: this.state.oldCrop, inEditMode: false });
  };

  doCropImage = () => {
    let resizeCanvas = document.createElement('canvas');
    let currentCrop = this.state.crop;
    let storeFile = this.props.newsletterStore.file;

    if(this.state.shouldCrop){
      try {
        let ogImg = new Image();
        ogImg.src = storeFile.image;
        ogImg.onload = () => {
          let width = (currentCrop.width / 100) * storeFile.width;
          let height = (currentCrop.height / 100) * storeFile.height;
  
          let x = (storeFile.width / 100) * currentCrop.x;
          let y = (storeFile.height / 100) * currentCrop.y;
  
          resizeCanvas.width = width;
          resizeCanvas.height = height;
          let cnv = resizeCanvas.getContext('2d');
          cnv.drawImage(
            ogImg,
            x,
            y,
            width,
            height,
            0,
            0,
            width,
            height
          );

          this.setState({
            croppedImg: resizeCanvas.toDataURL(storeFile.type).toString(),
          }, () => this.onChange());
        };
      } catch (ex) {
        openNotificationWithIcon('error', 'Ein Fehler beim Zuschneiden ist aufgetreten!');
      }
    }
  };

  render() {
    let storeFile = this.props.newsletterStore.file;
    let showCropIcon = this.state.croppedImg != placeholderImg;
    return (
      <>
        <div className="thumbnail-cropper">
          <div className="image-crop">
            {!!storeFile.image && this.state.inEditMode && (
              <ReactCrop
                crop={this.state.crop}
                onChange={(pixelCrop, percentCrop) =>
                  this.setState({ crop: percentCrop })
                }
                onComplete={(pixelCrop, percentCrop) => {
                  this.setState({ crop: percentCrop });
                }}
                aspect={this.state.aspect}
              >
                <img
                  ref={this.imgRef}
                  src={storeFile.image}
                  onLoad={this.onImageLoad}
                  onError={placeholderImg}
                  alt="Bild das zugeschnitten wird"
                />
              </ReactCrop>
            )}
            {!!this.state.croppedImg && !this.state.inEditMode && (
              <div
                className={showCropIcon ? 'edit-hover-container' : ''}
                onClick={() => this.startEdit()}
                onKeyDown={(e) => onEnterKey(e, this.startEdit)}
                tabIndex="0"
              >
                <img
                  ref={this.imgRef}
                  src={this.state.croppedImg}
                  onLoad={this.onImageLoad}
                  alt={showCropIcon ? "Hochgeladenes Bild" : "Hochgeladenes Bild Platzhalter"}
                />
                {showCropIcon && (
                  <div className="overlay">
                    <div className="overlay-icon-container">
                      <FontAwesomeIcon
                        size="2x"
                        icon={['fas', 'crop']}
                        className="overlay-icon"
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          {!this.state.inEditMode ? (
            <>
              <Button
                type="secondary"
                hidden={this.props.isViewMode}
                disabled={this.props.isViewMode}
                className="cropper-upload-btn"
                onClick={
                  () => document.getElementById('custom_uploadThumbnailButton').click() // open file dialog
                }
              >
                <div className="upload-button-container">
                  <FontAwesomeIcon
                    icon={['fas', 'cloud-upload']}
                    style={{ height: '30%' }}
                  />
                  <p>Hochladen</p>
                </div>
              </Button>
              <input
                id="custom_uploadThumbnailButton"
                className="custom_uploadThumbnailButton"
                type="file"
                accept=".png,.jpg,.jpeg"
                onChange={this.onSelectFile}
              />
            </>
          ) : (
            <div className="cropper-btn-bar">
              <Button onClick={() => this.cancelCrop()}>Abbrechen</Button>
              <Button onClick={() => this.saveCrop()}>Speichern</Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default ThumbnailCropper;
