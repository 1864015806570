import { observable, action } from 'mobx';
import axios from 'axios';

import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';
import { getQueryStringFromObject } from 'utils/functions';

class UserStore extends BaseEntitiesStore {

  @observable
  userLoaded = false;

  @observable
  currentUser = {};

  @observable
  roles = [];

  didTryToAuthenticate = false;

  @observable
  usersList = [];

  activeSorter = {};
  activeFilters = [];

  constructor() {
    super('User', 'UserStore');
    if (!isProduction) {
      window[`UserStore`] = this;
    }
  }

  resetSorterAndFilters = () => {
    this.activeFilters = [];
    this.activeSorter = {};
  }

  @action('Set roles')
  setRoles(roles) {
    this.roles = roles;
  }

  @action('Set current user')
  setCurrentUser(user) {
    this.currentUser = user;
  }

  async getAllUserRoles(){
    var resp = await axios.get(`${this.path}/roles`);
    this.setRoles(resp.data);
  }

  async getUserInformation(){
    if(!this.currentUserValid() && !this.didTryToAuthenticate){
      this.didTryToAuthenticate = true;
      let resp = (await axios.get(`${this.path}/info`)).data;
      if(!resp) resp = {};
      this.setCurrentUser(resp);
    }
  }

  async updateUserInformation(){
    let resp = (await axios.get(`${this.path}/info`)).data;
    if (!resp) resp = {};
    this.setCurrentUser(resp);
  }

  currentUserValid(){
    return Object.keys(this.currentUser) > 0;
  }

  async checkIfUserExists(email) {
    return (await axios.get(`${this.path}/userExists?email=${email}`)).data;
  }

  async getUsersList(){
    return (this.usersList = (await axios.get(`${this.path}/existing-users`)).data);
  }

  async getUsersListAdmin(){
    return (this.usersList = (await axios.get(`${this.path}/existing-users/admin`)).data);
  }

  async getUsersSortedAndFiltered() {
    let query = ``; 
    let sorterString = getQueryStringFromObject({
      sortingField: this.activeSorter?.field,
      sortingOrder: this.activeSorter?.order
    });
    if(sorterString) query += sorterString.slice(1);    
    let filterString = sorterString ? getQueryStringFromObject(this.activeFilters) : getQueryStringFromObject(this.activeFilters).slice(1);
    query += filterString;

    return (this.entities = (await axios.get(`${this.path}/filtered?${query}`)).data);
  }

  async getUsersWithInactivAccount() {
    return (this.entities = (await axios.get(`${this.path}/filtered?IsActivatedAccount=false`)).data);
  }

  async updateCurrentUserInformation(values){
    let result = (await axios.put(`${this.path}/user-info/${values.id}`, values)).data;
    return (this.currentUser = result);
  }

  async setUserPasswordReset(id) {
    await axios.put(`${this.path}/set-password-reset/${id}`);
  }

  async setNewUserPassword(values){
    return (this.currentUser = (await axios.put(`${this.path}/update-password`, values)).data);
  }
}

export default new UserStore();