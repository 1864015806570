import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import './AppsNav.scss';
import { ToggleButton } from './AppsNavToggleButton';

/* @inject('applicationStore')
@observer */
class AppsNav extends Component {
  state = {
    selectedKey: '',
  };

  componentDidMount() {
    /* this.props.applicationStore
      .fetchAll()
      .then((x) => {
        let selectedKey = this.props.selectApp || '';
        this.setState({ selectedKey: selectedKey });
      })
      .catch((err) => {
        console.error(err);
      }); */
  }

  handleClick = (app) => {
    this.setState({ selectedKey: app.appName });
    if (app.absoluteUrl && app.absoluteUrl !== '') {
      window.location = app.absoluteUrl;
    } else {
      window.location.href = app.relativeUrl || '';
    }
  };

  render() {
    //let apps = toJS(this.props.applicationStore.apps);
    let { selectedKey } = this.state;

    return (
      <div className="imd-platform-commons-apps">
        <div
          className={
            'imd-platform-commons-apps-nav' +
            (this.props.showMobileMenu ? ' visible' : ' hidden')
          }
        >
          <div className="toggle-apps-nav-wrapper">
            <ToggleButton
              toggleMobileMenu={this.props.toggleMobileMenu}
              showMobileMenu={this.props.showMobileMenu}
            />
          </div>

          {/* {apps.map((x) => {
            if (x.isFolder || !x.showApplication) return;

            let icon = DOMPurify.sanitize(x.icon);
            let classname =
              x.appName === selectedKey
                ? 'imd-platform-commons-apps-nav-item selected'
                : 'imd-platform-commons-apps-nav-item';
            let element =
              x.contentType === 'image/svg+xml' ? (
                <div dangerouslySetInnerHTML={{ __html: atob(icon) }} />
              ) : (
                <img src={`data:${x.contentType};base64,${x.icon}`} alt={x.appName} />
              );

            return (
              <div key={x.id} className={classname} onClick={() => this.handleClick(x)}>
                <div>
                  {element}
                  <span>{x.displayName || x.appName}</span>
                </div>
              </div>
            );
          })} */}
        </div>
        <div className="imd-platform-commons-apps-container">{this.props.children}</div>
      </div>
    );
  }
}

export default withRouter(AppsNav);
