import { isProduction } from "../globalVar";
import BaseEntitiesStore from "./BaseEntitiesStore";

class ClusterHolderStore extends BaseEntitiesStore {
    constructor(){
        super('ClusterHolder', 'ClusterHolderStore');
        if(!isProduction)
        {
            window[`ClusterHolderStore`] = this;
        }
    }
}

export default new ClusterHolderStore();