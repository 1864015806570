import { observable } from 'mobx';
import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class BreadcrumbStore extends BaseEntitiesStore {
  constructor() {
    super('Breadcrumb', 'BreadcrumbStore');
    if (!isProduction) {
      window[`BreadcrumbStore`] = this;
    }
  }

  @observable
  breadcrumbs = [];

  setBreadcrumbs(route, options){ // NOSONAR
    this.breadcrumbs = [];

    let hasOptions;
    if(options){
      hasOptions = Object.keys(options).length > 0;
    }
    else{
      hasOptions = false;
    }

    switch(route)
    {
      case 'clusterplattform-oesterreich':
        this.breadcrumbs.push({
          path: '/clusterplattform-oesterreich',
          name: 'Newsbeiträge'
        }); 

        if(hasOptions)
        {
          this.breadcrumbs.push({
            path: `/clusterplattform-oesterreich/${options.id}`,
            name: options.name
          });
        }
        break;
      case 'calendar':
        this.breadcrumbs.push({
          path: '/calendar',
          name: 'Veranstaltungskalender'
        });

        if(hasOptions)
        {
          this.breadcrumbs.push({
            path: `/calendar/${options.id}`,
            name: options.name
          }); 
        }
        break;
      case 'clusterkarte':
        this.breadcrumbs.push({
          path: '/clusterkarte',
          name: 'Clusterkarte'
        });
        break;
      case 'clusterverwaltung':
        this.breadcrumbs.push({
          path: '/clusterverwaltung',
          name: 'Admin-Übersicht'
        });
        break;
      case 'clusterverwaltungForm':
        this.breadcrumbs.push({
          path: '/clusterverwaltung',
          name: 'Admin-Übersicht'
        });
  
        if(hasOptions)
        {
          this.breadcrumbs.push({
            path: `/clusterverwaltung/${options.id}`,
            name: options.name
          });
        }
        else
        {
          this.breadcrumbs.push({
            path: `/cluster-form`,
            name: 'Cluster erstellen'
          });
        }
        break;
      case 'cluster-drafts':
        this.breadcrumbs.push({
          path: '/cluster-drafts',
          name: 'Meine Übersicht',
        });

        if(hasOptions)
        {
          if(options.isFromCluster)
          {  
            this.breadcrumbs.push({
              path: `/clusterverwaltung/${options.id}`,
              name: options.name
            });
          }
        }
        break;
      case 'clusterarchive':
        this.breadcrumbs.push({
          path: '/cluster-archive',
          name: 'Cluster-Archiv'
        });
        
        if(hasOptions)
        {
          if(options.isFromCluster)
          {  
            this.breadcrumbs.push({
              path: `/clusterverwaltung/${options.id}`,
              name: options.name
            });
          }
        }
        break;
      case 'approve-overview':
        this.breadcrumbs.push({
          path: '/approve-overview',
          name: 'Cluster-Freigaben'
        });

        if(hasOptions)
        {
          if(options.isFromCluster)
          {  
            this.breadcrumbs.push({
              path: `/clusterverwaltung/${options.id}`,
              name: options.name
            });
          }
        }
        break;
      case 'branchenverwaltung':
        this.breadcrumbs.push({
          path: '/branchenverwaltung',
          name: 'Branchenverwaltung',
        });
        break;
      case 'benutzerverwaltung':
        this.breadcrumbs.push({
          path: '/benutzerverwaltung',
          name: 'Benutzerverwaltung',
        });
        break;
      case 'verwaltung':
        if(hasOptions)
        {  
          this.breadcrumbs.push({
            path: `/verwaltung/${options.page}`,
            name: `${options.pageName}`,
          });
        }
        break;
      case 'newsletterRelease':
        this.breadcrumbs.push({
          path: `/newsletter/release`,
          name: 'Freigaben',
        });
        break;
      case 'newsletterOverview':
        this.breadcrumbs.push({
          path: `/newsletter/overview`,
          name: 'Gesamtübersicht',
        });
        break;
      case 'newsletterDrafts':
        this.breadcrumbs.push({
          path: '/newsletter/drafts',
          name: 'Drafts',
        });
        break;
      case 'newsletterCategories':
        this.breadcrumbs.push({
          path: '/newsletter/categories',
          name: 'Kategorienverwaltung',
        });
        break;
      case 'newsletterPublic':
        this.breadcrumbs.push({
          path: '/newsletter/public',
          name: 'Öffentliche Beiträge',
        });
        break;
      case 'newsletterArchive':
        this.breadcrumbs.push({
          path: '/newsletter/archive',
          name: 'Archiv',
        });
        break;
      case 'newsletterPersonal':
        this.breadcrumbs.push({
          path: '/newsletter/personal',
          name: 'Meine Beiträge',
        });
        break;
      case 'traegerorganisationsverwaltung':
        this.breadcrumbs.push({
          path: '/traegerorganisationsverwaltung',
          name: 'Trägerorganisationsverwaltung',
        });
        break;
      case 'beitragsart':
        this.breadcrumbs.push({
          path: '/beitragsartverwaltung',
          name: 'Beitragsartverwaltung',
        });
        break;
      case 'klassenverwaltung':
        this.breadcrumbs.push({
          path: '/klassenverwaltung',
          name: 'Klassenverwaltung',
        });
        break;
      case 'emailTemplate':
        this.breadcrumbs.push({
          path: '/email-templates',
          name: 'Email-Vorlagen'
        })
        break;
      case '':  
        this.breadcrumbs.push({
          path: '/',
          name: 'Clusterplattform-Österreich'
        });
        break;
    }
  }

  addCustomToBreadcrumbs(name, path)
  {
    this.breadcrumbs.push({
      path: path,
      name: name
    })
  }
}

export default new BreadcrumbStore();
