import './Sidebar.scss';

import React, { Component } from 'react';

import { Layout, Menu } from 'antd';
import {
  QuestionCircleFilled,
  CopyFilled,
  LockFilled,
  BankFilled,
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { includes } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import { ExternalLinks } from '../Headbar/ExternalLinks';
import { mobileBreakpoint, ROLES } from '../../globalVar';

import authService from '../../utils/api-authorization/AuthorizeService';
import { ApplicationPaths } from 'utils/api-authorization/ApiAuthorizationConstants';
import { Link } from 'react-router-dom';

const { SubMenu } = Menu;
const { Sider } = Layout;

const newsletterRoles = [
  ROLES.user,
  ROLES.winnovation,
  ROLES.subjectDepartment,
  ROLES.publicRelation,
];
const draftRoles = [ROLES.user, ROLES.winnovation];
const newsletterProcessRoles = [
  ROLES.winnovation,
  ROLES.subjectDepartment,
  ROLES.publicRelation,
];
const approverRoles = [ROLES.admin, ROLES.winnovation];

@inject('uiStore', 'userStore')
@observer
export default class Sidebar extends Component {
  state = {
    selectedKeys: [],
    openKeys: ['home'],
    showPopUp: false,
    popUpCounter: 0,
  };

  componentDidMount = async () => {
    let path = window.location.pathname;
    this.props.uiStore.path = path;

    let subMenuKey;
    let parts = path.split('/');

    if (parts.length > 2) {
      if (parts[2] === '') {
        subMenuKey = 'home';
      } else {
        subMenuKey = parts[2];
      }
    } else {
      subMenuKey = path;
    }

    if (await authService.isAuthenticated()) {
      await this.props.userStore.getUserInformation();
      this.setState({
        selectedKeys: [path],
        openKeys: [subMenuKey],
      });
    } else {
      this.setState({ selectedKeys: [path], openKeys: [subMenuKey] });
    }
  };

  onMenuItemClick = ({ key }) => {
    if (window.innerWidth <= mobileBreakpoint) {
      this.props.toggleMobileMenu();
    }

    let subMenuKey = key.split('/').length > 1 ? key.split('/')[1] : key;
    this.setState({ selectedKeys: [key], openKeys: [subMenuKey] });
    this.props.uiStore.navigateTo(key);
  };

  subMenuClick = (subMenuKey) => {
    if (includes(this.state.openKeys, subMenuKey)) {
      this.setState({ openKeys: [] });
    } else {
      this.setState({ openKeys: [subMenuKey] });
    }
  };

  render() {
    let role = this.props.userStore.currentUser.role;

    let path =
      window.location.origin +
      document.getElementsByTagName('base')[0].getAttribute('href');
    path = path.substring(0, path.length - 1);

    return (
      <>
        <Sider
          data-testid="sider"
          width={250}
          className={this.props.show ? 'visible' : ''}
        >
          <div>
            <div className="user-area-mobile hide-mobile">
              <FontAwesomeIcon icon={['fas', 'user-circle']} />
              {this.props.isAuthenticated && <span>{this.props.userStore.fullname}</span>}
            </div>
            <Menu mode="inline" className="menu" onClick={this.onMenuItemClick}>
              <Menu.Item key="/clusterplattform-oesterreich">
                <FontAwesomeIcon icon={['fas', 'house']} />
                <span className="item-title">Clusterplattform Österreich</span>
              </Menu.Item>
              <Menu.Item key="/clusterkarte">
                <FontAwesomeIcon icon={['fas', 'map-marked-alt']} />
                <span className="item-title">Clusterkarte</span>
              </Menu.Item>
              {this.props.isAuthenticated && (
                <>
                  {(role === ROLES.admin ||
                    role === ROLES.user ||
                    role === ROLES.winnovation) && (
                    <SubMenu
                      key="clustermanagment"
                      icon={<FontAwesomeIcon icon={['fas', 'folder-open']} />}
                      title="Clusterverwaltung"
                      className="sidebar-root bold"
                    >
                      {role === ROLES.admin && (
                        <Menu.Item key="/clusterverwaltung">
                          <FontAwesomeIcon icon={['fas', 'chart-pie']} />
                          <span className="item-title-submenu">Admin-Übersicht</span>
                        </Menu.Item>
                      )}

                      {role !== ROLES.winnovation && (
                        <Menu.Item key="/cluster-drafts">
                          <FontAwesomeIcon icon={['fas', 'file-chart-pie']} />
                          <span className="item-title-submenu">Meine Übersicht</span>
                        </Menu.Item>
                      )}

                      {approverRoles.includes(role) && (
                        <Menu.Item key="/approve-overview">
                          <FontAwesomeIcon icon={['fas', 'badge-check']} />
                          <span className="item-title-submenu">Cluster-Freigaben</span>
                        </Menu.Item>
                      )}

                      {role === ROLES.admin && (
                        <Menu.Item key="/cluster-archive">
                          <FontAwesomeIcon
                            icon={['fas', 'inbox']}
                            className="header-icon"
                          />
                          <span className="item-title-submenu">Cluster-Archiv</span>
                        </Menu.Item>
                      )}
                    </SubMenu>
                  )}

                  {newsletterRoles.includes(role) && (
                    <SubMenu
                      key="articlemanagement"
                      icon={<FontAwesomeIcon icon={['fas', 'folder-open']} />}
                      title="Beitragsverwaltung"
                      className="sidebar-root bold"
                    >
                      {role === ROLES.winnovation && (
                        <Menu.Item
                          className="sidebar-submenu-item"
                          key="/newsletter/overview"
                        >
                          <FontAwesomeIcon icon={['fas', 'list-ul']} />
                          <span className="item-title-submenu">Gesamtübersicht</span>
                        </Menu.Item>
                      )}
                      {newsletterProcessRoles.includes(role) && (
                        <Menu.Item
                          className="sidebar-submenu-item"
                          key="/newsletter/release"
                        >
                          <FontAwesomeIcon icon={['fas', 'newspaper']} />
                          <span className="item-title-submenu">Freigaben</span>
                        </Menu.Item>
                      )}
                      {draftRoles.includes(role) && (
                        <>
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/newsletter/personal"
                          >
                            <FontAwesomeIcon icon={['fas', 'user-edit']} />
                            <span className="item-title-submenu">Meine Beiträge</span>
                          </Menu.Item>
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/newsletter/drafts"
                          >
                            <FontAwesomeIcon icon={['fas', 'feather-alt']} />
                            <span className="item-title-submenu">Drafts</span>
                          </Menu.Item>
                        </>
                      )}

                      {newsletterProcessRoles.includes(role) && (
                        <Menu.Item
                          className="sidebar-submenu-item"
                          key="/newsletter/public"
                        >
                          <FontAwesomeIcon icon={['fas', 'bullhorn']} />
                          <span className="item-title-submenu">Öffentliche Beiträge</span>
                        </Menu.Item>
                      )}

                      {role === ROLES.winnovation && (
                        <>
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/newsletter/archive"
                          >
                            <FontAwesomeIcon icon={['fas', 'archive']} />
                            <span className="item-title-submenu">Archiv</span>
                          </Menu.Item>
                        </>
                      )}
                    </SubMenu>
                  )}

                  {role === ROLES.admin && (
                    <>
                      <SubMenu
                        key={'administration'}
                        icon={<FontAwesomeIcon icon={['fas', 'user']} />}
                        title="Administration"
                        className="sidebar-root bold"
                      >
                        <SubMenu
                          key={'adminArticlemanagement'}
                          icon={<FontAwesomeIcon icon={['fas', 'folders']} />}
                          title="Beitragsverwaltung"
                          className="sidebar-submenu"
                        >
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/newsletter/categories"
                          >
                            <FontAwesomeIcon icon={['fas', 'cubes']} />
                            <span className="item-title-submenu">
                              Kategorienverwaltung
                            </span>
                          </Menu.Item>
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/beitragsartverwaltung"
                          >
                            <FontAwesomeIcon icon={['fas', 'cabinet-filing']} />
                            <span className="item-title-submenu">
                              Beitragsartverwaltung
                            </span>
                          </Menu.Item>
                        </SubMenu>

                        <SubMenu
                          key={'clusterdatamanagment'}
                          icon={<FontAwesomeIcon icon={['fas', 'database']} />}
                          title={'Clusterdatenverwaltung'}
                          className="sidebar-submenu"
                        >
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/branchenverwaltung"
                          >
                            <FontAwesomeIcon icon={['fas', 'industry']} />
                            <span className="item-title-submenu">Branchenverwaltung</span>
                          </Menu.Item>
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/klassenverwaltung"
                          >
                            <FontAwesomeIcon icon={['fas', 'boxes']} />
                            <span className="item-title-submenu">Klassenverwaltung</span>
                          </Menu.Item>
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/traegerorganisationsverwaltung"
                          >
                            <FontAwesomeIcon icon={['far', 'building']} />
                            <span className="item-title-submenu">
                              Trägerorganisationsverwaltung
                            </span>
                          </Menu.Item>
                        </SubMenu>

                        <SubMenu
                          key={'contentmanagement'}
                          title={'Inhaltverwaltung'}
                          className="sidebar-submenu"
                          icon={<FontAwesomeIcon icon={['fas', 'file-alt']} />}
                        >
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/verwaltung/datenschutz"
                          >
                            <LockFilled />
                            <span className="item-title-submenu">
                              Datenschutzverwaltung
                            </span>
                          </Menu.Item>
                          <Menu.Item
                            className="ant-menu-item sidebar-submenu-item"
                            key="/verwaltung/hilfe"
                          >
                            <QuestionCircleFilled />
                            <span className="item-title-submenu">Hilfeverwaltung</span>
                          </Menu.Item>
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/verwaltung/impressum"
                          >
                            <CopyFilled />
                            <span className="item-title-submenu">
                              Impressumverwaltung
                            </span>
                          </Menu.Item>
                          <Menu.Item
                            className="sidebar-submenu-item"
                            key="/verwaltung/barrierefreiheit"
                          >
                            <BankFilled />
                            <span className="item-title-submenu">
                              Barrierefreiheitverwaltung
                            </span>
                          </Menu.Item>
                        </SubMenu>
                        
                        <Menu.Item
                          key="/email-templates"
                          className="sidebar-submenu-item"
                        >
                          <FontAwesomeIcon icon={['fas', 'envelope']} />
                          <span className="item-title-submenu">Email-Vorlagen</span>
                        </Menu.Item>
                        <Menu.Item
                          key="/benutzerverwaltung"
                          className="sidebar-submenu-item"
                        >
                          <FontAwesomeIcon icon={['fas', 'users']} />
                          <span className="item-title-submenu">Benutzerverwaltung</span>
                        </Menu.Item>
                      </SubMenu>
                    </>
                  )}
                </>
              )}
            </Menu>
            {this.props.isAuthenticated ? (
              <Menu mode="inline" className="menu mobile">
                <Menu.Item key="/logout" className="has-submenu logout-link">
                  <Link
                    className="logout-link-link"
                    to={{
                      pathname: `${ApplicationPaths.LogOut}`,
                    }}
                  >
                    <FontAwesomeIcon icon={['fas', 'sign-out']} />
                    <span className="item-title">Abmelden</span>
                  </Link>
                </Menu.Item>
              </Menu>
            ) : (
              !this.props.isAuthenticated && (
                <Menu mode="inline" className="menu mobile">
                  <Menu.Item key="/logout" className="has-submenu logout-link">
                    <Link
                      to={{
                        pathname: `${ApplicationPaths.Login}`,
                      }}
                    >
                      <FontAwesomeIcon icon={['fas', 'sign-out']} />
                      <span className="item-title">Anmelden</span>
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            )}
          </div>

          <div className="sider-footer">
            <div className="hide-mobile">
              <ExternalLinks />
            </div>
          </div>
        </Sider>
      </>
    );
  }
}
