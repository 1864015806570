import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Collapse, Form, Row, Select, Tag, Tooltip, Input, Table } from 'antd';
import { clusterState, translationMap } from 'App/globalVar';
import dayjs from 'dayjs';
import { map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { dateTimeFormat } from 'utils/dayjsLocale';
import { accessibleTableTitle, capitalizeFirstLetter, getClusterStateTagColour, openNotificationWithIcon, reverseMap } from 'utils/functions';
import ClusterChangelogs from 'components/Modals/Changelogs/ClusterChangelogs';
import MessageModal from './MessageModal';

const formLayout12x24 = { xxl: 12, xl: 24, lg: 24, sm: 24, xs: 24 };

@inject(
  'breadcrumbStore',
  'clusterStore',
  'industryStore',
  'clusterHolderStore',
  'clusterClassStore',
  'uiStore',
  'emailTemplateStore'
)
@observer
class ClusterArchiveTable extends React.Component {
  formRef = React.createRef();
  state = {
    loading: true,
    reverseClusterStateMap: reverseMap(clusterState),
    activeCollapseKey: [],
    showChangelogModal: false,
    changelogClusterId: -1,
    showMessageModal: false,
    messageModalData: [],

    filter: {},
  };

  async componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumbs('clusterarchive');
    this.onCollapseChange(['0']);
    await Promise.all([
      this.props.industryStore.getAll(),
      this.props.clusterHolderStore.getAll(),
      this.props.clusterClassStore.getAll(),
      this.props.emailTemplateStore.getAll(),
    ]);

    await this.fetchTableData();
    this.setState({ loading: false });
  }

  fetchTableData = async (filter = undefined) => {
    await this.props.clusterStore.getArchivedClusters(filter);
    this.forceUpdate();
  };

  //for filter panel
  onCollapseChange = (e) => {
    this.setState({ activeCollapseKey: e });
  };

  onFilter = (values) => {
    let filter = '';
    if (values.name) {
      filter += `&name=${encodeURIComponent(values.name)}`;
    }
    if (values.clusterClass) {
      filter += `&clusterClass=${encodeURIComponent(values.clusterClass)}`;
    }
    if (values.clusterState) {
      filter += `&clusterState=${encodeURIComponent(toInteger(values.clusterState))}`;
    }

    this.setState({ filter: filter });
    this.fetchTableData(filter);
  };

  //reset filter values
  onResetFilters = async () => {
    this.formRef.current?.resetFields();
    this.setState({ filter: undefined });
    await this.fetchTableData();
  };

  
  openChangelogModal = async (clusterId) => {
    await this.props.clusterStore.getChangelogsForCluster(clusterId);
    this.setState({ showChangelogModal: true, changelogClusterId: clusterId });
  }

  closeChangelogModal = () => {
    this.setState({ showChangelogModal: false, changelogClusterId: -1 });
  }

  openMessageModal = (data) => {
    this.setState({ showMessageModal: true, messageModalData: data });
  }

  closeMessageModal = () => {
    this.props.clusterStore.entity = {};
    this.setState({showMessageModal: false});
  }

  deArchiveCluster = async (id) => {
    try{
      await this.props.clusterStore.archiveCluster(id, false);
      await this.fetchTableData();
      openNotificationWithIcon('success', 'Archivierung wurde aufgehoben!');
    }
    catch {
      openNotificationWithIcon('error', 'Ein Problem ist aufgetreten.');
    }
  }

  columns = [
    {
      title: accessibleTableTitle('Clustername'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: accessibleTableTitle('Klasse'),
      dataIndex: 'clusterClassId',
      key: 'clusterClassId',
      sorter: (a, b) => {
        return a.name.localeCompare(b.name);
      },
      render: (clusterClassId) => {
        return this.props.clusterClassStore.entities.find((x) => x.id == clusterClassId)
          ?.name;
      },
    },
    {
      title: accessibleTableTitle('Status'),
      key: 'clusterState',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (cluster) => {
        let clusterState = cluster.clusterState;
        return (
          <div className="table-tag" key={`${cluster.id}_${clusterState}`}>
            <Tag color={getClusterStateTagColour(clusterState)}>
              {capitalizeFirstLetter(
                translationMap[this.state.reverseClusterStateMap[clusterState]]
              )}
            </Tag>
          </div>
        );
      },
    },
    {
      title: accessibleTableTitle('Letzte Statusänderung'),
      key: 'clusterStateLastChanged',
      dataIndex: 'clusterStateLastChanged',
      sorter: (a, b) =>
        a.clusterStateLastChanged?.localeCompare(b.clusterStateLastChanged),
      render: (utcTime) => {
        return !!utcTime ? dayjs(utcTime).format(dateTimeFormat) : '';
      },
    },
    {
      title: 'Item ansehen',
      key: 'seeProfile',
      render: (data) => {
        return (
          <Button
            onClick={() =>{
                this.props.uiStore.setReturnLink('clusterForm','/cluster-archive');
                this.props.uiStore.navigateTo(
                `/clusterverwaltung/${data.id}`
              )
            }
            }
          >
            Item ansehen
          </Button>
        );
      },
    },
    {
      title: 'Nachricht senden',
      key: 'sendMessage',
      render: (data) => {
        return (
          <Button onClick={() => this.openMessageModal(data)}>Nachricht senden</Button>
        );
      },
    },
    {
      title: 'Log-File sehen',
      key: 'seeLogFile',
      render: (data) => {
        return (
          <Button onClick={async () => await this.openChangelogModal(data.id)}>
            Log-File sehen
          </Button>
        );
      },
    },
    {
      title: 'Aktionsbuttons',
      align: 'center',
      render: (_field) => (
        <div className="table-actions">
          <Tooltip title="Archivierung aufheben">
            <Button
              className="table-action-btn teal"
              aria-label="Archivierung aufheben"
              onClick={() => this.deArchiveCluster(_field.id)}
            >
              <FontAwesomeIcon icon={['fas', 'inbox-out']} />
            </Button>
          </Tooltip>
          <Tooltip title={'Löschen'}>
            <Button
              className="table-action-btn red"
              aria-label="Löschen"
              onClick={async () => {
                if (
                  confirm(
                    `Wollen Sie den Cluster: "${_field.name}" wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.`
                  )
                ) {
                  try {
                    await this.props.clusterStore.delete(_field.id);
                    openNotificationWithIcon(
                      'success',
                      'Cluster wurde erfolgreich gelöscht!'
                    );
                  } catch {
                    openNotificationWithIcon(
                      'error',
                      'Es ist ein unerwarteter Fehler beim Löschen aufgetreten'
                    );
                  }
                }
              }}
            >
              <FontAwesomeIcon icon={['fas', 'trash-alt']} />
            </Button>
          </Tooltip>
        </div>
      ),
    },
  ];

  render() {
    const data = this.props.clusterStore.entities.slice();
    return (
      <div className="home">
        <div className="header">
          <h1 className="title">
            Cluster-Archiv
            <FontAwesomeIcon icon={['fas', 'inbox']} className="header-icon" />
          </h1>
        </div>
        <Collapse
          defaultActiveKey={['']}
          ghost
          className="filter-panel"
          activeKey={this.state.activeCollapseKey}
          onChange={this.onCollapseChange}
        >
          <Collapse.Panel header={<div>Filter</div>} key="1">
            <div className="flex">
              <div className="filter">
                <Form
                  ref={this.formRef}
                  scrollToFirstError={true}
                  name="basic"
                  onFinish={this.onFilter}
                  layout="vertical"
                  labelAlign="left"
                >
                  <Row gutter={80}>
                    <Col {...formLayout12x24}>
                      <Form.Item label="Clustername" name="name">
                        <Input placeholder="Filter nach Clustername..." allowClear />
                      </Form.Item>
                    </Col>
                    <Col {...formLayout12x24}>
                      <Row gutter={20}>
                        <Col {...formLayout12x24}>
                          <Form.Item label="Klasse" name="clusterClass">
                            <Select placeholder="Filter nach Klassen..." allowClear>
                              {map(this.props.clusterClassStore.entities.slice(), (x) => (
                                <Select.Option key={x.id} value={x.id}>
                                  {x.name}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col {...formLayout12x24}>
                          <Form.Item label="Status" name="clusterState">
                            <Select placeholder="Filter nach Status..." allowClear>
                              {map(this.state.reverseClusterStateMap, (x, id) => (
                                <Select.Option key={id} value={id}>
                                  {capitalizeFirstLetter(
                                    translationMap[this.state.reverseClusterStateMap[id]]
                                  )}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="filter-btns">
                <Button onClick={this.onResetFilters}>
                  Filter zurücksetzen <FontAwesomeIcon icon={['fas', 'times']} />
                </Button>
                <Button
                  className="margin-right-16"
                  onClick={() => this.formRef.current?.submit()}
                >
                  Filter anwenden
                  <FontAwesomeIcon icon={['far', 'filter']} />
                </Button>
              </div>
            </div>
          </Collapse.Panel>
        </Collapse>

        <div className="home-content">
          <div className="table-contianer">
            <Table
              className="ant-table-scroll"
              loading={this.state.loading}
              dataSource={data}
              columns={this.columns}
              rowKey={'id'}
              pagination={false}
              scroll={{ x: true }}
            />
          </div>
        </div>

        {this.state.showChangelogModal && (
          <ClusterChangelogs
            visible={this.state.showChangelogModal}
            onCancel={this.closeChangelogModal}
            data={this.props.clusterStore.changelogs}
            industries={this.props.industryStore.entities.slice()}
            holders={this.props.clusterHolderStore.entities.slice()}
            clusterId={this.state.changelogClusterId}
          />
        )}
        {this.state.showMessageModal && (
          <MessageModal
            visible={this.state.showMessageModal}
            onCancel={this.closeMessageModal}
            data={this.state.messageModalData}
            clusterStore={this.props.clusterStore}
            emailTemplates={this.props.emailTemplateStore.entities.slice()}
          />
        )}

      </div>
    );
  }
}

export default ClusterArchiveTable;
