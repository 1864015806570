import { Card, Skeleton, Row } from 'antd';
import { newsletterType } from 'App/globalVar';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { openNotificationWithIcon } from 'utils/functions';
import Gallery from './Gallery';
import NewsletterContent from './Newsletter/NewsletterContent';
import NewsletterFooter from './Newsletter/NewsletterFooter';
import NewsletterImage from './Newsletter/NewsletterImage';
import SourceList from './SourceList';
import StackNavigator from './StackNavigator';

@observer
@inject('newsletterStore', 'uiStore', 'breadcrumbStore')
class LazyNewsletterEntry extends React.Component {
  state = {
    entityId: null,
    fromDidMount: false
  };

  async componentDidMount() {
    this.setState({ entityId: this.props.newsletterStore.entityId, fromDidMount: true });
  }

  async shouldComponentUpdate(nextProps, nextState) {
    let entityId = this.props.newsletterStore.entityId;
    let entityType = this.props.newsletterStore.entityType;

    if(nextState.fromDidMount || (entityId != nextState.entityId && entityType == newsletterType.newsletter)){
      this.setState({entityId: entityId, fromDidMount: false});
      await this.loadEntityLazily(entityId);
    }
  }

  loadEntityLazily = async (entityId) => {
    // make 3 calls
    // set parts of the content in newsletterStore

    let urlType = newsletterType.newsletter;
    let isProcessUrl = this.props.isProcessUrl;
    let store = this.props.newsletterStore;

    try {
      await store.getByIdLazy(entityId, urlType, isProcessUrl);
      if(this.props.onMetaLoaded){
        await this.props.onMetaLoaded();
      }
      store
        .getAndSetContent(entityId, urlType, isProcessUrl)
        .then(() => this.forceUpdate());
      store
        .getAndSetThumbnail(entityId, urlType, isProcessUrl)
        .then(() => this.forceUpdate());
      store
        .getAndSetGallery(entityId, urlType, isProcessUrl)
        .then(() => this.forceUpdate());
      
      if(window.location.pathname.includes('newsletter'))
      {
        this.props.breadcrumbStore.addCustomToBreadcrumbs(store.entity.title, window.location.pathname + window.location.search);
      }
    } catch (err) {
      if (Object.keys(store.entity).length == 0) {
        this.giveErrorAndRedirect();
      }
    }
  }

  componentWillUnmount() {
    this.props.newsletterStore.setEntity({});
  }

  giveErrorAndRedirect = () => {
    openNotificationWithIcon(
      'error',
      'Ein Fehler beim Laden des Beitrags ist aufgetreten.'
    );
    this.props.uiStore.navigateTo(this.props.returnUrl);
  };

  render() {
    let newsletter = this.props.newsletterStore.entity;

    if(!newsletter || Object.keys(newsletter).length <= 0)
      return <Skeleton active />;

    return (
      <>
        <div className="card">
          <Card
            hoverable={this.props.hoverable}
            onClick={this.props.onClick}
            tabIndex={0}
            role="article"
            className="antd-card"
          >
            <div
              className={
                this.props.isCard ? 'card-content newscard' : 'card-content newsletter'
              }
            >
              <div className="card-body">
                {!!newsletter?.content ? (
                  <>
                    <NewsletterContent
                      newsletter={newsletter}
                      showTeaser={this.props.showTeaser}
                      justTeaser={this.props.justTeaser}
                    />
                    {this.props.addStyles && (
                      <SourceList entity={newsletter} addStyles={this.props.addStyles} />
                    )}
                  </>
                ) : (
                  <Skeleton loading={true} />
                )}
                {this.props.displayGallery ? (
                  <>
                    {!!newsletter?.galleryImages ? (
                      <Gallery entity={newsletter} />
                    ) : (
                      <Skeleton loading={true} />
                    )}
                  </>
                ) : null}
                  <NewsletterFooter
                    newsletter={newsletter}
                    addIdToLink={this.props.addIdToLink}
                    isCard={this.props.isCard}
                  />
              </div>
              {!!newsletter?.thumbnail ? (
                <NewsletterImage
                  displayThumbnailTitle={this.props.displayThumbnailTitle}
                  newsletter={newsletter}
                />
              ) : (
                <div className="lazy-loading-image-container">
                  <Skeleton className="lazy-loading-image" loading={true} />
                </div>
              )}
            </div>
          </Card>
          {(newsletter?.previousNewsletterId ||
            this.props.uiStore.newsletterNavigationStack.length > 0) &&
            this.props.showNavigator && (
              <StackNavigator entity={newsletter} type={newsletter?.type} />
            )}
        </div>
      </>
    );
  }
}

export default LazyNewsletterEntry;
