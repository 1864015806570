import React, { Component, createRef } from 'react';
import { observer, inject } from 'mobx-react';
import { forEach, map } from 'lodash-es';

// importing styles and helpers
import '../Clusterverwaltung/Cluster.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExclamationCircleOutlined } from '@ant-design/icons';

// importing components
import { Button, Table, Tooltip, Tag } from 'antd';
import { accessibleFilterIcon, accessibleTableTitle, openNotificationWithIcon } from '../../utils/functions';
import {
  translationMap
} from 'App/globalVar';
import ChangeClusterUserModal from './ChangeClusterUserModal';
import { toJS } from 'mobx';

@inject('userStore', 'clusterStore')
@observer
export default class UserTable extends Component {
  formRef = createRef();

  state = {
    roleFilters: [],
    activatedFilters: [],
    clusterFilters: [],

    //for changing clustermanager before deleting user
    showChangeClusterManagerModal: false,
    showChangeClusterManagerModalAry: [],
    modalCurrentField: {},

    clustersForUser: [],

    usersListWithoutClusterManager: [],

    isNewItem: false,

    activatedAccountsFilterApplied: false,
    roleFilterApplied: false
  };

  componentDidMount = async () => {
    this.setState({
      roleFilters: this.buildRoleFilters(),
      activatedFilters: this.buildActivatedFilters(),
      clusterFilters: this.buildClusterFilters(),
    });
    await this.props.userStore.getUsersListAdmin();
  };

  buildRoleFilters = () => {
    return this.props.store.roles.map((x) => {
      return {
        text: translationMap[x.normalizedName],
        value: x.id
      };
    });
  };

  buildActivatedFilters = () => {
    return [
      {
        text: 'Nur aktivierte Benutzer',
        value: true
      },
      {
        text: 'Nur nicht aktivierte Benutzer',
        value: false
      }
    ];
  };

  buildClusterFilters = () => {
    return map(this.props.clusters, (x) => {
      return {
        text: x.name,
        value: x.id,
      };
    });
  };

  renderClusterTag(obj, index) {
    let titleRed = 'Benutzer ist Manager';
    let titleBlue = 'Benutzer ist Hauptkontakt';

    return (
      <Tooltip title={obj.color == 'red' ? titleRed : titleBlue}>
        <Tag key={`${index}`} color={obj.color}>
          {obj.name}
        </Tag>
      </Tooltip>
    );
  }

  getClustersIncludingDeletingUser = async (user) => {
    let ids = [];
    forEach(user.clustersManagedByUser, (x) => ids.push(x.id));
    forEach(user.clustersUserIsMainContactFor, (x) => ids.push(x.id));

    let uniqueIds = [];
    forEach(ids, (x) => {
      if (!uniqueIds.includes(x)) {
        uniqueIds.push(x);
      }
    });

    for (let i = 0; i < uniqueIds.length; i++) {
      await this.getClusterById(uniqueIds[i]);
    }
  };

  getClusterById = async (id) => {
    let clustersForUserHelper = this.state.clustersForUser;
    let changeModal = this.state.showChangeClusterManagerModalAry;
    await this.props.clusterStore.getClusterForAdmin(id);
    clustersForUserHelper.push(this.props.clusterStore.entity);
    changeModal.push(id);
    this.setState({
      clustersForUser: clustersForUserHelper,
      showChangeClusterManagerModalAry: changeModal,
    });
  };

  onModalClose = async () => {
    let clustersForUserHelper = this.state.clustersForUser;
    clustersForUserHelper.shift();
    this.setState({ showChangeClusterManagerModal: false, clustersForUser: clustersForUserHelper });
    this.props.getFilteredTableData();
    if (this.state.clustersForUser.length > 0) {
      this.setState({ isNewItem: true, showChangeClusterManagerModal: true });
    } else {
      if (
        confirm(
          `Wollen Sie den Benutzer: "${this.state.modalCurrentField.fullname}" wirklich löschen?`
        )
      ) {
        try {
          await this.props.store.delete(this.state.modalCurrentField.id);
          openNotificationWithIcon(
            'success',
            'Benutzer wurde erfolgreich gelöscht!'
          );
          this.props.getFilteredTableData();
        } catch {
          openNotificationWithIcon(
            'error',
            'Benutzer wird verwendet, löschen nicht möglich!'
          );
        }
      }
    }
  }

  updateActivatedAccountFilterIcon = (value) => {
    this.setState({activatedAccountsFilterApplied: value});
  }

  updateRoleFilterIcon = (value) => {
    this.setState({roleFilterApplied: value});
  }

  render() {
    const columns = [
      {
        title: '',
        dataIndex: 'isActivatedAccount',
        key: 'isActivatedAccount',
        width: '50px',
        filters: this.state.activatedFilters,
        ...accessibleFilterIcon(
          'activated-account-filter',
          this.state.activatedAccountsFilterApplied,
          this.updateActivatedAccountFilterIcon,
          'Aktivierte Accounts Filter'
        ),
        render: (isActivated) => {
          if (!isActivated) {
            return (
              <Tooltip
                title="Dieser Benutzer hat seinen Account noch nicht aktiviert."
                key="exclamationMark"
              >
                <ExclamationCircleOutlined style={{ color: 'red' }} />
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        title: 'Benutzer',
        dataIndex: 'fullname',
        key: 'fullname',
      },
      {
        title: accessibleTableTitle('Email'),
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        responsive: ['sm'],
      },
      {
        title: 'Rolle',
        dataIndex: 'role',
        key: 'role',
        filters: this.state.roleFilters,
        ...accessibleFilterIcon(
          'roles-filter',
          this.state.roleFilterApplied,
          this.updateRoleFilterIcon,
          'Rollen Filter'
        ),
        responsive: ['sm'],
        render: (rolename) => {
          return translationMap[rolename];
        }
      },
      {
        title: 'Clusters',
        key: 'clusters',
        responsive: ['sm'],
        filters: this.state.clusterFilters,
        render: (user) => {
          let clustersToDisplay = [
            ...map(user.clustersManagedByUser, (x) => {
              return {
                ...x,
                color: 'red',
              };
            }),
            ...map(user.clustersUserIsMainContactFor, (x) => {
              return {
                ...x,
                color: 'geekblue',
              };
            }),
          ];
          return (
            <span>
              {map(clustersToDisplay, (x, index) => this.renderClusterTag(x, index))}
            </span>
          );
        },
      },
      {
        title: 'Aktionsbuttons',
        align: 'center',
        render: (_field) => (
          <div className="table-actions">
            <Tooltip title="Passwort zurücksetzen">
              <Button
                className="table-action-btn teal"
                aria-label="Passwort zurücksetzen"
                onClick={async () => {
                  if (
                    confirm(
                      'Sind sie sicher, dass Sie das Passwort dieses Benutzers zurücksetzen wollen?'
                    )
                  ) {
                    await this.props.store.setUserPasswordReset(_field.id);
                    openNotificationWithIcon(
                      'success',
                      'Das Passwort des Benutzers wurde zurückgesetzt.'
                    );
                  }
                }}
              >
                <FontAwesomeIcon icon={['far', 'key']} />
              </Button>
            </Tooltip>
            <Tooltip title="Benutzer bearbeiten">
              <Button
                className="table-action-btn yellow"
                aria-label="Benutzer bearbeiten"
                onClick={async () => {
                  await this.props.store.get(_field.id);
                  this.props.setUserModalVisible(true, true);
                }}
              >
                <FontAwesomeIcon icon={['far', 'pencil']} />
              </Button>
            </Tooltip>

            <Tooltip title={'Benutzer löschen'}>
              <Button
                className="table-action-btn red"
                aria-label="Benutzer löschen"
                onClick={async () => {
                  if (
                    _field.clustersManagedByUser.length > 0 ||
                    _field.clustersUserIsMainContactFor.length > 0
                  ) {
                    await this.getClustersIncludingDeletingUser(_field);
                    this.setState({
                      showChangeClusterManagerModal: true,
                      modalCurrentField: _field,
                    });
                  } else {
                    if (
                      confirm(
                        `Wollen Sie den Benutzer: "${_field.fullname}" wirklich löschen?`
                      )
                    ) {
                      try {
                        await this.props.store.delete(_field.id);
                        openNotificationWithIcon(
                          'success',
                          'Benutzer wurde erfolgreich gelöscht!'
                        );
                        this.props.getFilteredTableData();
                      } catch {
                        openNotificationWithIcon(
                          'error',
                          'Benutzer wird verwendet, löschen nicht möglich!'
                        );
                      }
                    }
                  }
                }}
              >
                <FontAwesomeIcon icon={['fas', 'trash-alt']} />
              </Button>
            </Tooltip>
          </div>
        ),
      },
    ];

    return (
      <>
        <Table
          dataSource={this.props.store.entities}
          columns={columns}
          rowKey="id"
          pagination={false}
          scroll={{ x: true }}
          onChange={(p, f, s) => {
            this.props.store.activeFilters = f;
            this.props.store.activeSorter = s;
            this.props.getFilteredTableData();
          }}
        />

        {this.state.clustersForUser[0] != undefined && (
          <ChangeClusterUserModal
            visible={this.state.showChangeClusterManagerModal}
            closeModal={() =>
              this.setState({ showChangeClusterManagerModal: false, clustersForUser: [], modalCurrentField: {} })
            }
            isNewItem={this.state.isNewItem}
            setIsNewItemFalse={() => this.setState({ isNewItem: false })}
            entity={this.state.clustersForUser[0]}
            users={this.props.store.entities}
            usersList={this.props.userStore.usersList.slice()}
            onOkay={() => this.onModalClose()}
            userToDelete={this.state.modalCurrentField}
          />
        )}

      </>
    );
  }
}
