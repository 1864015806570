import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Table,
  Tooltip,
  Select,
  Collapse,
  Form,
  Row,
  Col,
  Input,
  Modal,
  Card,
  Alert
} from 'antd';
import dayjs from 'dayjs';
import { find, map } from 'lodash-es';
import { inject, observer } from 'mobx-react';
import React, { Component, createRef } from 'react';
import { dateTimeFormat } from 'utils/dayjsLocale';
import { accessibleTableTitle, openNotificationWithIcon } from 'utils/functions';
import '../Newsletter/Newsletter.scss';

const formLayout12x24 = { xxl: 12, xl: 24, lg: 24, sm: 24, xs: 24 };
@inject(
  'clusterDraftStore',
  'clusterClassStore',
  'clusterStore',
  'clusterDataCacheStore',
  'uiStore',
  'userStore',
  'breadcrumbStore'
)
class DraftsTable extends React.Component {
  formRef = createRef();

  state = {
    loading: true,
    activeCollapseKey: [],
    filter: undefined,
    
    showAlertInactiveUser: true,
    alertDescription: [],
    currentCluster: {},

    clusterId: 0,
    showCacheModal: false,
    disableCacheButton: true,
  };

  async componentDidMount() {
    this.props.breadcrumbStore.setBreadcrumbs('cluster-drafts');
    this.props.uiStore.setReturnLink('clusterForm', '/cluster-drafts');
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    await this.props.clusterClassStore.getAll();
    this.fetchTableData();
    this.setState({ loading: false });
  }

  createClusterClasses = () => {
    return (
      <Select placeholder="Filter nach Klassen..." allowClear>
        {map(this.props.clusterClassStore.entities.slice(), (x) => (
          <Select.Option key={x.id} value={x.id}>
            {x.name}
          </Select.Option>
        ))}
      </Select>
    );
  };

  fetchTableData = async (filter = undefined) => {
    if (!this.state.loading) {
      this.setState({ loading: true });
    }
    await this.props.clusterDraftStore.getAllFiltered(filter);
    this.setState({ loading: false });
  };

  navigateToClusterForm = (id, type, statusDraft) => {
    this.props.uiStore.setReturnLink('clusterForm', '/cluster-drafts');
    this.props.uiStore.navigateTo(`/clusterverwaltung/${id}?clusterType=${type}&statusDraft=${statusDraft}`);
  };

  onCollapseChange = (e) => {
    this.setState({ activeCollapseKey: e });
  };

  onResetFilters = () => {
    this.formRef.current?.resetFields();
    this.setState({ filter: undefined });
    this.fetchTableData();
  };

  onFilter = (values) => {
    let filter = '';
    if (values.name) {
      filter += `&name=${encodeURIComponent(values.name)}`;
    }

    if (values.clusterClassId) {
      filter += `&clusterClassId=${encodeURIComponent(values.clusterClassId)}`;
    }

    this.setState({ filter: filter });
    this.fetchTableData(filter);
  };

  onDelete = async (field) => {
    try {
      if (field.clusterType == 'draft') {
        await this.props.clusterDraftStore.delete(field.id);
      } else {
        await this.props.clusterStore.delete(field.id);
      }
      this.fetchTableData();
      openNotificationWithIcon('success', 'Cluster wurde erfolgreich gelöscht!');
    } catch (error) {
      openNotificationWithIcon(
        'error',
        'Es ist ein unerwarteter Fehler beim Löschen aufgetreten!"'
      );
    }
  };

  checkClusterManagersValid = async () => {
    let cluster = this.props.clusterStore.currentNewClusterDraftsTable;
    let mainContact = cluster.mainContact;
    let contactPersons = cluster.contactPersons;
    let joinedContacts = contactPersons;
    joinedContacts.push(mainContact);

    let inactiveUsers = await this.props.userStore.getUsersWithInactivAccount();
    let invalidUsers = [];
    for (let i = 0; i < inactiveUsers.length; i++) {
      for (let j = 0; j < joinedContacts.length; j++) {
        if (inactiveUsers[i].id === joinedContacts[j].id) {
          invalidUsers.push(joinedContacts[j]);
        }
      }
    }
    invalidUsers = invalidUsers?.filter((value, index, array) => array.indexOf(value) === index);
    let res = [];
    map(invalidUsers, x => res.push(<>{x.email} <br /></>));
    this.setState({ alertDescription: res, currentCluster: cluster })
  }

  render() {
    let clusterDrafts = this.props.clusterDraftStore.entities;
    let clusterClass = this.createClusterClasses();
    let clusterClassesEntities = this.props.clusterClassStore.entities;

    if (this.props.clusterStore.currentNewClusterDraftsTable != undefined && this.state.currentCluster !== this.props.clusterStore.currentNewClusterDraftsTable) {
      this.checkClusterManagersValid();
    }

    let columns = [
      {
        title: accessibleTableTitle('Clustername'),
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: accessibleTableTitle('Klasse'),
        dataIndex: 'clusterClassId',
        key: 'clusterClassId',
        sorter: (a, b) => {
          a = find(clusterClassesEntities, (x) => x.id === a.clusterClassId)?.name || '';
          b = find(clusterClassesEntities, (x) => x.id === b.clusterClassId)?.name || '';
          return a.localeCompare(b);
        },
        render: (clusterClassId) => {
          return find(clusterClassesEntities, (x) => x.id === clusterClassId)?.name;
        },
      },
      {
        title: 'Letzte Aktualisierung',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: (a, b) => {
          if (!a.updatedAt && b.updatedAt) return -1;
          if (!b.updatedAt && a.updatedAt) return 1;
          if (a.updatedAt && b.updatedAt)
            return dayjs(a.updatedAt).diff(dayjs(b.updatedAt));
        },
        render: (updatedAt) => {
          return updatedAt ? dayjs(updatedAt).format(dateTimeFormat) : '';
        },
      },
      {
        title: 'Profil aktualisieren / vervollständigen',
        key: 'updateProfileAction',
        width: 280,
        align: 'center',
        render: (_field) => {
          return (
            <div className="table-actions">
              {_field.clusterType === 'cluster' && !_field.isClusterWithDraftStatus ? (
                <Button
                  onClick={async () => {
                    if (
                      (await this.props.clusterDataCacheStore.getCacheByClusterId(
                        _field.id
                      )) !== ''
                    ) {
                      this.setState({
                        disableCacheButton: false,
                        clusterId: _field.id,
                        showCacheModal: true,
                      });
                    } else {
                      this.props.uiStore.navigateTo(
                        `/cluster-aktualisierung/${_field.id}?isCache=false&returnUrl=${window.location.href}`
                      );
                    }
                  }}
                >
                  Profil aktualisieren
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    this.navigateToClusterForm(
                      _field.id,
                      _field.clusterType,
                      _field.isClusterWithDraftStatus
                    );
                  }}
                >
                  Profil vervollständigen
                </Button>
              )}
            </div>
          );
        },
      },
      {
        title: 'Profil ansehen',
        key: 'updateProfileAction',
        align: 'center',
        width: 220,
        render: (_field) => (
          <div className="table-actions">
            {_field.clusterType === 'cluster' && (
              <Button
                onClick={() => {
                  this.navigateToClusterForm(_field.id, _field.clusterType, false);
                }}
              >
                Profil ansehen
              </Button>
            )}
          </div>
        ),
      },
      {
        title: 'Aktionsbuttons',
        key: 'actions2',
        align: 'center',
        width: 150,
        render: (_field) => {
          const currentUserId = this.props.userStore.currentUser.id;
          return (
            <div className="table-actions">
              {currentUserId === _field.createdById ||
              _field.clusterType === 'draft' ||
              (_field.clusterManagerId && currentUserId === _field.clusterManagerId) ? (
                <Tooltip title="Löschen">
                  <Button
                    className="table-action-btn red"
                    aria-label="Löschen"
                    onClick={() => {
                      if (
                        confirm(
                          `Wollen Sie den Cluster "${_field.name}" wirklich löschen?`
                        )
                      ) {
                        this.onDelete(_field);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={['fas', 'trash-alt']} />
                  </Button>
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
    ];
    return (
      <>
        <div className="home">
          <div className="header">
            <h1 className="title">
              Meine Übersicht <FontAwesomeIcon icon={['fas', 'file-chart-pie']} />
            </h1>
            <Button
              type="primary"
              className="btn-with-icon yellow"
              onClick={async () => {
                this.props.uiStore.navigateTo(`/cluster-form`);
              }}
            >
              Cluster anlegen{' '}
              <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon" />
            </Button>
          </div>
          <Alert
            style={{ marginBottom: '20px' }}
            message="Der Cluster kann erst in Freigabe gegeben werden, sobald alle angegebenen E-Mail-Adressen bestätigt wurden."
            description= {this.state.alertDescription}
            type="error"
            closable
          />
          <Collapse
            defaultActiveKey={['']}
            ghost
            className="filter-panel"
            activeKey={this.state.activeCollapseKey}
            onChange={this.onCollapseChange}
          >
            <Collapse.Panel header={<div>Filter</div>} key="1">
              <div className="flex">
                <div className="filter">
                  <Form
                    ref={this.formRef}
                    scrollToFirstError={true}
                    name="basic"
                    onFinish={this.onFilter}
                    layout="vertical"
                    labelAlign="left"
                  >
                    <Row gutter={80}>
                      <Col {...formLayout12x24}>
                        <Form.Item label="Clustername" name="name">
                          <Input placeholder="Filter nach Clustername..." allowClear />
                        </Form.Item>
                      </Col>
                      <Col {...formLayout12x24}>
                        <Row gutter={20}>
                          <Col {...formLayout12x24}>
                            <Form.Item label="Klasse" name="clusterClassId">
                              {clusterClass}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
                <div className="filter-btns">
                  <Button onClick={this.onResetFilters}>
                    Filter zurücksetzen <FontAwesomeIcon icon={['fas', 'times']} />
                  </Button>
                  <Button
                    className="margin-right-16"
                    onClick={() => this.formRef.current?.submit()}
                  >
                    Filter anwenden
                    <FontAwesomeIcon icon={['far', 'filter']} />
                  </Button>
                </div>
              </div>
            </Collapse.Panel>
          </Collapse>

          <div className="home-content">
            <div className="table-container">
              <Table
                key="draftTable"
                rowKey="id"
                className="ant-table-scroll"
                pagination={false}
                scroll={{ x: true }}
                dataSource={clusterDrafts}
                columns={columns}
                loading={this.state.loading}
              ></Table>
            </div>
          </div>
        </div>
        <Modal
          className="selectTypeModal"
          visible={this.state.showCacheModal}
          onCancel={() => {
            this.setState({ showCacheModal: false });
          }}
          title={'Aktion wählen'}
          footer={
            <div className="footer-btns">
              <div className="close-footer-btns">
                <Button
                  className="btn-with-icon blue-medium"
                  type="secondary"
                  key="onCancelButton"
                  onClick={() => {
                    this.setState({ showCacheModal: false });
                  }}
                >
                  Abbrechen
                  <FontAwesomeIcon icon={['fas', 'times']} />
                </Button>
              </div>
            </div>
          }
        >
          <div className="step-content">
            <Row gutter={30} role="list">
              <Col {...formLayout12x24} className="select-card" role="listitem">
                <Card
                  className="type-card"
                  hoverable
                  onClick={() => {
                    this.props.uiStore.navigateTo(
                      `/cluster-aktualisierung/${this.state.clusterId}?isCache=false&returnUrl=${window.location.href}`
                    );
                  }}
                  onKeyDown={() => {
                    this.props.uiStore.navigateTo(
                      `/cluster-aktualisierung/${this.state.clusterId}?isCache=false&returnUrl=${window.location.href}`
                    );
                  }}
                  tabIndex="0"
                  title="Aktueller Cluster"
                >
                  <p className="card-content title">
                    Wählen Sie diese Version aus und bearbeiten Sie den aktuellen Cluster.
                  </p>
                </Card>
              </Col>
              <Col {...formLayout12x24} className="select-card" role="listitem">
                {!this.state.disableCacheButton && (
                  <Card
                    className="type-card"
                    hoverable
                    onClick={() => {
                      this.props.uiStore.navigateTo(
                        `/cluster-aktualisierung/${this.state.clusterId}?isCache=true&returnUrl=${window.location.href}`
                      );
                    }}
                    onKeyDown={() => {
                      this.props.uiStore.navigateTo(
                        `/cluster-aktualisierung/${this.state.clusterId}?isCache=true&returnUrl=${window.location.href}`
                      );
                    }}
                    tabIndex="0"
                    title="Zwischengespeicherter Cluster"
                  >
                    <p className="card-content title">
                      Wählen Sie diese Version aus und bearbeiten Sie den
                      zwischengespeicherten Cluster.
                    </p>
                  </Card>
                )}
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

export default DraftsTable;
