import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { map, forEach } from 'lodash-es';
import './News.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col,
  Row,
  Form,
  Select,
  Button,
  Input,
  InputNumber,
  Pagination,
  Empty,
  Spin,
  Tooltip,
  DatePicker,
  Modal,
} from 'antd';
import dayjs from 'dayjs';
import NewsletterCard from './NewsletterCard';
import {
  getFileName,
  openNotificationWithIcon,
  navigateToHomeWithError,
  beforeDateRule,
  afterDateRule,
} from 'utils/functions';
import { saveAs } from 'file-saver';
import { toJS } from 'mobx';
import { ROLES } from 'App/globalVar';
import NewsletterButton from 'components/Shared/Buttons/NewsletterButton';

const { Search } = Input;

const formLayout12x24 = { xxl: 12, xl: 24, lg: 24, sm: 24, xs: 24 };

@inject(
  'breadcrumbStore',
  'newsletterStore',
  'uiStore',
  'categoryStore',
  'clusterStore',
  'userStore'
)
@observer
class Homepage extends Component {
  state = {
    filter: '',
    searcher: '',
    data: [],
    cluster: '',
    loading: false,
    totalPage: 0,
    minIndex: 0,
    maxIndex: 0,
    exporting: false,
    activeCollapseKey: [],
    showFilter: false,
    showSucessEmailHashModal: false,
    updatedClustername: '',

    startDate: undefined,
    endDate: undefined
  };
  formRef = React.createRef();

  componentDidMount = async () => {
    // check for ClusterJob email link
    if (this.props.match?.params?.hash) {
      try {
        let result = await this.props.clusterStore.updateClusterDateByHash(
          this.props.match?.params?.hash
        );
        this.setState({
          updatedClustername: result,
          showSucessEmailHashModal: true,
        });
      } catch {
        navigateToHomeWithError('Link ist abgelaufen.');
      }
    }

    this.props.breadcrumbStore.setBreadcrumbs('clusterplattform-oesterreich');
    let sessionFilter = window.sessionStorage.getItem('filterNews');
    if (sessionFilter) {
      try {
        sessionFilter = JSON.parse(sessionFilter);
        if (sessionFilter.current) {
          this.props.newsletterStore.obj = sessionFilter;
        }
      } catch {
        // ignore error
        sessionFilter = null;
      }
    }
    let { pageSize, filter, fil } = this.props.newsletterStore.obj;

    if (filter || pageSize != 3) {
      this.onCollapseChange(['1']);
    }

    this.fetchData();
    await this.props.categoryStore.getAllTyped(true);
    this.setState({ cluster: await this.props.clusterStore.getSimpleClustersOnline(true) });

    let { currentUser } = this.props.userStore;
    if (fil?.categories) {
      this.formRef.current?.setFieldsValue({
        categories: toJS(fil.categories),
      });
    }
    if (fil?.clusters) {
      this.formRef.current?.setFieldsValue({
        clusters: toJS(fil.clusters),
      });
    }
    if (fil?.minDate) {
      this.formRef.current?.setFieldsValue({
        minDate: dayjs(fil.minDate),
      });
    }
    if (fil?.maxDate) {
      this.formRef.current?.setFieldsValue({
        maxDate: dayjs(fil.maxDate),
      });
    }
    this.formRef.current?.setFieldsValue({
      pages: pageSize,
    });
    this.props.newsletterStore.obj.userString = `&userName=${currentUser.fullname}&userEmail=${currentUser.email}&userRole=${currentUser.role}`;
  };

  fetchData = async () => {
    let { current, pageSize, filter, searcher, sorter } = this.props.newsletterStore.obj;
    this.setState({ loading: true });
    await this.props.newsletterStore.getAllFiltered(
      current,
      pageSize,
      filter,
      searcher,
      sorter
    );
    let newsLetters = this.props.newsletterStore.entities.slice();
    this.setState({
      data: newsLetters,
      totalPage: this.state.data.length / pageSize,
      minIndex: 0,
      maxIndex: pageSize,
      filter: filter,
      searcher: searcher,
      sorter: sorter,
      loading: false,
    });

    window.sessionStorage.setItem(
      'filterNews',
      JSON.stringify(this.props.newsletterStore.obj)
    );
    return this.state.data;
  };

  getNewsletter = () => {
    return (
      <Spin spinning={this.state.loading}>
        {this.state.data.length > 0 ? (
          <div>
            {this.state.data?.map(
              (data, index) =>
                index >= this.state.minIndex &&
                index < this.state.maxIndex && (
                  <NewsletterCard key={data.id} newsLetter={data} />
                )
            )}
          </div>
        ) : (
          <Empty description="Keine Newsbeiträge gefunden" />
        )}
      </Spin>
    );
  };

  getCategoriesSelect() {
    let categories = this.props.categoryStore.entities.slice();
    return (
      <Select
        className="input-100"
        placeholder="Alle"
        mode="multiple"
        allowClear
        optionFilterProp="children"
        maxTagCount="responsive"
        showArrow
      >
        {map(categories, (category) => (
          <Select.Option value={category.id} key={category.id}>
            {category.name}
          </Select.Option>
        ))}
      </Select>
    );
  }

  getClusterSelect() {
    return (
      <Select
        className="input-100"
        placeholder="Alle"
        mode="multiple"
        allowClear
        optionFilterProp="children"
        maxTagCount="responsive"
        showArrow
      >
        {map(this.state.cluster, (cluster) => (
          <Select.Option value={cluster.id} key={cluster.id}>
            {cluster.name}
          </Select.Option>
        ))}
      </Select>
    );
  }

  onFilter = async (values) => {
    this.props.newsletterStore.obj.fil = values;
    this.props.newsletterStore.obj.filter = '';

    if (values.categories?.length > 0) {
      forEach(values.categories, (x) => {
        this.props.newsletterStore.obj.filter += `&categoryIds=${x}`;
      });
    }
    if (values.clusters?.length > 0) {
      forEach(values.clusters, (x) => {
        this.props.newsletterStore.obj.filter += `&clusterIds=${x}`;
      });
    }
    if (values.pages) {
      this.props.newsletterStore.obj.pageSize = values.pages;
    }
    if (this.props.newsletterStore.obj.filter !== this.state.filter) {
      this.props.newsletterStore.obj.current = 1;
    }
    if (values.minDate) {
      this.props.newsletterStore.obj.filter += `&minDate=${dayjs(values.minDate).format(
        'YYYY-MM-DD'
      )}`;
    }
    if (values.maxDate) {
      this.props.newsletterStore.obj.filter += `&maxDate=${dayjs(values.maxDate).format(
        'YYYY-MM-DD'
      )}`;
    }
    if ((await this.fetchData()).length === 0) {
      this.props.newsletterStore.obj.current = 1;
      await this.fetchData();
    }
  };

  //reset filter values
  onResetFilters = () => {
    window.sessionStorage.removeItem('filterNews');
    this.formRef.current?.resetFields();
    this.props.newsletterStore.obj.current = 1;
    this.props.newsletterStore.obj.pageSize = 3;
    this.props.newsletterStore.obj.filter = '';
    this.fetchData();
  };

  onSearch = (value) => {
    this.props.newsletterStore.obj.searcher = '';
    if (value) {
      this.props.newsletterStore.obj.searcher += `&title=${value}`;
    }
    if (this.props.newsletterStore.obj.searcher !== this.state.searcher) {
      this.props.newsletterStore.obj.current = 1;
    }
    this.fetchData();
  };

  onChangeSearchInput = (event) => {
    this.props.newsletterStore.obj.sear = event.target.value;
  };

  onSort = () => {
    this.props.newsletterStore.obj.sorter = '';
    this.props.newsletterStore.obj.isSortedDown =
      !this.props.newsletterStore.obj.isSortedDown;
    this.props.newsletterStore.obj.sorter += `&sortedDown=${this.props.newsletterStore.obj.isSortedDown}`;
    this.fetchData();
  };

  handleChange = async (page, pageSize) => {
    this.props.newsletterStore.obj.current = page;
    this.props.newsletterStore.obj.pageSize = pageSize;
    await this.fetchData();
  };

  onCollapseChange = (e) => {
    this.setState({ activeCollapseKey: e });
  };

  loadCalenderSite = () => {
    this.props.uiStore.navigateTo('/calendar');
  };

  onExport = async () => {
    if (this.state.data.length > 0) {
      this.setState({ exporting: true });
      try {
        let resp = await this.props.newsletterStore.exportAllNewsletters(
          this.props.newsletterStore.obj.filter,
          this.props.newsletterStore.obj.searcher,
          this.props.newsletterStore.obj.sorter,
          this.props.newsletterStore.obj.userString
        );
        var name = getFileName(resp, 'Newsletterexport.pdf');
        saveAs(resp.data, name);
        this.setState({ exporting: false });
        switch (this.state.data.length) {
          case 1:
            openNotificationWithIcon(
              'success',
              `Newsbeitrag wurde erfolgreich exportiert.`
            );
            break;
          default:
            openNotificationWithIcon(
              'success',
              `Newsbeiträge wurden erfolgreich exportiert.`
            );
            break;
        }
      } catch (e) {
        this.setState({ exporting: false });
        openNotificationWithIcon(
          'error',
          `Newsbeiträge konnten nicht exportiert werden!`
        );
      }
    } else {
      openNotificationWithIcon(
        'warning',
        `Es sind keine Newsbeiträge zum Exportieren vorhanden!`
      );
    }
  };

  render() {
    let currentUser = this.props.userStore.currentUser;
    return (
      <div className="home">
        <div className="header">
          <h1 className="title">
            Newsbeiträge{' '}
            <FontAwesomeIcon icon={['far', 'newspaper']} className="header-icon" />
          </h1>
          <div className="reg-btn">
            <NewsletterButton />
            <div className="action-btns">
              {Object.keys(currentUser).length > 0 && currentUser.role != ROLES.user && (
                <Button
                  type="ghost"
                  className="btn-with-icon secondary-button"
                  disabled={this.state.exporting}
                  loading={this.state.exporting}
                  onClick={this.onExport}
                >
                  {this.props.newsletterStore.obj.filter == '' &&
                  this.props.newsletterStore.obj.searcher == ''
                    ? `Alle Beiträge exportieren (${this.props.newsletterStore.total}) `
                    : `Aktuelle Auswahl exportieren (${this.props.newsletterStore.total}) `}
                  <FontAwesomeIcon icon={['fas', 'file-export']} />
                </Button>
              )}
              <Button type="primary" onClick={this.loadCalenderSite}>
                Zu den Veranstaltungen{' '}
                <FontAwesomeIcon icon={['fas', 'calendar-alt']} className="btn-icon" />
              </Button>
            </div>
          </div>
        </div>

        <div className="search-sorter">
          <div className="search">
            <Search
              value={this.props.newsletterStore.obj.sear}
              placeholder="Newsbeitrag-Titel suchen"
              onSearch={this.onSearch}
              onChange={this.onChangeSearchInput}
              allowClear
            ></Search>
          </div>
          <div className="sorting">
            <Tooltip
              title={
                this.props.newsletterStore.obj.isSortedDown
                  ? 'Aktuell: Neueste Beiträge zuerst'
                  : 'Aktuell: Älteste Beiträge zuerst'
              }
            >
              <Button onClick={this.onSort} type="primary" className="btn-with-icon">
                Sortierung
                <FontAwesomeIcon
                  icon={
                    this.props.newsletterStore.obj.isSortedDown
                      ? ['fal', 'sort-circle-down']
                      : ['fal', 'sort-circle-up']
                  }
                />
              </Button>
            </Tooltip>
            <Button
              type="primary"
              className="btn-with-icon left15"
              onClick={() => this.setState({ showFilter: !this.state.showFilter })}
            >
              Filter auswählen
              <FontAwesomeIcon
                icon={
                  this.state.showFilter
                    ? ['fas', 'angle-double-up']
                    : ['fas', 'angle-double-down']
                }
              />
            </Button>
          </div>
        </div>
        {this.state.showFilter && (
          <div className="flex filter-panel-btn">
            <div className="filter">
              <Form
                ref={this.formRef}
                scrollToFirstError={true}
                name="filter-form"
                onFinish={this.onFilter}
                labelAlign="left"
                layout="vertical"
              >
                <Row gutter={80}>
                  <Col {...formLayout12x24}>
                    <Form.Item
                      label={
                        <>
                          {'Art(en) des Beitrags'}
                          <FontAwesomeIcon icon={['fas', 'tags']} />
                        </>
                      }
                      name="categories"
                    >
                      {this.getCategoriesSelect()}
                    </Form.Item>
                  </Col>
                  <Col {...formLayout12x24}>
                    <Form.Item
                      label={
                        <>
                          Cluster
                          <FontAwesomeIcon icon={['fas', 'thumbtack']} />
                        </>
                      }
                      name="clusters"
                    >
                      {this.getClusterSelect()}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={80}>
                  <Col {...formLayout12x24}>
                    <Row className="filter-date-row">
                      <Form.Item
                        label={
                          <>
                            VÖ-Datum von
                            <FontAwesomeIcon icon={['fas', 'calendar-day']} />
                          </>
                        }
                        name="minDate"
                        dependencies={['maxDate']}
                        rules={[
                          {
                            validator: (rule, validator) =>
                              beforeDateRule(rule, validator, this.state.endDate),
                            message:
                              'Das Startdatum darf nicht nach dem Enddatum liegen.',
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={(value) => this.setState({ startDate: value })}
                          className="input-100"
                          format={'DD.MM.YYYY'}
                        />
                      </Form.Item>

                      <Form.Item
                        label={
                          <>
                            VÖ-Datum bis
                            <FontAwesomeIcon icon={['fas', 'calendar-day']} />
                          </>
                        }
                        name="maxDate"
                        dependencies={['minDate']}
                        rules={[
                          {
                            validator: (rule, validator) =>
                              afterDateRule(rule, validator, this.state.startDate),
                            message: 'Das Enddatum darf nicht vor dem Startdatum liegen.',
                          },
                        ]}
                      >
                        <DatePicker
                          onChange={(value) => this.setState({ endDate: value })}
                          className="input-100"
                          format={'DD.MM.YYYY'}
                        />
                      </Form.Item>
                    </Row>
                  </Col>
                  <Col {...formLayout12x24}>
                    <Form.Item
                      label={
                        <>
                          Beiträge pro Seite
                          <FontAwesomeIcon icon={['fas', 'clone']} />
                        </>
                      }
                      name="pages"
                    >
                      <InputNumber
                        className="input-100"
                        min={1}
                        max={10}
                        defaultValue={3}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="filter-btns">
              <Button onClick={this.onResetFilters} type="ghost">
                Filter zurücksetzen
                <FontAwesomeIcon icon={['far', 'times']} className="icon" />
              </Button>
              <Button
                className="margin-right-16"
                onClick={() => this.formRef.current?.submit()}
                type="primary"
              >
                Filter anwenden
                <FontAwesomeIcon icon={['far', 'filter']} />
              </Button>
            </div>
          </div>
        )}

        <div>{this.getNewsletter()}</div>

        {this.state.data.length > 0 ? (
          <>
            <div className="reg-btn-below">
              <NewsletterButton />
            </div>
            <Pagination
              defaultCurrent={1}
              pageSize={this.props.newsletterStore.obj.pageSize}
              current={this.props.newsletterStore.obj.current}
              total={this.props.newsletterStore.total}
              onChange={this.handleChange}
            />
          </>
        ) : (
          <></>
        )}
        <Modal
          destroyOnClose
          visible={this.state.showSucessEmailHashModal}
          cancelButtonProps={{ style: { display: 'none' } }}
          title={'Cluster Aktualisierung'}
          onOk={() => this.setState({ showSucessEmailHashModal: false })}
          onCancel={() => this.setState({ showSucessEmailHashModal: false })}
        >
          <h1>
            {this.state.updatedClustername}{' '}
            <FontAwesomeIcon icon={['fas', 'chart-pie']} className="header-icon" />
          </h1>
          <h2>Alle Daten des Clusters '{this.state.updatedClustername}' sind aktuell!</h2>
        </Modal>
      </div>
    );
  }
}

export default Homepage;
