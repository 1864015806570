import React from 'react';

class PersonNotFoundText extends React.Component {
  render() {
    return (
      <div className="no-person-container">
        <span>
          Sie können die gesuchte Person nicht finden? <br />
          Das bedeutet, die gesuchte Person
          wurde noch nicht als Benutzer angelegt oder hat ihren Account noch nicht
          aktiviert.
          <ol>
            <li>
              Wählen Sie daher Ihren eigenen Namen aus der Liste und speichern Sie den
              Cluster.
            </li>
            <li>
              Schreiben Sie eine Mail mit den Personendaten der gesuchten Person an {' '}
              <a
                className="office-link underlined"
                href="mailto:office@clusterplattform.at"
              >
                office@clusterplattform.at
              </a>
              , um diese als Benutzer anlegen zu lassen.
            </li>
          </ol>
          Nachdem die von Ihnen angegebene Person ihren Account aktiviert hat, können Sie
          ihr diesen Cluster zuweisen. <br /> Sollten Sie dabei Unterstützung benötigen,
          können Sie uns gerne kontaktieren.
        </span>
      </div>
    );
  }
}

export default PersonNotFoundText;
