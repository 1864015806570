import React, { Component } from 'react';
import { Layout as AntLayout } from 'antd';

import { Headbar } from 'components/Headbar/Headbar';
import { CustomBreadcrumbs } from 'components/Headbar/CustomBreadcrumbs';
import { headbarFullMaxWidth, mobileBreakpoint } from '../../globalVar';
import Sidebar from '../Sidebar/Sidebar';
import AppsNav from '../AppsNav/AppsNav';

import { observer, inject } from 'mobx-react';

import authService from '../../utils/api-authorization/AuthorizeService.js';

@inject('permissionStore', 'userStore')
@observer
export class Layout extends Component {
  state = {
    showMobileMenu: false,
    _isAuthenticated: false,
    isMobileView: false,
  };

  static displayName = Layout.name;

  toggleMobileMenu = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu }, () => {
      if (this.state.showMobileMenu && window.innerWidth <= mobileBreakpoint) {
        document.getElementsByTagName('body')[0].style.overflow = 'hidden';
      } else {
        document.getElementsByTagName('body')[0].style.overflow = 'visible';
      }
    });

    if (window.innerWidth <= mobileBreakpoint) {
      this.setState({ isMobileView: true });
    } else {
      this.setState({ isMobileView: false });
    }
  };

  componentDidMount = async () => {
    if (window.innerWidth > mobileBreakpoint) {
      this.setState({ showMobileMenu: true, isMobileView: false });
    } else {
      this.setState({ isMobileView: true });
    }
    this.setState({
      _isAuthenticated: Object.keys(this.props.userStore.currentUser).length > 0,
    });
  };

  render() {
    return (
      <AntLayout style={{ minHeight: '100vh' }}>
        <AntLayout.Header style={{ backgroundColor: 'white' }}>
          <Headbar
            toggleMobileMenu={this.toggleMobileMenu}
            showMobileMenu={this.state.showMobileMenu}
            isMobileView={this.state.isMobileView}
          />
        </AntLayout.Header>

        <CustomBreadcrumbs />
        <AntLayout.Footer className="app-footer" >
          <a href="https://www.bmaw.gv.at/" target="_blank" className='bmaw-link underlined'>
            Ein Projekt des <abbr title="Bundesministerium für Arbeit und Wirtschaft">BMAW</abbr>
          </a>
        </AntLayout.Footer>
        <AntLayout>
          <Sidebar
            isAuthenticated={this.state._isAuthenticated}
            show={this.state.showMobileMenu}
            toggleMobileMenu={this.toggleMobileMenu}
            isMobileView={this.state.isMobileView}
          />
          <AntLayout.Content>{this.props.children}</AntLayout.Content>
        </AntLayout>
      </AntLayout>
    );
  }
}
