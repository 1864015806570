import React from 'react';
import { Card, Row } from 'antd';

import NewsletterImage from './Newsletter/NewsletterImage';
import NewsletterContent from './Newsletter/NewsletterContent';
import NewsletterFooter from './Newsletter/NewsletterFooter';
import Gallery from './Gallery';
import StackNavigator from './StackNavigator';
import { inject } from 'mobx-react';
import SourceList from './SourceList';

@inject('uiStore')
export default class NewsletterEntry extends React.Component {
  render() {
    let newsLetter = this.props.newsletter;

    return (
      <>
        <div className="card">
          <Card hoverable={this.props.hoverable} onKeyDown={this.props.onKeyDown} onClick={this.props.onClick} tabIndex={0} className="antd-card elevate">
            <div className={this.props.isCard ? "card-content newscard" : "card-content newsletter"}>
              <div className="card-body">
                <NewsletterContent newsletter={newsLetter} showTeaser={this.props.showTeaser} justTeaser={this.props.justTeaser}/>
                {this.props.addStyles && (
                  <>
                    <SourceList entity={newsLetter} addStyles={this.props.addStyles} />
                  </>
                )}
                {this.props.displayGallery ?  <Gallery entity={newsLetter}/> : null}
                  <NewsletterFooter
                    newsletter={newsLetter}
                    addIdToLink={this.props.addIdToLink}
                    isCard={this.props.isCard}
                  />
              </div>
              <NewsletterImage displayThumbnailTitle={this.props.displayThumbnailTitle} newsletter={newsLetter} />
            </div>
          </Card>
          {(newsLetter.previousNewsletterId || this.props.uiStore.newsletterNavigationStack.length > 0) && this.props.showNavigator && (
            <StackNavigator entity={newsLetter} type={newsLetter.type} />
          )}
        </div>
        
      </>
    );
  }
}
