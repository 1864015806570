const months = [
    'Jänner',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
];

function formatDateUTC(date){
    return `${date.getUTCDate()}.${months[date.getUTCMonth()]}.${date.getUTCFullYear()}`;
}
function formatTimeUTC(date){
    return `${date.getUTCHours()} Uhr ${date.getUTCMinutes()}`;
}

function formatDate(date){
    return `${date.getDate()}.${months[date.getMonth()]}.${date.getFullYear()}`
}
function formatTime(date){
    return `${date.getHours()} Uhr ${date.getMinutes()}`;
}

export const getAriaLabelDate = (date) => {
  if (!date) {
    return 'Nicht veröffentlicht.';
  }
  let dateObj = new Date(date);
  return `${formatDate(dateObj)}`;
};

// dateA bzw. dateB = null sollte nur im Fall eintreten,
// dass ein Draft auf der Veröffentlichungsseite angesehen wird
// und die Felder noch nicht ausgefüllt wurden
export const getAriaLabelDateStartEnd = (dateA, dateB) => {
    let missingText = 'Keine Angabe';
    let fromDate, fromTime;
    let toDate, toTime;

    if (!dateA) {
      fromDate = missingText;
      fromTime = missingText;
    } else {
      let dateObjA = new Date(dateA);
      fromDate = formatDate(dateObjA);
      fromTime = formatTime(dateObjA);
    }

    if (!dateB) {
      toDate = missingText;
      toTime = missingText;
    } else {
      let dateObjB = new Date(dateB);
      toDate = formatDate(dateObjB);
      toTime = formatTime(dateObjB);
    }

    return `Datum: von ${fromDate} um ${fromTime} bis ${toDate} um ${toTime}`;
}