import { isProduction, apiUrl } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class IndustryStore extends BaseEntitiesStore {
  constructor() {
    super('Industry', 'IndustryStore');
    if (!isProduction) {
      window[`IndustryStore`] = this;
    }
  }
}

export default new IndustryStore();
