import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, Button } from 'antd';
import LazyEventEntry from './ContentParts/LazyEventEntry';
import { newsletterType } from 'App/globalVar';

@inject('newsletterStore', 'uiStore', 'formatStore', 'breadcrumbStore')
@observer
export default class Event extends Component {

  state = {
    entityId: null
  }

  componentDidMount = async () => {
    let id = this.props.match.params.id;
    this.props.newsletterStore.entityId = id;
    this.props.newsletterStore.entityType = newsletterType.event;
    this.setState({entityId: id});
    await this.props.formatStore.getAll();
  };

  onMetaLoaded = () => {
    let entity = this.props.newsletterStore.entity;
    this.props.breadcrumbStore.setBreadcrumbs('calendar', {
      id: entity.id,
      name: entity.title,
    });
  }

  onReturnButton = () => {
    this.props.uiStore.navigateTo('/calendar');
    this.props.newsletterStore.entity = {};
  };

  componentWillUnmount = () => {
    this.props.newsletterStore.entity = {};
  }

  render() {
    if (this.state.entityId == null) {
      return <Skeleton active />;
    }

    let event = this.props.newsletterStore.entity;
    return (
      <div className="home">
        <div className="header">
          <h1 className="title">{event.title}</h1>
          <div className="action-btns">
            {event.registrationLink ? (
              <Button
                className="reg-btn-event btn-with-icon teal"
                onClick={() =>
                  window.open(normalizeUrl(event.registrationLink), '_blank')
                }
                type="primary"
              >
                Zur Anmeldung
                <FontAwesomeIcon icon={['far', 'pencil']} />
              </Button>
            ) : (
              <></>
            )}
            <Button type="primary" onClick={this.onReturnButton}>
              <FontAwesomeIcon
                icon={['fas', 'arrow-alt-to-left']}
                className="icon-left"
              />
              Zurück zu den Veranstaltungen
            </Button>
          </div>
        </div>
        <LazyEventEntry
          entityId={this.state.entityId} 
          returnUrl={'/calendar'}
          onMetaLoaded={this.onMetaLoaded}
          isProcessUrl={false}
          addIdToLink={false}
          displayGallery={false}
          displayThumbnailTitle={true}
          showNavigator={true}
          addStyles={true}
        />
      </div>
    );
  }
}
