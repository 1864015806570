import { observable, action } from 'mobx';
import axios from 'axios';

import { isProduction, apiUrl, newsletterType, newsletterState } from '../globalVar';
import { getQueryStringFromObject } from 'utils/functions';
import BasePagedEntitiesStore from './BasePagedEntitiesStore';

class NewsletterStore extends BasePagedEntitiesStore {
  newsletterUrl = apiUrl + '/newsletter';
  eventUrl = apiUrl + '/event';

  constructor() {
    super('newsletter', 'NewsletterStore');
    if (!isProduction) {
      window[`NewsletterStore`] = this;
    }
  }

  @observable
  entityId = null;
  @observable
  entityType = null;

  //make new store for events
  @observable
  eventEntity = {};

  //store newsletter parameters for BE
  @observable
  obj = {
    current: 1,
    pageSize: 3,
    filter: '',
    searcher: '',
    sorter: undefined,
    isSortedDown: true,
    sear: '',
    fil: {},
    userString: '',
  };

  //store event parameters for BE
  //TODO: seperate store --> need eventStore
  @observable
  eventObj = {
    current: 1,
    pageSize: 3,
    filter: '',
    searcher: '',
    sorter: undefined,
    isSortedDown: true,
    sear: '',
    fil: {},
    isCalendarView: false,
  };

  file = {};
  modalType = undefined;

  @action('Set newsletter thumbnail')
  setNewsletterThumbail(thumbnail) {
    if (this.entity && Object.keys(this.entity).length > 0) {
      this.entity.thumbnail = thumbnail;
    }
  }

  @action('Set newsletter galleryImages')
  setNewsletterGallery(gallery) {
    if (this.entity && Object.keys(this.entity).length > 0) {
      this.entity.galleryImages = gallery;
    }
  }

  @action('Set newsletter content')
  setNewsletterContent(content) {
    if (this.entity && Object.keys(this.entity).length > 0) {
      this.entity.content = content;
    }
  }

  @action('Set newsletter sources')
  setNewsletterSources(sources) {
    if (this.entity && Object.keys(this.entity).length > 0) {
      this.entity.sources = sources;
    }
  }

  async getAndSetContent(entityId, urlType, isProcessUrl) {
    let data = await this.getLazy(entityId, 'content', urlType, isProcessUrl);

    if (data) {
      this.setNewsletterContent(data.content);
      this.setNewsletterSources(data.sources);
    }
  }
  async getAndSetThumbnail(entityId, urlType, isProcessUrl) {
    let data = await this.getLazy(entityId, 'thumbnail', urlType, isProcessUrl);

    if (data) {
      this.setNewsletterThumbail(data);
    }
  }
  async getAndSetGallery(entityId, urlType, isProcessUrl) {
    let data = await this.getLazy(entityId, 'gallery', urlType, isProcessUrl);

    if (data) {
      this.setNewsletterGallery(data);
    }
  }

  async getByIdLazy(entityId, urlType, isProcessUrl) {
    let data = await this.getLazy(entityId, '', urlType, isProcessUrl);

    if (data) {
      this.setEntity(data);
    }
  }

  async getLazy(entityId, addToUrl, entityType, isProcessUrl) {
    let typeUrl = entityType == newsletterType.event ? this.eventUrl : this.newsletterUrl;
    let processUrl = isProcessUrl ? '/process' : '';
    let toBeAdded = addToUrl ? `/${addToUrl}` : '';

    let reqUrl = `${typeUrl}/${entityId}/lazy${processUrl}${toBeAdded}`;

    return (await axios.get(reqUrl))?.data;
  }

  //store tableType, maybe later -> store filter and sorters
  @observable
  tableType = {
    type: newsletterType.newsletter,
  };

  async uploadImage(formData) {
    return await axios.post(apiUrl + '/newsletter/image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    });
  }

  async uploadFormWithFile(data) {
    return await axios.post(apiUrl + '/newsletter', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  async getAllSimple() {
    let entities = (await axios.get(this.newsletterUrl + '/simple')).data;
    this.setEntities(entities);
    return entities;
  }

  async getAllSimpleTypedPaginated(type, currentPage = 1, filters, sorter) {
    let query = `?current=${currentPage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.newsletterUrl + '/simple/' + type + query)).data;
  }

  async getAllSimpleTypedPaginatedWithoutPredicate(type, currentPage = 1, filters, sorter) {
    let query = `?current=${currentPage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.newsletterUrl + '/simple/' + type + '/all' + query)).data;
  }

  async getPublicSimpleTypedPaginated(type, currentPage = 1, filters, sorter) {
    let query = `?current=${currentPage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.newsletterUrl + '/public/simple/' + type + query)).data;
  }

  async getArchivedSimpleTypedPaginated(type, currentpage = 1, filters, sorter) {
    let query = `?current=${currentpage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.newsletterUrl + '/archived/simple/' + type + query)).data;
  }

  async getPersonalSimpleTypedPaginated(type, currentpage = 1, filters, sorter) {
    let query = `?current=${currentpage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.newsletterUrl + '/personal/simple/' + type + query)).data;
  }

  async getPersonalEventsSimplePaginated(currentpage = 1, filters, sorter) {
    let query = `?current=${currentpage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.eventUrl + '/personal/simple' + query)).data;
  }

  async getById(id, type) {
    if (type == newsletterType.event) {
      return (await axios.get(this.eventUrl + '/' + id)).data;
    }
    return (await axios.get(this.newsletterUrl + '/' + id)).data;
  }

  async getDeletedById(id, type, typeForUrl) {
    if (type == newsletterType.event) {
      return (await axios.get(this.eventUrl + '/deleted/' + id)).data;
    }
    return (await axios.get(`${this.newsletterUrl}/deleted/${typeForUrl}/${id}`)).data;
  }

  async deArchive(id, type, typeForUrl) {
    if (type == newsletterType.event) {
      return await axios.put(`${this.eventUrl}/dearchive/${id}`, {});
    } else {
      return await axios.put(`${this.newsletterUrl}/dearchive/${typeForUrl}/${id}`, {});
    }
  }

  async upgradeDraft(id, type, entity, upgradeDto) {
    if (type == newsletterType.event) {
      return (
        await axios.put(`${this.eventUrl}/upgradeFromDraft/${id}`, {
          eventDto: entity,
          stateDto: upgradeDto,
        })
      ).data;
    } else {
      return (
        await axios.put(`${this.newsletterUrl}/upgradeFromDraft/${id}`, {
          newsletterDto: entity,
          stateDto: upgradeDto,
        })
      ).data;
    }
  }

  async getDrafts() {
    return (await axios.get(this.newsletterUrl + '/drafts')).data;
  }

  // this also gets event drafts
  async getSimpleDrafts(currentPage = 1, filters, sorter, type) {
    let query = `?current=${currentPage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    query += '&type=' + type;
    return (await axios.get(this.newsletterUrl + '/simple/draft' + query)).data;
  }

  async postDraft(body){
    return (await axios.post(this.newsletterUrl + "/draft", body)).data;
  }

  async putDraft(id, body) {
    return (await axios.put(this.newsletterUrl + '/draft/' + id, body)).data;
  }

  async publishNews(id, body) {
    return await axios.put(this.newsletterUrl + '/' + id + '/setState', body);
  }

  // events

  async getEventsSimple(currentPage = 1, filters, sorter) {
    let query = `?current=${currentPage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.eventUrl + '/simple' + query)).data;
  }

  async getAllEventsSimple(currentPage = 1, filters, sorter) {
    let query = `?current=${currentPage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.eventUrl + '/simple/all' + query)).data;
  }

  async getPublicEventsSimple(currentPage = 1, filters, sorter) {
    let query = `?current=${currentPage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.eventUrl + '/public/simple' + query)).data;
  }

  async getArchivedEventsSimple(currentPage = 1, filters, sorter) {
    let query = `?current=${currentPage}` + getQueryStringFromObject(filters);
    if (sorter) {
      sorter = {
        sortingField: sorter['field'],
        sortingOrder: sorter['order'],
      };
      query += getQueryStringFromObject(sorter);
    }
    return (await axios.get(this.eventUrl + '/archived/simple/' + query)).data;
  }

  async getEventById(id) {
    var resp = (await axios.get(this.eventUrl + '/' + id)).data;
    return (this.eventEntity = resp);
  }

  async postEventDraft(body) {
    return (await axios.post(this.eventUrl + '/draft', body)).data;
  }

  async putEvent(id, body) {
    await axios.put(this.eventUrl + '/' + id, body);
  }

  async putEventDraft(id, body) {
    return (await axios.put(this.eventUrl + '/draft/' + id, body)).data;
  }

  async publishEvent(id, body) {
    return await axios.put(this.eventUrl + '/' + id + '/setState', body);
  }

  async deleteEvent(id) {
    await axios.delete(this.eventUrl + '/' + id);
    this.deleteEntity(id);
  }

  async getAllFiltered(
    current = 1,
    pageSize = 3,
    filter = undefined,
    searcher = undefined,
    sorter = undefined
  ) {
    let entities = await super.getAllFiltered(
      current,
      pageSize,
      filter,
      searcher,
      sorter,
      false
    );
    this.setEntities(entities);
  }

  async getAllEventsFiltered(
    current = 1,
    pageSize = 3,
    filter = undefined,
    searcher = undefined,
    sorter = undefined
  ) {
    //is called data bc the function returns the paginatedResult and therefore data.result = entities
    let data = await super.getAllEventsFiltered(
      this.eventObj.isCalendarView,
      current,
      pageSize,
      filter,
      searcher,
      sorter,
      false
    );
    this.setEntities(data.result);
    return data;
  }

  //pdf export function for all newsletter (including filter, searcher and sorter)
  async exportAllNewsletters(
    filter = undefined,
    searcher = undefined,
    sorter = undefined,
    user = undefined
  ) {
    return await axios.get(
      `${apiUrl}/Export/newsletters?${filter ? filter : ''}${searcher ? searcher : ''}${
        sorter ? sorter : ''
      }${user ? user : ''}`,
      {
        responseType: 'blob',
      }
    );
  }

  //pdf export function for one single newsletter
  async exportNewsletter(
    id,
    filter = undefined,
    searcher = undefined,
    sorter = undefined,
    user = undefined
  ) {
    return await axios.get(
      `${apiUrl}/Export/newsletters/${id}?${filter ? filter : ''}${
        searcher ? searcher : ''
      }${sorter ? sorter : ''}${user ? user : ''}`,
      {
        responseType: 'blob',
      }
    );
  }

  async getLastChangelog(newsletterId, type) {
    if (type == newsletterType.event) {
      return (await axios.get(`${this.eventUrl}/${newsletterId}/changelogs/last`)).data;
    } else {
      return (await axios.get(`${this.newsletterUrl}/${newsletterId}/changelogs/last`))
        .data;
    }
  }

  async getAllChangelogs(entityId, type) {
    if (type == newsletterType.event) {
      return (await axios.get(`${this.eventUrl}/${entityId}/changelogs`)).data;
    } else {
      return (await axios.get(`${this.newsletterUrl}/${entityId}/changelogs`)).data;
    }
  }

  async returnEntityToDrafts(entityId, type, comment, typeForUrl) {
    let body = {
      state: newsletterState.draft,
      prRelevant: false,
      comment: comment,
    };
    if (type == newsletterType.event) {
      return (await axios.put(`${this.eventUrl}/archived/${entityId}`, body)).data;
    } else {
      return (
        await axios.put(`${this.newsletterUrl}/archived/${typeForUrl}/${entityId}`, body)
      ).data;
    }
  }

  async deleteDraft(draftId, type) {
    if (type == newsletterType.event) {
      return (await axios.delete(`${this.eventUrl}/draft/${draftId}`)).data;
    } else {
      return (await axios.delete(`${this.newsletterUrl}/draft/${draftId}`)).data;
    }
  }
}

export default new NewsletterStore();