import { Modal, Steps, Row, Col, Card, Button, Skeleton } from 'antd';
import { inject } from 'mobx-react';
import React from 'react';
import { ROLES, APP_defaultClusterName, modalType, newsletterType, translationMap } from '../../../globalVar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NewsletterForm from './NewsletterForm';
import TeaserForm from './TeaserForm';
import EventForm from './EventForm';
import { onEnterKey, reverseMap } from '../../../utils/functions';

const { Step } = Steps;
const formLayout8x24 = { xxl: 8, xl: 8, lg: 8, md: 8, sm: 24, xs: 24 };

@inject(
  'newsletterStore',
  'uiStore',
  'categoryStore',
  'clusterStore',
  'formatStore',
  'userStore'
)
export default class NewsletterWizard extends React.Component {
  state = {
    currentSelectedType: undefined,
    currentStep: 0,
    currentModalFooter: [],
    modalTitle: 'Art des Beitrages wählen',
    reverseTypeMap: [],

    simpleClusters: [],
  };

  changeStep = (step) => {
    this.setState({ currentStep: step }, () => {
      if (this.state.currentStep === 0) {
        this.setState({ currentModalFooter: [], modalTitle: 'Art des Beitrages wählen' });
      } else {
        this.setState({
          modalTitle: this.getModalTitle(
            this.state.reverseTypeMap,
            this.state.currentSelectedType
          ),
        });
      }
    });
  };

  selectType = async (type) => {
    await this.props.categoryStore.getAllTyped(type !== newsletterType.event);
    this.initializeModal(this.state.reverseTypeMap);
    this.setState({ currentSelectedType: type });
    this.changeStep(this.state.currentStep + 1);
  };

  buildSteps = () => {
    let steps = [];

    if (this.props.showTypeSelect) {
      steps.push({
        title: 'Art des Beitrages wählen',
        content: (
          <>
            <Row gutter={30} role="list">
              <Col {...formLayout8x24} className="select-card" role="listitem">
                <Card
                  className="type-card"
                  title="Newsbeitrag"
                  hoverable
                  onClick={() => this.selectType(newsletterType.newsletter)}
                  onKeyDown={async (e) =>
                    await onEnterKey(e, this.selectType, [newsletterType.newsletter])
                  }
                  tabIndex="0"
                >
                  <p className="card-content">
                    Unter einem Newsbeitrag versteht man einen Artikel, der über ein
                    gewisses Thema informieren soll.
                    <br /> <br />
                    Er beinhaltet einen Teaser beziehungsweise einen kurzen Text zur
                    Ankündigung des Inhaltes. Des Weiteren wird er dann durch eine
                    ausführliche Beschreibung ergänzt.
                  </p>
                </Card>
              </Col>
              <Col {...formLayout8x24} className="select-card" role="listitem">
                <Card
                  className="type-card"
                  title="Teaser"
                  hoverable
                  onClick={() => this.selectType(newsletterType.teaser)}
                  onKeyDown={async (e) =>
                    await onEnterKey(e, this.selectType, [newsletterType.teaser])
                  }
                  tabIndex="0"
                >
                  <p className="card-content">
                    Ein Teaser oder auch Ankündigungsbeitrag wird dann verwendet, wenn es
                    um eine kurze Information zu einem Thema in der Zukunft geht.
                    <br /> <br />
                    Sie werden meist verwendet, um große Veranstaltungen oder wichtige
                    News anzukündigen. Hierbei wird nur ein kurzer Text verfasst.
                  </p>
                </Card>
              </Col>
              <Col {...formLayout8x24} className="select-card" role="listitem">
                <Card
                  className="type-card"
                  title="Veranstaltung"
                  hoverable
                  onClick={() => this.selectType(newsletterType.event)}
                  onKeyDown={async (e) =>
                    await onEnterKey(e, this.selectType, [newsletterType.event])
                  }
                  tabIndex="0"
                >
                  <p className="card-content">
                    Ein Veranstaltungsbeitrag wird dann erstellt, wenn ein Event
                    stattfindet.
                    <br /> <br />
                    Dies kann zum Beispiel eine Feier, eine Fortbildung oder eine
                    Konferenz sein.
                  </p>
                </Card>
              </Col>
            </Row>
          </>
        ),
      });
    }

    // if (this.state.currentSelectedType) -> ignores 0, which is the type for newsletters
    if (this.state.currentSelectedType != undefined) {
      let simpleNewsletters = this.props.newsletterStore.entities.filter(
        (x) => x.id != this.props.newsletterStore.editObject?.id
      );

      switch (+this.state.currentSelectedType) {
        case newsletterType.newsletter:
          steps.push({
            title: 'Newsletter',
            content: (
              <NewsletterForm
                categories={this.props.categoryStore.entities}
                simpleClusters={this.state.simpleClusters}
                formats={this.props.formatStore.entities}
                newsletters={simpleNewsletters}
                addSubmitButton={this.pushButtonToFooter}
                onCancel={this.props.onCancel}
                fromDraftTable={this.props.fromDraftTable}
                handleDirtyForm={this.props.handleDirtyForm}
              />
            ),
          });
          break;
        case newsletterType.teaser:
          steps.push({
            title: 'Teaser',
            content: (
              <TeaserForm
                categories={this.props.categoryStore.entities}
                simpleClusters={this.state.simpleClusters}
                formats={this.props.formatStore.entities}
                newsletters={simpleNewsletters}
                addSubmitButton={this.pushButtonToFooter}
                onCancel={this.props.onCancel}
                fromDraftTable={this.props.fromDraftTable}
                handleDirtyForm={this.props.handleDirtyForm}
              />
            ),
          });
          break;
        case newsletterType.event:
          steps.push({
            title: 'Event',
            content: (
              <EventForm
                categories={this.props.categoryStore.entities}
                simpleClusters={this.state.simpleClusters}
                formats={this.props.formatStore.entities}
                newsletters={simpleNewsletters}
                addSubmitButton={this.pushButtonToFooter}
                onCancel={this.props.onCancel}
                fromDraftTable={this.props.fromDraftTable}
                handleDirtyForm={this.props.handleDirtyForm}
              />
            ),
          });
          break;
        default:
          steps.push(<p>Something went wrong.</p>);
      }
    }

    return steps;
  };

  initializeModal = (reverseTypeMap) => {
    let buttons = [];

    let title = 'Art des Beitrages wählen';
    if (this.props.newsletterType == undefined) {
      buttons.push(
        <div className="close-footer-btns">
          <Button
            key="backButton"
            type="secondary"
            className="btn-with-icon-right blue-medium"
            onClick={() => {
              if (
                confirm(
                  'Wenn Sie zur Auswahl zurückkehren, werden die Einträge nicht gespeichert.'
                )
              ) {
                this.changeStep(this.state.currentStep - 1);
              }
            }}
          >
            <FontAwesomeIcon icon={['fas', 'step-backward']} />
            Zurück
          </Button>
          <Button
            key="onCancelButton"
            className="btn-with-icon blue-medium"
            type="secondary"
            onClick={() => {
              this.props.onCancel();
            }}
          >
            Abbrechen
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        </div>
      );
    } else {
      title = this.getModalTitle(reverseTypeMap, this.props.newsletterType);
    }

    if (
      this.props.newsletterStore.modalType != modalType.view &&
      this.props.newsletterType != undefined
    ) {
      buttons.push(
        <div className="close-footer-btns">
          <Button
            key="onCancelButton"
            className="btn-with-icon blue-medium"
            type="secondary"
            onClick={() => {
              if (confirm('Wenn Sie bestätigen, wird dieser Beitrag verworfen.')) {
                this.props.onCancel();
              }
            }}
          >
            Abbrechen
            <FontAwesomeIcon icon={['fas', 'times']} />
          </Button>
        </div>
      );
    }
    this.setState({
      currentModalFooter: buttons,
      modalTitle: title,
    });
  };

  getModalTitle = (reverseTypeMap, type) => {
    let entity = translationMap[reverseTypeMap[+type]];
    let verb =
      this.props.newsletterStore.modalType == modalType.view
        ? ''
        : this.props.newsletterStore.modalType == modalType.new
        ? 'erstellen'
        : 'bearbeiten';
    return entity + ' ' + verb;
  };

  pushButtonToFooter = (button) => {
    let footer = this.state.currentModalFooter;
    footer.push(...button);
    this.setState({ currentModalFooter: footer });
  };

  async componentDidMount() {
    await Promise.all([
      this.props.newsletterStore.getAllSimple(),
      this.props.formatStore.getAll(),
    ]);
    let simpleClusters = await this.props.clusterStore.getSimpleClustersOnline();
    if(this.props.userStore.currentUser.role !== ROLES.winnovation) {
      simpleClusters = simpleClusters.filter(x => x.name != APP_defaultClusterName);
    };

    let reverseTypes = reverseMap(newsletterType);
    if (this.props.newsletterType != undefined) {
      this.initializeModal(reverseTypes);
      this.setState({ currentSelectedType: this.props.newsletterType });
    }

    this.setState({ simpleClusters: simpleClusters, reverseTypeMap: reverseTypes });
  }

  render() {
    let loading = this.props.uiStore.loading;
    if (loading)
      return (
        <Modal visible={true}>
          <Skeleton loading={loading} />
        </Modal>
      );

    let steps = this.buildSteps();
    return (
      <>
        <Modal
          maskClosable={false}
          destroyOnClose
          visible={true}
          title={this.state.modalTitle}
          onCancel={this.props.onCancel}
          footer={<div className="footer-btns">{this.state.currentModalFooter}</div>}
          className="selectTypeModal"
        >
          <Steps
            size="small"
            style={{ display: 'none' }}
            current={this.state.currentStep}
            onChange={this.changeStep}
          >
            {steps.map((step) => (
              <Step title={step.title} key={step.title}></Step>
            ))}
          </Steps>

          <div className="step-content">{steps[this.state.currentStep]?.content}</div>
        </Modal>
      </>
    );
  }
}
