import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { apiUrl } from '../../globalVar';

// importing styles and helpers
import './Modals.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openNotificationWithIcon } from '../../utils/functions';

// importing components
import { Button, Modal, Upload, message, Form } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';

@inject('importStore', 'clusterStore')
@observer
export default class Hilfe extends Component {
  state = {
    fileList: [],
    uploading: false,
  };

  handleUpload = async () => {
    const { fileList } = this.state;
    const formData = new FormData();
    formData.append('file', fileList[0]);
    formData.append('name', fileList[0].name);
    this.setState({
      uploading: true,
    });
    try {
      let cluster = await this.props.importStore.importExcel(formData);
      this.props.clusterStore.entities.push(cluster.data);
      openNotificationWithIcon('success', 'Exceldatei wurde erfolgreich hochgeladen!');
      openNotificationWithIcon('info', 'Kontaktperson muss noch hinzugefügt werden!');
      this.setState({
        fileList: [],
        uploading: false,
      });
    } catch (e) {
      openNotificationWithIcon(
        'error',
        'Es ist ein unerwarteter Fehler beim Hochladen aufgetreten'
      );
      openNotificationWithIcon('info', 'Kontrolle: Clusterkarte mit gleichen Namen!');
      openNotificationWithIcon(
        'info',
        'Kontrolle: korrekte und vollständige Daten der Exceldatei!'
      );
      this.setState({
        fileList: [],
        uploading: false,
      });
    }
  };

  render() {
    const { Dragger } = Upload;
    const { uploading, fileList } = this.state;
    const props = {
      accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      onRemove: (file) => {
        this.setState((state) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file) => {
        let files = [];
        this.setState((state) => ({
          fileList: [...files, file],
        }));
        return false;
      },
      fileList,
    };
    return (
      <Modal
        destroyOnClose
        width={600}
        visible={this.props.visible}
        onCancel={() => this.props.toggleImportModal(false)}
        title={'Cluster Exceldatei hochladen'}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              this.props.toggleImportModal(false);
              this.setState({
                fileList: [],
              });
            }}
            type="primary"
            className="teal"
          >
            Abbrechen{' '}
            <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon rotate" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={fileList.length === 0}
            loading={uploading}
            onClick={async () => {
              await this.handleUpload();
              this.props.toggleImportModal(false);
            }}
          >
            Hochladen <UploadOutlined />
          </Button>,
        ]}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Exceldatei</p>
          <p className="ant-upload-hint">klicke hier oder ziehe eine Datei hier hinein</p>
        </Dragger>
      </Modal>
    );
  }
}
