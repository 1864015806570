import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class FormatStore extends BaseEntitiesStore {
  constructor() {
    super('Format', 'FormatStore');
    if (!isProduction) {
      window[`FormatStore`] = this;
    }
  }
}

export default new FormatStore();
