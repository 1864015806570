import '../../components/Home/News.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Checkbox, Form, Modal, Skeleton } from 'antd';
import { inject } from 'mobx-react';
import React from 'react';
import { newsletterState, newsletterType, ROLES } from '../../globalVar';
import { reverseMap, openNotificationWithIcon, navigateToHomeWithError } from '../../utils/functions';
import { LeftOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import LazyNewsletterEntry from 'components/Home/ContentParts/LazyNewsletterEntry';
import LazyEventEntry from 'components/Home/ContentParts/LazyEventEntry';
import { toJS } from 'mobx';

@inject('uiStore', 'newsletterStore', 'formatStore', 'breadcrumbStore', 'userStore')
export default class PublishPage extends React.Component {
  formRef = React.createRef();
  state = {
    type: newsletterType.newsletter,
    loading: true,

    showModal: false,
    modalType: false, // true -> up ; false -> down

    userRole: null,

    navigateBackUrl: '/newsletter',
    navigateBackUrlType: null,

    entityId: null
  };

  componentDidMount = async () => { // NOSONAR
    const { match, uiStore, userStore, breadcrumbStore, newsletterStore, formatStore } = this.props;
    const id = match.params.id;
    const type = match.params.type;
    const queryParams = new URLSearchParams(window.location.search);
    const returnUrl = uiStore.getReturnLink('newsletterPublishPage');
    const fromNews = queryParams.get('fromNews');
    const fromPublic = queryParams.get('fromPublic');
    const fromArchive = queryParams.get('fromArchive');
    const fromDrafts = queryParams.get('fromDrafts');
    const fromPersonal = queryParams.get('fromPersonal');
    const fromOverview = queryParams.get('fromOverview');
    const newsletterProcessRoles = [ROLES.winnovation, ROLES.subjectDepartment, ROLES.publicRelation];

    if (!returnUrl || !this.checkSinglePossibleQueryParam([fromNews, fromPublic, fromArchive, fromDrafts, fromPersonal, fromOverview])) {
      navigateToHomeWithError('Beim Weiterleiten ist ein Fehler aufgetreten.');
      return;
    }

    const currentUserRole = userStore.currentUser.role;

    if(!currentUserRole){
      navigateToHomeWithError('Sie müssen eingeloggt sein, um diese Seite anzusprechen');
    }

    if(fromNews && returnUrl){
      if(currentUserRole === ROLES.user || currentUserRole === ROLES.admin){
        navigateToHomeWithError('Ihre Rolle gibt Ihnen keinen Zugriff auf diese Seite.');
        return;
      }
      this.changeNavigateBackUrl(returnUrl, 'news');
    }

    if(fromPublic && returnUrl){
      if(!newsletterProcessRoles.includes( currentUserRole )){
        navigateToHomeWithError('Ihre Rolle gibt Ihnen keinen Zugriff auf diese Seite.');
        return;
      }
      this.changeNavigateBackUrl(returnUrl, 'public');
    }

    if(fromArchive && returnUrl){
      if(currentUserRole !== ROLES.winnovation){
        navigateToHomeWithError('Ihre Rolle gibt Ihnen keinen Zugriff auf diese Seite.');
        return;
      }
      this.changeNavigateBackUrl(returnUrl, 'archive');
    }

    if(fromDrafts && returnUrl){
      if(currentUserRole !== ROLES.winnovation && currentUserRole !== ROLES.user){
        navigateToHomeWithError('Ihre Rolle gibt Ihnen keinen Zugriff auf diese Seite.');
        return;
      }
      this.changeNavigateBackUrl(returnUrl, 'drafts');
    }

    if(fromPersonal && returnUrl){
      if(currentUserRole !== ROLES.winnovation && currentUserRole !== ROLES.user){
        navigateToHomeWithError('Ihre Rolle gibt Ihnen keinen Zugriff auf diese Seite.');
        return;
      }
      this.changeNavigateBackUrl(returnUrl, 'personal');
    }

    if(fromOverview && returnUrl){
      if(currentUserRole !== ROLES.winnovation){
        navigateToHomeWithError('Ihre Rolle gibt Ihnen keinen Zugriff auf diese Seite.');
        return;
      }
      this.changeNavigateBackUrl(returnUrl, 'overview');
    }

    this.setState({ type }, async () => { // NOSONAR
      const breadcrumbs = {
        fromNews: 'newsletterPublic',
        fromArchive: 'newsletterArchive',
        fromDrafts: 'newsletterDrafts',
        fromPersonal: 'newsletterPersonal',
        fromOverview: 'newsletterOverview',
        default: 'newsletterRelease'
      };
      const breadcrumb = breadcrumbs[fromNews || fromArchive || fromDrafts || fromPersonal || fromOverview] || breadcrumbs.default;
      breadcrumbStore.setBreadcrumbs(breadcrumb, { id, type });

      newsletterStore.entityId = id;
      newsletterStore.entityType = type;

      try {
        await formatStore.getAll();
        this.setState({
          loading: false,
          userRole: currentUserRole,
          entityId: id
        });
      } catch (err) {
        openNotificationWithIcon('error', `Ein Fehler trat beim Laden des Beitrages auf!`);
      }
    });
  };

  checkSinglePossibleQueryParam = (possibleParams) => {
    let notNull = [];
    possibleParams.forEach(x => {
      if(x) notNull.push(x);
    });

    return notNull.length == 1;
  }

  onMetaLoaded = () => {
    let entity = this.props.newsletterStore.entity;
    if(this.state.navigateBackUrlType == 'drafts' && this.props.userStore.currentUser.id != entity.createdById){
      navigateToHomeWithError('Ein Fehler ist aufgetreten.');
    }
  }

  changeNavigateBackUrl = (returnUrl, type) => {
    this.setState({
      navigateBackUrl: returnUrl,
      navigateBackUrlType: type,
      reverseTypeMap: reverseMap(newsletterType),
    });
  }

  changeNewsletterState = async (id) => {
    let verb = this.determineVerbForNotification();

    try {
      if (this.state.type == newsletterType.event) {
        await this.props.newsletterStore.publishEvent(id, {
          ...this.formRef.current?.getFieldsValue(),
          state: this.state.modalType
            ? this.determineNewStateUp()
            : this.determineNewStateDown(),
        });
      } else {
        await this.props.newsletterStore.publishNews(id, {
          ...this.formRef.current?.getFieldsValue(),
          state: this.state.modalType
            ? this.determineNewStateUp()
            : this.determineNewStateDown(),
        });
      }
      openNotificationWithIcon('success', `Beitrag wurde ${verb}.`);
    } catch (error) {
      openNotificationWithIcon('error', `Beitrag konnte nicht ${verb} werden.`);
    }

    this.navigateBack();
  };

  determineNewStateUp = () => {
    const formValues = this.formRef.current?.getFieldsValue();
    switch(this.state.userRole){
      case ROLES.winnovation:
        if(formValues.faRelevant){
          return newsletterState.inProcessSD;
        }
        if(formValues.prRelevant){
          return newsletterState.inProcessPR;
        }
        return newsletterState.published;
      case ROLES.subjectDepartment:
        let state =
          formValues.prRelevant
            ? newsletterState.inProcessPR
            : newsletterState.published;
        return state;
      case ROLES.publicRelation:
        return newsletterState.published;
      default:
        return -2;
    }
  }

  determineNewStateDown = () => {
    switch(this.state.userRole){
      case ROLES.winnovation:
        return newsletterState.draft;
      case ROLES.subjectDepartment:
        return newsletterState.inProcessWin;
      case ROLES.publicRelation:
        return newsletterState.inProcessWin;
      default:
        return -2;
    }
  }

  determineVerbForNotification = () => { // NOSONAR
    const { userRole, modalType } = this.state;
    const { prRelevant, faRelevant } = this.formRef.current?.getFieldsValue() || {};
  
    if (userRole === ROLES.publicRelation) {
      return modalType ? 'veröffentlicht' : 'zurückgestuft';
    }
  
    if (userRole === ROLES.subjectDepartment) {
      if (modalType) {
        return prRelevant ? 'weitergestuft' : 'veröffentlicht';
      }
      return 'zurückgestuft';
    }
  
    if (userRole === ROLES.winnovation) {
      if (modalType) {
        return faRelevant || prRelevant ? 'weitergestuft' : 'veröffentlicht';
      }
      return 'zurückgestuft.';
    }
  };

  openModal = (type) => {
    this.setState({showModal: true, modalType: type});
  }

  closeModal = () => {
    this.setState({showModal: false})
  }

  navigateBack = () => {
    this.props.uiStore.navigateToReturnLink('newsletterPublishPage');
  };

  renderActionButtons = () => {
    let downGradeButton = (
      <Button
        className="btn-with-icon teal"
        type="primary"
        onClick={() => this.openModal(false)}
      >
        Zurückstufen
        <FontAwesomeIcon icon={['fas', 'feather-alt']} />
      </Button>
    );

    if (this.state.navigateBackUrlType == 'archive') {
      return (
        <>
          {downGradeButton}
          <Button
            type="primary"
            className="btn-with-icon yellow"
            onClick={() => this.deArchive(this.state.entityId)}
          >
            Archivierung aufheben <FontAwesomeIcon icon={['fas', 'inbox-out']} />
          </Button>
        </>
      );
      // don't render "Zurückstufen" Button here
    } else if (this.state.navigateBackUrlType == 'drafts') {
      return (
        <Button
          className="btn-with-icon yellow"
          type="primary"
          onClick={() => this.openModal(true)}
        >
          In Freigabe geben
          <FontAwesomeIcon icon={['fas', 'paper-plane']} className="icon-left" />
        </Button>
      );
    } else if(this.state.navigateBackUrlType == 'public' && this.state.userRole == ROLES.winnovation) {
      return downGradeButton;
    } else if (this.state.navigateBackUrlType == 'personal' || this.state.navigateBackUrlType == 'public' || this.state.navigateBackUrlType == 'overview' && (this.state.userRole == ROLES.subjectDepartment || this.state.userRole == ROLES.publicRelation)) {
      // this should be empty since no buttons should be rendered
      
    } else if (this.state.navigateBackUrlType == 'news') {
      return (
        <>
          {downGradeButton}
          <Button
            className="btn-with-icon yellow"
            type="primary"
            onClick={() => this.openModal(true)}
          >
            Weiterstufen / Veröffentlichen
            <FontAwesomeIcon icon={['fas', 'paper-plane']} className="icon-left" />
          </Button>
        </>
      );
    } else if (this.state.navigateBackUrlType == 'overview' && this.state.userRole == ROLES.winnovation) {
      // this should be empty since no buttons should be rendered
    } else {
      navigateToHomeWithError('Ein Fehler ist aufgetreten.');
    }
  }

  onOk = async (entity) => {
    if(this.state.navigateBackUrlType == 'drafts'){
      await this.upgradeDraft(entity);
    } else if(this.state.navigateBackUrlType == 'public'){
      await this.returnToDrafts(entity.id);
    } else if(this.state.navigateBackUrlType){
      await this.changeNewsletterState(entity.id);
    } else{
      navigateToHomeWithError('Ein Fehler ist aufgetreten.');
    }
  }

  returnToDrafts = async (id) => {
    try{
      await this.props.newsletterStore.returnEntityToDrafts(id, this.state.type, this.formRef.current?.getFieldsValue().comment, this.state.reverseTypeMap[this.state.type]);
      openNotificationWithIcon('success', 'Beitrag wurde zur Freigabe - Winnovation zurückgeschickt.');
    }
    catch(e){
      openNotificationWithIcon('error', 'Beitrag konnte nicht zur Freigabe - Winnovation zurückgeschickt werden.');
    }

    this.navigateBack();
  }

  deArchive = async (id) => {
    try{
      await this.props.newsletterStore.deArchive(id, this.state.type, this.state.reverseTypeMap[this.state.type]);
      openNotificationWithIcon('success', 'Beitrag wurde aus Archiv entfernt.');
    }
    catch(e){
      openNotificationWithIcon('error', 'Beitrag konnte nicht aus Archiv entfernt werden.');
    }

    this.navigateBack();
  }

  upgradeDraft = async () => {
    let entity = toJS(this.props.newsletterStore.entity);
    entity.clusterIds = [];
    entity.categoryIds = [];
    entity.cluster.forEach(x => entity.clusterIds.push(x.id));
    entity.categories.forEach(x => entity.categoryIds.push(x.id));
    try{
      await this.props.newsletterStore.upgradeDraft(entity.id, this.state.type, entity, {
        ...this.formRef.current?.getFieldsValue(),
        state: newsletterState.inProcessWin
      });
      openNotificationWithIcon('success', 'Beitrag wurde in den Freigabe gegeben!');
    }
    catch(e){
      let description = 'Überprüfen Sie den Beitrag auf Vollständigkeit.';
      let resp = e?.response;
      if(resp?.data?.errors){
        const errors = resp.data.errors;
        Object.keys(errors).some(x => {
          if(x.includes('Starting') && errors[x].some(y => y.includes('Zukunft'))){
            description = 'Startdatum muss in der Zukunft liegen.';
            return true;
          }
        })
      } else if(resp?.status == 400){
        description = resp.data.title;
      }
      openNotificationWithIcon('error', 'Beitrag konnte nicht in den Freigabeprozess gegeben werden.', {
        description: description,
        duration: 10
      });
    }

    this.navigateBack();
  }

  render() {
    if (this.state.loading) {
      return <Skeleton loading={this.state.loading} />;
    }

    let entity = this.props.newsletterStore.entity;

    return (
      <>
        {entity && (
          <div className="home">
            <div className="header">
              <h1 style={{ textAlign: 'left' }}>{entity.title}</h1>
              <div className="action-btns">
                <Button type="ghost" icon={<LeftOutlined />} onClick={this.navigateBack}>
                  Zurück
                </Button>
                {this.renderActionButtons()}
              </div>
            </div>
            {this.state.type == newsletterType.event ? (
              <LazyEventEntry
                entityId={this.state.entityId}
                returnUrl={this.state.navigateBackUrl}
                onMetaLoaded={this.onMetaLoaded}
                isProcessUrl={true}
                displayGallery={false}
                displayThumbnailTitle={true}
                showNavigator={false}
                addStyles={true}
              />
            ) : (
              <>
                <LazyNewsletterEntry
                  entityId={this.state.entityId}
                  returnUrl={this.state.navigateBackUrl}
                  onMetaLoaded={this.onMetaLoaded}
                  isProcessUrl={true}
                  displayGallery={true}
                  displayThumbnailTitle={true}
                  showNavigator={false}
                  addStyles={true}
                />
              </>
            )}
          </div>
        )}

        <Modal
          visible={this.state.showModal}
          onCancel={this.closeModal}
          onOk={() => this.onOk(entity)}
          title="Kommentar hinzufügen"
          okButtonProps={{ className: 'yellow' }}
        >
          <Form
            ref={this.formRef}
            initialValues={{
              faRelevant: entity.isFARelevant,
              prRelevant: entity.isPRRelevant,
            }}
          >
            <Form.Item id="comment" name="comment" label="Kommentar">
              <TextArea />
            </Form.Item>
            {this.state.navigateBackUrlType !== 'drafts' && (
              <>
                {this.state.userRole == ROLES.winnovation && this.state.modalType && (
                  <Form.Item
                    name="faRelevant"
                    label="FA-Relevanz"
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                )}
                {this.state.userRole == ROLES.publicRelation ||
                  (this.state.modalType && (
                    <Form.Item
                      name="prRelevant"
                      label="ÖA-Relevanz"
                      valuePropName="checked"
                    >
                      <Checkbox />
                    </Form.Item>
                  ))}
              </>
            )}
          </Form>
        </Modal>
      </>
    );
  }
}
