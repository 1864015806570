import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import React from 'react';
import BaseEntityAdministration from 'components/Administrationsansicht/BaseEntityAdministration';
import { Skeleton } from 'antd';

@inject('categoryStore')
@observer
export default class CategoryAdministration extends React.Component {

  state = {
    loading: true
  }

  componentDidMount(){
    this.props.categoryStore.isSpecificationPage = false;
    this.setState({loading: false});
  }

  render() {
    if(this.state.loading) return <Skeleton loading={this.state.loading} />;
    return (
      <BaseEntityAdministration
        store={this.props.categoryStore}
        breadcrumbsRoute={'newsletterCategories'}
        entityName={'Kategorie'}
        headerTitle={'Kategorienverwaltung'}
        headerIcon={<FontAwesomeIcon icon={['fas', 'cubes']} className="header-icon" />}
      />
    );
  }
}

