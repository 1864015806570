import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseEntityAdministration from './BaseEntityAdministration';
@inject('clusterClassStore')
@observer
export default class Klassenverwaltung extends Component {
  render(){
    return <BaseEntityAdministration 
      store={this.props.clusterClassStore}
      breadcrumbsRoute={"klassenverwaltung"}
      entityName={"Klasse"}
      headerTitle={"Klassenverwaltung"}
      headerIcon={<FontAwesomeIcon icon={['fas', 'boxes']} className="header-icon" />}
    />
  }
}
