import axios from 'axios';
import { isProduction } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class Nuts3Store extends BaseEntitiesStore {
  constructor() {
    super('Nuts3', 'Nuts3Store');
    if (!isProduction) {
      window[`Nuts3Store`] = this;
    }
  }

  async getNuts3ByPostalCode(postalCode) {
    return (await axios.get(`${this.path}/regionalCode/${postalCode}`)).data;
  }
}

export default new Nuts3Store();
