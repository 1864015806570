import './Benutzerverwaltung.scss';
import { Button, Form, Input } from 'antd';
import React from 'react';
import { inject } from 'mobx-react';
import { openNotificationWithIcon } from 'utils/functions';
import PasswordSetterComponent from 'components/Shared/PasswordSetterComponent';

@inject('userStore', 'uiStore')
class ResetPasswordPage extends React.Component {

  formRef = React.createRef();

  updatePassword = async (values) => {
    try {
      await this.props.userStore.setNewUserPassword(values);
      openNotificationWithIcon('success', 'Ihr neues Passwort wurde gesetzt.');
      this.props.uiStore.navigateTo('/');
    } catch (ex) {
      openNotificationWithIcon('error', 'Ein Fehler ist aufgetreten.');
    }
  };

  render() {
    let user = this.props.userStore.currentUser;
    return (
      <>
        <div className="user-info-form-container">
          <h1>Sehr geehrte/r/s {user.fullname}!</h1>
          <p>
            Wir bitten Sie Ihr Passwort neu zu vergeben. Dies dauert nur wenige Minuten.
          </p>
          <Form
            ref={this.formRef}
            className="user-info-form"
            layout="vertical"
            size="large"
            labelAlign="left"
            onFinish={this.updatePassword}
            autoComplete="off"
            initialValues={{ id: this.props.userStore.currentUser.id }}
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <PasswordSetterComponent form={this.formRef} />
          </Form>
        </div>
        <div className="user-info-button-container">
          <Button
            className="user-info-button yellow"
            onClick={() => this.formRef.current?.submit()}
            type="primary"
          >
            Neues Passwort festlegen
          </Button>
        </div>
      </>
    );
  }
}

export default ResetPasswordPage;
