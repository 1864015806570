import React, { Component } from 'react';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import './PowerBiPage.scss';

import * as pbi from 'powerbi-client';

import { Tabs } from 'antd';
const { TabPane } = Tabs;
import ClusterTable from './ClusterTable';

@inject('powerBiStore', 'breadcrumbStore')
@observer
export default class PowerBiPage extends Component {
  state = {
    report: null,
  };

  componentDidMount() {
    this.props.powerBiStore.fetchReportConfig().then((x) => {
      let config = {
        type: 'report',
        tokenType: 1,
        accessToken: x.embedToken.token,
        embedUrl: x.embedUrl,
        id: x.id,
        settings: {
          filterPaneEnabled: false,
          navContentPaneEnabled: true,
        },
      };
      if(this.refs.reportContainer){
        this.setState({ report: powerbi.embed(this.refs.reportContainer, config) });
      }
    });
    this.props.breadcrumbStore.setBreadcrumbs('clusterkarte');
  }

  selectReportPage = (event, selectByName) => {
    if (selectByName) {
      let report = this.state.report;

      report.getPages().then((pages) => {
        var filtered = pages.filter((x) => x.displayName === 'DESI - Connectivity');
        if (filtered.length === 1) {
          filtered[0].setActive();
        }
      });
    }
  };

  render() {
    let report = this.state.report;

    if (report && report !== null) {
      report.on('loaded', this.selectReportPage);
    }

    return (
      <Tabs defaultActiveKey="1" type="card">
        <TabPane tab="Clusterkarte" key="1">
          <div ref="reportContainer" id="reportContainer" style={{ height: '85vh' }}>
            <div>
              <h3>Verbindung zu PowerBi wird hergestellt...</h3>
            </div>
          </div>
        </TabPane>
        <TabPane tab="Clusterkarte-Tabelle" key="2">
          <ClusterTable/>
        </TabPane>
      </Tabs>
    );
  }
}
