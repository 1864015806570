import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BaseEntityAdministration from './BaseEntityAdministration';

@inject('clusterHolderStore')
@observer
export default class Traegerorganistationsverwaltung extends Component {
  render() {
    return <BaseEntityAdministration
      store={this.props.clusterHolderStore}
      breadcrumbsRoute={"traegerorganisationsverwaltung"}
      entityName={"Trägerorganisation"}
      headerTitle={"Trägerorganisationsverwaltung"}
      headerIcon={<FontAwesomeIcon icon={['far', 'building']} className="header-icon" />}
    />;
  }
}
