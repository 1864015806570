import { inject, observer } from 'mobx-react';
import React from 'react';
import { Route, withRouter } from 'react-router';

import { Layout } from 'components/Layout/Layout';
import { passwordStates, ROLES } from 'App/globalVar';

import ClusterOverview from '../Clusterverwaltung/ClusterOverview';
import UserOverview from 'components/Benutzerverwaltung/UserOverview';
import Branchenverwaltung from '../Administrationsansicht/Branchenverwaltung';
import RoleAuthRoute from '../api-authorization/RoleAuthRoute';
import PowerBiPage from '../PowerBi/PowerBiPage'
import PageAdministration from '../Seitenverwaltung/PageAdministration';
import NewsletterTable from '../Newsletter/NewsletterTable';
import Homepage from '../Home/Homepage';
import Newsletter from '../Home/Newsletter';
import DraftTable from '../Newsletter/DraftTable';
import CategoryAdministration from '../Administrationsansicht/CategoryAdministration';
import Calendar from '../Home/Calendar';
import Event from '../Home/Event';
import PublishPage from '../Newsletter/PublishPage';
import PublicTable from 'components/Newsletter/PublicTable';
import ArchiveTable from 'components/Newsletter/ArchiveTable';
import PersonalTable from 'components/Newsletter/PersonalTable';
import ClusterForm from 'components/Clusterverwaltung/ClusterForm';
import Traegerorganistationsverwaltung from 'components/Administrationsansicht/Traegerorganisationsverwaltung';
import Beitragsartverwaltung from 'components/Administrationsansicht/Beitragsartverwaltung';
import Klassenverwaltung from 'components/Administrationsansicht/Klassenverwaltung';
import ClusterArchiveTable from 'components/Clusterverwaltung/ClusterArchiveTable';

import UserInformationForm from 'components/Benutzerverwaltung/UserInformationForm';
import ResetPasswordPage from 'components/Benutzerverwaltung/ResetPasswordPage';
import DraftsTable from 'components/Clusterverwaltung/DraftsTable';
import ApproverTable from 'components/Clusterverwaltung/ApproverTable';
import EmailTemplates from 'components/Administrationsansicht/EmailTemplates';
import OverviewTable from 'components/Newsletter/OverviewTable';

@inject('userStore', 'uiStore')
@observer
class AppRouter extends React.Component {
  render() {
    if(!this.props.userStore.userLoaded) return <></>;

    let currentUser = this.props.userStore.currentUser;
    if(Object.keys(currentUser).length > 0 && currentUser.passwordState == passwordStates.passwordNeverSet){
      return (
        <RoleAuthRoute exact path="/validate-user-data" component={UserInformationForm} />
      )
    } else if(Object.keys(currentUser).length > 0 && currentUser.passwordState == passwordStates.passwordReset){
      return (
        <RoleAuthRoute exact path="/reset-password" component={ResetPasswordPage} />
      );
    }

    return (
        <Layout>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/:hash/uptodate" component={Homepage} />
            <Route exact path="/:hash/notuptodate" component={ClusterForm} />
            <Route exact path="/clusterplattform-oesterreich" component={Homepage} />
            <Route exact path="/clusterplattform-oesterreich/:id" component={Newsletter} />
            <Route exact path="/calendar" component={Calendar} />
            <Route exact path="/calendar/:id" component={Event} />
            <Route exact path="/clusterkarte" component={PowerBiPage} /> 
            <RoleAuthRoute exact path="/clusterverwaltung" component={ClusterOverview} roles={[ROLES.admin]} />
            <RoleAuthRoute exact path="/clusterverwaltung/:id" component={ClusterForm} roles={[ROLES.admin, ROLES.winnovation, ROLES.user]} />
            <RoleAuthRoute exact path="/cluster-aktualisierung/:clusterId" component={ClusterForm} roles={[ROLES.admin, ROLES.user]} />
            <RoleAuthRoute exact path="/cluster-drafts" component={DraftsTable} roles={[ROLES.admin, ROLES.user]} />
            <RoleAuthRoute exact path="/cluster-archive" component={ClusterArchiveTable} roles={[ROLES.admin]} />
            <RoleAuthRoute exact path="/approve-overview" component={ApproverTable} roles={[ROLES.admin, ROLES.winnovation]} />
            <RoleAuthRoute exact path="/cluster-form" component={ClusterForm} roles={[ROLES.admin, ROLES.user]} />
            <RoleAuthRoute exact path="/branchenverwaltung" component={Branchenverwaltung} roles={[ROLES.admin]} />
            <RoleAuthRoute exact path="/benutzerverwaltung" component={UserOverview} roles={[ROLES.admin]} />
            <RoleAuthRoute exact path="/verwaltung/:page" component={PageAdministration} roles={[ROLES.admin]} />
            <RoleAuthRoute exact path="/newsletter/release" component={NewsletterTable} roles={[ROLES.winnovation, ROLES.subjectDepartment, ROLES.publicRelation]} />
            <RoleAuthRoute exact path="/newsletter/drafts" component={DraftTable} roles={[ROLES.user, ROLES.winnovation]} />
            <RoleAuthRoute exact path="/newsletter/categories" component={CategoryAdministration} roles={[ROLES.admin]} />
            <RoleAuthRoute exact path="/newsletter/public" component={PublicTable} roles={[ROLES.winnovation, ROLES.subjectDepartment, ROLES.publicRelation]} />
            <RoleAuthRoute exact path="/newsletter/archive" component={ArchiveTable} roles={[ROLES.winnovation]} />
            <RoleAuthRoute exact path="/newsletter/personal" component={PersonalTable} roles={[ROLES.user, ROLES.winnovation]} />
            <RoleAuthRoute exact path="/newsletter/overview" component={OverviewTable} roles={[ROLES.winnovation]} />
            <RoleAuthRoute exact path="/newsletter/:id/:type" component={PublishPage} roles={[ROLES.user, ROLES.winnovation, ROLES.subjectDepartment, ROLES.publicRelation]} />
            <RoleAuthRoute exact path="/traegerorganisationsverwaltung" component={Traegerorganistationsverwaltung} roles={[ROLES.admin]}/>
            <RoleAuthRoute exact path="/beitragsartverwaltung" component={Beitragsartverwaltung} roles={[ROLES.admin]}/>
            <RoleAuthRoute exact path="/klassenverwaltung" component={Klassenverwaltung} roles={[ROLES.admin]}/>
            <RoleAuthRoute exact path="/email-templates" component={EmailTemplates} roles={[ROLES.admin]} />
        </Layout>
  );
  }

  async componentDidMount() {
    await this.props.userStore.getUserInformation();
    this.props.userStore.userLoaded = true;
    let currentUser = this.props.userStore.currentUser;
    if(Object.keys(currentUser).length > 0 && currentUser.passwordState == passwordStates.passwordNeverSet){
      this.props.uiStore.navigateTo('/validate-user-data');
    } else if(Object.keys(currentUser).length > 0 && currentUser.passwordState == passwordStates.passwordReset){
      this.props.uiStore.navigateTo('/reset-password');
    }
    this.forceUpdate();
  }
}

export default withRouter(AppRouter);
