import React, { Component, createRef } from 'react';
import { observer, inject } from 'mobx-react';

// importing styles and helpers
import "../Modals/Modals.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// importing components
import { Button, Form, Input, Select, Modal } from 'antd';
import { openNotificationWithIcon, validateFirstLetterUppercase } from 'utils/functions';
import { academicTitlesRegex, genders, wordsOnlyRegex, translationMap } from 'App/globalVar';
import { map } from 'lodash-es';
import PhoneNumberFormItem from 'components/Shared/PhoneNumberFormItem';

@inject('userStore')
@observer
export default class UserModal extends Component {
  formRef = createRef();

  onFinish = async () => {
    try {
    await this.formRef.current?.validateFields();
    let values = this.formRef.current?.getFieldsValue();
      if (this.props.isEdit) {
        await this.props.userStore.put(values.id, values);
        openNotificationWithIcon(
          'success',
          'Benutzer wurde erfolgreich bearbeitet!'
        );
      } else {
        // for some reason setting value to null / something other than a string sets id to 0
        // this throws an error in the BE
        values.id = 'overwrite';
        await this.props.userStore.post(values);
        openNotificationWithIcon(
          'success',
          'Benutzer wurde erfolgreich erstellt!'
        );
      }
      this.props.handleSavingDirtyForm(true);
      this.props.setUserModalVisible(false, false, this.formRef.current?.getFieldsValue());
    } catch (ex) {
      let notificiationText =
        'Es ist ein unerwarteter Fehler beim Speichern aufgetreten';
      if (ex.response?.status === 409) {
        notificiationText =
          'Ein Benutzer mit dieser Email existiert bereits!';
      }
      openNotificationWithIcon('error', notificiationText);
    }
  }

  render() {
    const translatedRolesWithId = map(this.props.userStore.roles, role => {
      return {
        id: role.id,
        name: role.normalizedName,
        translatedName: translationMap[role.normalizedName]
      }
    });
    return (
      <Modal
        className="app-modal"
        destroyOnClose
        visible={this.props.visible}
        onCancel={() => {
          this.props.setUserModalVisible(
            false,
            false,
            this.formRef.current?.getFieldsValue()
          );
        }}
        title={this.props.isEdit ? 'Benutzer bearbeiten' : 'Benutzer anlegen'}
        footer={[
          <Button
            key="cancel"
            onClick={() =>
              this.props.setUserModalVisible(
                false,
                false,
                this.formRef.current?.getFieldsValue()
              )
            }
            type="primary"
            className="teal"
          >
            Abbrechen{' '}
            <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon rotate" />
          </Button>,
          <Button
            key="submit"
            type="primary"
            className="yellow"
            onClick={this.onFinish}
          >
            {this.props.isEdit ? 'Benutzer bearbeiten' : 'Benutzer anlegen'}{' '}
            <FontAwesomeIcon icon={['fas', 'plus']} className="btn-icon" />
          </Button>,
        ]}
      >
        <div className="modal-content">
          <div className="content-form">
            <Form
              layout="horizontal"
              size="large"
              labelCol={{ span: 10 }}
              labelAlign="left"
              ref={this.formRef}
              initialValues={this.props.userStore.entity}
              autoComplete="off"
            >
              <div className="content-title">
                <h2>Benutzerdaten</h2>
              </div>
              <Form.Item hidden name={'id'}>
                <Input type="hidden" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    type: 'email',
                    required: true,
                    message: 'Bitte geben Sie eine valide Email-Adresse an.',
                  },
                ]}
                normalize={(value, prevVal, prevVals) => value.trim()}
              >
                <Input placeholder="max.mustermann@mail.com" autoComplete="new-password" />
              </Form.Item>
              <Form.Item
                name="role"
                label="Rolle"
                rules={[
                  {
                    required: true,
                    message: 'Bitte wählen Sie eine Rolle aus.',
                  },
                ]}
              >
                <Select placeholder="Rolle">
                  {map(translatedRolesWithId, (item) => (
                    <Select.Option key={item.id} value={item.name}>
                      {item.translatedName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="content-title">
                <h2>Zusätzliche Benutzerdaten</h2>
              </div>
              <Form.Item
                name="gender"
                label="Geschlecht"
                rules={[
                  {
                    required: true,
                    message: 'Bitte geben Sie ein Geschlecht an.',
                  },
                ]}
              >
                <Select
                  placeholder="Geschlecht"
                  showSearch
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {map(genders, (item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name={'title'}
                label="Akademischer Titel"
                rules={[
                  {
                    validator: validateFirstLetterUppercase,
                    pattern: academicTitlesRegex,
                    message:
                      'Der akademische Titel enthält nicht erlaubte Zeichen oder beginnt nicht mit einem Großbuchstaben.',
                  },
                  { max: 30, message: 'Der akademische Titel darf maximal 30 Zeichen haben.' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name={'givenname'}
                label="Vorname"
                rules={[
                  {
                    required: true,
                    message: 'Bitte geben Sie einen Vornamen an.',
                  },
                  {
                    pattern: wordsOnlyRegex,
                    message: 'Der Vorname darf nur aus Buchstaben bestehen.',
                  },
                ]}
              >
                <Input placeholder="Max" autoComplete="new-password"/>
              </Form.Item>
              <Form.Item
                name={'surname'}
                label="Nachname"
                rules={[
                  {
                    required: true,
                    pattern: wordsOnlyRegex,
                    message: 'Bitte geben Sie einen Nachnamen an.',
                  },
                  {
                    pattern: wordsOnlyRegex,
                    message: 'Der Nachname darf nur aus Buchstaben bestehen.',
                  },
                ]}
              >
                <Input placeholder="Mustermann" autoComplete="new-password"/>
              </Form.Item>
              <PhoneNumberFormItem 
                name="phoneNumber"
                label="Telefon"
                required={true}
              />
              <PhoneNumberFormItem 
                name="mobilePhoneNumber"
                label="Telefon 2"
              />
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
}
