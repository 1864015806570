import React from 'react';
import { Form, Input } from 'antd';
import { passwordRegex } from 'App/globalVar';

class PasswordSetterComponent extends React.Component {
  state = {
    passwordVisible: false,
    repeatPasswordVisible: false,
  }

  setPasswordVisible = (value, isRepeat) => {
    if (isRepeat) {
      this.setState({ repeatPasswordVisible: value });
    } else {
      this.setState({ passwordVisible: value });
    }
  };

  onChange = (pw, type) => {
    let { form, property } = this.props;
    if(!property || typeof property == 'string'){
      if(type == 'repeat'){
        form.current?.setFieldsValue({ ['repeatPassword']: pw});
      }
      else{
        form.current?.setFieldsValue({ ['password']: pw});
      } 
    } else if(!!property) {
      let obj = {};
      obj[property[0]] = {};
      if(type == 'repeat'){
        Object.values(obj)[0]['repeatPassword'] = pw;
      } else{
        Object.values(obj)[0]['password'] = pw;
      }

      form.current?.setFieldsValue(obj);
    }
  }

  render() {
    return (
      <>
        <Form.Item
          name={'password'}
          label="Neues Passwort"
          rules={[
            { required: true, message: 'Bitte geben Sie ein neues Passwort an.' },
            {
              pattern: passwordRegex,
              message: (
                <p>
                  Ihr Passwort muss eine Mindestlänge von 6 Zeichen besitzen. Außerdem
                  muss es mindestens:
                  <ul>
                    <li>1 Kleinbuchstaben</li>
                    <li>1 Großbuchstaben</li>
                    <li>1 Ziffer</li>
                    <li>1 Sonderzeichen (!@#$%^&*_=+-.)</li>
                  </ul>
                  beinhalten.
                </p>
              ),
            },
          ]}
        >
          <Input.Password
            type="password"
            placeholder="Neues Passwort"
            onChange={(e)  => this.onChange(e.target.value, 'password')}
            visibilityToggle={{
              visible: this.state.passwordVisible,
              onVisibleChange: (value) => this.setPasswordVisible(value, false),
            }}
          />
        </Form.Item>
        <Form.Item
          name={'repeatPassword'}
          label="Passwort wiederholen"
          rules={[
            { required: true, message: 'Bitte wiederholen Sie Ihr neues Passwort.' },
            {
              validator: (_, value) => {
                if (value == this.props.form?.current?.getFieldsValue().password) {
                  return Promise.resolve();
                } else {
                  return Promise.reject('Die Passwörter stimmen nicht überein.');
                }
              },
            },
          ]}
        >
          <Input.Password
            type="password"
            placeholder="Passwort wiederholen"
            onChange={(e) => this.onChange(e.target.value, 'repeat')}
            visibilityToggle={{
              visible: this.state.repeatPasswordVisible,
              onVisibleChange: (value) => this.setPasswordVisible(value, true),
            }}
          />
        </Form.Item>
      </>
    );
  }
}

export default PasswordSetterComponent;
