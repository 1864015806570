import { isProduction, apiUrl } from '../globalVar';
import BaseEntitiesStore from './BaseEntitiesStore';

class EmailTemplateStore extends BaseEntitiesStore {
  constructor() {
    super('emailTemplate', 'EmailTemplateStore');
    if (!isProduction) {
      window[`EmailTemplateStore`] = this;
    }
  }
}

export default new EmailTemplateStore();