import React from 'react';

import { Row, Skeleton } from 'antd';
import { inject } from 'mobx-react';
import DOMPurify from 'dompurify';

@inject('uiStore', 'formatStore')
export default class EventContentText extends React.Component {

  render() {
    if (this.props.uiStore.loading) return <Skeleton active />;

    let event = this.props.event;

    return (
      <div className="body-text">
        <Row>
          <h2 className="entry-title">{event.title}</h2>
        </Row>
        <Row>
          <p
            className="text-ellipsis"
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.content?.teaser) }}
          ></p>
          {this.props.addStyles && (
            <>
              <p
                className="text-ellipsis"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(event.content?.body) }}
              ></p>
            </>
          )}
        </Row>
      </div>
    );
  }
}
