import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { onEnterKey } from 'utils/functions';
import HeadbarModal from './HeadbarModal';

@inject('pageAdministrationStore')
@observer
export class ExternalLinks extends Component {
  state = {
    showModal: false
  };

  closeModal = () => {
    this.setState({ showModal: false });
  }

  openModal = async (page, that) => {
    if(!that) that = this;

    await this.props.pageAdministrationStore.getByName(page);
    this.setState({ showModal: true });
  }

  render() {
    const entity = this.props.pageAdministrationStore.entity;
    return (
      <div className="external-links">
        <ul>
          <li>
            <a
              tabIndex="0"
              className="selectable-external-link"
              onClick={async () => await this.openModal('datenschutz')}
              onKeyDown={async (e) =>
                await onEnterKey(e, this.openModal, ['datenschutz', this])
              }
            >
              Datenschutz
            </a>
          </li>
          <li>
            <a
              tabIndex="0"
              className="selectable-external-link"
              onClick={async () => await this.openModal('hilfe')}
              onKeyDown={async (e) =>
                await onEnterKey(e, this.openModal, ['hilfe', this])
              }
            >
              Hilfe
            </a>
          </li>
          <li>
            <a
              tabIndex="0"
              className="selectable-external-link"
              onClick={async () => await this.openModal('impressum')}
              onKeyDown={async (e) =>
                await onEnterKey(e, this.openModal, ['impressum', this])
              }
            >
              Impressum
            </a>
          </li>
          <li>
            <a
              tabIndex="0"
              className="selectable-external-link"
              onClick={async () => await this.openModal('barrierefreiheit')}
              onKeyDown={async (e) =>
                await onEnterKey(e, this.openModal, ['barrierefreiheit', this])
              }
            >
              Barrierefreiheit
            </a>
          </li>
        </ul>

        {this.state.showModal && (
          <HeadbarModal
            visible={this.state.showModal}
            closeModal={this.closeModal}
            entity={entity}
          />
        )}
      </div>
    );
  }
}
