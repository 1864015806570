import React from 'react';
import { inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Button, Tag, Row } from 'antd';
import { map } from 'lodash-es';
import { createEmptyScreenreaderSpan, normalizeUrl } from '../../../../utils/functions';
import dayjs from 'dayjs';
import { dateFormat } from '../../../../utils/dayjsLocale';
import { getAriaLabelDate } from 'utils/aria-date';

@inject('newsletterStore', 'uiStore', 'formatStore', 'breadcrumbStore')
export default class EventFooter extends React.Component {
  state = {
    linkTooltipText: 'Link kopieren',
  };

  getClusterTags(event) {
    return (
      <div className="tags-list-container" role="list" aria-label="Cluster">
        {map(event.cluster, (cluster) => (
          <Tag
            className="tag-spacing cluster"
            role="listitem"
            aria-label={cluster.name}
            key={cluster.id}
          >
            <span className="tag-text">{cluster.name}</span>
          </Tag>
        ))}
      </div>
    );
  }

  getCategoriesTags(event) {
    return (
      <div className="tags-list-container" role="list" aria-label="Kategorien">
        {map(event.categories, (categories) => (
          <Tag
            className="tag-spacing category"
            role="listitem"
            aria-label={categories.name}
            key={categories.id}
          >
            <span className="tag-text">{categories.name}</span>
          </Tag>
        ))}
      </div>
    );
  }

  onCopyLinkButton = (id) => {
    let addedId = this.props.addIdToLink ? '/' + id : '';
    navigator.clipboard.writeText(`${window.location.href}${addedId}`);
    this.setState({
      linkTooltipText: 'Link kopiert!',
    });
  };

  onReadMoreButton = (link) => {
    window.open(normalizeUrl(link), '_blank');
  };

  render() {
    let event = this.props.event;

    return (
      <div className="whole-footer">
        <Row className="card-footer">
          <div className="card-categories">
            <Tooltip title="Kategorie(n)">
              <FontAwesomeIcon icon={['far', 'tags']} />
            </Tooltip>
            {this.getCategoriesTags(event)}
          </div>
          <div className="card-clusters">
            <Tooltip title="Cluster">
              <FontAwesomeIcon icon={['far', 'thumbtack']} />
            </Tooltip>
            {this.getClusterTags(event)}
          </div>
          {event.link ? (
            <Button
              type="text"
              className="footer-more-info-btn"
              aria-label="Mehr lesen"
              onClick={(e) => {
                e.stopPropagation();
                this.onReadMoreButton(event?.link);
              }}
            >
              <span style={{ paddingRight: '10px' }}>Mehr lesen</span>
              <FontAwesomeIcon icon={['far', 'info']} />
            </Button>
          ) : (
            <></>
          )}
          {event?.registrationLink ? (
            <Button
              className="footer-reg-btn teal"
              onClick={() =>
                window.open(
                  normalizeUrl(event.registrationLink),
                  '_blank',
                  'noopener,noreferrer'
                )
              }
              type="primary"
            >
              <span style={{ paddingRight: '10px', marginBottom: '0px' }}>
                Zur Anmeldung
              </span>
              <FontAwesomeIcon icon={['far', 'pencil']} />
            </Button>
          ) : (
            <></>
          )}
        </Row>
        <div className="footer-pub-dates">
          <div className="publishing-dates">
            <div className="published">
              <Tooltip title="Erscheinungsdatum">
                <FontAwesomeIcon icon={['far', 'clock']} />
                {createEmptyScreenreaderSpan(
                  `Erscheinungsdatum: ${getAriaLabelDate(event?.publishingDate)}`,
                  '',
                  'note'
                )}
                <span aria-hidden="true">
                  {event.publishingDate
                    ? dayjs(event.publishingDate).format(dateFormat)
                    : 'nicht veröffentlicht'}
                </span>
              </Tooltip>
            </div>
            <div className="author">
              <Tooltip title="Autor">
                <FontAwesomeIcon icon={['far', 'user']} />
                {createEmptyScreenreaderSpan('Autor', '', 'note')}
                {event.author ?? 'Keine Angabe'}
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
