import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

// IMPORTING STYLES & HELPERS
import './News.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Skeleton, Button } from 'antd';
import {
  getFileName,
  openNotificationWithIcon,
} from '../../utils/functions';
import { saveAs } from 'file-saver';
import LazyNewsletterEntry from './ContentParts/LazyNewsletterEntry';
import { newsletterType, ROLES } from 'App/globalVar';

@inject('newsletterStore', 'uiStore', 'breadcrumbStore', 'userStore')
@observer
export default class Newsletter extends Component {
  state = {
    exporting: false,
    entityId: null
  };

  componentDidMount = async () => {
    let id = this.props.match.params.id;
    this.props.newsletterStore.entityId = id;
    this.props.newsletterStore.entityType = newsletterType.newsletter;
    this.setState({entityId: id});
  };

  onMetaLoaded = () => {
    let entity = this.props.newsletterStore.entity;
    this.props.breadcrumbStore.setBreadcrumbs('clusterplattform-oesterreich', {
      id: entity.id,
      name: entity.title,
    });
  }

  onReturnButton = () => {
    this.props.uiStore.navigateTo('/clusterplattform-oesterreich');
    this.props.newsletterStore.entity = {};
  };

  componentWillUnmount = () => {
    this.props.newsletterStore.entity = {};
  };

  onExportNewsletter = async (id) => {
    this.setState({ exporting: true });
    try {
      let resp = await this.props.newsletterStore.exportNewsletter(
        id,
        this.props.newsletterStore.obj.filter,
        this.props.newsletterStore.obj.searcher,
        this.props.newsletterStore.obj.sorter,
        this.props.newsletterStore.obj.userString
      );
      var name = getFileName(resp, 'Testergebnis.pdf');
      saveAs(resp.data, name);
      openNotificationWithIcon('success', `Newsbeitrag wurde erfolgreich exportiert.`);
    } catch (e) {
      openNotificationWithIcon('error', `Newsbeitrag konnte nicht exportiert werden!`);
    }
    this.setState({ exporting: false });
  };

  render() {
    if(this.state.entityId == null){
      return <Skeleton active />;
    }

    let newsLetter = this.props.newsletterStore.entity;
    let currentUser = this.props.userStore.currentUser;
    return (
      <div className="home">
        <div className="header">
          <h1 className="title">{newsLetter?.title}</h1>
          <div className="action-btns">
            {Object.keys(currentUser).length > 0 && currentUser.role != ROLES.user && (
              <Button
                type="ghost"
                className="btn-with-icon secondary-button"
                disabled={this.state.exporting}
                loading={this.state.exporting}
                onClick={() => this.onExportNewsletter(newsLetter?.id)}
              >
                Export <FontAwesomeIcon icon={['fas', 'file-export']} />
              </Button>
            )}
            <Button type="primary" onClick={this.onReturnButton}>
              <FontAwesomeIcon
                icon={['fas', 'arrow-alt-to-left']}
                className="icon-left"
              />
              Zurück zu den Newsbeiträgen
            </Button>
          </div>
        </div>
        <LazyNewsletterEntry
          entityId={this.state.entityId}
          returnUrl={'/clusterplattform-oesterreich'}
          onMetaLoaded={this.onMetaLoaded}
          isProcessUrl={false}
          addIdToLink={false}
          displayGallery={true}
          displayThumbnailTitle={true}
          showTeaser={false}
          showNavigator={true}
          addStyles={true}
        />
      </div>
    );
  }
}
